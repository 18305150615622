import { useMemo } from 'react';
import { ArrayPath, FieldValues, Path, PathValue, Validate } from 'react-hook-form';
import { t } from 'utils/getTranslations';
import { stringIsNotWhitespaceOnly } from '../rhf-validators/validators';

type UseMemoizedRulesProps<T extends FieldValues, TPath extends Path<T> | ArrayPath<T> = Path<T>> = {
    required?: boolean;
    validate?: Validate<PathValue<T, TPath>, T> | Record<string, Validate<PathValue<T, TPath>, T>> | undefined;
};

export const useMemoizedRules = <T extends FieldValues, TPath extends Path<T> | ArrayPath<T>>({
    required,
    validate,
}: UseMemoizedRulesProps<T, TPath>) => {
    return useMemo(() => {
        const originalValidationRules = typeof validate === 'function' ? { default: validate } : validate;
        return {
            required: { value: required ?? false, message: t('validation.required-field') },
            validate: {
                ...originalValidationRules,
                ...(required ? { whitespaces: stringIsNotWhitespaceOnly(t('validation.required-field')) } : {}),
            },
        };
    }, [required, validate]);
};

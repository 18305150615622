import { portalAPI } from './portalAPI';
import { Company, CorporateRoleFilling, UpdateCorporateRoleFillingDto } from 'types/companies';
import { accumulatedQueryWithFilters } from './util/endpointBuilder';

export const APICompaniesSlice = portalAPI
    .enhanceEndpoints({ addTagTypes: ['Companies', 'CompaniesMin'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCompaniesList: builder.query<Company[], void>({
                query: () => '/v1/companies/min',
                providesTags: ['CompaniesMin'],
            }),
            getCompaniesWithFilters: accumulatedQueryWithFilters<Company>(builder, '/v1/companies', {
                providesTags: ['Companies'],
            }),
            getCompanyById: builder.query<Company, number>({
                query: (entityId) => `/v1/companies/${entityId}`,
                providesTags: (_1, _2, arg) => [{ type: 'Companies', id: arg }],
            }),
            getCorporateRoleFillingList: builder.query<CorporateRoleFilling[], void>({
                query: () => '/v1/companies/corporate-role-fillings',
                keepUnusedDataFor: 0,
            }),
            updateCorporateRoleFillings: builder.mutation<CorporateRoleFilling[], UpdateCorporateRoleFillingDto[]>({
                query: (body) => ({
                    url: '/v1/companies/corporate-role-fillings',
                    method: 'PUT',
                    body,
                }),
            }),
        }),
        overrideExisting: false,
    });

export const {
    useGetCompaniesListQuery,
    useGetCompanyByIdQuery,
    useLazyGetCompaniesWithFiltersQuery,
    useGetCorporateRoleFillingListQuery,
    useUpdateCorporateRoleFillingsMutation,
} = APICompaniesSlice;

import { useCallback, useEffect } from 'react';
import { BlockerFunction, useBlocker } from 'react-router';

import ConfirmationModal from 'components/modals/confirmation/ConfirmationModal';
import { useModal } from 'components/modals/useModal';
import { t } from 'utils/getTranslations';
import { Control, FieldValues, useFormState } from 'react-hook-form';

type Props<T extends FieldValues> = {
    control: Control<T>;
    message?: string;
    titleTextId?: string;
    confirmTextId?: string;
    cancelTextId?: string;
};

const ConfirmNavigation = <T extends FieldValues>({
    control,
    titleTextId = 'confirm-navigation-modal.title',
    message = t('confirm-navigation-modal.default-message'),
    confirmTextId = 'confirm-navigation-modal.yes',
    cancelTextId = 'confirm-navigation-modal.no',
}: Props<T>) => {
    const { openModal, closeModal } = useModal();
    const { isDirty } = useFormState({ control });

    const shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
        [isDirty]
    );
    const blocker = useBlocker(shouldBlock);
    const isBlockedState = blocker.state === 'blocked';

    const handleRouteChange = useCallback(
        (event: BeforeUnloadEvent) => {
            if (isDirty) {
                event.preventDefault();
            }
            return message;
        },
        [isDirty, message]
    );

    useEffect(() => {
        if (isBlockedState && !isDirty) {
            window.removeEventListener('beforeunload', handleRouteChange);
            blocker.reset?.();
        } else {
            window.addEventListener('beforeunload', handleRouteChange);
        }

        return () => {
            window.removeEventListener('beforeunload', handleRouteChange);
        };
    }, [blocker, isDirty, handleRouteChange, isBlockedState]);

    const handleProceed = useCallback(() => {
        closeModal();
        blocker.proceed?.();
    }, [closeModal, blocker]);

    const handleCancel = useCallback(() => {
        closeModal();
        blocker.reset?.();
    }, [closeModal, blocker]);

    useEffect(() => {
        if (isBlockedState) {
            openModal(
                <ConfirmationModal
                    content={message}
                    onCancel={handleCancel}
                    onConfirm={handleProceed}
                    confirmId={confirmTextId}
                    cancelId={cancelTextId}
                    titleId={titleTextId}
                />,
                handleCancel
            );
        }
    }, [isBlockedState, cancelTextId, confirmTextId, handleCancel, handleProceed, message, openModal, titleTextId]);

    return null;
};

export default ConfirmNavigation;

// types
// import { ButtonsReducer } from 'types/buttons';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ButtonsReducerState {
    buttonsDisabled: boolean;
}

// initial state
const initialState: ButtonsReducerState = {
    buttonsDisabled: false,
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'buttons',
    initialState,
    reducers: {
        disableButtons: (state, action: PayloadAction<boolean>) => {
            state.buttonsDisabled = action.payload;
        },
    },
});

export default menu.reducer;

export const { disableButtons } = menu.actions;

import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, FieldValues, Path, UseFormSetValue, useFormState, useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useSelector } from 'store';
import { useDebouncedCallback } from './useDebouncedCallback';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorage';

export enum DraftFormType {
    CHAT = 'chat',
}

interface Props<T extends FieldValues> {
    control: Control<T>;
    type: DraftFormType;
    objectId: number;
    objectType: string;
    setValue: UseFormSetValue<T>;
    debounceMillis?: number;
}

export const useDraftForm = <T extends FieldValues>({
    control,
    type,
    objectId,
    objectType,
    setValue,
    debounceMillis = 250,
}: Props<T>) => {
    const { id: userId } = useSelector((state) => state.user);
    const { dirtyFields } = useFormState({ control });
    const formValues = useWatch({ control });
    const isInitialLoadRef = useRef(true);

    const LOCAL_STORAGE_KEY = `${userId}-${type}-draft-${objectType}-${objectId}`;

    const [draftFormValues, setDraftFormValues] = useState<Partial<T>>(
        JSON.parse(getLocalStorage(LOCAL_STORAGE_KEY) || '{}') as Partial<T>
    );

    const dirtyFieldValues = useMemo(() => {
        return Object.keys(dirtyFields).reduce((acc, fieldName) => {
            if ((dirtyFields as unknown as any)[fieldName]) {
                acc[fieldName as keyof T] = formValues[fieldName];
            }
            return acc;
        }, {} as Partial<T>);
    }, [dirtyFields, formValues]);

    const dirtyFieldValuesRef = useRef(dirtyFieldValues);
    const lastSavedData = useRef(dirtyFieldValues);

    const saveFormData = (value: Partial<T>, isCleanupFunction = false) => {
        const isCleanupAndEqualToPreviousSave = isCleanupFunction && isEqual(value, lastSavedData.current);
        if (isEqual(value, draftFormValues) || isCleanupAndEqualToPreviousSave) {
            return;
        }
        if (!isEmpty(value)) {
            // save
            setLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(value));
            setDraftFormValues(value);
            lastSavedData.current = value;
            return;
        }
        if (!isEmpty(draftFormValues)) {
            // delete
            removeLocalStorage(LOCAL_STORAGE_KEY);
            setDraftFormValues({});
            lastSavedData.current = {};
        }
    };

    const debouncedSaveFormData = useDebouncedCallback((value: Partial<T>) => {
        saveFormData(value);
    }, debounceMillis);

    useEffect(() => {
        dirtyFieldValuesRef.current = dirtyFieldValues;
        if (!isInitialLoadRef.current && isEmpty(dirtyFieldValues) && !isEmpty(draftFormValues)) {
            saveFormData(dirtyFieldValues);
            return;
        }
        debouncedSaveFormData(dirtyFieldValues);
    }, [dirtyFieldValues, draftFormValues, debouncedSaveFormData]);

    // NB! This useEffect should remain last relative to the other already defined useEffects
    useEffect(() => {
        isInitialLoadRef.current = false;
        Object.keys(draftFormValues).forEach((key) => {
            const value = draftFormValues[key as keyof T];
            if (value !== undefined && value !== null) {
                setValue(key as Path<T>, value, { shouldDirty: true, shouldValidate: true });
            }
        });

        return () => {
            saveFormData(dirtyFieldValuesRef.current, true);
        };
    }, []);

    return { isDraft: !isEmpty(draftFormValues) };
};

import { portalAPI } from './portalAPI';
import { DocumentTemplateDto, DocumentTemplateUpdateDto } from '../../types/document-templates.ts';
import { accumulatedQueryWithFilters } from './util/endpointBuilder.ts';

export const APITeamsSlice = portalAPI.enhanceEndpoints({ addTagTypes: ['DocumentTemplates'] }).injectEndpoints({
    endpoints: (builder) => ({
        getDocumentTemplatesWithFilters: accumulatedQueryWithFilters<DocumentTemplateDto>(
            builder,
            '/v1/document-templates',
            {
                providesTags: ['DocumentTemplates'],
            }
        ),
        updateDocumentTemplate: builder.mutation<DocumentTemplateDto, DocumentTemplateUpdateDto>({
            query: ({ id, ...body }) => ({
                url: `/v1/document-templates/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['DocumentTemplates'],
        }),
    }),
    overrideExisting: false,
});

export const { useLazyGetDocumentTemplatesWithFiltersQuery, useUpdateDocumentTemplateMutation } = APITeamsSlice;

import { Fragment, memo, useMemo } from 'react';
import {
    Column,
    TableState,
    useColumnOrder,
    useExpanded,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';

import { alpha } from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useMediaQuery,
} from '@mui/material';

import { HeaderSort } from '../../third-party/ReactTable';
import { DefaultColumnFilter } from '../../../utils/react-table';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import RowLoader from './loader/RowLoader';
import { QueryResponse } from 'types/paginationAndFilter';
import { t } from 'utils/getTranslations';
import { LoadingButton } from '@mui/lab';
import ScrollX from 'components/ScrollX';
import { useObserveElementWidth } from 'utils/useObserveElementWidth';
import { ReactTable } from '../BasicTable';

export type UmbrellaBaseProps<DataType extends object> = {
    columns?: Column<DataType>[];
    subRowColumns?: Column<any>[];
    getSubRowsData?: (row: any) => any[];
    renderCustomTable?: (
        dataToDisplay: DataType[],
        initialLoad: boolean,
        noData: boolean,
        textId?: string,
        paginationProps?: Partial<QueryResponse<DataType>>,
        handleLoadMore?: () => void
    ) => void;
    initialState?: Partial<TableState>;
    isLoading: boolean;
    paginationProps?: Partial<QueryResponse<DataType>>;
    skipHeader?: boolean;
    bgcolor?: string;
};

export type UmbrellaMainProps<DataType extends object> = UmbrellaBaseProps<DataType> & {
    handleLoadMore?: () => void;
    dataToDisplay?: DataType[];
    theme: Theme;
};

const getStickyColumnStyle = (theme: Theme, header: boolean, isSmallScreen: boolean, row?: any) => ({
    position: 'sticky',
    left: 0,
    backgroundColor: header
        ? theme.palette.grey[50]
        : !!row && row.isSelected
          ? theme.palette.grey[100]
          : theme.palette.common.white,
    zIndex: header ? 10 : 'initial',
    // boxShadow: isSmallScreen ? `4px 0px 4px 0px ${alpha(theme.palette.divider, 0.25)}` : undefined,
    // borderRight: isSmallScreen ? `1px solid ${theme.palette.divider}` : 0,
    boxShadow: 'none',
    borderRight: 'none',
});

const emptyColumns: Column[] = [];

export const UmbrellaTable = <DataType extends object>(props: UmbrellaMainProps<DataType>) => {
    const {
        columns = [],
        subRowColumns = emptyColumns,
        getSubRowsData,
        initialState,
        handleLoadMore,
        dataToDisplay,
        paginationProps,
        isLoading,
        theme,
        skipHeader = false,
        bgcolor = theme.palette.grey[50],
    } = props;

    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
    const tableContent = useMemo(() => {
        return dataToDisplay ? Object.values(dataToDisplay).flat() : [];
    }, [dataToDisplay]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        visibleColumns,
    } = useTable<DataType>(
        {
            columns,
            data: tableContent as any[],
            // @ts-ignore
            defaultColumn,
            initialState,
        },
        useGlobalFilter,
        useFilters,
        useColumnOrder,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const Loader = memo(RowLoader);

    // We keep track of the header column size to determine if all sticky cells should have boxshadow
    const { width, ref: headerElementRef } = useObserveElementWidth<HTMLElement>();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const tableContainerMemoized = useMemo(
        () => (
            <TableContainer sx={{ height: '100%' }}>
                <Table {...getTableProps()} stickyHeader sx={{ zIndex: 13 }}>
                    {tableContent.length ? (
                        <TableHead
                            sx={{
                                zIndex: 13,
                                '.MuiTableCell-root': {
                                    textTransform: 'none',
                                },
                                '.MuiTableRow-root:last-of-type': {
                                    '.MuiTableCell-root': {
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                    },
                                },
                            }}
                        >
                            {headerGroups.map((headerGroup) => {
                                const { key: tableRowHeaderKey, ...tableRowHeaderProps } =
                                    headerGroup.getHeaderGroupProps();
                                return (
                                    <TableRow key={tableRowHeaderKey} {...tableRowHeaderProps}>
                                        {headerGroup.headers.map((column: any, index: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps([
                                                {
                                                    className: column.className,
                                                    style: {
                                                        textAlign: column.textAlign,
                                                        minWidth: index === 0 && !isMobile ? 'auto' : column.minWidth,
                                                        width: column.width,
                                                    },
                                                },
                                            ]);

                                            if (index === 0) {
                                                return (
                                                    <TableCell
                                                        key={columnKey}
                                                        {...columnProps}
                                                        ref={headerElementRef}
                                                        sx={
                                                            !isMobile
                                                                ? getStickyColumnStyle(
                                                                      theme,
                                                                      true,
                                                                      isLoading ? false : width <= 291 && !isMobile
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            spacing={1.15}
                                                            alignItems="center"
                                                            sx={{ display: 'inline-flex' }}
                                                        >
                                                            <HeaderSort column={column} sort />
                                                        </Stack>
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell key={columnKey} {...columnProps}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1.15}
                                                        alignItems="center"
                                                        sx={{ display: 'inline-flex' }}
                                                    >
                                                        <HeaderSort column={column} sort />
                                                    </Stack>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableHead>
                    ) : null}
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row: any, index) => {
                            prepareRow(row);
                            const { key: rowKey, ...rowProps } = row.getRowProps();
                            return (
                                <Fragment key={row.id}>
                                    <TableRow
                                        key={rowKey}
                                        {...rowProps}
                                        sx={{
                                            cursor: 'auto',
                                            bgcolor: row.isSelected ? theme.palette.grey[100] : 'inherit',
                                        }}
                                    >
                                        {row.cells.map((cell: any, index: number) => {
                                            const { key: cellKey, ...cellProps } = cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                    style: {
                                                        textAlign: cell.column.textAlign,
                                                        minWidth: cell.column.minWidth,
                                                        width: cell.column.width,
                                                        padding: cell.column.padding,
                                                    },
                                                },
                                            ]);

                                            const { key: _cellKey, ...props } =
                                                index === 0
                                                    ? {
                                                          ...cellProps,
                                                          component: 'th',
                                                          key: cellKey,
                                                          scope: 'row',
                                                          sx: !isMobile
                                                              ? getStickyColumnStyle(
                                                                    theme,
                                                                    false,
                                                                    isLoading ? false : width <= 291,
                                                                    row
                                                                )
                                                              : {
                                                                    bgcolor: row.isSelected
                                                                        ? theme.palette.grey[100]
                                                                        : 'inherit',
                                                                },
                                                      }
                                                    : { ...cellProps, key: cellKey };

                                            if (index === 0) {
                                                return (
                                                    <TableCell key={_cellKey} {...props}>
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell key={_cellKey} {...props}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                    {row.isExpanded && (
                                        <tr>
                                            <td colSpan={visibleColumns.length} style={{ padding: 0 }}>
                                                <ReactTable
                                                    data={getSubRowsData ? getSubRowsData(row.original) : []}
                                                    columns={subRowColumns}
                                                    skipHeader={skipHeader}
                                                    sx={{
                                                        '.MuiTableRow-root:last-of-type': {
                                                            '.MuiTableCell-root': {
                                                                borderBottom: `1px solid ${theme.palette.divider}`,
                                                            },
                                                        },
                                                        bgcolor,
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        {isLoading ? <Loader amountOfColumns={visibleColumns.length} /> : null}
                    </TableBody>
                </Table>
            </TableContainer>
        ),
        [
            getTableProps,
            tableContent.length,
            theme,
            headerGroups,
            getTableBodyProps,
            rows,
            isLoading,
            Loader,
            visibleColumns.length,
            isMobile,
            headerElementRef,
            width,
            prepareRow,
            getSubRowsData,
            subRowColumns,
            skipHeader,
            bgcolor,
        ]
    );
    return (
        <ScrollX sx={{ display: 'flex', width: '100%' }}>
            <Stack spacing={2} sx={{ overflowY: 'hidden', width: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        display: 'flex',
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: '4px',
                        overflowY: 'hidden',
                        position: 'relative',
                    }}
                >
                    {isLoading && tableContent.length > 0 ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                backgroundColor: alpha(theme.palette.divider, 0.4),
                                zIndex: 12,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <CircularProgress size={60} disableShrink />
                        </Box>
                    ) : null}
                    {tableContainerMemoized}
                </Box>
                {paginationProps && !paginationProps.last ? (
                    <LoadingButton
                        variant="outlined"
                        onClick={handleLoadMore}
                        sx={{ mt: '14px' }}
                        startIcon={<KeyboardDoubleArrowDownIcon fontSize="small" />}
                        disabled={isLoading}
                        loading={isLoading}
                        loadingIndicator={t('loading')}
                    >
                        {t('load-more')}
                    </LoadingButton>
                ) : null}
            </Stack>
        </ScrollX>
    );
};

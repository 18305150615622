import NumberFormat from 'react-number-format';
import { t } from 'utils/getTranslations';

type Props = {
    value?: number;
};

const HoursDisplay = ({ value }: Props) => {
    const parsedValue = value || 0;
    return (
        <NumberFormat
            displayType="text"
            decimalScale={1}
            fixedDecimalScale={parsedValue > 0}
            suffix={t('time.hours-suffix')}
            value={parsedValue}
        />
    );
};

export default HoursDisplay;

import React from 'react';

// @MUI
import { Theme, Stack, Button, CardActions, CSSObject } from '@mui/material';

// Third-party
import { FormattedMessage } from 'react-intl';

// Store
import { useSelector } from 'store';

interface Props {
    onCancel: () => void;
    onSave: () => void;
    theme: Theme;
    extraButton?: JSX.Element;
    saveButtonTextId?: string;
    cancelButtonTextId?: string;
    readOnly?: boolean;
    disableSave?: boolean;
    height?: number;
}

export const ModalFooter = ({
    onCancel,
    onSave,
    theme,
    extraButton,
    saveButtonTextId,
    cancelButtonTextId,
    readOnly,
    disableSave = false,
    height = 80,
}: Props): JSX.Element => {
    const style: CSSObject = {
        height,
        bgcolor: theme.palette.grey[0],
        px: 3,
        zIndex: 1,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderRadius: '0 0 12px 12px',
    };

    const buttonsDisabled = useSelector((state) => state.buttons?.buttonsDisabled);
    const saveDisabled = disableSave || buttonsDisabled;

    return (
        <CardActions sx={style}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={extraButton ? 'space-between' : 'flex-end'}
                sx={{ width: 1 }}
            >
                {extraButton ? extraButton : <></>}
                <Stack direction="row" spacing={1} sx={{ px: 0, py: 0 }}>
                    <Button
                        color="primary"
                        size="small"
                        onClick={onCancel}
                        variant="outlined"
                        disabled={buttonsDisabled}
                    >
                        <FormattedMessage id={cancelButtonTextId ? cancelButtonTextId : 'cancel'} />
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => onSave()}
                        disabled={saveDisabled || readOnly}
                    >
                        <FormattedMessage id={saveButtonTextId ? saveButtonTextId : 'save'} />
                    </Button>
                </Stack>
            </Stack>
        </CardActions>
    );
};

import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
    titleId?: string;
};

const NoData = ({ titleId = 'no-data' }: Props): JSX.Element => {
    return (
        <Typography variant="h6">
            <FormattedMessage id={titleId} />
        </Typography>
    );
};

export default NoData;

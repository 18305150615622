import { FC, useMemo, useRef, useState } from 'react';
import { Column } from 'react-table';
import CopyToClipboard from 'react-copy-to-clipboard';
import HistoryIcon from '@mui/icons-material/History';
import { Box, SxProps } from '@mui/system';
import { ClickAwayListener } from '@mui/base';
import { CircularProgress, Popper, Typography } from '@mui/material';

import { t } from 'utils/getTranslations';
import BasicTable from 'components/tables/BasicTable';
import { useGetTimecardsWithFiltersQuery } from 'store/api/timecards';
import { MatterDto } from 'types/matters';
import { CallForActionButton, CallForActionButtonTooltip } from './CallForActionButton';
import { useSelector } from 'store';
import { secondsToHours } from 'utils/time';
import ContentCopyFilled from 'assets/images/icons/ContentCopyFilled.svg?react';
import { useGetMatterByIdQuery } from 'store/api/matters';
import { MinDto } from 'types/other';
import { TimecardListItemData } from 'types/timecards';
import { skipToken } from '@reduxjs/toolkit/query';

type Props = {
    matterId?: number | null;
    handleTimecardCopy?: (timecard: TimecardListItemData) => void | Promise<void>;
    sx?: SxProps;
};

const TimecardHistoryButton: FC<Props> = ({ matterId, handleTimecardCopy, sx }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<any>();
    const { id: userId } = useSelector((state) => state.user);

    const filter = useMemo(
        () =>
            [matterId ? `matter.id==${matterId}` : null, userId != null ? `employee.id==${userId}` : null]
                .filter(Boolean)
                .join(';'),
        [matterId, userId]
    );

    const { data: timecards, isLoading: isLoadingTimecards } = useGetTimecardsWithFiltersQuery({
        filters: filter,
        page: 0,
        pageSize: 20,
    });

    const { data: matterDetailsData, isLoading: isLoadingMatterDetails } = useGetMatterByIdQuery(
        matterId?.toString() ?? skipToken
    );

    const matterDetails = useMemo(() => {
        return !matterId ? undefined : matterDetailsData;
    }, [matterId, matterDetailsData]);

    const timecardColumns = useMemo<Column[]>(
        () => [
            { Header: t('date'), accessor: 'timecardDate', Cell: ({ value }: any) => value ?? '' },
            {
                Header: t('timecards.history.used-time'),
                accessor: 'totalTimeInSeconds',
                disableSortBy: true,
                Cell: ({ cell }: any) => {
                    const { totalTimeInSeconds } = cell.row.original;
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {secondsToHours(totalTimeInSeconds ?? 0)}
                        </Box>
                    );
                },
            },
            {
                Header: t('timecards.history.spec-of-work'),
                accessor: 'description',
                Cell: ({ value }: any) => value ?? '',
            },
            {
                accessor: '_action',
                Cell: ({ cell }: any) => {
                    const timecard = cell.row.original as TimecardListItemData;
                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <CopyToClipboard
                                text={timecard.description || ''}
                                onCopy={async () => {
                                    await handleTimecardCopy?.(timecard);
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <CallForActionButtonTooltip title={t('timecards.copy-description')}>
                                    <CallForActionButton
                                        variant="contained"
                                        shape="rounded"
                                        color="info"
                                        aria-label={t('timecards.copy-description')}
                                        sx={{
                                            padding: '6px',
                                        }}
                                    >
                                        <ContentCopyFilled />
                                    </CallForActionButton>
                                </CallForActionButtonTooltip>
                            </CopyToClipboard>
                        </Box>
                    );
                },
            },
        ],
        [isOpen, handleTimecardCopy]
    );

    const primaryContactColumns = useMemo<Column[]>(
        () => [
            {
                Header: t('timecards.history.name-of-contact-person'),
                accessor: 'primaryContactParty',
                Cell: ({ value }: any) => {
                    const contact: MinDto | undefined = value;
                    return contact?.name ?? '-';
                },
            },
            {
                accessor: '_action',
                Cell: ({ cell }: any) => {
                    const { primaryContactParty } = cell.row.original as MatterDto;
                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <CopyToClipboard
                                text={primaryContactParty?.name ?? ''}
                                onCopy={(_, result) => {
                                    if (result) {
                                        setIsOpen(!isOpen);
                                    }
                                }}
                            >
                                <CallForActionButtonTooltip title={t('timecards.copy-name')}>
                                    <CallForActionButton
                                        variant="contained"
                                        shape="rounded"
                                        color="info"
                                        aria-label={t('timecards.copy-name')}
                                        sx={{
                                            padding: '6px',
                                        }}
                                    >
                                        <ContentCopyFilled />
                                    </CallForActionButton>
                                </CallForActionButtonTooltip>
                            </CopyToClipboard>
                        </Box>
                    );
                },
            },
        ],
        [isOpen]
    );

    const noRecordsElement = <Typography sx={{ p: 2, pt: 1, pb: 1 }}>No records</Typography>;

    return (
        <Box>
            <CallForActionButtonTooltip title={t('history.history')}>
                <CallForActionButton
                    ref={buttonRef}
                    variant="contained"
                    shape="rounded"
                    color="info"
                    aria-label={t('history.history')}
                    sx={{
                        marginTop: '9px',
                        alignSelf: 'center',
                        ...sx,
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <HistoryIcon />
                </CallForActionButton>
            </CallForActionButtonTooltip>
            <Popper
                popperOptions={{
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: { tether: true, padding: 1 },
                        },
                    ],
                }}
                sx={{ zIndex: 1500, maxWidth: '696px', width: '100%' }}
                disablePortal={true}
                placement="bottom-end"
                open={isOpen}
                anchorEl={buttonRef.current}
            >
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <Box
                        sx={{
                            boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.10)',
                            borderRadius: '4px',
                            marginTop: 1,
                        }}
                    >
                        <Box>
                            {isLoadingTimecards || isLoadingMatterDetails ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <BasicTable
                                        columns={timecardColumns}
                                        data={timecards?.content ?? []}
                                        stickyHeader
                                        containerProps={{ sx: { maxHeight: 325 } }}
                                        wrapperSx={{
                                            borderBottomRightRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderBottom: 'unset',
                                        }}
                                        noContentJSX={noRecordsElement}
                                    />
                                    <BasicTable
                                        columns={primaryContactColumns}
                                        data={
                                            matterDetails && matterDetails?.primaryContactParty ? [matterDetails] : []
                                        }
                                        stickyHeader
                                        containerProps={{ sx: { maxHeight: 325 } }}
                                        wrapperSx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                                        noContentJSX={noRecordsElement}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </Box>
    );
};

export default TimecardHistoryButton;

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Theme, useMediaQuery, useTheme } from '@mui/material';
import { FilterCheckboxInForm, MainFilterForm, QuickFilterForm } from '../UmbrellaTableTypes';
import { FormattedMessage } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';
import { t } from 'utils/getTranslations';
import InfoTooltip from 'components/InfoTooltip';

export type Props = {
    quickFilters?: QuickFilterForm;
    handleChange: (value: boolean, groupSelector: string, key: string) => void;
    clearFilters: () => void;
    hasFilters: boolean;
    mainFilterState: MainFilterForm;
    handleMainFilterStateChange: (state: MainFilterForm) => void;
    disabled?: boolean;
};

export const OptionalTooltip = ({
    children,
    tooltip,
    theme,
}: {
    children: JSX.Element;
    tooltip?: string;
    theme: Theme;
}) => {
    if (tooltip)
        return (
            <Box sx={TOOLTIP_WRAP_STYLE}>
                {children}
                <InfoTooltip text={tooltip} />
            </Box>
        );
    return children;
};

const QuickFilters: React.FC<Props> = ({
    quickFilters,
    handleChange,
    clearFilters,
    hasFilters,
    mainFilterState,
    handleMainFilterStateChange,
    disabled = false,
}) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'grid',
                alignItems: 'center',
                gap: 1,
                gridTemplateColumns: '1fr auto',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    gridTemplateRows: '1fr auto',
                    gridTemplateColumns: 'auto',
                },
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {quickFilters
                    ? Object.values(quickFilters).map((group, index) => {
                          return group?.filters.length ? (
                              <FormGroup key={`fg-${group.selector}`} sx={FILTER_GROUP_STYLE} row>
                                  <Box sx={DIVIDER_STYLE(theme, index)} />
                                  {group?.label && !matchDownSM ? (
                                      <Box sx={{ pr: '4px' }}>{t(group?.label)}:</Box>
                                  ) : null}
                                  <Box>
                                      {group.filters.map((filter: FilterCheckboxInForm) => {
                                          return (
                                              <OptionalTooltip
                                                  tooltip={filter?.tooltip}
                                                  key={`fg-f-${filter.argument}`}
                                                  theme={theme}
                                              >
                                                  <FormControlLabel
                                                      sx={{
                                                          m: '0 4px 0 0',
                                                          whiteSpace: 'nowrap',
                                                          userSelect: 'none',
                                                      }}
                                                      label={filter.label}
                                                      control={
                                                          <Checkbox
                                                              checked={filter.isSelected}
                                                              disabled={disabled}
                                                              color="secondary"
                                                              onChange={(e) => {
                                                                  const groupSelector = `${group.selector}${
                                                                      group.comperator !== undefined
                                                                          ? `:${group.comperator}`
                                                                          : ':=in='
                                                                  }`;
                                                                  if (group.onChange) {
                                                                      group.onChange(
                                                                          e.target.checked,
                                                                          groupSelector,
                                                                          mainFilterState,
                                                                          handleMainFilterStateChange
                                                                      );
                                                                  }
                                                                  handleChange(
                                                                      e.target.checked,
                                                                      groupSelector,
                                                                      filter.key
                                                                  );
                                                              }}
                                                              size="small"
                                                          />
                                                      }
                                                  />
                                              </OptionalTooltip>
                                          );
                                      })}
                                  </Box>
                              </FormGroup>
                          ) : null;
                      })
                    : null}
            </Box>
            {hasFilters ? (
                <Button
                    color="primary"
                    size="small"
                    onClick={clearFilters}
                    disabled={disabled}
                    sx={CLEAR_FILTERS_STYLE}
                    startIcon={<ClearIcon />}
                >
                    <FormattedMessage id="clear-filters" />
                </Button>
            ) : null}
        </Box>
    );
};

export default QuickFilters;

const TOOLTIP_WRAP_STYLE = {
    display: 'flex',
    alignItems: 'center',
};

const DIVIDER_STYLE = (theme: Theme, index: number) => ({
    '&:before': {
        width: '8px',
        height: '22px',
        fontSize: '14px',
        content: '"|"',
        m: '0 12px',
        color: theme.palette.grey[400],
        [theme.breakpoints.down('sm')]: {
            m: '0 6px',
            display: 'none',
        },
    },
});

const FILTER_GROUP_STYLE = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'unset',
};

const CLEAR_FILTERS_STYLE = {
    fontWeight: 400,
    fontSize: '14px',
    height: 'fit-content',
    padding: '0px 4px',
    '.MuiButton-startIcon': {
        mr: '2px',
        '> :nth-of-type(1)': {
            fontSize: '13px',
        },
    },
};

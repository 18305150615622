import { useSelector } from 'react-redux';
import { useSelector as useStoreSelector } from 'store';

// material-ui
import { Box, Typography } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import getMenuItems from 'menu-items';
import { useMemo } from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const menu = useSelector((state: RootStateProps) => state.menu);
    const { id: userId } = useStoreSelector((state) => state.user);

    const menuItems = useMemo(() => getMenuItems(userId ? userId.toString() : undefined), [userId]);
    const { drawerOpen } = menu;

    const navGroups = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;

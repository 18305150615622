import React, { useMemo } from 'react';
import { Drawer, useTheme } from '@mui/material';

import { dispatch, useSelector } from 'store';
import ChatContent from './ChatContent';
import { useGetMessagesByTypeAndIdQuery } from 'store/api/notifications';
import { closeChat } from 'store/reducers/chat';

const Chat: React.FC = () => {
    const theme = useTheme();
    const { isOpen, objectId, objectType } = useSelector((state) => state.chat);

    const {
        data: loadedMessages,
        isLoading,
        isFetching,
    } = useGetMessagesByTypeAndIdQuery(
        { objectType, objectId: objectId ?? 0 },
        { skip: !objectType || typeof objectId !== 'number' }
    );

    const messages = useMemo(() => loadedMessages || [], [loadedMessages]);

    const handleClose = () => {
        dispatch(closeChat());
    };

    return (
        <Drawer
            open={isOpen}
            variant="temporary"
            slotProps={{
                backdrop: { sx: { opacity: '0 !important' } },
            }}
            onClose={handleClose}
            anchor="right"
            PaperProps={{
                sx: {
                    boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.10)',
                    top: '41px',
                    height: 'calc(100% - 41px)',
                },
            }}
        >
            {objectId === null || objectType === null ? null : (
                <ChatContent
                    theme={theme}
                    messages={messages}
                    objectType={objectType}
                    objectId={objectId}
                    isLoading={isLoading || isFetching}
                />
            )}
        </Drawer>
    );
};

export default Chat;

import { number, object, string } from 'yup';

type ValidationResponse = true | string;

export const numberIsGreaterThan = (
    moreThan: number,
    errorMsg: string
): ((input: any) => Promise<ValidationResponse>) => {
    return async (input: any) => {
        const validator = number().moreThan(moreThan);

        try {
            await validator.validate(input);
            return true;
        } catch {
            return errorMsg;
        }
    };
};

export const isValidUrl = async (input: any, errorMsg: string): Promise<ValidationResponse> => {
    const validator = object({
        input: string().url(),
    });

    try {
        await validator.validate({ input });
        return true;
    } catch {
        return errorMsg;
    }
};

export const stringIsNotWhitespaceOnly = (errorMsg: string): ((input: any) => Promise<ValidationResponse>) => {
    return async (input: any) => {
        const isValid = typeof input.trim === 'function' ? !!input.trim() : true;
        return isValid || errorMsg;
    };
};

export const isValidEmail = async (input: any, errorMsg: string): Promise<ValidationResponse> => {
    const validator = object({
        input: string().email(),
    });

    try {
        await validator.validate({ input });
        return true;
    } catch (e) {
        return errorMsg;
    }
};

import Loader from 'components/Loader';
import { useCallback, useEffect, useState } from 'react';
import JobPositionPageForm from './JobPositionPageForm';
import { JobPositionForm } from '../../types/jobPositions';
import { useClassifiers } from 'utils/useClassifiers';

const USED_CLASSIFIERS = ['JOB_POSITION_TYPE', 'LANGUAGE'];

const JobPositionPageAdd = () => {
    const [initialData, setInitialData] = useState<JobPositionForm>();

    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: USED_CLASSIFIERS,
    });

    // Set initial form data
    const getInitialData = useCallback((): JobPositionForm | undefined => {
        const supportedLanguages = Array.from(
            new Set(
                classifiers['LANGUAGE']?.values
                    ?.map((value) => value.code)
                    .sort((a: string, b: string) => a.localeCompare(b)) ?? []
            )
        );

        return {
            name: '',
            externalId: '',
            positionTypeCode: null,
            entityTexts: supportedLanguages.map((langCode) => ({
                id: null,
                langCode,
                value: '',
                description: '',
                textMale: '',
                textFemale: '',
            })),
        };
    }, [classifiers]);

    useEffect(() => {
        setInitialData(getInitialData());
    }, [classifiers]);

    if (isLoadingClassifiers || initialData === undefined) {
        return <Loader />;
    }

    return (
        <JobPositionPageForm type="add" initialData={initialData ? initialData : undefined} classifiers={classifiers} />
    );
};

export default JobPositionPageAdd;

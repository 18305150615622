import FormWrapper from 'components/forms/form-components/FormWrapper';
import SelectInput from 'components/forms/inputs/rhf-inputs/SelectInput';
import TextInput from 'components/forms/inputs/rhf-inputs/TextInput';
import Section from 'components/section-menu/Section';
import SectionLayout from 'components/section-menu/SectionLayout';
import { errorAlert } from 'utils/alert';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'utils/getTranslations';
import {
    useAddJobPositionMutation,
    useDeleteJobPositionMutation,
    useUpdateJobJobPositionMutation,
} from '../../store/api/jobPositions';
import { JobPositionCreateOrUpdateDto, JobPositionForm } from '../../types/jobPositions';
import { Button, Typography } from '@mui/material';
import PermissionButton from '../../components/buttons/PermissionButton';
import { FormattedMessage } from 'react-intl';
import ActionHeader from '../../components/forms/ActionHeader';
import ConfirmationModal from '../../components/modals/confirmation/ConfirmationModal';
import { useModal } from '../../components/modals/useModal';
import { useIsFeatureEnabled } from '../../utils/useFeatureFlags';
import { FeatureFlag } from '../../types/features';
import { getValidOptionsFromClassifier } from 'utils/formUtil';
import { ClassifierData } from 'types/classifiers';
import TranslationsInput from 'components/forms/inputs/translations/TranslationsInput';
import InfoTooltip from 'components/InfoTooltip';
import ConfirmNavigation from 'components/navigation/ConfirmNavigation';
import { deferCall } from 'utils/deferCall';

type Props = {
    type: 'add' | 'edit';
    initialData?: JobPositionForm;
    classifiers: Record<string, ClassifierData>;
    refetch?: () => any;
};

const defaultValues: JobPositionForm = {
    positionTypeCode: null,
    name: '',
    externalId: '',
    entityTexts: [],
};

const JobPositionPageForm = ({ type = 'add', initialData, classifiers, refetch }: Props): JSX.Element => {
    const { id: jobPositionId } = useParams();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModal();

    const [isInViewMode, setIsInViewMode] = useState(type === 'edit');
    const { handleSubmit, control, setValue, getValues, reset } = useForm<JobPositionForm>({
        defaultValues: initialData ? { ...defaultValues, ...initialData } : defaultValues,
        values: initialData,
    });

    const [addJobPosition] = useAddJobPositionMutation();
    const [updateJobPosition] = useUpdateJobJobPositionMutation();
    const [deleteJobPosition] = useDeleteJobPositionMutation();

    const onStartEditing = useCallback(() => {
        setIsInViewMode(false);
    }, []);

    const cancelEditing = useCallback(() => {
        setIsInViewMode(true);
        reset();
    }, [reset]);

    const isJobPositionExternalIdEnabled = useIsFeatureEnabled(FeatureFlag.JOB_POSITION_EXTERNAL_ID_ENABLED);
    const isJobPositionTypeEnabled = useIsFeatureEnabled(FeatureFlag.JOB_POSITION_TYPE);

    const onSubmit = async (
        formData: JobPositionCreateOrUpdateDto,
        action: 'create' | 'update' | 'delete'
    ): Promise<void> => {
        try {
            if (action === 'create') {
                await addJobPosition({
                    ...formData,
                })
                    .unwrap()
                    .then((resp) => {
                        reset({}, { keepValues: true });
                        deferCall(() => navigate(`/job-position/${resp.id}`));
                    })
                    .catch(console.error);
            } else if (action === 'update' && jobPositionId && !isNaN(Number(jobPositionId))) {
                await updateJobPosition({
                    ...formData,
                    id: Number(jobPositionId),
                })
                    .unwrap()
                    .then(() => {
                        if (refetch) refetch();
                        setIsInViewMode(true);
                    })
                    .catch(console.error);
            }
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    const submitCreate = () => {
        handleSubmit((data) => onSubmit(mapToJobPositionDto(data), 'create'))();
    };

    const submitUpdate = () => {
        handleSubmit((data) => onSubmit(mapToJobPositionDto(data), 'update'))();
    };

    const handleDeleteJobPosition = async () => {
        try {
            if (jobPositionId && !isNaN(Number(jobPositionId))) {
                openModal(
                    <ConfirmationModal
                        onCancel={closeModal}
                        onConfirm={async () =>
                            await deleteJobPosition({ id: jobPositionId })
                                .unwrap()
                                .then(() => navigate(`/job-positions`))
                                .catch(console.error)
                                .finally(() => closeModal())
                        }
                        content={<Typography>{t('job-positions.delete-confirmation-text')}</Typography>}
                    />
                );
            }
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    // Show inital name if is edit form. Dont do dynamically to prevent unneeded rerenders
    const getFormTitle = () => {
        switch (type) {
            case 'edit':
                return initialData?.name;
            default:
                return t('job-positions.add-new-job-position');
        }
    };

    const getFormHeader = () => {
        const actions: JSX.Element[] = [];

        if (type === 'edit' && jobPositionId && isInViewMode) {
            actions.push(
                <PermissionButton
                    key="edit"
                    permissions={['UPDATE.JOB_POSITION']}
                    variant="outlined"
                    size="small"
                    onClick={onStartEditing}
                >
                    <FormattedMessage id="edit" />
                </PermissionButton>
            );
        } else if (type === 'add') {
            actions.push(
                <Button key="addCancel" variant="outlined" size="small" onClick={() => navigate('/job-positions')}>
                    <FormattedMessage id="cancel" />
                </Button>,
                <PermissionButton
                    key="addSave"
                    permissions={['CREATE.JOB_POSITION']}
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={submitCreate}
                >
                    <FormattedMessage id="save" />
                </PermissionButton>
            );
        } else if (type === 'edit' && !isInViewMode && jobPositionId) {
            actions.push(
                <Button key="editCancel" variant="outlined" size="small" onClick={cancelEditing}>
                    <FormattedMessage id="cancel" />
                </Button>,
                <PermissionButton
                    key="editDeleteJobPosition"
                    permissions={['DELETE.JOB_POSITION']}
                    sx={{ fontWeight: 400 }}
                    color="error"
                    size="small"
                    onClick={handleDeleteJobPosition}
                >
                    <FormattedMessage id="delete" />
                </PermissionButton>,
                <PermissionButton
                    key="editSave"
                    permissions={['UPDATE.JOB_POSITION']}
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={submitUpdate}
                >
                    <FormattedMessage id="save" />
                </PermissionButton>
            );
        }

        return <ActionHeader title={getFormTitle()} actions={actions} />;
    };

    return (
        <FormWrapper>
            {getFormHeader()}
            <SectionLayout showMenu={false} readonly={isInViewMode}>
                <Section title="job-positions.job-positions">
                    <TextInput
                        control={control}
                        label="name"
                        placeholder="forms.enter-name"
                        name="name"
                        required
                        readonly={isInViewMode}
                    />
                    {isJobPositionExternalIdEnabled ? (
                        <TextInput
                            control={control}
                            label="job-positions.external-id"
                            placeholder="forms.enter-external-id"
                            name="externalId"
                            required
                            readonly={isInViewMode}
                        />
                    ) : null}
                    {isJobPositionTypeEnabled ? (
                        <SelectInput
                            setValue={setValue}
                            control={control}
                            label="job-positions.position-type"
                            placeholder="forms.select-position-type"
                            name="positionTypeCode"
                            options={
                                (getValidOptionsFromClassifier(classifiers['JOB_POSITION_TYPE']) as {
                                    label: string;
                                    value: string;
                                }[]) ?? []
                            }
                            getOptionLabel="label"
                            getOptionIdentifier="value"
                            required
                            readonly={isInViewMode}
                        />
                    ) : null}
                </Section>
                <Section
                    title="job-positions.position-name-translations"
                    tooltip={<InfoTooltip text="translations.mandatory-fields" />}
                >
                    <Controller
                        control={control}
                        name="entityTexts"
                        render={({ field }) => (
                            <TranslationsInput
                                classifiers={classifiers}
                                control={control}
                                entityTexts={field.value ?? []}
                                getValues={getValues}
                                readonly={isInViewMode}
                            />
                        )}
                    />
                </Section>
            </SectionLayout>
            <ConfirmNavigation control={control} />
        </FormWrapper>
    );
};

export const mapToJobPositionDto = (form: JobPositionForm): JobPositionCreateOrUpdateDto => ({
    name: form.name,
    externalId: form.externalId ? form.externalId : null,
    positionTypeCode: form.positionTypeCode ? form.positionTypeCode.value : null,
    entityTexts: form.entityTexts?.filter((el) => !!el.value || (!!el.textMale && !!el.textFemale)) ?? null,
});

export default JobPositionPageForm;

import { MatterMin, MatterMinWithClientPartyId } from './matters';
import { MinDto, NamedEntityMinDto } from './other';
import { Dayjs } from 'dayjs';

export enum TaskTypeCode {
    CORRESPONDENCE = 'CORRESPONDENCE',
}

export enum TaskPriorityCode {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export enum TaskStatusType {
    DONE = 'DONE',
    IN_PROGRESS = 'IN_PROGRESS',
}

export interface TaskListItem {
    id: number;
    name: string;
    clientParty: NamedEntityMinDto;
    assignedToEmployees: NamedEntityMinDto[];
    deadlineDate?: string;
    taskPriorityTypeCode: string;
    taskStatusTypeCode: `${TaskStatusType}`;
    taskTypeCodes: string[];
}

export interface AddressedTaskListItem {
    id: number;
    name: string;
    clientParty: NamedEntityMinDto;
    matters: MatterMin[];
    taskPriorityTypeCode: string;
    taskStatusTypeCode: string;
}

export interface Task {
    id: number;
    name: string;
    description?: string;
    taskPriorityTypeCode: string;
    taskStatusTypeCode: string;
    taskTypeCodes: string[];
    assignedToEmployees: NamedEntityMinDto[];
    assignedByEmployees: NamedEntityMinDto[];
    clientParties: NamedEntityMinDto[];
    matters: MatterMinWithClientPartyId[];
    correspondenceDate?: string | null;
    documentDate?: string | null;
    correspondenceTypeCode?: string;
    correspondenceDirectionType?: string;
    correspondenceSenders?: NamedEntityMinDto[];
    correspondenceRecipients?: NamedEntityMinDto[];
    hasDeadline: boolean;
    deadlineDate?: string;
    taskEvent?: TaskEventDto;
    dmsData?: DmsDataDto;
    createdAt: number;
    lastModifiedAt: number;
    createdByEmployee?: MinDto;
    lastModifiedByEmployee?: MinDto;
}

export interface TaskDto {
    id?: string | number | null;
    name?: string;
    description?: string;
    taskStatusTypeCode?: string;
    taskPriorityTypeCode?: string;
    clientParties?: { id: number }[];
    taskTypeCodes?: string[];
    assignedToEmployees?: { id: number }[];
    assignedByEmployees?: { id: number }[];
    matters?: { id: number }[];
    correspondenceDate: string | null;
    documentDate?: string | null;
    correspondenceTypeCode?: string;
    correspondenceDirectionType?: string;
    correspondenceSenders?: { id: number }[];
    correspondenceRecipients?: { id: number }[];
    hasDeadline: boolean;
    deadlineDate: string | null;
    taskEvent?: TaskEventDto | null;
    dmsData: DmsDataDto | null;
    replyTo?: string[];
}

export interface TaskForm {
    id?: string | number | null;
    name?: string;
    description?: string;
    taskStatusTypeCode?: string;
    taskPriority?: { label: string; value: string };
    taskTypes?: { label: string; value: string }[];
    assignedToEmployees?: NamedEntityMinDto[];
    assignedByEmployees?: NamedEntityMinDto[];
    clientParties?: NamedEntityMinDto[];
    matters?: MatterMin[];
    correspondenceDate: string | null;
    documentDate?: string | null;
    correspondenceTypeCode?: { label: string; value: string };
    correspondenceDirectionType?: string;
    correspondenceSenders?: NamedEntityMinDto[];
    correspondenceRecipients?: NamedEntityMinDto[];
    hasDeadline?: boolean;
    allDayMeeting?: boolean;
    calendarSync?: boolean;
    deadlineDate: string | null;
    taskEvent: TaskEventForm | null;
    dmsData: DmsDataForm | null;
    replyTo?: string[];
}

export interface TaskEventForm {
    id?: string | number | null;
    inviteSender: string | null;
    description: string | null;
    eventLocation: string | null;
    reminder: ReminderType[];
    dateStart: string | null;
    timeStart: Dayjs | null;
    dateEnd: string | null;
    timeEnd: Dayjs | null;
    eventInvitees: NamedEntityMinDto[] | null;
}

export interface TaskEventDto {
    id?: string | number | null;
    inviteSender: string | null;
    description: string | null;
    eventLocation: string | null;
    reminder: string | null;
    dateStart: Date | null;
    dateEnd: Date | null;
    eventInvitees: EventInviteeDto[] | null;
}

export interface DmsDataForm {
    dmsLink: string | null;
    dmsDatabase?: { label: string; value: string } | null;
    dmsNumber?: string | null;
}

export interface DmsDataDto {
    dmsLink: string | null;
    dmsDatabase?: string | null;
    dmsNumber?: string | null;
}

export interface EventInviteeForm {
    eventId?: string | number | null;
    inviteeEmail?: string | null;
}

export interface EventInviteeDto {
    employeeId: number;
    employeeName?: string | null;
}

export interface ReminderType {
    label: string;
    value: string;
}

export const getPriorityStatusChipType = (taskPriorityTypeCode: string) => {
    switch (taskPriorityTypeCode) {
        case 'HIGH':
            return 'error';
        case 'MEDIUM':
            return 'warning';
        case 'LOW':
            return 'neutral';
        default:
            return 'success';
    }
};

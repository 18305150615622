// material-ui
import { Theme } from '@mui/material/styles';

// assets
import { RightOutlined } from '@ant-design/icons';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme: Theme) {
    const { palette, spacing } = theme;

    return {
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <RightOutlined style={{ fontSize: '0.75rem' }} />,
            },
            styleOverrides: {
                root: {
                    minHeight: 46,
                    padding: '5px 16px',
                    [theme.breakpoints.down('figmaDefault')]: {
                        padding: '5px 8px',
                    },
                },
                expandIconWrapper: {
                    '&.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                },
                content: {
                    marginTop: spacing(1.25),
                    marginBottom: spacing(1.25),
                    marginLeft: spacing(1),
                    '.MuiTypography-root': { fontWeight: 600, fontSize: '16px' },
                },
            },
        },
    };
}

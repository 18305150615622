type LocalStorageKeys =
    | 'serviceToken'
    | 'cognito_user'
    | 'locale'
    | `${string}-${string}-tracked-timecard-id`
    | `${string}-${string}-timecards-added`
    | `${string}-${string}-expenses-added`
    | 'sso_nonce'
    | `${string}-${string}-draft-${string}-${string}`
    | string;

export const getLocalStorage = (key: LocalStorageKeys) => {
    if (!key || typeof key !== 'string') {
        console.error('getLocalStorage > Invalid key: ', key);
        return undefined;
    }

    try {
        return localStorage.getItem(key);
    } catch (e) {
        console.error('Error receiving the local storage value for: ', key);
    }
};

export const setLocalStorage = (key: LocalStorageKeys, value: string) => {
    if (!key || typeof key !== 'string') {
        console.error('setLocalStorage > Invalid key: ', key);
        return undefined;
    }

    try {
        return localStorage.setItem(key, value);
    } catch (e) {
        console.error('Error setting the local storage value for: ', key);
    }
};

export const removeLocalStorage = (key: LocalStorageKeys) => {
    if (!key || typeof key !== 'string') {
        console.error('removeLocalStorage > Invalid key: ', key);
        return undefined;
    }

    try {
        return localStorage.removeItem(key);
    } catch (e) {
        console.error('Error removing the local storage value for: ', key);
    }
};

export const removeExpiredInvoiceCookies = (userId: number) => {
    const now = new Date().getTime();
    const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
    const expensesAddedPattern = new RegExp(`^${userId}-.*-expenses-added$`);
    const timecardsAddedPattern = new RegExp(`^${userId}-.*-timecards-added$`);
    const expensesOrTimecardsAddedKeys = Object.keys(localStorage).filter(
        (key) => key.match(expensesAddedPattern) || key.match(timecardsAddedPattern)
    );
    expensesOrTimecardsAddedKeys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (value && now - JSON.parse(value).timestamp > thirtyDaysInMs) {
            localStorage.removeItem(key);
        }
    });
};

export const removeAllDraftForms = (userId: number) => {
    const draftFormsPattern = /^117-.+-draft-.+-.+$/;
    const draftFormsAddedKeys = Object.keys(localStorage).filter((key) => key.match(draftFormsPattern));
    draftFormsAddedKeys.forEach((key) => {
        localStorage.removeItem(key);
    });
};

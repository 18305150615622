import { Box, Stack, Theme } from '@mui/material';
import React from 'react';
import { useLazyGetTasksAddressedToEmployeeQuery } from '../../../../../../../store/api/tasks';
import { useForm } from 'react-hook-form';
import { useAsyncDebounce } from 'react-table';
import TextInput from '../../../../../../../components/forms/inputs/rhf-inputs/TextInput';
import AddressedTaskItem from './AddressedTaskItem';
import { AddressedTaskListItem } from 'types/tasks';
import { useClassifiers } from 'utils/useClassifiers';
import PermissionButton from '../../../../../../../components/buttons/PermissionButton';
import { FormattedMessage } from 'react-intl';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';

interface AddressedTaskListProps {
    tasks: AddressedTaskListItem[];
    theme: Theme;
    userId: number | null;
    openAddressedTaskView: (taskId: number) => void;
    toggleDrawer: () => void;
}

interface AddressedTaskSearchForm {
    searchLine: string;
}

const AddressedTasksList: React.FC<AddressedTaskListProps> = ({
    tasks,
    theme,
    userId,
    openAddressedTaskView,
    toggleDrawer,
}) => {
    const navigate = useNavigateWithReturnState();
    const [searchTasks, { data: addressedTasks }] = useLazyGetTasksAddressedToEmployeeQuery();
    const { classifiers } = useClassifiers({
        codes: ['TASK_PRIORITY_TYPE'],
    });

    const { control, getValues } = useForm<AddressedTaskSearchForm>({
        defaultValues: {
            searchLine: '',
        },
    });

    const onSearchSubmit = async (value: string): Promise<void> => {
        if (userId === null) {
            return;
        }

        const trimmedSearch = value.trim();
        searchTasks({ employeeId: userId, search: trimmedSearch });
    };

    const handleSearchChange = useAsyncDebounce(() => {
        onSearchSubmit(getValues('searchLine'));
    }, 600);

    const handleAddNewTas = () => {
        toggleDrawer();
        navigate('task/new');
    };

    return (
        <>
            <Stack
                sx={{
                    width: '100%',
                    p: '8px 24px 16px',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    justifyContent: 'space-around',
                    alignItems: 'end',
                }}
                component="div"
                direction="row"
            >
                <TextInput
                    wrapperSx={{ width: '75%', height: '100%' }}
                    control={control}
                    name="searchLine"
                    placeholder="search"
                    onChange={handleSearchChange}
                />
                <PermissionButton
                    sx={{ height: '36px' }}
                    permissions={['CREATE.TASKS']}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleAddNewTas}
                >
                    <FormattedMessage id="tasks.add-task" />
                </PermissionButton>
            </Stack>
            <Box
                component="ul"
                sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                }}
            >
                {(addressedTasks ?? tasks).map((val) => (
                    <AddressedTaskItem
                        key={`addressed-task-${val.id}`}
                        theme={theme}
                        addressedTask={val}
                        openAddressedTaskView={openAddressedTaskView}
                        classifiers={classifiers}
                    />
                ))}
            </Box>
        </>
    );
};

export default AddressedTasksList;

import { Grid, SxProps, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    title: string;
    rightComponent: React.ReactNode;
    sx?: SxProps;
};

const SectionTitle = ({ title, rightComponent, sx }: Props): JSX.Element => {
    return (
        <Grid container justifyContent="space-between" alignItems="center" py={1} sx={sx}>
            <Grid item>
                <Typography variant="h5">
                    <FormattedMessage id={title} />
                </Typography>
            </Grid>
            <Grid>{rightComponent}</Grid>
        </Grid>
    );
};

export default SectionTitle;

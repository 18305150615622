import { CardActions, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

type Props = {
    title: string | undefined;
    titleSuffixComponent?: React.ReactNode;
    actions: React.ReactNode;
};

const ActionHeader = ({ title, titleSuffixComponent, actions }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <>
            {title && (
                <Helmet>
                    <title>{title}</title>
                    <meta name="breadcrumb" content={title} />
                </Helmet>
            )}
            <CardActions
                sx={{
                    position: 'sticky',
                    top: 0,
                    height: '72px',
                    bgcolor: theme.palette.grey[0],
                    px: 3,
                    zIndex: 1,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
                    <Stack direction="row" alignItems="center" gap={2}>
                        {title && (
                            <Typography variant="h5" sx={{ mb: 0 }}>
                                {title}
                            </Typography>
                        )}
                        {titleSuffixComponent ?? null}
                    </Stack>
                    <Stack direction="row" spacing={1} p={0}>
                        {actions}
                    </Stack>
                </Stack>
            </CardActions>
        </>
    );
};

export default ActionHeader;

import { Link, Stack, Theme, Typography } from '@mui/material';
import StatusChip from '../../../../../../../components/@extended/StatusChip';
import { FormattedMessage } from 'react-intl';
import { NamedEntityMinDto } from '../../../../../../../types/other';

interface AddressedTaskPropertyProps {
    labelId: string;
    properties: string[] | NamedEntityMinDto[];
    direction: 'column' | 'row';
    useStatusChip?: boolean;
    useLink?: boolean;
    theme: Theme;
    onClick?: (id: number) => void;
}

const AddressedTaskProperties: React.FC<AddressedTaskPropertyProps> = ({
    labelId,
    properties,
    direction,
    useStatusChip = false,
    useLink = true,
    theme,
    onClick,
}) => {
    const handleClickProperty = (property: string | NamedEntityMinDto) => {
        if (typeof property === 'object' && 'id' in property && onClick) {
            onClick(property.id);
        }
    };

    const content = properties.map((property, index) => {
        let displayedValue = property.toString();
        if (typeof property === 'object' && 'name' in property) {
            displayedValue = property.name;
        }

        if (useStatusChip) {
            return <StatusChip key={index} label={displayedValue} type={'neutral'} theme={theme} />;
        } else if (useLink) {
            return (
                <Link key={index} onClick={() => handleClickProperty(property)}>
                    <Typography sx={{ color: 'text.link' }}>{displayedValue}</Typography>
                </Link>
            );
        } else {
            return <Typography sx={{ color: 'text.primary' }}>{displayedValue}</Typography>;
        }
    });

    return (
        <Stack
            direction="column"
            component="div"
            sx={{ borderBottom: `1px solid ${theme.palette.divider}`, gap: 1, pb: '8px' }}
        >
            <Typography sx={{ color: 'text.secondary' }}>
                <FormattedMessage id={labelId} />
            </Typography>
            <Stack direction={direction} component="div" sx={{ gap: 1 }} flexWrap={'wrap'}>
                {content}
            </Stack>
        </Stack>
    );
};

export default AddressedTaskProperties;

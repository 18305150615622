// ==============================|| OVERRIDES - LOADING BUTTON ||============================== //

import { Theme, alpha } from '@mui/material';

export default function LoadingButton(theme: Theme) {
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    padding: '6px 16px',
                    '&.MuiLoadingButton-loading': {
                        backgroundColor: alpha(theme.palette.divider, 0.25),
                        textShadow: 'none',

                        '.MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.common.black,
                        },
                    },
                },
            },
        },
    };
}

import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography(theme: Theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12,
                },
                colorPrimary: { color: theme.palette.text.primary },
                colorSecondary: { color: theme.palette.text.secondary },
            },
        },
    };
}

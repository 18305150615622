import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { t } from 'utils/getTranslations';
import { TimecardListItemData, TimecardStatusType } from 'types/timecards';
import React from 'react';
import { dispatch, useSelector } from 'store';
import TimecardTrackingButton from './buttons/TimecardTrackingButton';
import { CallForActionButton } from '../../components/buttons/CallForActionButton';
import FinishTimecardIconButton from './buttons/FinishTimecardIconButton';
import AddExpenseIconButton from './buttons/AddExpenseIconButton';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';
import { useModal } from 'components/modals/useModal';
import ConfirmationModal from 'components/modals/confirmation/ConfirmationModal';
import { useDeleteTimecardMutation } from 'store/api/timecards';
import { resetTimecardState } from 'store/reducers/timecard';
import { useHasPermissions } from '../../utils/permissionUtil';
import { CSSObject } from '@emotion/react';
import BlockedTag from './buttons/BlockedTag.tsx';

type Props = {
    timecard: TimecardListItemData;
};

type MenuItemType = {
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
    sx?: CSSObject;
};

const TimecardActionButtons = ({ timecard }: Props): JSX.Element => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { openModal, closeModal } = useModal();

    const hasCreateExpensePermission = useHasPermissions(['CREATE.EXPENSES']);
    const hasCreateTimecardPermission = useHasPermissions(['CREATE.TIMECARDS']);

    const { id: userId, activeTenantId } = useSelector((state) => state.user);

    const { trackedTimecardId } = useSelector((store) => store.timecard);
    const isTrackedTimecard = trackedTimecardId && parseInt(trackedTimecardId) === timecard.id;

    const navigateWithReturn = useNavigateWithReturnState();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDuplicate = () => {
        navigateWithReturn(`/timecards/duplicate/${timecard.id}`);
    };

    const [deleteTimecard] = useDeleteTimecardMutation();

    const handleDeleteTimecard = async () => {
        if (timecard.id && !isNaN(Number(timecard.id))) {
            handleClose();
            openModal(
                <ConfirmationModal
                    onCancel={closeModal}
                    onConfirm={async () => {
                        if (isTrackedTimecard) {
                            dispatch(resetTimecardState({ userId, tenantId: activeTenantId }));
                        }
                        await deleteTimecard({ id: timecard.id, matterId: timecard.matter.id })
                            .unwrap()
                            .catch(console.error)
                            .finally(closeModal);
                    }}
                    content={<Typography>{t('timecards.delete-confirmation-text')}</Typography>}
                />
            );
        }
    };

    const menuItems: MenuItemType[] = [
        {
            label: t('timecards.duplicate'),
            onClick: handleDuplicate,
            isDisabled: !hasCreateTimecardPermission,
        },
        {
            label: t('timecards.delete'),
            sx: { color: theme.palette.text.error },
            onClick: handleDeleteTimecard,
            isDisabled: !timecard.isDeletable,
        },
    ];

    const hasActiveMenuItems = menuItems.some((item) => !item.isDisabled);

    return (
        <>
            {timecard.matter.isTimecardAddingBlocked ? (
                <BlockedTag />
            ) : (
                <>
                    {[TimecardStatusType.DRAFT, TimecardStatusType.FINISHED].includes(
                        timecard.timecardStatusTypeCode as TimecardStatusType
                    ) &&
                        hasCreateExpensePermission && <AddExpenseIconButton timecard={timecard} />}

                    {userId === timecard.employee.id &&
                        timecard.timecardStatusTypeCode === TimecardStatusType.DRAFT && (
                            <>
                                <FinishTimecardIconButton timecard={timecard} />
                                <TimecardTrackingButton timecard={timecard} />
                            </>
                        )}
                </>
            )}
            <>
                <CallForActionButton
                    id="timecard-hidden-menu-toggle"
                    disabled={!hasActiveMenuItems}
                    shape="rounded"
                    sx={{
                        backgroundColor: 'white',
                        color: theme.palette.grey['500'],
                        ['&:hover']: { color: 'black', backgroundColor: theme.palette.grey['200'] },
                    }}
                    aria-controls={open ? 'timecard-hidden-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </CallForActionButton>
                <Menu
                    id="timecard-hidden-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'timecard-hidden-menu-toggle',
                    }}
                >
                    {menuItems.map((item, index) =>
                        item.isDisabled ? null : (
                            <MenuItem key={`menu-item-${index}`} sx={item.sx} onClick={item.onClick}>
                                {item.label}
                            </MenuItem>
                        )
                    )}
                </Menu>
            </>
        </>
    );
};

export default TimecardActionButtons;

import { Grid } from '@mui/material';
import MainCard from 'components/MainCard';

type Props = {
    children: React.ReactNode;
};

const FormWrapper = ({ children }: Props) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MainCard title="" content={false} sx={{ overflow: 'visible', borderRadius: 0, borderWidth: 0 }}>
                    {children}
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default FormWrapper;

import React from 'react';
import { Typography, IconButton, Box, Theme, CSSObject } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
    title: string;
    onCancel: () => void;
    theme: Theme;
}

export const ModalHeader = ({ title, onCancel, theme }: Props): JSX.Element => {
    const style: CSSObject = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px 10px 23px',
        borderBottom: `solid 1px ${theme.palette.divider}`,
        borderRadius: '12px 12px 0 0',
    };

    return (
        <Box sx={style}>
            <Typography variant="h5">{title}</Typography>
            <IconButton size="medium" aria-label="close" color="inherit" sx={{ borderRadius: 5 }} onClick={onCancel}>
                <CloseOutlined />
            </IconButton>
        </Box>
    );
};

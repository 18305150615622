// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - MUI MENU ||============================== //

export default function Menu(theme: Theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-paper': {
                        borderRadius: 8,
                        boxShadow: theme.customShadows.z2,
                        minWidth: 156,
                    },
                },
            },
        },
    };
}

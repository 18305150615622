import React from 'react';
import PaddedLayout from '../../layout/MainLayout/PaddedLayout.tsx';
import { Box } from '@mui/material';

const Configuration = () => {
    return (
        <PaddedLayout>
            <Box></Box>
        </PaddedLayout>
    );
};

export default Configuration;

import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import InputWrapper from './InputWrapper';
import { CommonInputProps } from './InputTypes';
import { useMemoizedRules } from './useMemoizedRules';
import { t } from 'utils/getTranslations';
import ReadonlyField from '../rhf-other/ReadonlyField';

interface Props<T extends FieldValues> extends CommonInputProps<T> {
    maxRows?: number;
    minRows?: number;
    maxLength?: number;
    readonly?: boolean;
    textAreaReadonly?: boolean;
    decimalScale?: number;
    onBlur?: (value: string | null) => void;
}

const TextAreaInput = <T extends FieldValues>({
    name,
    disabled,
    required,
    label,
    shouldTranslateLabel,
    rightLabelComponent,
    placeholder,
    shouldTranslatePlaceholder = true,
    validate,
    onBlur,
    control,
    maxRows = 3,
    minRows = 7,
    maxLength,
    readonly = false,
    textAreaReadonly = false,
    decimalScale,
}: Props<T>) => {
    const rules = useMemoizedRules({ required, validate });
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { ref, value, onChange: innerOnChange, onBlur: innerOnBlur, ...field },
                fieldState: { error },
            }) => (
                <InputWrapper
                    name={name}
                    label={label}
                    shouldTranslateLabel={shouldTranslateLabel}
                    required={required}
                    readonly={!!readonly}
                    rightLabelComponent={rightLabelComponent}
                    hasErrors={!!error}
                >
                    {readonly && !textAreaReadonly ? (
                        <ReadonlyField value={value} decimalScale={decimalScale} />
                    ) : (
                        <TextField
                            inputRef={ref}
                            {...field}
                            onChange={(e) => {
                                innerOnChange(e);
                            }}
                            onBlur={() => {
                                innerOnBlur();
                                if (onBlur) {
                                    innerOnChange(onBlur(value));
                                } else {
                                    innerOnChange(value.trim());
                                }
                            }}
                            value={value}
                            id={name}
                            multiline
                            fullWidth
                            minRows={minRows}
                            maxRows={maxRows}
                            disabled={disabled}
                            required={required}
                            error={!!error}
                            helperText={error?.message}
                            placeholder={
                                placeholder ? (shouldTranslatePlaceholder ? t(placeholder) : placeholder) : undefined
                            }
                            inputProps={{ maxLength, readOnly: textAreaReadonly }}
                        />
                    )}
                </InputWrapper>
            )}
        />
    );
};

export default TextAreaInput;

import { Box, CardContent, Grid, Stack, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import SelectInput from 'components/forms/inputs/rhf-inputs/SelectInput';
import { getValidOptionsFromClassifier } from 'utils/formUtil';
import { ModalHeader } from '../ModalHeader';
import { ModalFooter } from '../ModalFooter';
import { t } from 'utils/getTranslations';
import { ExpenseCreateDto, ExpenseDto, ExpenseForm } from 'types/expenses';
import DecimalInput from 'components/forms/inputs/rhf-inputs/DecimalInput';
import DateInput from 'components/forms/inputs/rhf-inputs/DateInput';
import TextAreaInput from 'components/forms/inputs/rhf-inputs/TextAreaInput';
import CheckboxInput from 'components/forms/inputs/rhf-inputs/CheckboxInput';
import { TenantDto } from '../../../types/tenants';
import TimecardLanguageLabel from '../../../pages/timecards/labels/TimecardLanguageLabel.tsx';
import React, { useEffect, useMemo, useState } from 'react';
import DynamicSelectInput from 'components/forms/inputs/rhf-inputs/DynamicSelectInput';
import { useLazyGetEmployeesListWithFiltersQuery } from 'store/api/employees';
import { useGetMattersListQuery, useLazyGetMattersListQuery } from 'store/api/matters';
import Loader from 'components/Loader';
import { useClassifiers } from '../../../utils/useClassifiers.ts';

interface Props {
    titleId?: string;
    onChange?: (value: ExpenseCreateDto) => void;
    onChangeRaw?: (value: ExpenseForm) => void;
    onClose: () => void;
    initialData?: ExpenseForm;
    expense?: ExpenseDto;
    disableSelectMatter?: boolean;
    hideSelectMatter?: boolean;
    tenant?: TenantDto;
    readOnly: boolean;
    preselectMatterId?: number;
}

const defaultValues: ExpenseForm = {
    expenseType: null,
    currencyType: null,
    description: '',
    expenseAmount: '',
    expenseDate: '',
    isNonBillable: false,
    employee: null,
    matter: null,
    timecardId: null,
};

const ExpenseModal = (props: Props) => {
    const {
        titleId = 'expenses.edit',
        initialData,
        disableSelectMatter,
        hideSelectMatter,
        onClose,
        onChange,
        onChangeRaw,
        readOnly,
        tenant,
        preselectMatterId,
    } = props;
    const theme = useTheme();

    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['CURRENCY', 'EXPENSE_TYPE', 'LANGUAGE'],
    });

    const [initialModalData, setInitialModalData] = useState<ExpenseForm | undefined>();

    const { data: preselectedMatterQueryData } = useGetMattersListQuery(
        { pageSize: 1, page: 0, filters: `id==${preselectMatterId}` },
        { skip: preselectMatterId === undefined }
    );

    const preselectedMatterData = useMemo(() => {
        return preselectedMatterQueryData?.content.at(0);
    }, [preselectedMatterQueryData]);

    const { handleSubmit, control, setValue, getValues, watch } = useForm<ExpenseForm>({
        defaultValues: defaultValues,
        values: initialModalData,
    });
    const currentMatter = watch('matter');

    const { data: currentMatterQueryData } = useGetMattersListQuery(
        { pageSize: 1, page: 0, filters: `id==${currentMatter?.id}` },
        { skip: !currentMatter?.id }
    );

    const currentMatterData = useMemo(() => {
        if (!currentMatter) {
            return null;
        }
        return currentMatterQueryData?.content.at(0);
    }, [currentMatterQueryData, currentMatter]);

    useEffect(() => {
        const option =
            getValidOptionsFromClassifier(classifiers['CURRENCY']).filter((option) => {
                const matter = currentMatterData?.id === getValues('matter')?.id ? currentMatterData : null;
                if (hideSelectMatter) {
                    return option.value === tenant?.baseCurrencyTypeCode;
                }
                return option.value === matter?.pricelistCurrencyTypeCode;
            })?.[0] || null;
        setValue(
            'currencyType',
            option !== null ? { label: option.label as string, value: option.value as string } : null
        );
    }, [classifiers, getValues, setValue, hideSelectMatter, tenant?.baseCurrencyTypeCode, currentMatterData]);

    const handleOnChange = (data: ExpenseForm) => {
        if (onChange) onChange(mapFormDataToDto(data));
        if (onChangeRaw) onChangeRaw(data);
        onClose();
    };
    const onSave = async () => {
        await handleSubmit((data) => handleOnChange(data))();
    };

    useEffect(() => {
        if (preselectMatterId === undefined) {
            setInitialModalData(initialData);
            return;
        }
        if (preselectedMatterData) {
            setInitialModalData({
                ...initialData,
                matter: {
                    id: preselectedMatterData.id,
                    name: preselectedMatterData.name,
                    fullName: preselectedMatterData.fullName,
                },
            } as ExpenseForm);
        }
    }, [preselectedMatterData, preselectMatterId, initialData]);

    if (!initialModalData || isLoadingClassifiers) {
        return <Loader />;
    }

    return (
        <>
            <ModalHeader title={readOnly ? t('expenses.view-expense') : t(titleId)} onCancel={onClose} theme={theme} />
            <CardContent
                sx={{
                    padding: '38px 32px',
                    height: '100%',
                    overflowY: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        padding: '24px 16px',
                    },
                }}
            >
                <Stack direction="column" spacing={2}>
                    {!hideSelectMatter ? (
                        <DynamicSelectInput
                            setValue={setValue}
                            control={control}
                            label="expenses.form.case-client"
                            required
                            name="matter"
                            paginated={{
                                fetch: useLazyGetMattersListQuery,
                                searchField: 'fullName',
                            }}
                            getOptionLabel="fullName"
                            getOptionIdentifier="id"
                            placeholder="expenses.form.case-client"
                            readonly={disableSelectMatter || readOnly}
                        />
                    ) : null}

                    <SelectInput
                        setValue={setValue}
                        control={control}
                        label="expenses.form.expense-type"
                        required
                        name="expenseType"
                        options={
                            (getValidOptionsFromClassifier(classifiers['EXPENSE_TYPE']) as {
                                label: string;
                                value: string;
                            }[]) ?? []
                        }
                        getOptionLabel="label"
                        getOptionIdentifier="value"
                        placeholder="expenses.form.expense-type"
                        readonly={readOnly}
                    />

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <DecimalInput
                                    name="expenseAmount"
                                    control={control}
                                    label="expenses.form.amount"
                                    placeholder="expenses.form.amount"
                                    required
                                    readonly={readOnly}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <SelectInput
                                    setValue={setValue}
                                    control={control}
                                    label="expenses.form.currency"
                                    required
                                    disabled={true}
                                    name="currencyType"
                                    options={
                                        (getValidOptionsFromClassifier(classifiers['CURRENCY']).filter((option) => {
                                            const matter =
                                                currentMatterData?.id === getValues('matter')?.id
                                                    ? currentMatterData
                                                    : null;
                                            if (hideSelectMatter) {
                                                return option.value === tenant?.baseCurrencyTypeCode;
                                            }
                                            return option.value === matter?.pricelistCurrencyTypeCode;
                                        }) as {
                                            label: string;
                                            value: string;
                                        }[]) ?? []
                                    }
                                    getOptionLabel="label"
                                    getOptionIdentifier="value"
                                    placeholder="expenses.form.currency"
                                    readonly={readOnly}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <DynamicSelectInput
                        setValue={setValue}
                        control={control}
                        label="expenses.form.added-by"
                        required
                        name="employee"
                        paginated={{
                            fetch: useLazyGetEmployeesListWithFiltersQuery,
                            searchField: 'fullName',
                        }}
                        getOptionLabel="name"
                        getOptionIdentifier="id"
                        placeholder="expenses.form.added-by"
                        readonly={readOnly}
                    />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <DateInput
                                    control={control}
                                    name="expenseDate"
                                    label="date"
                                    required
                                    readonly={readOnly}
                                    maxDate={new Date()}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextAreaInput
                        name="description"
                        control={control}
                        label="expenses.form.details-of-expense"
                        placeholder="expenses.form.details-of-expense"
                        readonly={readOnly}
                        rightLabelComponent={
                            currentMatterData?.timecardLanguageCode ? (
                                <Box>
                                    <TimecardLanguageLabel
                                        classifierData={classifiers?.['LANGUAGE']}
                                        timecardLanguageTypeCode={currentMatterData?.timecardLanguageCode}
                                    />
                                </Box>
                            ) : null
                        }
                    />
                    <CheckboxInput
                        control={control}
                        name="isNonBillable"
                        label="expenses.form.is-non-billable"
                        readonly={readOnly}
                    />
                </Stack>
            </CardContent>
            <ModalFooter onCancel={onClose} onSave={onSave} readOnly={readOnly} theme={theme} />
        </>
    );
};

export default ExpenseModal;

const mapFormDataToDto = (expense: ExpenseForm): ExpenseCreateDto => ({
    expenseTypeCode: expense?.expenseType?.value ?? null,
    currencyTypeCode: expense?.currencyType?.value ?? null,
    description: expense?.description ?? '',
    expenseAmount: expense?.expenseAmount ?? null,
    expenseDate: expense?.expenseDate ? new Date(expense.expenseDate) : null,
    isNonBillable:
        expense?.isNonBillable !== undefined && expense?.isNonBillable !== null ? expense.isNonBillable : false,
    employeeId: expense?.employee?.id ?? null,
    matterId: expense?.matter?.id ?? null,
    timecardId: expense?.timecardId ?? null,
});

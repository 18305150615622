// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function Accordion(theme: Theme) {
    return {
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                square: true,
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.palette.divider}`,
                    '--borderRadius': '8px',
                    borderRadius: 'var(--borderRadius)',
                    '&:before': {
                        display: 'none',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.grey[50],
                        color: theme.palette.grey[300],
                        opacity: 1,
                    },
                },
            },
        },
    };
}

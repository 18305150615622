import { Checkbox, FormControlLabel, Stack, Tooltip } from '@mui/material';
import { t } from 'utils/getTranslations';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = {
    onChange: (value: boolean) => void;
    value?: boolean;
};

const SearchMyCasesCheckbox = ({ onChange, value }: Props): JSX.Element => {
    return (
        <Stack direction="row" alignItems="center">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        color="secondary"
                        onChange={(e) => {
                            onChange(e.target.checked);
                        }}
                        size="large"
                        sx={{ py: 0 }}
                    />
                }
                sx={{ marginRight: 1 }}
                label={t('timecards.form.search-my-cases')}
            />
            <Tooltip title={t('matters.tooltips.my-cases')} placement="bottom">
                <InfoOutlinedIcon sx={{ ml: '1px', height: '1.2rem' }} htmlColor="#bfbfbf" />
            </Tooltip>
        </Stack>
    );
};

export default SearchMyCasesCheckbox;

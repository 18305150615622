import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import config from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useReturnToLocation } from 'utils/useNavigateWithReturnState';

// ==============================|| GUEST GUARD ||============================== //

const LOGIN_PATHS = [
    '/',
    '/login',
    '/forgot-password',
    '/check-mail',
    '/reset-password',
    '/set-password',
    '/code-verification',
];

const GuestGuard = ({ children }: GuardProps): React.ReactElement | null => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const returnTo = useReturnToLocation('/login');
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            if ('/mfa-setup' === location.pathname) {
                //allow this page to be rendered with not-auth layout while being logged in
                return;
            }
            const redirectPath =
                typeof returnTo === 'string' && LOGIN_PATHS.includes(returnTo) ? config.defaultPath : returnTo;
            navigate(redirectPath, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

export default GuestGuard;

import React from 'react';
import { CommonInputProps } from './InputTypes';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { Checkbox, InputLabel, Stack } from '@mui/material';
import { t } from 'utils/getTranslations';
import { useMemoizedRules } from './useMemoizedRules';
import InputWrapper from './InputWrapper';
import ReadonlyField from '../rhf-other/ReadonlyField';

interface Props<T extends FieldValues> extends CommonInputProps<T> {
    onChange?: (value: boolean) => void;
    readonly?: boolean;
}

const CheckboxInput = <T extends FieldValues>({
    name,
    control,
    disabled,
    label,
    required,
    rightLabelComponent,
    shouldTranslateLabel = true,
    wrapperSx,
    validate,
    onChange,
    readonly = false,
}: Props<T>) => {
    const rules = useMemoizedRules({ required, validate });
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState: { error } }) =>
                readonly ? (
                    <InputWrapper
                        name={name}
                        label={label}
                        required={required}
                        readonly={!!readonly}
                        rightLabelComponent={rightLabelComponent}
                        shouldTranslateLabel={shouldTranslateLabel}
                        hasErrors={!!error}
                    >
                        <ReadonlyField value={field.value ? t('yes') : t('no')} />
                    </InputWrapper>
                ) : (
                    <Stack direction="row" alignItems="center" sx={wrapperSx}>
                        <Stack direction="row" alignItems="center">
                            <Checkbox
                                id={name}
                                color="secondary"
                                size="large"
                                disabled={disabled}
                                {...field}
                                checked={field.value}
                                onChange={(event, checked) => {
                                    field.onChange(event.target.checked as PathValue<T, Path<T>>);
                                    if (onChange) {
                                        onChange(checked);
                                    }
                                }}
                                sx={{ pl: 0 }}
                            />
                            {label && (
                                <InputLabel htmlFor={name} required={required} error={!!error}>
                                    {shouldTranslateLabel ? t(label) : label}
                                </InputLabel>
                            )}
                        </Stack>
                        {rightLabelComponent}
                    </Stack>
                )
            }
        />
    );
};

export default CheckboxInput;

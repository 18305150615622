import { Theme, useTheme } from '@mui/material/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CSSObject,
    Grid,
    List,
    ListItem,
    Stack,
    Typography,
} from '@mui/material';
import { TimecardListItemData, TimecardStatusType } from 'types/timecards';
import { format, isSameDay } from 'date-fns';
import { t } from 'utils/getTranslations';
import TimecardListItem from './TimecardListItem';
import LabelledData from './LabelledData';
import { ClassifierData } from 'types/classifiers';
import { dateStringToDateObject } from 'utils/parseDate';
import HoursDisplay from 'components/util/HoursDisplay';
import MoneyDisplay from 'components/util/MoneyDisplay';

export interface GroupedTimecards {
    loggedTimeSumInHours: number;
    billableTimeSumInHours: number;
    baseCurrency?: string;
    totalAmountInBaseCurrency: number;
    timecards: TimecardListItemData[];
}

type Props = {
    date: string;
    timecardGroup: GroupedTimecards;
    classifier: ClassifierData;
    compact?: boolean;
    selectedIds?: number[];
    setSelectedIds?: (value: number[]) => void;
    selectableTimecards?: boolean;
};

const headerStyles = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.grey[50],
    padding: '9px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
});

const cardContentStyles: CSSObject = {
    padding: '0',
    paddingBottom: '0 !important',
};

const boldStyle = { fontWeight: 600 };

const TimecardGroup = ({
    date,
    timecardGroup,
    classifier,
    compact,
    selectedIds,
    setSelectedIds,
    selectableTimecards = false,
}: Props): JSX.Element => {
    const theme = useTheme();

    const getGroupDate = () => {
        const dateObj = dateStringToDateObject(date);
        const isToday = isSameDay(dateObj, new Date());
        if (isToday) {
            return `${t('time.today')}, ${format(dateObj, t('timecards.list.group-date-format-without-day'))}`;
        } else {
            return format(dateObj, t('timecards.list.group-date-format'));
        }
    };

    return (
        <Card
            sx={{
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: 0,
                '&:not(:first-of-type)': {
                    mt: compact ? 'initial' : 3,
                },
                minWidth: 600,
            }}
        >
            <CardHeader
                sx={headerStyles(theme)}
                title={
                    <Grid container justifyContent="space-between" alignItems="baseline">
                        <Grid item>
                            <Typography variant="subtitle2">{getGroupDate()}</Typography>
                        </Grid>
                        <Grid item>
                            <Stack spacing={1} direction="row">
                                <LabelledData label="timecards.list.total-time" sx={boldStyle}>
                                    <HoursDisplay value={timecardGroup.loggedTimeSumInHours} />
                                </LabelledData>
                                <LabelledData label="timecards.list.billable-time" sx={boldStyle}>
                                    <HoursDisplay value={timecardGroup.billableTimeSumInHours} />
                                </LabelledData>
                                <LabelledData label="timecards.list.total-amount" sx={boldStyle}>
                                    <MoneyDisplay
                                        amount={timecardGroup.totalAmountInBaseCurrency}
                                        currency={timecardGroup.baseCurrency}
                                    />
                                </LabelledData>
                            </Stack>
                        </Grid>
                    </Grid>
                }
            >
                {date}
            </CardHeader>
            <CardContent sx={cardContentStyles}>
                <List disablePadding>
                    {timecardGroup.timecards.map((timecard, index) => (
                        <ListItem
                            key={timecard.id}
                            disableGutters
                            disablePadding
                            divider={index !== timecardGroup.timecards.length - 1}
                        >
                            <>
                                {selectableTimecards &&
                                    timecard.timecardStatusTypeCode === TimecardStatusType.FINISHED && (
                                        <Checkbox
                                            id={timecard.id.toString()}
                                            color="secondary"
                                            size="large"
                                            onChange={(event, checked) =>
                                                checked
                                                    ? setSelectedIds?.([...(selectedIds || []), timecard.id])
                                                    : setSelectedIds?.(selectedIds!.filter((id) => id !== timecard.id))
                                            }
                                        />
                                    )}
                                <TimecardListItem timecard={timecard} classifier={classifier} compact={compact} />
                            </>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default TimecardGroup;

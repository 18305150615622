import DrawerPortal from 'components/drawer/DrawerPortal';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { useGetNotificationsListQuery } from 'store/api/notifications';
import { closeTemporaryDrawer, toggleTemporaryDrawer } from 'store/reducers/temporaryDrawer';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Chip, styled, Theme, useTheme } from '@mui/material';

import DrawerContent from './Notifications/DrawerContent';
import StyledHeaderButton from './StyledHeaderButton';

const Notifications: React.FC = () => {
    const theme = useTheme();
    const isOpen = useSelector((state) => state.temporaryDrawer.activeDrawerId === 'notifications');

    // Refetch notifications every 4 min
    const { data: notifications, isLoading } = useGetNotificationsListQuery({}, { pollingInterval: 240000 });

    const [unreadCount, unreadImportantCount] = useMemo(() => {
        let unreadImportantCount = 0;
        let unreadCount = 0;
        (notifications ?? [])?.forEach((val) => {
            if (val.isImportant && !val.readAt) unreadImportantCount += 1;
            if (!val.readAt) unreadCount += 1;
        });
        return [unreadCount, unreadImportantCount];
    }, [notifications]);

    const toggleDrawer = () => {
        if (isOpen) dispatch(closeTemporaryDrawer());
        else dispatch(toggleTemporaryDrawer('notifications'));
    };

    if (isLoading) return null;
    return (
        <>
            <StyledHeaderButton size="small" color="inherit" onClick={() => toggleDrawer()}>
                <NotificationIconWrap theme={theme} hasUnread={!!unreadCount}>
                    <NotificationsIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </NotificationIconWrap>
                {unreadCount}
                {unreadImportantCount ? (
                    <StyledChip
                        label={
                            <FormattedMessage id="notifications.important" values={{ count: unreadImportantCount }} />
                        }
                    />
                ) : null}
            </StyledHeaderButton>
            <DrawerPortal
                content={<DrawerContent notifications={notifications ?? []} theme={theme} />}
                drawerId="notifications"
                applyColor="secondary"
                type="temporary"
                sx={{
                    width: '480px',
                    height: '100%',
                    [theme.breakpoints.down('sm')]: {
                        width: 'calc(100vw - 48px)',
                    },
                }}
            />
        </>
    );
};

export const StyledChip = styled(
    Chip,
    {}
)(({ theme }) => ({
    alignSelf: 'center',
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: 600,
    minHeight: '24px',
    borderRadius: '4px',
    height: 'auto',
    marginLeft: '12px',
    marginRight: '-4px',
    '.MuiChip-label': {
        padding: '0px 8px',
    },
}));

export const NotificationIconWrap = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hasUnread',
})(({ theme, hasUnread }: { theme: Theme; hasUnread: boolean }) => ({
    width: 'fit-content',
    height: 'fit-content',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
        content: '""',
        display: hasUnread ? 'block' : 'none',
        position: 'absolute',
        backgroundColor: theme.palette.error.main,
        height: '8px',
        width: '8px',
        top: '-1px',
        right: '-1px',
        borderRadius: '50%',
    },
}));

export default Notifications;

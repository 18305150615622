import { AutomatedNotification } from './notifications';
import { NamedEntityMinDto } from './other';

export interface ChatStoreProps {
    objectId: number | null;
    objectType: ObjectType | null;
    isOpen: boolean;
}

export enum ObjectType {
    INVOICE = 'INVOICE',
    MATTER = 'MATTER',
    PARTY = 'PARTY',
}

export interface ChatMessageCreateDto {
    employee: number | null;
    manualMessageText: string | null;
    objectType: string | null;
    objectId: number | string | null;
}

export interface ChatForm {
    message: string;
    employee: NamedEntityMinDto | null;
    objectType: ObjectType | null;
    objectId: number | null;
}

export interface MessageDetailsDto extends AutomatedNotification {
    manualMessageText: string;
    objectType: string;
    objectId: number;
    employee: NamedEntityMinDto;
}

export enum FeatureFlag {
    ADDRESS_ADDITIONAL_INFORMATION = 'ADDRESS_ADDITIONAL_INFORMATION',
    AI_SUMMARY_ENABLED = 'AI_SUMMARY_ENABLED',
    BASE_CURRENCY_CONVERSION_DATE_CONTROL = 'BASE_CURRENCY_CONVERSION_DATE_CONTROL',
    CUSTOM_FIELD_TRANSLATION = 'CUSTOM_FIELD_TRANSLATION',
    DMS_DATABASE = 'DMS_DATABASE',
    DMS_NUMBER = 'DMS_NUMBER',
    DOWNLOAD_CONTRACT_TEMPLATE = 'DOWNLOAD_CONTRACT_TEMPLATE',
    EMPLOYEE_VAT_REG_NUM = 'EMPLOYEE_VAT_REG_NUM',
    INVOICE_ACCOUNTING_TYPE = 'INVOICE_ACCOUNTING_TYPE',
    INVOICING_INFO_ENABLED = 'INVOICING_INFO_ENABLED',
    JOB_POSITION_EXTERNAL_ID_ENABLED = 'JOB_POSITION_EXTERNAL_ID_ENABLED',
    JOB_POSITION_TYPE = 'JOB_POSITION_TYPE',
    LOCALIZED_FIELDS = 'LOCALIZED_FIELDS',
    MATTER_ACCOUNTING_REVENUE_TYPE = 'MATTER_ACCOUNTING_REVENUE_TYPE',
    PAYMENT_CURRENCY_CONVERSION_DATE_CONTROL = 'PAYMENT_CURRENCY_CONVERSION_DATE_CONTROL',
    SERVICE_DATE_CONTROL = 'SERVICE_DATE_CONTROL',
    SIGNED_BY_ON_INVOICE_INSTEAD = 'SIGNED_BY_ON_INVOICE_INSTEAD',
    SUB_PRACTICE_AREA = 'SUB_PRACTICE_AREA',
    USE_MANUAL_PERSON_TITLES = 'USE_MANUAL_PERSON_TITLES',
    USE_MFA = 'USE_MFA',
}

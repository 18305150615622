import { Chip as MuiChip, ChipProps, Theme } from '@mui/material';
import { CSSProperties, ElementType, useMemo } from 'react';

export type ChipType =
    | 'neutral'
    | 'success'
    | 'warning'
    | 'error'
    | 'outlined'
    | 'inactive'
    | 'link'
    | 'custom'
    | 'info'
    | undefined;

interface StatusChipProps extends ChipProps {
    type?: ChipType;
    theme: Theme;
    link?: string;
    component?: ElementType<any>;
    whiteSpace?: CSSProperties['whiteSpace'];
}

const StatusChip: React.FC<StatusChipProps> = (props) => {
    const { theme, type = 'neutral', sx, link, whiteSpace, ...rest } = props;

    const defaultLabelStyle = {
        padding: '1px 8px',
        lineHeight: '20px',
        ...(whiteSpace !== undefined ? { whiteSpace } : {}),
        ...(props.icon !== undefined && props.label === undefined
            ? {
                  padding: 0,
              }
            : {}),
    };

    const defaultStyle = {
        fontSize: '12px',
        minHeight: '24px',
        minWidth: '24px',
        height: 'auto',
        fontWeight: 600,
        backgroundColor: theme.palette.common.white,
        alignSelf: 'flex-end',
        '.MuiChip-label': defaultLabelStyle,
        ...(props.icon !== undefined && props.label === undefined
            ? {
                  '.MuiChip-icon': {
                      margin: '0',
                  },
              }
            : {}),
        ...sx,
    };

    const style = useMemo(() => {
        switch (type) {
            case 'custom': {
                return {
                    ...defaultStyle,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                    },
                };
            }
            case 'neutral': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.grey[200],
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.grey.A400,
                    },
                };
            }
            case 'success': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.success.lighter,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.text.success,
                    },
                };
            }
            case 'warning': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.warning.lighter,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.text.warning,
                    },
                };
            }
            case 'error': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.error.lighter,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.text.error,
                    },
                };
            }
            case 'outlined': {
                return {
                    ...defaultStyle,
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                    },
                };
            }
            case 'inactive': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.grey.A100,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.grey[400],
                    },
                };
            }
            case 'link': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.info.lighter,
                    cursor: 'pointer',
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.text.link,
                        userSelect: 'unset',
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.info.lighter,
                        textDecoration: 'underline',
                        textDecorationColor: theme.palette.text.link,
                    },
                    '&:focus-visible': {
                        backgroundColor: theme.palette.info.lighter,
                        outline: '-webkit-focus-ring-color auto 1px',
                    },
                };
            }
            case 'info': {
                return {
                    ...defaultStyle,
                    backgroundColor: theme.palette.info.lighter,
                    '.MuiChip-label': {
                        ...defaultLabelStyle,
                        color: theme.palette.text.link,
                    },
                };
            }
        }
    }, [type]);

    if (link) {
        const linkStyle = {
            cursor: 'pointer',
            fontWeight: 600,
        };
        return (
            <a
                target="_blank"
                href={link}
                rel="noreferrer"
                style={{
                    textDecoration: 'none',
                    outlineOffset: '0',
                }}
            >
                <MuiChip sx={{ ...style, ...linkStyle }} {...rest} />
            </a>
        );
    }

    return <MuiChip sx={style} {...rest} />;
};

export default StatusChip;

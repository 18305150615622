import { ContactMinDto } from './contacts';
import { DocumentTemplateMinDto } from './document';
import { Employment } from './employees';
import { MatterBasicDetailsListItemMinDto, MatterListItemMin } from './matters';
import { AuditDates, BillingInvoiceInfo, MinDto, NamedEntityMinDto } from './other';

export enum InvoicePaymentTypeCode {
    BANK_TRANSFER = 'BANK_TRANSFER',
    PREPAID_INVOICE = 'PREPAID_INVOICE',
}

export enum EditIntentTypeCode {
    MOVE_TO_ANOTHER_CASE = 'MOVE_TO_ANOTHER_CASE',
    MARK_AS_NONBILLABLE = 'MARK_AS_NONBILLABLE',
    POSTPONE = 'POSTPONE',
}

export enum InvoiceStatusType {
    DRAFT = 'DRAFT',
    REVIEWED = 'REVIEWED',
    APPROVED = 'APPROVED',
    FINALIZED = 'FINALIZED',
    SENT = 'SENT',
    PAID = 'PAID',
    CREDITED = 'CREDITED',
    POSTPONED = 'POSTPONED',
}

export enum InvoiceType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    PREPAYMENT = 'PREPAYMENT',
}

export enum InvoiceTransactionActionType {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    POSTPONED = 'POSTPONED',
}

export enum InvoiceEmailTemplateType {
    DRAFT = 'DRAFT',
    FINALIZED = 'FINALIZED',
}

export interface InvoiceStatusUpdateDto {
    id: number;
    invoiceStatusTypeCode?: InvoiceStatusType | null;
    transitionComment?: string;
    transitionActionTypeCode?: InvoiceTransactionActionType | null;
}

export interface MatterAmountDto {
    matterId: number;
    amount: string;
}

export interface InvoiceStatusChangeData {
    id: number;
    invoiceTimecards: InvoiceTimecardMin[];
    invoiceTransitions: InvoiceTransitionDto[];
}

export interface CreatedDraftsDto {
    createdInvoices: number[];
    erroredMatters: number[];
}

export interface InvoiceDataListItem {
    id: number;
    clientParty: ContactMinDto;
    invoiceTypeCode: string;
    invoiceNumber: string;
    invoiceDate: string;
    dueDate: string;
    invoiceStatusTypeCode: string;
    invoiceTotals?: InvoiceListItemTotalsDto;
    matter: InvoiceMatterItemDto;
}

export interface InvoiceExportListItemDto extends InvoiceDataListItem {
    issuedByOffice: string;
    issuedByCompany: string;
    payerName: string;
    payerVatNumber: string;
    payerCountryCode: string;
    invoiceCurrencyTypeCode: string;
    feesBeforeAdjustments: number;
    discountAdjustments: number;
    otherAdjustments: number;
    expenses: number;
    nonVatExpenses: number;
    totalWithoutVat: number;
    totalVat: number;
    totalAmount: number;
    prepayment: number;
    payableAmount: number;
    remainsUnpaid: number;
    billingInstructions: string;
    fromDate: string;
    toDate: string;
    paidOnDate: string;
    issuedForCases: string;
    serviceAgreements: string;
    serviceAgreementSentOnDate: string;
    primaryContacts: string;
    supervisingPartners: string;
    responsibleLawyers: string;
}

export interface InvoiceMatterItemDto {
    id: number;
    name: string;
    caseNumber: string;
    fullName: string;
    pricelistCurrencyTypeCode?: string;
    pricingLimitationTypeCode?: string;
    capUnitTypeCode?: string;
    capLimit: number;
    fixedFee: number;
}

export interface PrepaymentDataListItem {
    id: number;
    invoiceDate: string;
    invoiceDescription: string;
    payments: InvoicePayment[];
    paymentCurrencyTypeCode: string;
    totalAmount: number;
    usedAmount: number;
    remainingAmount: number;
    usedAmountByCurrentInvoice: number;
    isConnected: boolean;
}

export interface InvoiceData {
    id: number;
    clientParty: ContactMinDto & BillingInvoiceInfo & AuditDates;
    invoiceTypeCode: string;
    invoiceAccountingTypeCode?: string;
    invoiceNumber: string;
    invoiceDate: string;
    dueDate?: string;
    serviceDate?: string;
    maxInvoiceTimecardOrExpenseDate?: string;
    invoiceStatusTypeCode: string;
    invoicedByOffice: NamedEntityMinDto;
    invoiceTemplate?: DocumentTemplateMinDto;
    invoiceSpecTemplate?: DocumentTemplateMinDto;
    signedByEmployee?: NamedEntityMinDto & { employeeJobRole?: Employment | null };
    primaryRecipientParty: ContactMinDto;
    primaryRecipientAdditionalEmails?: string;
    secondaryRecipientParty?: ContactMinDto;
    secondaryRecipientAdditionalEmails?: string;
    invoiceCurrencyTypeCode: string;
    paymentCurrencyTypeCode: string;
    billingInstructions?: string;
    invoiceDescription?: string;
    invoiceMatters: InvoiceMatterMinWithBillingInvoiceInfoDto[];
    createdAt: Date;
    createdBy: string;
    invoiceTransitions: InvoiceTransitionDto[];
    invoicePayments: InvoicePayment[];
    hasUninvoicedTimecardsAll: boolean;
    hasUninvoicedTimecardsPrevMonth: boolean;
    hasUninvoicedExpensesAll: boolean;
    hasUninvoicedExpensesPrevMonth: boolean;
    invoicePrepaymentAmount: number;
    hasMatterHourlyPriceChanged: boolean;
    hasInvoiceTimecardEmployeeChanged: boolean;
    hasCreditInvoice: boolean;
    creditInvoices?: InvoiceMinDto[];
    debitInvoiceId?: number;
    matter: MatterBasicDetailsListItemMinDto;
    chatMessageCount: number;
    isSpecAvailable: boolean;
    containsMissingJobRole: boolean;
    paymentCurrencyConversionDate?: string;
    baseCurrencyConversionDate?: string;
}

export interface GroupingInvoiceRowUpdateDto {
    id: number;
    groupingRowId: string;
    sourceType: string;
    description: string;
    appliedCap?: number;
}

export interface InvoiceTotals {
    invoiceDetailsTotals?: InvoiceDetailsTotals[];
    baseCurrencyTypeCode: string;
    invoiceCurrencyTypeCode: string;
    paymentCurrencyTypeCode: string;
    feeAmountPaymentCurrency: number;
    feeVatAmountPaymentCurrency: number;
    expenseAmountPaymentCurrency: number;
    expenseVatAmountPaymentCurrency: number;
    rowAmountPaymentCurrency: number;
    rowVatAmountPaymentCurrency: number;
    feeAmountOriginalCurrency: number;
    feeVatAmountOriginalCurrency: number;
    feeAmountBaseCurrency: number;
    expenseAmountInvoiceCurrency: number;
    expenseVatAmountInvoiceCurrency: number;
    expenseAmountBaseCurrency: number;
    rowAmountInvoiceCurrency: number;
    rowVatAmountInvoiceCurrency: number;
    rowAmountBaseCurrency: number;
}

export interface InvoiceDetailsTotals {
    matterId: number;
    matterNumber: string;
    matterName: string;
    fullName: string;
    feeArrangementTypeCode: string;
    invoiceTimecardTotals?: InvoiceTimecardTotals[];
    invoiceExpenseTotals?: InvoiceExpenseTotals[];
    invoiceRowTotals?: InvoiceRowTotals[];
}

export interface InvoiceTimecardTotals {
    groupingRowId?: string;
    description?: string;
    timecardGroupingTypeCode?: string;
    totalHours?: number;
    totalBilledHours?: number;
    averageHourlyPrice?: number;
    totalBeforeAdjustments?: number;
    discountRate?: number;
    amountAdjustmentDiscount?: number;
    amountAdjustmentCap?: number;
    totalAfterAdjustments?: number;
    vatRate?: number;
    vatAmount?: number;
    totalAfterAdjustmentsWithVat?: number;
    vatAmountPaymentCurrency?: number;
    totalWithVatPaymentCurrency?: number;
    totalWoVatPaymentCurrency?: number;
}

export interface InvoiceExpenseTotals {
    description?: string;
    totalExpenses?: number;
    itemTotal?: number;
    discountRate?: number;
    discountAmount?: number;
    totalAfterDiscount?: number;
    appliedCap?: number;
    totalWoVat?: number;
    vatRate?: number;
    vatAmount?: number;
    totalWithVat?: number;
    groupingRowId?: string;
    vatAmountPaymentCurrency?: number;
    totalWithVatPaymentCurrency?: number;
    totalWoVatPaymentCurrency?: number;
}

export interface InvoiceRowTotals {
    invoiceRowId: number;
    description?: string;
    itemTotal?: number;
    discountRate?: number;
    discountAmount?: number;
    amountBeforeDiscount?: number;
    totalWoVat?: number;
    vatRate?: number;
    vatAmount?: number;
    totalWithVat?: number;
    invoiceRowTypeCode?: string;
    vatAmountPaymentCurrency?: number;
    totalWithVatPaymentCurrency?: number;
    totalWoVatPaymentCurrency?: number;
}

export interface InvoiceMinDto {
    id: number;
    invoiceTypeCode: string;
    invoiceNumber: string;
    invoiceDate: string;
    dueDate: string;
    invoiceStatusTypeCode: string;
    debitInvoiceId?: number;
}

export interface ExistingPrepaymentDto {
    id: number;
    remainingAmount: number;
    isConnected: boolean;
    payments: InvoicePayment[];
}

export interface ExistingPrepaymentForm {
    id: number;
    invoiceDate: string;
    invoiceDescription: string;
    payments: InvoicePayment[];
    totalAmount: number;
    usedAmount: number;
    remainingAmount: number;
    usedAmountByCurrentInvoice: number;
    isConnected: boolean;
}

export interface InvoiceTimecardMin {
    id: number;
    matter: MatterListItemMin;
}

export interface InvoiceTransitionDto {
    id: number;
    invoiceId: number;
    fromInvoiceStatusType: InvoiceStatusType;
    toInvoiceStatusType: InvoiceStatusType;
    invoiceTransitionActionType: InvoiceTransactionActionType;
    changedByEmployee: {
        name: string;
        id: number;
    };
    comment?: string;
    createdAt: Date;
    lastModifiedAt?: Date;
}

export interface InvoiceForm {
    isPaymentCurrencyDifferent: boolean;
    paymentCurrencyType: { label: string; value: string } | null;
    invoiceAccountingType: { label: string; value: string } | null;
    invoicedByOffice: NamedEntityMinDto | null;
    invoiceTemplate: NamedEntityMinDto | null;
    invoiceSpecTemplate: NamedEntityMinDto | null;
    signedByEmployee: NamedEntityMinDto | null;
    client: ContactMinDto;
    billingInstructions: string | null;
    invoiceDate: string | null;
    dueDate: string | null;
    serviceDate: string | null;
    maxInvoiceTimecardOrExpenseDate: string | null;
    invoiceCurrencyType: { label: string; value: string } | null;
    invoiceNumber: string | null;
    invoiceDescription: string | null;
    primaryRecipientParty: ContactMinDto | null;
    primaryRecipientAdditionalEmails: string | null;
    secondaryRecipientParty: ContactMinDto | null;
    secondaryRecipientAdditionalEmails: string | null;
    invoiceTransitions: InvoiceTransitionDto[];
    existingPrepayments: ExistingPrepaymentForm[];
    paymentCurrencyConversionDate: string | null;
    baseCurrencyConversionDate: string | null;
}

export interface InvoiceTotalsForm {
    invoiceDetailsTotals?: InvoiceDetailsTotalsFormDto[];
}

export interface InvoiceDetailsTotalsFormDto {
    matterId: number;
    matterNumber: string;
    matterName: string;
    fullName: string;
    invoiceTimecardTotals?: InvoiceTotalsFormDto[];
    invoiceExpenseTotals?: InvoiceTotalsFormDto[];
    invoiceRowTotals?: InvoiceTotalsFormDto[];
}

export interface InvoiceTotalsFormDto {
    description?: string;
    discountRate?: number;
    appliedCap?: number;
    totalWoVat?: number;
    vatRate?: number;
    totalWithVat?: number;
}

export interface InvoiceUpdateDto {
    id: number;
    paymentCurrencyTypeCode: string | null;
    invoicedByOffice: NamedEntityMinDto | null;
    invoiceAccountingTypeCode: string | null;
    invoiceTemplate: number | null;
    invoiceSpecTemplate: number | null;
    signedByEmployee: NamedEntityMinDto | null;
    billingInstructions: string | null;
    invoiceDate: string | null;
    dueDate: string | null;
    serviceDate: string | null;
    invoiceNumber: string | null;
    invoiceDescription: string | null;
    primaryRecipientParty: NamedEntityMinDto | null;
    primaryRecipientAdditionalEmails: string | null;
    secondaryRecipientParty: NamedEntityMinDto | null;
    secondaryRecipientAdditionalEmails: string | null;
    existingPrepayments: ExistingPrepaymentDto[];
    paymentCurrencyConversionDate: string | null;
    baseCurrencyConversionDate: string | null;
}

export interface InvoicePaymentFormDto {
    invoiceId: number | null;
    date: string | null;
    amount: string | null;
}

export interface InvoicePayment {
    id: number;
    invoicePaymentTypeCode: string;
    date: string;
    amount: number;
    currencyTypeCode: string;
    isPartOfInvoice?: boolean;
    invoice: InvoiceMinDto;
    prepaidInvoice?: InvoiceMinDto;
}

export interface InvoiceRowCreateDto {
    invoiceId: number;
    matterId: number;
    description: string;
    totalAmount: number;
    discountRate: number;
}

export interface InvoiceRowUpdateDto {
    id: number;
    description: string;
    totalAmount: number;
    discountRate: number;
}

export interface InvoiceEmailForm {
    to: string[];
    cc: string[];
    replyTo: string[];
    subject: string;
    content: string;
    files: File[] | null;
    generatedAttachments: string[];
}

export interface GroupingInvoiceRowEditForm {
    description: string;
    appliedCap?: number;
    groupingRowId: string;
    sourceType: string;
}

export interface InvoiceRowForm {
    invoiceRowId: number;
    matterId: number;
    description: string;
    itemTotal?: number;
    amountBeforeDiscount?: number;
    discountRate?: number;
}

export interface InvoiceListItemTotalsDto {
    originalCurrencyTypeCode?: string;
    rowCurrencyTypeCode?: string;
    baseCurrencyTypeCode?: string;
    feeAmountOriginalCurrency?: number;
    feeAmountBaseCurrency?: number;
    rowAmountInvoiceCurrency?: number;
    rowAmountBaseCurrency?: number;
    invoiceCurrencyTypeCode?: string;
    expenseAmountInvoiceCurrency?: number;
    expenseAmountBaseCurrency?: number;
}

export interface ExpenseVatListItem {
    vatRate?: number;
    totalWoVAT?: number;
    totalVAT?: number;
}

export interface InvoiceMatterMinWithBillingInvoiceInfoDto {
    matterId: number;
    matterName: string;
    fullName: string;
    hasMatterCapError: boolean;
    matterBillingDetails: BillingInvoiceInfo & AuditDates;
    serviceAgreementBillingDetails: BillingInvoiceInfo & AuditDates;
}

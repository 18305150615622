import TabsGallery, { TabType } from 'components/TabsGallery';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { dispatch, useSelector } from 'store';
import {
    useArchiveNotificationMutation,
    useArchiveNotificationsMutation,
    useReadNotificationMutation,
    useReadNotificationsMutation,
} from 'store/api/notifications';
import { AutomatedNotification, NotificationCategory } from 'types/notifications';
import { t } from 'utils/getTranslations';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Button, IconButton, Stack, styled, Theme, Typography } from '@mui/material';

import NotificationItem from './NotificationItem';
import { closeTemporaryDrawer } from 'store/reducers/temporaryDrawer';
import CountBox from 'layout/MainLayout/Header/CountBox';

interface Props {
    notifications: AutomatedNotification[];
    theme: Theme;
}

interface PropsNotificationList extends Props {
    unread: number;
    handleRead: (id: string | number | 'all') => Promise<void>;
    handleArchive: (id: string | number | 'all') => Promise<void>;
    areButtonsDisabled: boolean;
}

const DrawerContent: React.FC<Props> = ({ notifications, theme }) => {
    const [readNotification] = useReadNotificationMutation();
    const [archiveNotification] = useArchiveNotificationMutation();
    const [readNotifications] = useReadNotificationsMutation();
    const [archiveNotifications] = useArchiveNotificationsMutation();

    const { buttonsDisabled: areButtonsDisabled } = useSelector((state) => state.buttons);

    const [caseAndClientNotifications, invoicingNotifications] = useMemo((): [
        AutomatedNotification[],
        AutomatedNotification[],
    ] => {
        const caseAndClient: AutomatedNotification[] = [];
        const invoicing: AutomatedNotification[] = [];

        notifications.forEach((val) => {
            if (val.category === NotificationCategory.CASE_AND_CLIENT) caseAndClient.push(val);
            else if (val.category === NotificationCategory.INVOICING) invoicing.push(val);
        });
        return [caseAndClient, invoicing];
    }, [notifications]);

    const handleRead = useCallback(async (id: string | number | 'all', type: NotificationCategory): Promise<void> => {
        if (id === 'all') {
            await readNotifications({ type }).unwrap().then().catch(console.error);
        } else if (id && !isNaN(Number(id))) {
            await readNotification({ id }).unwrap().then().catch(console.error);
        }
    }, []);

    const handleArchive = useCallback(
        async (id: string | number | 'all', type: NotificationCategory): Promise<void> => {
            if (id === 'all') {
                await archiveNotifications({ type }).unwrap().then().catch(console.error);
            } else if (id && !isNaN(Number(id))) {
                await archiveNotification({ id }).unwrap().then().catch(console.error);
            }
        },
        []
    );

    const TABS: TabType[] = useMemo(() => {
        const caseAndClientUnreadNotificationsCount =
            caseAndClientNotifications?.filter((val) => !val.readAt).length ?? 0;
        const invoicinUnreadNotificationsCount = invoicingNotifications?.filter((val) => !val.readAt).length ?? 0;
        return [
            {
                id: 'invoicing',
                label: t('notifications.invoicing'),
                component: (
                    <NotificationList
                        notifications={invoicingNotifications}
                        unread={invoicinUnreadNotificationsCount}
                        theme={theme}
                        handleRead={(id) => handleRead(id, NotificationCategory.INVOICING)}
                        handleArchive={(id) => handleArchive(id, NotificationCategory.INVOICING)}
                        areButtonsDisabled={areButtonsDisabled}
                    />
                ),
                sx: { height: '100%', overflow: 'auto' },
                icon: <CountBox>{invoicinUnreadNotificationsCount}</CountBox>,
            },
            {
                id: 'cases-and-clients',
                label: t('notifications.cases-and-clients'),
                component: (
                    <NotificationList
                        notifications={caseAndClientNotifications}
                        unread={caseAndClientUnreadNotificationsCount}
                        theme={theme}
                        handleRead={(id) => handleRead(id, NotificationCategory.CASE_AND_CLIENT)}
                        handleArchive={(id) => handleArchive(id, NotificationCategory.CASE_AND_CLIENT)}
                        areButtonsDisabled={areButtonsDisabled}
                    />
                ),
                sx: { height: '100%', overflow: 'auto' },
                icon: <CountBox>{caseAndClientUnreadNotificationsCount}</CountBox>,
            },
        ];
    }, [caseAndClientNotifications, invoicingNotifications, handleArchive, handleRead, areButtonsDisabled]);

    const closeDrawer = useCallback(() => {
        dispatch(closeTemporaryDrawer());
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box sx={{ padding: '20px 24px 4px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
                    <FormattedMessage id="notifications.notifications" />
                </Typography>
                <IconButton aria-label="close notifications" onClick={closeDrawer}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <TabsGallery
                wrapperSx={{
                    display: 'grid',
                    gridTemplateRows: '1fr auto',
                    height: '100%',
                    overflow: 'hidden',
                }}
                sx={{ mb: 0, padding: '0px 8px' }}
                tabs={TABS}
            />
        </Box>
    );
};

const NotificationList: React.FC<PropsNotificationList> = ({
    notifications,
    theme,
    unread,
    handleArchive,
    handleRead,
    areButtonsDisabled,
}) => {
    return (
        <Box
            component="ul"
            sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
            }}
        >
            <NotificationListHeader
                theme={theme}
                unread={unread}
                handleArchive={handleArchive}
                handleRead={handleRead}
                areButtonsDisabled={areButtonsDisabled}
            />
            {notifications.map((val) => (
                <NotificationItem
                    key={`notification-${val.id}`}
                    notification={val}
                    theme={theme}
                    handleArchive={handleArchive}
                    handleRead={handleRead}
                    areButtonsDisabled={areButtonsDisabled}
                />
            ))}
        </Box>
    );
};

export const NotificationListHeader = ({
    theme,
    unread,
    areButtonsDisabled,
    handleArchive,
    handleRead,
}: {
    theme: Theme;
    unread: number;
    handleRead: (id: string | number | 'all') => Promise<void>;
    handleArchive: (id: string | number | 'all') => Promise<void>;
    areButtonsDisabled: boolean;
}) => (
    <Stack
        sx={{
            width: '100%',
            padding: '9px 24px',
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.grey[50],
            justifyContent: 'space-between',
        }}
        component="li"
        direction="row"
    >
        <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
            <FormattedMessage values={{ count: unread }} id="notifications.unread" />
        </Typography>
        <Stack direction="row" spacing={2}>
            <Button
                sx={{ fontWeight: 400, color: theme.palette.text.link, height: 22, px: 0 }}
                size="small"
                onClick={() => handleRead('all')}
                disabled={areButtonsDisabled}
            >
                <FormattedMessage id="notifications.mark-all-as-read" />
            </Button>
            <Button
                sx={{ fontWeight: 400, color: theme.palette.text.link, height: 22, px: 0 }}
                size="small"
                onClick={() => handleArchive('all')}
                disabled={areButtonsDisabled}
            >
                <FormattedMessage id="notifications.archive-all" />
            </Button>
        </Stack>
    </Stack>
);

export default DrawerContent;

// ==============================|| COMPANY TYPES ||============================== //

import { FinancialCondition } from './financial-conditions';
import { Office } from './offices';
import { NamedEntityMinDto } from './other';

export interface Company extends CompanyMinDto {
    offices?: Office[];
    financialCondition?: FinancialCondition;
}

export interface CompanyMinDto {
    id: number;
    name: string;
    address: string;
    regCode?: string;
    vatRegCode: string;
    email?: string;
    phone?: string;
    isDefault?: boolean;
    isVatLiable?: boolean;
    cityName: string;
    country_id: number;
    countryCode: string;
}

export interface CorporateRoleFilling {
    corporateRoleFillingId: number;
    name: string;
    level: number;
    corporateRoleTypeCode: string;
    scopeTypeCode: string;
    roleFilledByEmployee?: NamedEntityMinDto;
    company?: NamedEntityMinDto;
    office?: NamedEntityMinDto;
}

export interface CorporateRoleFillingForm {
    corporateRoleFillings: UpdateCorporateRoleFillingDto[];
}

export interface UpdateCorporateRoleFillingDto {
    corporateRoleFillingId: number;
    roleFilledByEmployee: NamedEntityMinDto | null;
}

export enum CorporateRoleType {
    LEADING_REVENUE_PERSON = 'LEADING_REVENUE_PERSON',
    LEADING_COMPLIANCE_PERSON = 'LEADING_COMPLIANCE_PERSON',
}

import { Box, CSSObject, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface TimeDataProps {
    label: string;
    children: React.ReactNode;
    sx?: CSSObject;
}

const LabelledData = ({ label, children, sx }: TimeDataProps): JSX.Element => {
    return (
        <Box>
            <Typography variant="caption" color="textSecondary">
                <FormattedMessage id={label} />
            </Typography>
            <Typography variant="caption" sx={sx}>
                {children}
            </Typography>
        </Box>
    );
};

export default LabelledData;

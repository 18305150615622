import { useSelector as useStoreSelector } from 'store';
import { FeatureFlag } from '../types/features';

type FeatureActions = {
    isFlowEnabled: boolean;
    isFlowDisabled: boolean;
};

export const useFeatureFlags = (requiredFeature: FeatureFlag | undefined): FeatureActions => {
    const { enabledFeatures } = useStoreSelector((state) => state.user);
    if (!requiredFeature) {
        return {
            isFlowEnabled: true,
            isFlowDisabled: false,
        };
    }

    if (!enabledFeatures) {
        return {
            isFlowEnabled: false,
            isFlowDisabled: true,
        };
    }

    const isFlowEnabled = enabledFeatures.includes(requiredFeature);
    const isFlowDisabled = !isFlowEnabled;

    return {
        isFlowEnabled,
        isFlowDisabled,
    };
};

/**
 *
 * @param {(FeatureFlag | FeatureFlag[])} requiredFeature
 * @param {Boolean} requireAll whether all feature flags provided need to match
 * @returns {Boolean}
 */
export const useIsFeatureEnabled = (requiredFeature: FeatureFlag | FeatureFlag[], requireAll = false): boolean => {
    const { enabledFeatures } = useStoreSelector((state) => state.user);
    const requiredFeatures = Array.isArray(requiredFeature) ? requiredFeature : [requiredFeature];
    if (enabledFeatures == undefined || requiredFeature === undefined || requiredFeature === null) {
        return false;
    } else if (requiredFeatures.length === 0) {
        return true;
    } else {
        return requireAll
            ? requiredFeatures.every((feature) => enabledFeatures.includes(feature))
            : requiredFeatures.some((feature) => enabledFeatures.includes(feature));
    }
};

import { Box, Link, Theme, Typography } from '@mui/material';
import { MessageDetailsDto as ChatMessageType } from 'types/chat';
import format from 'date-fns/format';
import LinkWithOrigin from 'components/util/LinkWithOrigin';

interface Props {
    theme: Theme;
    message: ChatMessageType;
}

const ChatMessage: React.FC<Props> = ({ theme, message }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '24px', lineHeight: '22px' }}>
            <Typography color={theme.palette.text.secondary} lineHeight="inherit">
                {message?.createdAt ? format(new Date(message.createdAt), 'HH:mm yyyy-MM-dd') : '-'}
            </Typography>
            <Typography fontWeight={600} lineHeight="inherit">
                {message?.metaDataJson?.employee?.name ?? '-'}
            </Typography>
            <Typography lineHeight="inherit">{message.manualMessageText}</Typography>
            {message.employee ? (
                <Link
                    to={`/employee/${message.employee.id}`}
                    component={LinkWithOrigin}
                    color="text.link"
                    lineHeight="inherit"
                >
                    {message.employee.name ? `@${message.employee.name}` : '-'}
                </Link>
            ) : null}
        </Box>
    );
};

export default ChatMessage;

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from 'utils/getTranslations';
import { dateStringToDateObject, DEFAULT_DATE_FORMAT } from 'utils/parseDate';
import { CustomFormData, DateField, RequiredFields } from '../EntityFormTypes';
import { format } from 'date-fns';

type Props<FormDataType> = {
    field: DateField<FormDataType>;
    formData: CustomFormData<FormDataType>;
    isDisabled: boolean | undefined;
    requiredFields: RequiredFields<FormDataType> | undefined;
    onInputChange: (field: string, value: any) => void;
};

export const formatDateStringInput = (value: Date) => {
    if (value === null || isNaN(value.getTime())) {
        return '';
    }
    return format(value, DEFAULT_DATE_FORMAT);
};

const DatePickerInput = <FormDataType,>({
    field,
    formData,
    isDisabled,
    requiredFields,
    onInputChange,
}: Props<FormDataType>) => {
    const onChange = (val: Date | null) => {
        if (val) {
            if (field.valueType === 'string') {
                onInputChange(field.key, formatDateStringInput(val));
            } else {
                onInputChange(field.key, val.getTime());
            }
        } else {
            onInputChange(field.key, null);
        }
    };

    const getValue = () => {
        if (field.valueType === 'string') {
            return formData[field.key] === null
                ? formData[field.key]
                : dateStringToDateObject(formData[field.key] as unknown as string);
        }

        return formData[field.key];
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                componentsProps={{
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                disabled={isDisabled}
                format={DEFAULT_DATE_FORMAT}
                value={getValue() ? new Date(getValue() as string) : null}
                onChange={onChange}
                slotProps={{
                    textField: {
                        placeholder: field?.placeholder ? t(field.placeholder) : t('select'),
                        error: field.error ?? requiredFields?.[field.key]?.isErrored,
                        sx: { width: field.width },
                        helperText:
                            field.error && field.errorMsg
                                ? field.errorMsg
                                : requiredFields?.[field.key]?.isErrored
                                  ? requiredFields?.[field.key]?.errorMsg
                                  : undefined,
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePickerInput;

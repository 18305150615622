import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
    reverse?: boolean;
    isIcon?: boolean;
    sx?: SxProps;
    to?: To;
    onClick?: () => void;
    target?: string;
    href?: string;
}

const LogoSection = ({ reverse, isIcon, sx, to, onClick, href, target }: Props): JSX.Element =>
    href && target ? (
        <ButtonBase disableRipple sx={sx} href={href} target={target}>
            {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
        </ButtonBase>
    ) : (
        <ButtonBase
            disableRipple
            component={Link}
            to={!to ? {} : to}
            sx={sx}
            onClick={() => {
                if (!onClick || to) return;
                onClick();
            }}
        >
            {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
        </ButtonBase>
    );

export default LogoSection;

import { ComponentType, LazyExoticComponent, Suspense, PropsWithoutRef } from 'react';

// project import
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

// Typescript: https://stackoverflow.com/questions/75445756/typescript-what-is-the-type-to-pass-for-lazy-import

const Loadable =
    <P extends Record<string, unknown>>(LoadedComponent: LazyExoticComponent<ComponentType<P>>) =>
    (props: PropsWithoutRef<P>) => (
        <Suspense fallback={<Loader />}>
            <LoadedComponent {...props} />
        </Suspense>
    );

export default Loadable;

import { portalAPI } from './portalAPI';
import { AddressedTaskListItem, Task, TaskListItem, TaskDto } from '../../types/tasks';
import { accumulatedQueryWithFilters } from './util/endpointBuilder';

export const APITasksSlice = portalAPI
    .enhanceEndpoints({ addTagTypes: ['Tasks', 'AddressedTaskListItems', 'TaskListItems'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTaskById: builder.query<Task, string>({
                query: (entityId) => `v1/tasks/${entityId}`,
                keepUnusedDataFor: 0,
                providesTags: (_1, _2, arg) => [{ type: 'Tasks', id: arg }],
            }),
            getTasksWithFilters: accumulatedQueryWithFilters<TaskListItem>(builder, '/v1/tasks', {
                providesTags: ['TaskListItems'],
            }),
            getTasksAddressedToEmployee: builder.query<AddressedTaskListItem[], { employeeId: number; search: string }>(
                {
                    query: ({ employeeId, search }) =>
                        `/v1/tasks/assigned?employeeId=${employeeId}&searchText=${search}`,
                    keepUnusedDataFor: 0,
                    providesTags: (_1, _2, arg) => [{ type: 'AddressedTaskListItems', task: arg }],
                }
            ),
            addTask: builder.mutation<Task, TaskDto>({
                query: ({ ...body }) => ({
                    url: '/v1/tasks',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Tasks', 'AddressedTaskListItems', 'TaskListItems'],
            }),
            updateTask: builder.mutation<Task, TaskDto>({
                query: ({ id, ...body }) => ({
                    url: `/v1/tasks/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? ['Tasks', 'AddressedTaskListItems', 'TaskListItems', { type: 'Tasks', id: arg.id }]
                        : ['Tasks', 'AddressedTaskListItems', 'TaskListItems'],
            }),
            deleteTask: builder.mutation<boolean, { id: string }>({
                query: ({ id }) => ({
                    url: `/v1/tasks/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Tasks', 'AddressedTaskListItems', 'TaskListItems'],
            }),
            switchTasksStatus: builder.mutation<boolean, { id: string }>({
                query: ({ id }) => ({
                    url: `/v1/tasks/${id}/switch-status`,
                    method: 'PUT',
                }),
                invalidatesTags: (_1, _2, arg) =>
                    arg.id
                        ? ['Tasks', 'AddressedTaskListItems', 'TaskListItems', { type: 'Tasks', id: arg.id }]
                        : ['Tasks', 'AddressedTaskListItems', 'TaskListItems'],
            }),
        }),
    });

export const {
    useGetTaskByIdQuery,
    useGetTasksAddressedToEmployeeQuery,
    useLazyGetTasksAddressedToEmployeeQuery,
    useLazyGetTasksWithFiltersQuery,
    useAddTaskMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useSwitchTasksStatusMutation,
} = APITasksSlice;

import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger, UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { PaginationProps, QueryResponse } from 'types/paginationAndFilter';
import {
    CheckBoxGroupFilterForm,
    DropdownFilterForm,
    FilterCheckboxInForm,
    FilterConfig,
    MainFilterForm,
    MainFilterInForm,
    QuickFilterForm,
    SelectOption,
} from './UmbrellaTableTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isArray from 'lodash/isArray';
import { getSelectorKey } from './getSelectorKey';

type Props<DataType> = {
    data?: QueryResponse<DataType>;
    rowsPerPage?: number;
    filterConfig?: FilterConfig;
    disableUrlState?: boolean;
    disableFetch?: boolean;
    initialSearchParams?: string;
    fetchData: LazyQueryTrigger<
        QueryDefinition<
            PaginationProps,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
            never,
            QueryResponse<DataType>,
            'portalAPI'
        >
    >;
    fetchCount?: UseLazyQuery<
        QueryDefinition<
            Pick<PaginationProps, 'filters'>,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
            string,
            number,
            'portalAPI'
        >
    >;
};

const useUmbrellaTableFilter = <DataType>(props: Props<DataType>) => {
    const {
        data,
        fetchData,
        fetchCount,
        filterConfig,
        rowsPerPage = 50,
        disableUrlState = false,
        disableFetch = false,
        initialSearchParams,
    } = props;
    const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
    const [quickFilterState, setQuickFilterState] = useState({} as QuickFilterForm);
    const [mainFilterState, setMainFilterState] = useState({} as MainFilterForm);
    const [activeFilter, setActiveFilter] = useState<string>('');

    const [searchBarState, setSearchBarState] = useState('');
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams(initialSearchParams);

    const synchronizeQuickFilterState = (mainFilterState: MainFilterForm) => {
        setQuickFilterState((prevQuickFilterState) => {
            const newQuickFilterState = { ...prevQuickFilterState };
            for (const [_, mainFilter] of Object.entries(mainFilterState)) {
                if (mainFilter && mainFilter.type === 'checkboxgroup') {
                    const checkedMainFilter = mainFilter as CheckBoxGroupFilterForm;
                    const { filters } = checkedMainFilter;
                    const itemKey = getSelectorKey(checkedMainFilter);
                    if (newQuickFilterState[itemKey]) {
                        newQuickFilterState[itemKey] = {
                            ...newQuickFilterState[itemKey],
                            filters: (newQuickFilterState[itemKey].filters as FilterCheckboxInForm[]).map((filter) => {
                                const matchingMainFilter = (filters as FilterCheckboxInForm[]).find(
                                    (f) => f.key.split('-').at(1) === filter.key.split('-').at(1)
                                );
                                if (matchingMainFilter) {
                                    return { ...filter, isSelected: matchingMainFilter.isSelected };
                                }
                                return filter;
                            }),
                        };
                    }
                }
            }
            return newQuickFilterState;
        });
    };

    const synchronizeMainFilterStatePure = (quickFilterState: QuickFilterForm, mainFilterState: MainFilterForm) => {
        const newMainFilterState: MainFilterForm = { ...mainFilterState };
        for (const [_, mainFilter] of Object.entries(mainFilterState)) {
            if (mainFilter && mainFilter.type === 'checkboxgroup') {
                const checkedMainFilter = mainFilter as CheckBoxGroupFilterForm | undefined;
                if (checkedMainFilter) {
                    const itemKey = getSelectorKey(checkedMainFilter);
                    if (quickFilterState[itemKey]) {
                        newMainFilterState[itemKey] = {
                            ...checkedMainFilter,
                            filters: ((newMainFilterState[itemKey] as CheckBoxGroupFilterForm)?.filters || []).map(
                                (filter: FilterCheckboxInForm) => {
                                    const matchingQuickFilter = (
                                        (quickFilterState[itemKey]?.filters as FilterCheckboxInForm[]) || []
                                    ).find((f) => f.key.split('-').at(1) === filter.key.split('-').at(1));
                                    if (matchingQuickFilter) {
                                        return { ...filter, isSelected: matchingQuickFilter.isSelected };
                                    }
                                    return filter;
                                }
                            ),
                        };
                    }
                }
            }
            if (mainFilter && mainFilter.type === 'dropdown') {
                const itemKey = getSelectorKey(mainFilter);
                const quickFilterStateElement = quickFilterState[itemKey];
                const newMainFilterStateElement = newMainFilterState[itemKey] as DropdownFilterForm;
                if (quickFilterStateElement && newMainFilterStateElement) {
                    quickFilterStateElement.filters.forEach((filter) => {
                        if (filter.argument === newMainFilterStateElement.selected?.value && !filter.isSelected) {
                            newMainFilterStateElement.selected = null;
                        }
                    });
                }
            }
        }
        return newMainFilterState;
    };

    const synchronizeMainFilterState = useCallback((quickFilterState: QuickFilterForm) => {
        setMainFilterState((prevMainFilterState) => {
            return synchronizeMainFilterStatePure(quickFilterState, prevMainFilterState);
        });
    }, []);

    const handleQuickFilterStateChange = useCallback(
        (state: QuickFilterForm, shouldSynchronizeMainFilterState: boolean = true) => {
            setQuickFilterState({ ...state });
            if (shouldSynchronizeMainFilterState) {
                synchronizeMainFilterState(state);
            }
        },
        [synchronizeMainFilterState]
    );

    const handleMainFilterStateChange = useCallback(
        (state: MainFilterForm, shouldSynchronizeQuickFilterState: boolean = true) => {
            setMainFilterState({ ...state });
            if (shouldSynchronizeQuickFilterState) {
                synchronizeQuickFilterState(state);
            }
        },
        []
    );

    const setSearchBarStateCallback = useCallback((value = '') => {
        setSearchBarState(value);
    }, []);

    const scrollTop = useCallback(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    const getQuickSearchDerivedFilterState = (shouldIgnoreParams: boolean) => {
        const derivedFilterState = {} as Record<string, string[]>;

        if (!shouldIgnoreParams && searchParams.get('qf')) {
            const filterString = searchParams.get('qf');
            const filterArray = filterString?.split(';');

            filterArray?.forEach((filter) => {
                const filterPieces = filter.split(/(=[a-zA-Z0-9]+=)/);
                if (filterPieces.length >= 3) {
                    derivedFilterState[`${filterPieces[0]}:${filterPieces[1]}`] = filterPieces[2]
                        .replace(/[()]+/g, '')
                        .split(',');
                }
            });
        }

        return derivedFilterState;
    };

    const getInitialQuickFilters = (shouldIgnoreParams = false) => {
        const newCheckboxState = {} as QuickFilterForm;
        const derivedFilterState = getQuickSearchDerivedFilterState(shouldIgnoreParams);

        filterConfig?.quickFilters?.forEach((group) => {
            const itemKey = `${group.selector}${group.comperator !== undefined ? `:${group.comperator}` : ':=in='}`;
            newCheckboxState[itemKey] = {
                ...group,
                filters: group.filters.map((filter, index) => ({
                    ...filter,
                    isSelected: derivedFilterState?.[itemKey]?.includes(filter.argument) ?? false,
                    key: `qf-${itemKey}${filter.argument}${index}`,
                })),
            };
        });

        return newCheckboxState;
    };

    const mergeDerivedFilterState = (state1: Record<string, string[]>, state2: Record<string, string[]>) => {
        const mergedState = { ...state1 };
        Object.keys(state2).forEach((key) => {
            if (!mergedState[key]) {
                mergedState[key] = [...state2[key]];
            }
        });
        return mergedState;
    };

    const getInitialMainFilters = (shouldIgnoreParams = false) => {
        const newMainFilterState = {} as MainFilterForm;

        const mainFilterDerivedFilterState = {} as Record<string, string[]>;
        const quickFilterDerivedFilterState = getQuickSearchDerivedFilterState(shouldIgnoreParams);
        const filterString = searchParams.get('mf');
        let filterArray = [] as string[];

        if (!shouldIgnoreParams && filterString) {
            filterArray = filterString?.split(';');
            const pattern = /(=[a-zA-Z0-9]+=)/;
            filterArray?.forEach((filter) => {
                if (!pattern.test(filter)) return;
                const filterPieces = filter.split(pattern);
                if (filterPieces.length >= 3) {
                    mainFilterDerivedFilterState[`${filterPieces[0]}:${filterPieces[1]}`] = filterPieces[2]
                        .replace(/[()]+/g, '')
                        .split(',');
                }
            });
        }

        const derivedFilterState = mergeDerivedFilterState(mainFilterDerivedFilterState, quickFilterDerivedFilterState);

        filterConfig?.filters?.forEach((group) => {
            const itemKey = getSelectorKey(group as MainFilterInForm);

            if (group.type === 'date' || group.type === 'offset-date-time') {
                newMainFilterState[itemKey] = {
                    ...group,
                    value: derivedFilterState?.[itemKey] ? derivedFilterState?.[itemKey][0] : null,
                };
            } else if (group.type === 'daterange') {
                const urlFrom = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=ge='))
                    ?.split('=ge=')?.[1];
                const urlTo = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=le='))
                    ?.split('=le=')?.[1];
                newMainFilterState[itemKey] = {
                    ...group,
                    from: urlFrom ? new Date(urlFrom).toISOString() : null,
                    to: urlTo ? new Date(urlTo).toISOString() : null,
                };
            } else if (group.type === 'offset-date-time-range') {
                const urlFrom = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=ge='))
                    ?.split('=ge=')?.[1];
                const urlTo = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=le='))
                    ?.split('=le=')?.[1];
                newMainFilterState[group.selector] = {
                    ...group,
                    from: urlFrom ? new Date(urlFrom).toISOString() : null,
                    to: urlTo ? new Date(urlTo).toISOString() : null,
                };
            } else if (group.type === 'interval') {
                const urlFrom = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=ge='))
                    ?.split('=ge=')?.[1];
                const urlTo = filterArray
                    .find((item) => item.includes(group.selector) && item.includes('=le='))
                    ?.split('=le=')?.[1];
                newMainFilterState[itemKey] = {
                    ...group,
                    from: urlFrom ? Number(urlFrom) : null,
                    to: urlTo ? Number(urlFrom) : null,
                };
            } else if (group.type === 'checkboxgroup') {
                newMainFilterState[itemKey] = {
                    ...group,
                    filters: group.filters.map((filter, index) => ({
                        ...filter,
                        isSelected: derivedFilterState?.[itemKey]?.includes(filter.argument) ?? false,
                        key: `mf-${itemKey}${filter.argument}${index}`,
                    })),
                };
            } else if (group.type === 'text') {
                const isMultiple = group.multiple === true;
                newMainFilterState[itemKey] = {
                    ...group,
                    value: isMultiple
                        ? (filterArray
                              .find((item) => item.includes(group.selector))
                              ?.split('=lk=')?.[1]
                              .replace(/['"]+|^\(|\)$/g, '') ?? null)
                        : (filterArray
                              .find((item) => item.includes(group.selector))
                              ?.split('=lk=')?.[1]
                              ?.replace(/['"]+/g, '') ?? null),
                };
            } else if (group.type === 'multiselect') {
                newMainFilterState[itemKey] = {
                    ...group,
                    selected: group.options.filter((option) =>
                        derivedFilterState?.[itemKey]?.includes(String(option.value))
                    ),
                };
            } else if (group.type === 'dynamicmultiselect') {
                const selected = (derivedFilterState?.[itemKey] || []).map((id) => ({
                    value: Number(id),
                    label: '',
                })) as unknown as SelectOption[];
                newMainFilterState[itemKey] = {
                    ...group,
                    selected,
                };
            } else if (group.type === 'dropdown') {
                newMainFilterState[itemKey] = {
                    ...group,
                    selected:
                        group?.options?.find((option) =>
                            derivedFilterState?.[itemKey]?.includes(String(option.value))
                        ) ?? null,
                };
            } else if (group.type === 'dynamicdropdown') {
                const selected = (derivedFilterState?.[itemKey] || []).map((id) => ({
                    value: Number(id),
                    label: '',
                }))?.[0] as unknown as SelectOption;
                newMainFilterState[itemKey] = {
                    ...group,
                    selected: selected ?? null,
                };
            }
        });

        return newMainFilterState;
    };

    const initialGet = (resetSearch = false) => {
        const newCheckboxState = getInitialQuickFilters();
        const newMainFilterState = getInitialMainFilters();

        let searchText = searchBarState;

        if (searchParams.get('search')) {
            searchText = searchParams?.get('search')?.replace(/['"]+/g, '') ?? searchBarState;
        }

        if (resetSearch) {
            searchText = '';
            setSearchBarStateCallback(searchText);
        }

        handleQuickFilterStateChange(newCheckboxState);
        handleMainFilterStateChange(newMainFilterState, false);

        if (searchBarState == '') {
            setSearchBarStateCallback(searchText);
        }
        scrollTop();
        refetch({ searchText, quickFilterState: newCheckboxState, mainFilterState: newMainFilterState }).then(() => {
            setCurrentPageIndex(0);
        });
    };

    useEffect(() => {
        if (!isInitialFetchDone && !data && !disableFetch) {
            initialGet();
            setIsInitialFetchDone(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, searchParams, disableFetch]);

    useEffect(() => {
        const shouldResetSearch = searchParams.get('search') === null;
        if (!disableFetch) {
            initialGet(shouldResetSearch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterConfig, searchParams, disableFetch]);

    const refetch = async ({
        searchText,
        quickFilterState,
        mainFilterState,
        pageIndex = 0,
    }: {
        searchText: string;
        quickFilterState: QuickFilterForm;
        mainFilterState: MainFilterForm;
        pageIndex?: number;
    }) => {
        const searchParamObject = {} as Record<string, string>;
        const quickFilterParams = [] as string[];
        const mainFilterParams = [] as string[];
        const textFilter = [] as string[];
        let filterUrl = '';

        Object.values(quickFilterState).forEach((group) => {
            const selector = group.selector;
            const comperator = group?.comperator;
            const multiple = group?.multiple ?? false;
            const filterArguments = [] as string[];
            group.filters.forEach((filter: FilterCheckboxInForm) => {
                if (!filter.isSelected) return;
                filterArguments.push(`${filter.argument}`);
            });
            if (filterArguments.length) {
                quickFilterParams.push(
                    `${selector}${comperator ? comperator : '=in='}${
                        !comperator || multiple ? '(' : ''
                    }${filterArguments.join(',')}${!comperator || multiple ? ')' : ''}`
                );
            }
        });

        Object.values(mainFilterState).forEach((group) => {
            const { selector, comperator, type } = group;

            const itemKey = getSelectorKey(group);

            const quickFilterStateElement = quickFilterState[itemKey];
            if (quickFilterStateElement?.filters.filter((filter) => filter.isSelected)?.length) {
                return;
            }

            if (type === 'checkboxgroup') {
                const filterArguments = [] as string[];
                group.filters.forEach((filter: FilterCheckboxInForm) => {
                    if (!filter.isSelected) return;
                    filterArguments.push(`${filter.argument}`);
                });
                if (filterArguments.length) {
                    const params = `${selector}${comperator ? comperator : '=in='}${
                        !comperator || group?.multiple ? '(' : ''
                    }${filterArguments.join(',')}${!comperator || group?.multiple ? ')' : ''}`;
                    mainFilterParams.push(params);
                }
            } else if (type === 'date') {
                if (group.value) {
                    mainFilterParams.push(
                        `${selector}${comperator ? comperator : '=in='}${getFormatedDate(group.value)}`
                    );
                }
            } else if (type === 'offset-date-time') {
                if (group.value) {
                    mainFilterParams.push(`${selector}=lk='${getFormatedDate(group.value)}'`);
                }
            } else if (type === 'daterange' || type === 'interval') {
                if (group.from) {
                    mainFilterParams.push(
                        `${selector}=ge=${type === 'daterange' ? getFormatedDate(group.from) : group.from}`
                    );
                }
                if (group.to) {
                    mainFilterParams.push(
                        `${selector}=le=${type === 'daterange' ? getFormatedDate(group.to) : group.to}`
                    );
                }
            } else if (type === 'offset-date-time-range') {
                if (group.from) {
                    mainFilterParams.push(`${selector}=ge=${getFormattedOffsetDateTime(group.from)}`);
                }
                if (group.to) {
                    mainFilterParams.push(`${selector}=le=${getFormattedOffsetDateTime(group.to)}`);
                }
            } else if (type === 'text') {
                if (group.value && group.value.length) {
                    if (!group.multiple) {
                        mainFilterParams.push(`${selector}=lk="${group.value}"`);
                    } else {
                        const groupValue = isArray(group.value)
                            ? group.value
                            : group.value.replace(/['"]+/g, '').split(',');
                        const filterArguments = [] as string[];
                        groupValue.forEach((item) => {
                            filterArguments.push(`"${item}"`);
                        });

                        if (filterArguments.length) {
                            mainFilterParams.push(`${selector}=lk=(${filterArguments.join(',')})`);
                        }
                    }
                }
            } else if (type === 'multiselect' || type === 'dynamicmultiselect') {
                const filterArguments = [] as string[];
                group.selected.forEach((filter: SelectOption) => {
                    filterArguments.push(`${filter.value}`);
                });

                if (filterArguments.length) {
                    mainFilterParams.push(
                        `${selector}${comperator ? comperator : '=in='}(${filterArguments.join(',')})`
                    );
                }
            } else if (type === 'dropdown' || type === 'dynamicdropdown') {
                if (!group.selected?.value) return;
                mainFilterParams.push(`${selector}=in=(${group.selected?.value})`);
            }
        });

        //Stop, dont change the order of the actions below
        if (mainFilterParams.length) {
            searchParamObject['mf'] = mainFilterParams.join(';');
        }

        if (quickFilterParams.length) {
            searchParamObject['qf'] = quickFilterParams.join(';');
        }

        if (searchText.length) {
            filterConfig?.searchBarSelectors.forEach((selector) => {
                const isModifiedSearch = filterConfig?.searchBarSelectorsLimitToFirstArg?.includes(selector);
                if (isModifiedSearch) {
                    const firstArg = searchText.split(' ')[0];
                    if (firstArg) {
                        textFilter.push(`${selector}=lk="${firstArg}"`);
                    }
                } else {
                    textFilter.push(`${selector}=lk="${searchText}"`);
                }
            });
            quickFilterParams.push(`(${textFilter.join(',')})`);
            searchParamObject['search'] = searchText;
        }

        if (quickFilterParams.length || mainFilterParams.length) {
            filterUrl = `${mainFilterParams.concat(quickFilterParams).join(';')}`;
        }

        const passiveUrl = filterConfig?.passiveFilter
            ? `${filterUrl.length ? ';' : ''}${filterConfig?.passiveFilter}`
            : '';
        if (!disableUrlState && !isEqual(Object.fromEntries(searchParams), searchParamObject)) {
            setSearchParams(searchParamObject);
        }
        setActiveFilter(filterUrl + passiveUrl);
        return await fetchData({ filters: filterUrl + passiveUrl, page: pageIndex, pageSize: rowsPerPage }).unwrap();
    };

    const [paginationProps] = useMemo(() => {
        if (!data) return [undefined, undefined];
        const { content, ...rest } = data;
        return [{ ...rest }];
    }, [data]);

    const shouldFetchCount = paginationProps?.totalCountAccurate === false;

    const [
        fetchElementCount,
        { data: _realTotalCount, isLoading: _isLoadingRealTotalCount, isFetching: _isFetchingRealTotalCount },
    ] = fetchCount?.() ?? [undefined, {}];

    useEffect(() => {
        if (shouldFetchCount && paginationProps.first === true) {
            fetchElementCount?.({ filters: activeFilter }, true);
        }
    }, [shouldFetchCount, activeFilter, paginationProps?.first, fetchElementCount]);

    const isLoadingRealTotalCount = _isLoadingRealTotalCount || _isFetchingRealTotalCount;

    const realTotalCount = useMemo(() => {
        if (isLoadingRealTotalCount || !shouldFetchCount || (shouldFetchCount && _realTotalCount === undefined)) {
            return undefined;
        }
        return _realTotalCount;
    }, [_realTotalCount, isLoadingRealTotalCount, shouldFetchCount]);

    const handleApplyFilters = () => {
        refetch({ searchText: searchBarState, quickFilterState, mainFilterState }).then(() => {
            setCurrentPageIndex(0);
        });
    };

    const clearFilters = () => {
        scrollTop();
        refetch({
            searchText: '',
            quickFilterState: getInitialQuickFilters(true),
            mainFilterState: getInitialMainFilters(true),
        }).then(() => {
            setSearchBarStateCallback('');
            handleMainFilterStateChange(getInitialMainFilters(true));
            handleQuickFilterStateChange(getInitialQuickFilters(true));
            setCurrentPageIndex(0);
        });
    };

    const handleQuickFilterToggle = (value: boolean, groupSelector: string, key: string) => {
        const newQuickFilterState = quickFilterState;
        newQuickFilterState[groupSelector].filters = newQuickFilterState[groupSelector].filters.map(
            (filter: FilterCheckboxInForm) => {
                if (filter.key === key) {
                    return { ...filter, isSelected: value };
                }
                return filter;
            }
        );
        scrollTop();
        const mainFiltersSynced: MainFilterForm = synchronizeMainFilterStatePure(quickFilterState, mainFilterState);
        refetch({
            searchText: searchBarState,
            quickFilterState: newQuickFilterState,
            mainFilterState: mainFiltersSynced,
        }).then(() => {
            handleQuickFilterStateChange(newQuickFilterState);
            setCurrentPageIndex(0);
        });
    };

    const scrollAndRefetch = (searchText: string) => {
        scrollTop();
        if (!disableFetch) {
            refetch({ searchText, quickFilterState: quickFilterState, mainFilterState }).then(() => {
                setCurrentPageIndex(0);
            });
        }
    };

    const handleLoadMore = useCallback(() => {
        if (data?.last) return;
        refetch({
            searchText: searchBarState,
            quickFilterState: quickFilterState,
            pageIndex: currentPageIndex + 1,
            mainFilterState,
        }).then(() => {
            setCurrentPageIndex(currentPageIndex + 1);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPageIndex, searchBarState, quickFilterState, mainFilterState]);

    const handleNewSearch = useCallback(
        (searchText: string) => {
            refetch({
                searchText,
                quickFilterState: quickFilterState,
                pageIndex: 0,
                mainFilterState,
            }).then(() => {
                setSearchBarStateCallback(searchText);
                setCurrentPageIndex(0);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [quickFilterState, mainFilterState]
    );

    const mainFilterCount = useMemo(() => {
        if (!activeFilter) {
            return 0;
        }
        const mainFiltersCount = searchParams?.get('mf')?.split(';')?.length ?? 0;
        const quickFiltersInMainFilterStateCount =
            searchParams
                ?.get('qf')
                ?.split(';')
                ?.filter((quickFilter) => {
                    const filterPieces = quickFilter.split(/(=[a-zA-Z0-9]+=)/);
                    return mainFilterState[`${filterPieces[0]}:${filterPieces[1]}`];
                }).length ?? 0;
        return mainFiltersCount + quickFiltersInMainFilterStateCount;
    }, [searchParams, mainFilterState]);

    const hasFilters = useMemo(
        () => !!searchParams?.get('mf') || !!searchParams?.get('qf') || !!searchParams?.get('search'),
        [searchParams]
    );

    return {
        activeFilter,
        dataToDisplay: data?.content || [],
        handleLoadMore,
        handleNewSearch,
        isInitialFetchDone,
        clearFilters,
        handleApplyFilters,
        searchBarProps: {
            searchTextState: searchBarState,
            handleSearchBarChange: setSearchBarStateCallback,
            handleSearch: scrollAndRefetch,
        },
        quickFilterProps: {
            quickFilters: quickFilterState,
            handleChange: handleQuickFilterToggle,
            clearFilters,
            hasFilters,
        },
        mainFilterProps: {
            filters: mainFilterState,
            handleChange: handleMainFilterStateChange,
        },
        mainFilterCount,
        isLoadingRealTotalCount: isLoadingRealTotalCount || (shouldFetchCount && _realTotalCount === undefined),
        realTotalCount,
    };
};

const getFormatedDate = (args: string) => {
    const newDate = new Date(args);
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    const monthToDisplay = month > 9 ? month : `0${month}`;
    const dayToDisplay = day > 9 ? day : `0${day}`;

    return `${newDate.getFullYear()}-${monthToDisplay}-${dayToDisplay}`;
};

const getFormattedOffsetDateTime = (args: string): string => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const newDate = dayjs(args).utc();
    return newDate.format();
};

export { useUmbrellaTableFilter };

import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { t } from 'utils/getTranslations';
import { useEffect, useRef, useState } from 'react';

const CopyToClipboardIcon = ({ text }: { text: string }) => {
    const initialCopyText = t('copy-to-clipboard');
    const [tooltipText, setTooltipText] = useState(initialCopyText);
    const timeoutRef = useRef<number | null>(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    });

    return (
        <CopyToClipboard
            text={text}
            onCopy={() => {
                setTooltipText(t('copied-to-clipboard'));
                timeoutRef.current = window.setTimeout(() => setTooltipText(initialCopyText), 1500);
            }}
        >
            <Tooltip title={tooltipText}>
                <IconButton sx={{ height: 24, width: 24, ml: 0.5 }}>
                    <ContentCopyIcon sx={{ fontSize: '16px' }} />
                </IconButton>
            </Tooltip>
        </CopyToClipboard>
    );
};

export default CopyToClipboardIcon;

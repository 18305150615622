import { Button, styled, Theme, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { t } from 'utils/getTranslations';
import IconButton from 'components/@extended/IconButton';

const CustomOpenButton = styled(Button)(({ theme }) => ({
    fontSize: '0.875rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        backgroundColor: theme.palette.primary['500'],
        color: theme.palette.common.white,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    '&:hover svg': {
        color: theme.palette.common.white,
    },
}));

const DesktopPlayIcon = styled(PlayCircleIcon)(({ theme }) => ({
    color: theme.palette.primary['500'],
    height: 24,
    width: 24,
}));

const MobilePlayIcon = styled(PlayArrowIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    height: 24,
    width: 24,
}));

type Props = {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
    theme: Theme;
};
const StartTrackingButton: React.FC<Props> = (props) => {
    const { isDrawerOpen, toggleDrawer, theme } = props;

    if (isDrawerOpen) {
        return (
            <>
                <CustomOpenButton
                    onClick={toggleDrawer}
                    sx={{
                        width: '100%',
                        ':hover': {
                            background: theme.palette.info.main,
                        },
                    }}
                    size="large"
                    startIcon={<DesktopPlayIcon color="info" />}
                >
                    {t('timecards.start-tracking')}
                </CustomOpenButton>
            </>
        );
    }

    return (
        <>
            <Tooltip title={t('timecards.start-tracking')}>
                <IconButton
                    onClick={toggleDrawer}
                    variant="contained"
                    color="info"
                    shape="rounded"
                    aria-label={t('timecards.start-tracking')}
                    sx={{
                        backgroundColor: theme.palette.primary['500'],
                        color: theme.palette.common.white,
                        width: 32,
                        height: 32,
                    }}
                >
                    <MobilePlayIcon color="info" />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default StartTrackingButton;

import { Box, Divider, InputLabel, Stack, SxProps } from '@mui/material';
import React from 'react';
import { t } from 'utils/getTranslations';

type Props = {
    name: string;
    children: React.ReactElement | React.ReactElement[];
    rightLabelComponent?: React.ReactNode;
    hasErrors: boolean;
    label?: string;
    shouldTranslateLabel?: boolean;
    tooltip?: JSX.Element;
    required?: boolean;
    sx?: SxProps;
    gap?: number;
    readonly?: boolean;
    noReadOnlyDivider?: boolean;
};

const InputWrapper = ({
    name,
    label,
    shouldTranslateLabel = true,
    tooltip,
    required,
    children,
    rightLabelComponent,
    hasErrors,
    gap = 1,
    readonly = false,
    noReadOnlyDivider = false,
    sx,
}: Props) => {
    return (
        <Box width="100%" sx={sx}>
            {label && (
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Box sx={{ display: 'flex' }}>
                        <InputLabel htmlFor={name} required={readonly ? false : required} error={hasErrors}>
                            {shouldTranslateLabel ? t(label) : label}
                        </InputLabel>
                        {tooltip ? tooltip : null}
                    </Box>
                    {rightLabelComponent}
                </Stack>
            )}
            <Box pt={gap}>{children}</Box>
            {readonly && !noReadOnlyDivider ? <Divider sx={{ pt: '10px' }} /> : null}
        </Box>
    );
};

export default InputWrapper;

// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Box } from '@mui/material';
import SidebarTracker from 'components/sidebar-tracker/SidebarTracker';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
    <SimpleBar
        sx={{
            '& .simplebar-content': {
                height: '100%',
            },
            height: '100%',
        }}
    >
        <Box display="flex" height="100%" flexDirection="column" justifyContent="space-between">
            <Navigation />
            <SidebarTracker />
        </Box>
    </SimpleBar>
);

export default DrawerContent;

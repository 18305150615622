import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { CorporateRoleFillingForm, CorporateRoleType } from 'types/companies';
import { getClassifierText, t } from 'utils/getTranslations';
import { UmbrellaTable } from 'components/tables/umbrella/UmbrellaTable';
import { Control, SetFieldValue } from 'react-hook-form';
import { CorporateRoleFillingRowType } from './CorporateRolePage';
import { ClassifierData } from 'types/classifiers';
import { useLazyGetEmployeesListWithFiltersQuery } from 'store/api/employees';
import DynamicSelectInput from 'components/forms/inputs/rhf-inputs/DynamicSelectInput';
import { Column } from 'react-table';

interface Props {
    isReadOnly: boolean;
    dataToDisplay: CorporateRoleFillingRowType[];
    setValue: SetFieldValue<CorporateRoleFillingForm>;
    control: Control<CorporateRoleFillingForm>;
    classifiers: Record<string, ClassifierData>;
}

const fieldStyles = {
    '.MuiAutocomplete-root': {
        width: '90%',
    },
};

const CorporateRoleFillingsTable = ({
    control,
    setValue,
    isReadOnly,
    dataToDisplay,
    classifiers,
}: Props): JSX.Element => {
    const theme = useTheme();

    const columns = useMemo<Column<CorporateRoleFillingRowType>[]>(
        () => [
            {
                Header: t('corporate-role.level'),
                accessor: 'name',
                disableSortBy: true,
                Cell: ({ cell }: any) => {
                    const { name, level } = cell.row.original;
                    const levelStep = (level - 1) * 10;
                    return (
                        <Box sx={{ display: 'flex', whiteSpace: 'nowrap', fontWeight: 600 }}>
                            <div style={{ width: `${levelStep}%` }} />
                            <div style={{ width: `${100 - levelStep}%` }}>{name}</div>
                        </Box>
                    );
                },
            },
            {
                Header: getClassifierText(
                    classifiers?.['CORPORATE_ROLE_TYPE'],
                    CorporateRoleType.LEADING_REVENUE_PERSON
                ),
                accessor: 'leadingRevenuePersonIndex',
                disableSortBy: true,
                padding: '0px 0px 0px 16px',
                Cell: ({ value }: any) => (
                    <DynamicSelectInput
                        wrapperSx={fieldStyles}
                        setValue={setValue}
                        control={control}
                        gap={0}
                        name={`corporateRoleFillings.${value}.roleFilledByEmployee`}
                        paginated={{
                            fetch: useLazyGetEmployeesListWithFiltersQuery,
                            searchField: 'fullName',
                        }}
                        getOptionLabel="name"
                        getOptionIdentifier="id"
                        readonly={isReadOnly ? { linkTo: 'employee' } : false}
                        noReadOnlyDivider
                    />
                ),
            },
            {
                Header: getClassifierText(
                    classifiers?.['CORPORATE_ROLE_TYPE'],
                    CorporateRoleType.LEADING_COMPLIANCE_PERSON
                ),
                accessor: 'leadingCompliancePersonIndex',
                disableSortBy: true,
                padding: '0px 0px 0px 16px',
                Cell: ({ value }: any) => (
                    <DynamicSelectInput
                        wrapperSx={fieldStyles}
                        setValue={setValue}
                        control={control}
                        gap={0}
                        name={`corporateRoleFillings.${value}.roleFilledByEmployee`}
                        paginated={{
                            fetch: useLazyGetEmployeesListWithFiltersQuery,
                            searchField: 'fullName',
                        }}
                        getOptionLabel="name"
                        getOptionIdentifier="id"
                        readonly={isReadOnly ? { linkTo: 'employee' } : false}
                        noReadOnlyDivider
                    />
                ),
            },
        ],
        [classifiers, control, setValue, isReadOnly]
    );

    return (
        <UmbrellaTable<CorporateRoleFillingRowType>
            columns={columns}
            theme={theme}
            dataToDisplay={dataToDisplay}
            isLoading={false}
        />
    );
};

export default CorporateRoleFillingsTable;

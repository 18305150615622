import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { t } from 'utils/getTranslations';
import { CommonInputProps } from './InputTypes';
import InputWrapper from './InputWrapper';
import { useMemoizedRules } from './useMemoizedRules';
import ReadonlyField from '../rhf-other/ReadonlyField';
import React, { InputHTMLAttributes, useEffect } from 'react';

export interface TextInputProps<T extends FieldValues> extends CommonInputProps<T> {
    suffixComponent?: React.ReactNode;
    onBlur?: (value?: string) => void;
    onFocus?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    transformEventBeforeChange?: (e: React.ChangeEvent<HTMLInputElement>) => React.ChangeEvent<HTMLInputElement>;
    maxLength?: number;
    pattern?: string;
    readonly?: boolean;
    focused?: boolean;
    readOnlyField?: JSX.Element;
    multiline?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    decimalScale?: number;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    noReadOnlyDivider?: boolean;
}

const TextInput = <T extends FieldValues>({
    name,
    disabled,
    required,
    label,
    gap,
    shouldTranslateLabel,
    rightLabelComponent,
    placeholder,
    shouldTranslatePlaceholder = true,
    validate,
    control,
    suffixComponent,
    onBlur,
    onFocus,
    onChange,
    onMouseEnter,
    onMouseLeave,
    transformEventBeforeChange = (e: React.ChangeEvent<HTMLInputElement>) => e,
    maxLength = undefined,
    pattern,
    readonly = false,
    focused = false,
    readOnlyField,
    multiline,
    wrapperSx,
    decimalScale,
    inputProps,
    noReadOnlyDivider = false,
}: TextInputProps<T>) => {
    const rules = useMemoizedRules({ required, validate });
    const regExp = pattern ? new RegExp(pattern) : null;

    const inputRef = React.useRef<HTMLElement>(null);

    const isDeleteAction = (nativeEvent: Event) => {
        return nativeEvent instanceof InputEvent && nativeEvent.inputType?.startsWith('deleteContent');
    };

    useEffect(() => {
        if (focused) {
            inputRef.current && inputRef.current.focus();
        } else {
            inputRef.current && inputRef.current.blur();
        }
    }, [focused]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { ref, value, onChange: innerOnChange, onBlur: innerOnBlur, ...field },
                fieldState: { error },
            }) => (
                <InputWrapper
                    name={name}
                    label={label}
                    shouldTranslateLabel={shouldTranslateLabel}
                    required={required}
                    readonly={!!readonly}
                    rightLabelComponent={rightLabelComponent}
                    hasErrors={!!error}
                    sx={wrapperSx}
                    gap={gap}
                    noReadOnlyDivider={noReadOnlyDivider}
                >
                    {readonly ? (
                        readOnlyField || <ReadonlyField value={value} decimalScale={decimalScale} />
                    ) : (
                        <TextField
                            inputRef={inputRef}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (!regExp) {
                                    innerOnChange(transformEventBeforeChange(e));
                                    if (onChange) {
                                        onChange(e);
                                    }
                                } else if (regExp.test(e.target.value) || isDeleteAction(e.nativeEvent)) {
                                    innerOnChange(transformEventBeforeChange(e));
                                    if (onChange) {
                                        onChange(e);
                                    }
                                } else if (value === undefined || value === null) {
                                    e.target.value = '';
                                }
                            }}
                            value={value}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onFocus={onFocus}
                            onBlur={() => {
                                const val = value ? value.toString() : '';
                                innerOnBlur();
                                innerOnChange(val?.trim());
                                if (onBlur) {
                                    onBlur(val);
                                }
                            }}
                            id={name}
                            fullWidth
                            disabled={disabled}
                            required={required}
                            error={!!error}
                            helperText={error?.message}
                            placeholder={
                                placeholder ? (shouldTranslatePlaceholder ? t(placeholder) : placeholder) : undefined
                            }
                            inputProps={{ maxLength, ...inputProps }}
                            InputProps={{ endAdornment: suffixComponent }}
                            multiline={multiline}
                        />
                    )}
                </InputWrapper>
            )}
        />
    );
};

export default TextInput;

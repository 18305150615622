import { useEffect, useState } from 'react';
import { TimecardDetails } from 'types/timecards';

type Props = {
    timecard: TimecardDetails;
};

const getSecondsSinceDate = (date: Date): number => (new Date().getTime() - date.getTime()) / 1000;

const formatDuration = (timeInSeconds: number): string => {
    const hh = Math.floor(timeInSeconds / 3600)
        .toString()
        .padStart(2, '0');
    const mm = Math.floor((timeInSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const ss = Math.floor(timeInSeconds % 60)
        .toString()
        .padStart(2, '0');
    return `${hh}:${mm}:${ss}`;
};

const getCurrentDurationInSeconds = (timecard: TimecardDetails): number => {
    if (timecard.isStopwatchRunning) {
        return timecard.totalTimeInSeconds + getSecondsSinceDate(new Date(timecard.stopwatchRunningSince));
    } else {
        return timecard.totalTimeInSeconds;
    }
};

const TrackingTimer = ({ timecard }: Props) => {
    const [duration, setDuration] = useState(formatDuration(getCurrentDurationInSeconds(timecard)));

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (timecard.isStopwatchRunning) {
            interval = setInterval(() => setDuration(formatDuration(getCurrentDurationInSeconds(timecard))), 1000);
        } else {
            setDuration(formatDuration(timecard.totalTimeInSeconds));
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [timecard]);

    return <span>{duration}</span>;
};

export default TrackingTimer;

import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
} from '@mui/material';

// project import
import NavItem from './NavItem';
import Transitions from 'components/@extended/Transitions';

// assets
import { BorderOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

// types
import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { activeItem } from '../../../../../store/reducers/menu.ts';

type VirtualElement = {
    getBoundingClientRect: () => ClientRect | DOMRect;
    contextElement?: Element;
};

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
    overflow: 'visible',
    zIndex: 1202,
    minWidth: 216,
}));

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
    menu: NavItemType;
    level: number;
}

const NavCollapse = ({ menu, level }: Props) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const menuState = useSelector((state: RootStateProps) => state.menu);
    const { drawerOpen, openItem } = menuState;

    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<VirtualElement | (() => VirtualElement) | null | undefined>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
    ) => {
        setAnchorEl(null);
        if (drawerOpen) {
            setOpen(!open);
        } else {
            setAnchorEl(event?.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const itemPathnameStripped = menu.url?.split('?')?.[0];
        // below regex will split the string at any "/" character if there is any character before it, I only want the first part
        const pathnameClipped =
            pathname
                ?.split('/')
                .filter((segment) => isNaN(Number(segment)))
                .pop() || '';

        if (
            pathname === itemPathnameStripped ||
            pathnameClipped === itemPathnameStripped ||
            `/${pathnameClipped}s` === itemPathnameStripped ||
            menu.altActiveUrls?.includes(pathnameClipped || `${pathnameClipped}s`)
        ) {
            dispatch(activeItem({ openItem: [menu.id] }));
        }
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        const children = menu.children || [];
        const childrenUrls = children.map((child) => child.id).filter((url) => url);

        const parts = pathname.split('/').filter((part) => part);

        const shouldOpen = children.some((item) => {
            const foundUrl = parts.find((part) => childrenUrls.includes(part) || childrenUrls.includes(part + 's'));
            const isExactMatch = item.url === pathname;

            return !!(foundUrl || isExactMatch);
        });

        if (shouldOpen) {
            setOpen(true);
        }
    }, [pathname, menu]);

    const openMini = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const navCollapse = useMemo(() => {
        if (!menu.children) return null;
        return menu.children.map((item) => {
            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} menu={item} level={level + 1} />;
                case 'item':
                    return <NavItem isPopup={openMini} key={item.id} item={item} level={level + 1} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Fix - Collapse or Item
                        </Typography>
                    );
            }
        });
    }, [menu.children, openMini, level]);

    const borderIcon = useMemo(() => (level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : null), [level]);

    const menuIcon = useMemo(() => {
        if (!menu.icon) return borderIcon;
        const Icon = menu.icon;
        return <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />;
    }, [menu.icon, borderIcon, drawerOpen]);

    const textColor = useMemo(
        () => (theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary'),
        [theme.palette.mode]
    );

    const isSelected = useMemo(() => {
        const openedItem = openItem[0];
        return (
            openedItem === menu.id ||
            (Array.isArray(menu.children) && menu.children.some((child) => child.id === openedItem))
        );
    }, [menu.id, menu.children, openItem]);

    const iconSelectedColor = useMemo(() => {
        return theme.palette.mode === 'dark' && drawerOpen ? theme.palette.text.primary : theme.palette.primary.main;
    }, [theme.palette.mode, drawerOpen, theme.palette.text.primary, theme.palette.primary.main]);

    return (
        <>
            <ListItemButton
                component={menu.url ? RouterLink : 'div'}
                {...(menu.url && { to: menu.url })}
                disableRipple
                selected={isSelected}
                {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
                onClick={handleClick}
                sx={{
                    pl: level * 3,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    opacity: 0.6,
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
                        },
                        '&.Mui-selected': {
                            bgcolor: 'transparent',
                            color: iconSelectedColor,
                            opacity: 1,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'transparent',
                            },
                        },
                    }),
                    ...(!drawerOpen && {
                        px: 0,
                        py: 0.25,
                        justifyContent: 'center',
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        '&.Mui-selected': {
                            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
                            borderRight: `2px solid ${theme.palette.primary.main}`,
                            '&:hover': {
                                bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
                            },
                        },
                        ...(menuIcon &&
                            isSelected && {
                                borderRight: `2px solid #335131`,
                                bgcolor: 'red',
                                opacity: 1,
                            }),
                    }),
                }}
            >
                {menuIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 24,
                            color: 'primary.main',
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter',
                                },
                            }),
                            ...(!drawerOpen &&
                                isSelected && {
                                    bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter',
                                    },
                                }),
                        }}
                    >
                        {menuIcon}
                    </ListItemIcon>
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <ListItemText
                        primary={
                            <Typography
                                variant="h6"
                                color={isSelected ? 'primary' : textColor}
                                sx={{ opacity: isSelected ? 1 : 0.6 }}
                            >
                                {menu.title}
                            </Typography>
                        }
                        secondary={
                            menu.caption && (
                                <Typography variant="caption" color="secondary">
                                    {menu.caption}
                                </Typography>
                            )
                        }
                    />
                )}
                {!menu.showChildrenOnlyOnPopper &&
                    (drawerOpen || (!drawerOpen && level !== 1)) &&
                    (openMini || open ? (
                        <UpOutlined
                            style={{ fontSize: '0.625rem', marginLeft: 1, color: theme.palette.primary.main }}
                        />
                    ) : (
                        <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1 }} />
                    ))}

                {!drawerOpen && (
                    <PopperStyled
                        open={openMini}
                        anchorEl={anchorEl}
                        placement="right-start"
                        style={{
                            zIndex: 2001,
                        }}
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 0],
                                    },
                                },
                            ],
                        }}
                    >
                        {({ TransitionProps }) => (
                            <Transitions in={openMini} {...TransitionProps}>
                                <Paper
                                    sx={{
                                        overflow: 'hidden',
                                        py: 1,
                                        ml: '10px',
                                        boxShadow: theme.customShadows.z2,
                                        backgroundImage: 'none',
                                        borderRadius: 2,
                                    }}
                                >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <Box>{navCollapse}</Box>
                                    </ClickAwayListener>
                                </Paper>
                            </Transitions>
                        )}
                    </PopperStyled>
                )}
            </ListItemButton>
            {drawerOpen && !menu.showChildrenOnlyOnPopper && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List sx={{ p: 0 }}>{navCollapse}</List>
                </Collapse>
            )}
        </>
    );
};

export default NavCollapse;

import { CallForActionButton, CallForActionButtonTooltip } from '../../../components/buttons/CallForActionButton';
import StopIcon from '@mui/icons-material/Stop';
import { useNavigate } from 'react-router-dom';
import { t } from 'utils/getTranslations';
import { useSelector } from 'store';
import { usePauseTrackingTimecard } from '../usePauseTrackingTimecard';

type Props = {
    timecard: { id: number };
};

const FinishTimecardIconButton = ({ timecard }: Props): JSX.Element => {
    const navigate = useNavigate();
    const { trackedTimecardId, isStopwatchRunning } = useSelector((state) => state.timecard);
    const { pauseTracking } = usePauseTrackingTimecard();

    const onClick = async () => {
        if (trackedTimecardId && parseInt(trackedTimecardId) === timecard.id && isStopwatchRunning) {
            await pauseTracking(timecard.id);
        }
        navigate(`/timecards/finish/${timecard.id}`);
    };

    return (
        <CallForActionButtonTooltip title={t('timecards.finish')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('timecards.finish')}
                onClick={onClick}
            >
                <StopIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    );
};

export default FinishTimecardIconButton;

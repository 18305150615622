import { BoxProps, styled, CSSObject } from '@mui/material';
import { Box } from '@mui/system';

const Layout = styled(Box)<BoxProps>(({ theme }) => ({
    padding: 24, // 3 * 8 = 24px
    [theme.breakpoints.down('sm')]: {
        padding: 16,
    },
}));

interface Props {
    children: React.ReactNode;
    sx?: CSSObject;
}
const PaddedLayout: React.FC<Props> = ({ children, sx = {} }) => <Layout sx={sx}>{children}</Layout>;
export default PaddedLayout;

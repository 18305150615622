// types
import { DefaultConfigProps, AvailableLocales } from 'types/config';

export const drawerWidth = 230;

export const availableLocales: AvailableLocales = {
    est: {
        title: 'Eesti',
        countryCode: 'Estonian',
    },
    eng: {
        title: 'English',
        countryCode: 'UK',
    },
};

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const AWS_API = {
    poolId: import.meta.env.VITE_COGNITO_POOL_ID,
    appClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
};

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
    defaultPath: '/home',
    fontFamily: `'Inter', sans-serif`,
    i18n: 'eng',
    miniDrawer: false,
    container: false,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
};

export default config;

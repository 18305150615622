import { ReactNode, useEffect, useState } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

// project import
import useConfig from 'hooks/useConfig';
import { availableLocales } from 'config';
import { setLocalStorage, getLocalStorage } from 'utils/localStorage';
import { flattenMessages } from 'utils/getTranslations';

// Types
import { I18n } from 'types/config';

// load locales files
const loadLocaleData = (locale: I18n) => {
    switch (locale) {
        case 'est':
            return import('utils/locales/et.json');
        case 'eng':
        default:
            return import('utils/locales/en.json');
    }
};

// ==============================|| LOCALIZATION ||============================== //

interface Props {
    children: ReactNode;
}

interface LocaleRecord {
    [key: string]: string | LocaleRecord;
}

interface LocaleData {
    default: LocaleRecord | undefined;
}

const Locales = ({ children }: Props): JSX.Element => {
    const { i18n, onChangeLocalization } = useConfig();

    const [messages, setMessages] = useState<
        Record<string, string> | Record<string, MessageFormatElement[]> | undefined
    >();

    // Load the locale setting from localStorage
    useEffect(() => {
        const locale = getLocalStorage('locale');

        if (locale && locale in Object.keys(availableLocales)) {
            // @ts-ignore
            onChangeLocalization(locale);
        }
    }, []);

    useEffect(() => {
        loadLocaleData(i18n).then((data: LocaleData) => {
            setMessages(flattenMessages(data.default));
            setLocalStorage('locale', i18n);
        });
    }, [i18n]);

    return (
        <>
            {messages && (
                <IntlProvider locale={i18n} defaultLocale="eng" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;

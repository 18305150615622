import { portalAPI } from './portalAPI';

export const APIVersionSlice = portalAPI.enhanceEndpoints({ addTagTypes: ['Version'] }).injectEndpoints({
    endpoints: (builder) => ({
        getVersion: builder.query<string, void>({
            query: () => ({
                url: '/v1/version',
                responseHandler: 'text',
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Version'],
        }),
    }),
});

export const { useGetVersionQuery, useLazyGetVersionQuery } = APIVersionSlice;

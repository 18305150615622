import React from 'react';
import { FieldValues } from 'react-hook-form';
import TextInput, { TextInputProps } from './TextInput';

const HoursInput = <T extends FieldValues>(props: TextInputProps<T>) => {
    // Backend cannot handle comma value. Lets allow user to use comma but just change it to dot. win-win
    const replaceDotWithComma = (str: string) => {
        return str.replace(/,/g, '.');
    };

    const transformEventBeforeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): React.ChangeEvent<HTMLInputElement> => {
        if (e?.target?.value) e.target.value = replaceDotWithComma(e.target.value);
        return e;
    };

    return (
        <TextInput pattern="^(\d*([\.\,]\d?)?)?$" {...props} transformEventBeforeChange={transformEventBeforeChange} />
    );
};

export default HoursInput;

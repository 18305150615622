// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetPalettes, PalettesProps } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// types
import { PaletteThemeProps } from 'types/theme';
import { PresetColor, ThemeMode } from 'types/config';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode: ThemeMode, presetColor: PresetColor) => {
    const colors: PalettesProps = presetPalettes;

    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000',
    ];

    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    const greyConstant = ['#fafafb', '#e6ebf1'];

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor: PaletteThemeProps = ThemeOption(colors, presetColor, mode);

    return createTheme({
        palette: {
            mode,
            common: {
                black: '#000',
                white: '#fff',
            },
            ...paletteColor,
            typography: {},
            text: {
                primary: '#262626',
                secondary: '#8C8C8C',
                disabled: paletteColor.grey[400],
                link: '#003A8C',
                success: '#389E0D',
                error: '#F5222D',
                warning: '#FA8C16',
            },
            action: {
                disabled: paletteColor.grey[300],
            },
            divider: '#E7E7E7',
            background: {
                paper: paletteColor.grey[0],
                default: paletteColor.grey.A50,
            },
        },
    });
};

export default Palette;

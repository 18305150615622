import { CallForActionButtonTooltip, CallForActionButton } from '../../../components/buttons/CallForActionButton';
import { useTheme } from '@mui/material/styles';
import PauseIcon from '@mui/icons-material/Pause';
import { t } from 'utils/getTranslations';

type Props = {
    onClick: () => void;
};

const PauseTrackingIconButton = ({ onClick }: Props): JSX.Element => {
    const theme = useTheme();

    return (
        <CallForActionButtonTooltip title={t('timecards.pause')}>
            <CallForActionButton
                variant="contained"
                color="secondary"
                shape="rounded"
                aria-label={t('timecards.pause')}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                }}
                onClick={onClick}
            >
                <PauseIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    );
};

export default PauseTrackingIconButton;

import React, { lazy, useCallback, useMemo } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { LazyQueryTrigger, UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { CSVLink } from 'react-csv';

import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { FileDownloadOutlined, FilterList } from '@mui/icons-material';

import SearchBar, { Props as SearchBarProps } from './filter-pieces/SearchBar';
import QuickFilters, { Props as QuickFilterProps } from './filter-pieces/QuickFilters';

import { FilterCheckboxInForm, FilterConfig } from './UmbrellaTableTypes';
import { PaginationProps, QueryResponse } from 'types/paginationAndFilter';
import { useUmbrellaTableFilter } from './useUmbrelllaTableFilter';
import { toggleSubDrawer } from 'store/reducers/subdrawer';
import { dispatch, useSelector } from 'store';
import DrawerPortal from 'components/drawer/DrawerPortal';
import { QueryParams } from 'store/api/timecards';
import { UmbrellaBaseProps, UmbrellaTable } from './UmbrellaTable';
import { ModalArgs } from 'utils/useCSVExport';
import SuspendedPlaceholder from 'components/SuspendedPlaceholder';
import { useIsMounted } from 'utils/useIsMounted';
import { useAsyncDebounce } from 'react-table';

const MainFilters = lazy(() => import('./filter-pieces/MainFilters'));

type Props<DataType extends object> = UmbrellaBaseProps<DataType> & {
    pluralTextId?: string | Map<string, string>;
    singularTextId?: string | Map<string, string>;
    noEntriesId?: string | Map<string, string>;
    data?: QueryResponse<DataType>;
    filterConfig?: FilterConfig;
    rowsPerPage?: number;
    searchBarProps?: SearchBarProps;
    quickFilterProps?: QuickFilterProps;
    extraHeaderComponent?: React.ReactNode;
    disableUrlState?: boolean;
    initialSearchParams?: string;
    onExportClicked?: () => void;
    fetchData: LazyQueryTrigger<
        QueryDefinition<
            PaginationProps,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
            never,
            QueryResponse<DataType>,
            'portalAPI'
        >
    >;
    fetchCount?: UseLazyQuery<
        QueryDefinition<
            Pick<PaginationProps, 'filters'>,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
            string,
            number,
            'portalAPI'
        >
    >;
    exportHookResult?: {
        data: DataType[] | undefined;
        filename: string;
        isLoading?: boolean;
        isFetching?: boolean;
        isBusy: boolean;
        csvLinkRef: React.RefObject<
            CSVLink &
                HTMLAnchorElement & {
                    link: HTMLAnchorElement;
                }
        >;
        triggerManualDownload: (args: QueryParams, modalArgs?: ModalArgs<DataType[]>) => Promise<void>;
    };
    exportResultMapper?: (args: any[] | undefined) => any;
    exportAlertRequireConfirmation?: ModalArgs<DataType[]>;
    renderCustomHeader?: (fetchFilteredData: (searchText: string) => void, searchText: string) => JSX.Element;
    renderSearchDescriptionLevelComponent?: () => JSX.Element;
    skipHeader?: boolean;
    bgcolor?: string;
};

const UmbrellaTableWithFilters = <DataType extends object>(props: Props<DataType>) => {
    const {
        renderCustomTable,
        renderCustomHeader,
        fetchData,
        fetchCount,
        data,
        filterConfig,
        isLoading,
        extraHeaderComponent,
        exportHookResult,
        exportResultMapper,
        exportAlertRequireConfirmation,
        pluralTextId = 'entries',
        singularTextId = 'entry',
        rowsPerPage = 10,
        disableUrlState = false,
        initialSearchParams,
        noEntriesId = 'no-entries-found',
        renderSearchDescriptionLevelComponent,
        skipHeader,
        bgcolor,
        ...rest
    } = props;
    const theme = useTheme();
    const isMounted = useIsMounted();
    const { isExporting } = useSelector((state) => state.exporting);

    const {
        searchBarProps,
        quickFilterProps,
        isInitialFetchDone,
        dataToDisplay,
        handleLoadMore,
        mainFilterProps,
        clearFilters,
        handleApplyFilters,
        mainFilterCount,
        activeFilter,
        isLoadingRealTotalCount,
        realTotalCount,
    } = useUmbrellaTableFilter<DataType>({
        fetchData,
        fetchCount,
        data,
        filterConfig,
        rowsPerPage,
        disableUrlState,
        initialSearchParams,
    });

    const toggleSidebar = useCallback(() => {
        dispatch(toggleSubDrawer('filter-sidebar'));
    }, []);

    const getTextId = useCallback(
        (value: string | Map<string, string>): string | undefined => {
            if (value instanceof Map) {
                const hasFilter = Object.values(quickFilterProps.quickFilters)
                    .find((el) => el.comperator === value.get('filter'))
                    ?.filters.find((el: FilterCheckboxInForm) => el.isSelected);
                return hasFilter ? value.get('hasFilter') : value.get('default');
            } else {
                return value;
            }
        },
        [quickFilterProps]
    );

    const [paginationProps, _] = useMemo(() => {
        if (!data) return [undefined, undefined];
        const { content, ...rest } = data;
        return [{ ...rest }, content];
    }, [data]);

    const DrawerContent = useMemo(
        () => (
            <SuspendedPlaceholder>
                <MainFilters
                    {...mainFilterProps}
                    quickFilters={quickFilterProps.quickFilters}
                    handleQuickFilterChange={quickFilterProps.handleChange}
                />
            </SuspendedPlaceholder>
        ),
        [mainFilterProps, quickFilterProps]
    );

    const initialLoad = !isInitialFetchDone && !data;
    const hasFilters = filterConfig?.filters.length;
    const headerLayout = `${hasFilters ? 'auto ' : ''}${exportHookResult ? 'auto ' : ''} 1fr${
        extraHeaderComponent ? ' auto' : ''
    }`;

    const noData = (!dataToDisplay.length && !isLoading) || !isMounted;

    const handleSearchDebounced = useAsyncDebounce((value = '') => {
        searchBarProps.handleSearch(value);
    }, 600);

    const elementCount = useMemo(() => {
        if (!isLoadingRealTotalCount && realTotalCount !== undefined) {
            return realTotalCount;
        }
        if (realTotalCount === undefined) {
            return paginationProps?.totalElements ?? 0;
        }
        return 0;
    }, [paginationProps?.totalElements, isLoadingRealTotalCount, realTotalCount]);

    return (
        <>
            <DrawerPortal
                content={DrawerContent}
                titleId="all-filters"
                drawerId="filter-sidebar"
                onFooterSecondaryAction={clearFilters}
                onFooterPrimaryAction={handleApplyFilters}
                applyColor="secondary"
                sx={filterConfig?.mainFilterSx}
                type="sub"
                afterTitle={
                    mainFilterCount ? (
                        <Box
                            sx={{
                                background: theme.palette.warning.main,
                                width: '20px',
                                height: '20px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '20px',
                                color: theme.palette.common.white,
                                borderRadius: '50%',
                            }}
                        >
                            {mainFilterCount}
                        </Box>
                    ) : null
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    pt: '8px',
                    flexDirection: 'column',
                    position: 'sticky',
                    top: '0',
                    background: theme.palette.common.white,
                    zIndex: 30,
                }}
            >
                {renderCustomHeader
                    ? renderCustomHeader(searchBarProps.handleSearch, searchBarProps.searchTextState)
                    : null}
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: headerLayout }}>
                    {hasFilters ? (
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<FilterList sx={{ fontSize: '20px' }} />}
                            onClick={toggleSidebar}
                            sx={{
                                '.MuiButton-endIcon': {
                                    '> :nth-of-type(1)': {
                                        fontSize: '12px',
                                    },
                                },
                            }}
                            disabled={isExporting}
                            endIcon={
                                mainFilterCount ? (
                                    <Box
                                        sx={{
                                            background: theme.palette.warning.main,
                                            width: '20px',
                                            height: '20px',
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                            color: theme.palette.common.white,
                                            borderRadius: '50%',
                                        }}
                                    >
                                        {mainFilterCount}
                                    </Box>
                                ) : null
                            }
                        >
                            <FormattedMessage id="filter" />
                        </Button>
                    ) : null}
                    {exportHookResult && exportResultMapper ? (
                        <>
                            <Button
                                disabled={exportHookResult.isBusy}
                                variant="outlined"
                                size="small"
                                startIcon={<FileDownloadOutlined sx={{ fontSize: '20px' }} />}
                                onClick={() =>
                                    exportHookResult.triggerManualDownload(
                                        { filter: activeFilter },
                                        exportAlertRequireConfirmation
                                    )
                                }
                            >
                                <FormattedMessage id="export" />
                            </Button>
                            <Box sx={{ display: 'none' }}>
                                <CSVLink
                                    ref={exportHookResult.csvLinkRef}
                                    data={exportResultMapper(exportHookResult.data)}
                                    filename={exportHookResult.filename}
                                    separator=";"
                                    target="_blank"
                                />
                            </Box>
                        </>
                    ) : null}
                    {searchBarProps ? (
                        <SearchBar
                            value={searchBarProps.searchTextState}
                            onChange={(event) => {
                                const value = event.target.value;
                                searchBarProps.handleSearchBarChange(value);
                                handleSearchDebounced(value);
                            }}
                            singularTextId={getTextId(singularTextId)}
                            pluralTextId={getTextId(pluralTextId)}
                            count={`${elementCount}${isLoadingRealTotalCount ? '+' : ''}`}
                            size="medium"
                            sx={{ width: '100%' }}
                            disabled={isExporting}
                        />
                    ) : null}
                    {extraHeaderComponent ? extraHeaderComponent : null}
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            mb: Object.keys(quickFilterProps.quickFilters).length === 0 ? 1 : 0,
                            [theme.breakpoints.down('sm')]: {
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Box sx={{ minWidth: '90px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    [theme.breakpoints.down('sm')]: {
                                        alignItems: 'flex-end',
                                    },
                                }}
                            >
                                <Typography sx={{ fontWeight: 600 }}>
                                    {elementCount}
                                    {isLoadingRealTotalCount ? '+' : null}
                                </Typography>
                                &nbsp;
                                <Box sx={{ whiteSpace: 'nowrap', textTransform: 'lowercase' }}>
                                    <FormattedPlural
                                        value={elementCount}
                                        one={<FormattedMessage id={getTextId(singularTextId)} />}
                                        other={<FormattedMessage id={getTextId(pluralTextId)} />}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {quickFilterProps ? (
                            <QuickFilters
                                {...quickFilterProps}
                                disabled={isExporting}
                                mainFilterState={mainFilterProps.filters}
                                handleMainFilterStateChange={mainFilterProps.handleChange}
                            />
                        ) : null}
                        {renderSearchDescriptionLevelComponent && renderSearchDescriptionLevelComponent()}
                    </Box>
                </Box>
            </Box>
            {renderCustomTable ? (
                <>
                    {renderCustomTable(
                        dataToDisplay,
                        initialLoad,
                        noData,
                        getTextId(noEntriesId),
                        paginationProps,
                        handleLoadMore
                    )}
                </>
            ) : null}
            {!noData && !renderCustomTable && (
                <UmbrellaTable<DataType>
                    {...rest}
                    theme={theme}
                    handleLoadMore={handleLoadMore}
                    dataToDisplay={dataToDisplay}
                    isLoading={isLoading || isExporting}
                    paginationProps={paginationProps}
                    skipHeader={skipHeader}
                    bgcolor={bgcolor}
                />
            )}
        </>
    );
};

export default UmbrellaTableWithFilters;

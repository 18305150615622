import { useSelector } from 'store';
import PauseTrackingIconButton from './PauseTrackingIconButton';
import StartTrackingIconButton from './StartTrackingIconButton';
import { useStartTrackingTimecard } from '../useStartTrackingTimecard';
import { usePauseTrackingTimecard } from '../usePauseTrackingTimecard';

type Props = {
    timecard: { id: number };
};

const TimecardTrackingButton = ({ timecard }: Props): JSX.Element => {
    const { startTracking } = useStartTrackingTimecard();
    const { pauseTracking } = usePauseTrackingTimecard();
    const { trackedTimecardId, isStopwatchRunning } = useSelector((store) => store.timecard);
    const isCurrentlyTracking = isStopwatchRunning && trackedTimecardId && parseInt(trackedTimecardId) === timecard.id;

    return isCurrentlyTracking ? (
        <PauseTrackingIconButton onClick={() => pauseTracking(timecard.id)} />
    ) : (
        <StartTrackingIconButton onClick={() => startTracking(timecard.id)} />
    );
};

export default TimecardTrackingButton;

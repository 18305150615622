import { Link, Typography } from '@mui/material';

import { AutomatedNotification } from 'types/notifications';
import IntlMessageFormat from 'intl-messageformat';
import LinkWithOrigin from 'components/util/LinkWithOrigin';
import { closeTemporaryDrawer } from 'store/reducers/temporaryDrawer';
import { dispatch } from 'store';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { formatDateStringInput } from 'components/forms/inputs/DatePickerInput';
import { ObjectType } from 'types/chat';
import { openChat } from 'store/reducers/chat';

/// !!! Format needs to include all possible meta data tags or it will crash
const FormattedNotifcationText: React.FC<{ notification: AutomatedNotification }> = React.memo(
    ({ notification }) => {
        const closeDrawer = () => {
            dispatch(closeTemporaryDrawer());
        };

        const handleLinkClick = (objectId: number | undefined, objectType: ObjectType) => {
            closeDrawer();
            if (!notification.isMessage || objectId === undefined) {
                return;
            }
            dispatch(openChat({ objectId, objectType }));
        };

        return (
            <Typography sx={{ fontWeight: notification?.readAt ? 400 : 600 }}>
                {new IntlMessageFormat(notification.content).format({
                    matter: notification?.metaDataJson?.matter ? (
                        <Link
                            key="notification-link-matter"
                            onClick={() => handleLinkClick(notification?.metaDataJson?.matter?.id, ObjectType.MATTER)}
                            to={`/matter/${notification.metaDataJson.matter.id}`}
                            component={LinkWithOrigin}
                            color="text.link"
                        >
                            {notification.metaDataJson.matter.fullName}
                        </Link>
                    ) : undefined,
                    matterText: notification?.metaDataJson?.matter
                        ? notification.metaDataJson.matter.fullName
                        : undefined,
                    party: notification?.metaDataJson?.party ? (
                        <Link
                            key="notification-link-party"
                            onClick={() => handleLinkClick(notification?.metaDataJson?.party?.id, ObjectType.PARTY)}
                            to={`/contact/${notification.metaDataJson.party.id}`}
                            component={LinkWithOrigin}
                            color="text.link"
                        >
                            {notification.metaDataJson.party.name}
                        </Link>
                    ) : undefined,
                    partyText: notification?.metaDataJson?.party ? notification.metaDataJson.party.name : undefined,
                    contractSentAt: notification?.metaDataJson?.contractSentAt
                        ? formatDateStringInput(new Date(notification.metaDataJson.contractSentAt))
                        : undefined,
                    invoice: notification?.metaDataJson?.invoice ? (
                        <Link
                            key="notification-link-invoice"
                            onClick={() => handleLinkClick(notification?.metaDataJson?.invoice?.id, ObjectType.INVOICE)}
                            to={`/invoice/${notification.metaDataJson.invoice.id}`}
                            component={LinkWithOrigin}
                            color="text.link"
                        >
                            {notification.metaDataJson.invoice.invoiceNumber}
                        </Link>
                    ) : undefined,
                })}
            </Typography>
        );
    },
    (prevProps, currentProps) => isEqual(prevProps.notification, currentProps.notification)
);

export default FormattedNotifcationText;

import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

export interface StoreProps {
    activeDrawerId: string;
    currentTaskId: string;
    noBackdrop?: boolean;
}
const initialState: StoreProps = {
    activeDrawerId: '',
    currentTaskId: '',
    noBackdrop: false,
};

const temporaryDrawer = createSlice({
    name: 'temporaryDrawer',
    initialState,
    reducers: {
        toggleTemporaryDrawer: {
            reducer: (state, payload: PayloadAction<{ activeDrawerId: string }>) => {
                if (state.activeDrawerId === payload.payload.activeDrawerId) {
                    state.activeDrawerId = '';
                }

                state.activeDrawerId = payload.payload.activeDrawerId;
            },
            prepare: (activeDrawerId: string) => {
                const id = nanoid();
                return { payload: { id, activeDrawerId } };
            },
        },
        setCurrentTaskId: {
            reducer: (state, payload: PayloadAction<{ currentTaskId: string }>) => {
                state.currentTaskId = payload.payload.currentTaskId;
            },
            prepare: (currentTaskId: string) => {
                const id = nanoid();
                return { payload: { id, currentTaskId } };
            },
        },
        setNoBackdrop: {
            reducer: (state, payload: PayloadAction<{ noBackdrop: boolean }>) => {
                state.noBackdrop = payload.payload.noBackdrop;
            },
            prepare: (noBackdrop: boolean) => {
                const id = nanoid();
                return { payload: { id, noBackdrop } };
            },
        },
        closeTemporaryDrawer(state) {
            state.activeDrawerId = '';
            state.currentTaskId = '';
            state.noBackdrop = false;
        },
    },
});

export default temporaryDrawer.reducer;

export const { toggleTemporaryDrawer, setCurrentTaskId, setNoBackdrop, closeTemporaryDrawer } = temporaryDrawer.actions;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// third-party
import 'regenerator-runtime/runtime';
import { init as SentryInit, browserTracingIntegration, replayIntegration } from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';

// scroll bar
import 'simplebar/src/simplebar.css';

// apex-chart
import 'assets/third-party/react-table.css';

// project import
import App from './App';

import reportWebVitals from './reportWebVitals';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const isDevEnv = import.meta.env.VITE_ENV === 'dev' || import.meta.env.VITE_ENV === 'development';
const isTestEnv = import.meta.env.VITE_ENV === 'test';
const isProdEnv = import.meta.env.VITE_ENV === 'prod' || import.meta.env.VITE_ENV === 'production';

SentryInit({
    dsn: 'https://7659f63af9c14902a266b62e9611fcfb@o4505045851897856.ingest.sentry.io/4505045853470720',
    integrations: [browserTracingIntegration(), replayIntegration(), captureConsoleIntegration({ levels: ['error'] })],
    environment: import.meta.env.VITE_ENV,
    // debug: import.meta.env.VITE_ENV === 'development' || import.meta.env.VITE_ENV === 'dev' ? true : false,
    debug: false,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: window.location.hostname === 'localhost' ? 0 : 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Don't send events in localhost
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
});

if (window.location.hostname !== 'localhost' && isDevEnv) {
    import('@datadog/browser-rum').then(({ datadogRum }) => {
        datadogRum.init({
            applicationId: '99443304-ae34-4f86-8ea5-df31c697b5a0',
            clientToken: 'pub7c330e4cd2410f050a92490bce3f05a9',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.eu',
            service: 'crespect-dev-fe',
            env: (import.meta.env.VITE_ENV ?? '').toLowerCase(),
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const router = createBrowserRouter([{ path: '*', element: <App /> }]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme: Theme) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 46,
                    borderRadius: 4,
                    minWidth: 'unset',
                    padding: '12px 0px',
                    margin: '0 16px',
                    '&.MuiButtonBase-root': {
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        opacity: 0.5,
                    },
                    '&.Mui-selected': {
                        opacity: 1,
                    },
                    '&:focus-visible': {
                        borderRadius: 4,
                        outline: `2px solid ${theme.palette.secondary.dark}`,
                        outlineOffset: -3,
                    },
                },
            },
        },
    };
}

import { useTheme } from '@mui/material/styles';
import StatusChip from 'components/@extended/StatusChip';
import { ClassifierData } from 'types/classifiers';
import { getClassifierText, t } from 'utils/getTranslations';

type Props = {
    classifierData: ClassifierData;
    timecardLanguageTypeCode?: string;
};

const TimecardLanguageLabel = ({ classifierData, timecardLanguageTypeCode = '' }: Props): JSX.Element => {
    const theme = useTheme();
    const labelText = getClassifierText(classifierData, timecardLanguageTypeCode);

    return <StatusChip theme={theme} label={t('in-language', { language: labelText })} type="warning" />;
};

export default TimecardLanguageLabel;

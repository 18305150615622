import { ExpenseCreateDto, ExpenseDto, ExpenseUpdateDto } from 'types/expenses';
import { portalAPI } from './portalAPI';
import { accumulatedQueryWithFilters } from './util/endpointBuilder';

export const APIExpensesSlice = portalAPI.enhanceEndpoints({ addTagTypes: ['Expenses', 'Timecards'] }).injectEndpoints({
    endpoints: (builder) => ({
        getExpenseById: builder.query<ExpenseDto, string>({
            query: (id) => `/v1/expenses/${id}`,
            keepUnusedDataFor: 0,
            providesTags: (_1, _2, arg) => [{ type: 'Expenses', id: arg }],
        }),
        getExpensesList: builder.query<ExpenseDto[], void>({
            query: () => '/v1/expenses',
            providesTags: ['Expenses'],
        }),
        getExpensesWithFilters: accumulatedQueryWithFilters<ExpenseDto>(builder, '/v1/expenses', {
            providesTags: ['Expenses'],
        }),
        addExpense: builder.mutation<ExpenseDto, ExpenseCreateDto>({
            query: ({ ...body }) => ({
                url: `/v1/expenses`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, expense) => [
                'Expenses',
                { type: 'Timecards', id: expense.timecardId || undefined },
            ],
        }),
        updateExpense: builder.mutation<ExpenseDto, ExpenseUpdateDto>({
            query: ({ id, ...body }) => ({
                url: `/v1/expenses/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_1, _2, arg) => (arg.id ? ['Expenses', { type: 'Expenses', id: arg.id }] : ['Expenses']),
        }),
        deleteExpense: builder.mutation<boolean, { id: number }>({
            query: ({ id }) => ({
                url: `/v1/expenses/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Expenses'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetExpensesListQuery,
    useLazyGetExpensesWithFiltersQuery,
    useGetExpenseByIdQuery,
    useAddExpenseMutation,
    useUpdateExpenseMutation,
    useDeleteExpenseMutation,
} = APIExpensesSlice;

import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import BlockIcon from '@mui/icons-material/Block';
import { t } from 'utils/getTranslations';

interface BlockedTagProps {
    text?: string;
}

const TagContainer = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 20,
    padding: theme.spacing(1, 2),
    height: 40,
}));

const BlockedTag: React.FC<BlockedTagProps> = ({ text = 'Blocked' }) => {
    return (
        <Tooltip title={t('dashboard.tooltips.timecard-blocked')}>
            <TagContainer>
                <BlockIcon
                    sx={{
                        fontSize: 20,
                        marginRight: 1,
                        color: (theme) => theme.palette.text.secondary,
                    }}
                />
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 700,
                        color: (theme) => theme.palette.text.secondary,
                    }}
                >
                    {t('dashboard.timecard-blocked')}
                </Typography>
            </TagContainer>
        </Tooltip>
    );
};

export default BlockedTag;

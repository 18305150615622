export enum HistoryEntityType {
    COMPANY_RELATIONSHIP_MANAGER = 'COMPANY_RELATIONSHIP_MANAGER',
    COMPANY_RELATIONSHIP_PARTNER = 'COMPANY_RELATIONSHIP_PARTNER',
    MATTER = 'MATTER',
    PARTY = 'PARTY',
    EMPLOYEE = 'EMPLOYEE',
    PARTY_GROUP = 'PARTY_GROUP',
    RELATIONSHIP_MANAGER = 'RELATIONSHIP_MANAGER',
    RELATIONSHIP_PARTNER = 'RELATIONSHIP_PARTNER',
    RESPONSIBLE_EMPLOYEE = 'RESPONSIBLE_EMPLOYEE',
    SUPERVISOR_EMPLOYEE = 'SUPERVISOR_EMPLOYEE',
}

export enum HistoryFieldType {
    MATTER_STATE_TYPE_CODE = 'MATTER_STATE_TYPE_CODE',
    RESPONSIBLE_EMPLOYEE = 'RESPONSIBLE_EMPLOYEE',
    SUPERVISING_EMPLOYEE = 'SUPERVISING_EMPLOYEE',
    RELATIONSHIP_MANAGER_EMPLOYEE = 'RELATIONSHIP_MANAGER_EMPLOYEE',
    IS_ACTIVE = 'IS_ACTIVE',
    RELATIONSHIP_PARTNER_EMPLOYEE = 'RELATIONSHIP_PARTNER_EMPLOYEE',
}

export enum HistoryRecordState {
    EDITING = 'EDITING',
    VIEWING = 'VIEWING',
}

export interface HistoryDto {
    id: number;
    entityType: string;
    entityId: string;
    fieldType: string;
    fieldValue: string;
    startDate: string;
    endDate: string;
}

export interface UpdateHistoryDto {
    startDate: string;
    endDate: string;
}

export interface HistoryForm {
    id: number;
    entityType: string;
    entityId: string;
    fieldType: string;
    fieldValue: string;
    startDate: string;
    endDate?: string;
    recordState: HistoryRecordState;
}

export const getClassifierCode = (fieldType: string): string | null => {
    switch (fieldType) {
        case HistoryFieldType.MATTER_STATE_TYPE_CODE:
            return 'MATTER_STATE_TYPE';
        default:
            return null;
    }
};

import IconButton from 'components/@extended/IconButton';
import { styled } from '@mui/material/styles';
import { alpha, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const CallForActionButtonTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));

export const CallForActionButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.info.darker,
    backgroundColor: alpha('rgba(41, 41, 91', 0.1),
    height: 32,
    width: 32,
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.darker,
    },
    '& svg': {
        height: 20,
        width: 20,
    },
}));

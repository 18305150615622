import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    CardContent,
    ClickAwayListener,
    Grid,
    Link,
    Paper,
    Popper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Link as RouterLink } from 'react-router-dom';

// assets
import { LogoutOutlined } from '@ant-design/icons';

// store
import { useSelector } from 'store';
import StyledHeaderButton from '../actions/StyledHeaderButton';
import { t } from '../../../../../utils/getTranslations.ts';
import { useIsMfa } from '../../../../../hooks/useIsMfa.ts';
import { useFeatureFlags } from '../../../../../utils/useFeatureFlags.ts';
import { FeatureFlag } from '../../../../../types/features.ts';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();

    const { logout } = useAuth();

    const [isMfaInUse, setIsMfaInUse] = useIsMfa();
    const { isFlowEnabled: isMfaEnabled } = useFeatureFlags(FeatureFlag.USE_MFA);
    const { name } = useSelector((state) => state.user);
    const splitName = name ? name.split(' ') : ['', ''];
    const initials =
        splitName.length > 1
            ? `${splitName[0]?.[0] || 0}${splitName.pop()?.[0] || ''}`
            : splitName.length > 0
              ? splitName[0]
              : '';
    const handleLogout = async () => {
        try {
            setIsMfaInUse('NOT_SET');
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box sx={{ flexShrink: 0 }}>
            <StyledHeaderButton
                size="small"
                color="inherit"
                onClick={handleToggle}
                ref={anchorRef}
                sx={{ padding: '6px 12px' }}
            >
                {splitName[0]}
                <ExpandMoreRoundedIcon sx={{ fontSize: '10px' }} />
            </StyledHeaderButton>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
                sx={{ zIndex: 1201 }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    zIndex: 1206,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250,
                                    },
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar
                                                            alt="profile user"
                                                            sx={{
                                                                height: 32,
                                                                width: 32,
                                                                fontSize: 10,
                                                                bgcolor: '#335131',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            {initials}
                                                        </Avatar>
                                                        <Stack>
                                                            <Typography variant="h6">{name}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {/* UI/UX Designer */}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Logout">
                                                        <IconButton
                                                            size="large"
                                                            sx={{ color: 'text.primary' }}
                                                            onClick={handleLogout}
                                                        >
                                                            <LogoutOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            {!isMfaInUse && isMfaEnabled && (
                                                <Box sx={{ paddingTop: '20px' }}>
                                                    <Link to={'/mfa-setup'} component={RouterLink}>
                                                        {t('auth.mfa-setup-question')}
                                                    </Link>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;

import { useDispatch, useSelector } from 'store';
import { useGetCurrentlyTrackedTimecardQuery, useGetTimecardByIdQuery } from 'store/api/timecards';
import { TimecardDetails, TimecardStatusType } from 'types/timecards';
import { useEffect } from 'react';
import { resetTimecardState } from 'store/reducers/timecard';
import { useHasPermissions } from 'utils/permissionUtil';

export default function useTrackedTimecard() {
    const { id: userId, activeTenantId } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const hasReadTimecardsPermission = useHasPermissions([
        'READ.TIMECARDS.MY_OWN',
        'READ.TIMECARDS.RELATED_TO_MY_CASES',
    ]);
    const storedTimecardId = useSelector((state) => state.timecard.trackedTimecardId);
    const { data: currentlyTrackedTimecard } = useGetCurrentlyTrackedTimecardQuery(undefined, {
        skip: !hasReadTimecardsPermission,
    });
    const { data: storedTimecard, isError } = useGetTimecardByIdQuery(parseInt(storedTimecardId || '0'), {
        skip: !hasReadTimecardsPermission || storedTimecardId === undefined || storedTimecardId === null,
    });

    // Remove timecard id from localstorage if the query fails and there's no currentlyTrackedTimecard
    useEffect(() => {
        if (isError && currentlyTrackedTimecard === null) {
            dispatch(resetTimecardState({ userId, tenantId: activeTenantId }));
        }
    }, [isError, currentlyTrackedTimecard]);

    const currentTimecard = ((): TimecardDetails | undefined => {
        if (currentlyTrackedTimecard) {
            return currentlyTrackedTimecard;
        }

        if (
            storedTimecard &&
            storedTimecard.timecardStatusTypeCode === TimecardStatusType.DRAFT &&
            storedTimecard.employee.id === userId
        ) {
            return storedTimecard;
        }
    })();

    return currentTimecard;
}

// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme: Theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: '1.375rem',
                    borderColor: theme.palette.divider,
                    '.MuiLink-root': {
                        whiteSpace: 'normal',
                        color: theme.palette.text.primary,
                        '&:hover': {
                            color: theme.palette.text.link,
                        },
                    },
                },
                sizeSmall: {
                    padding: 8,
                },
                head: {
                    fontSize: '0.75rem',
                    lineHeight: '1.25rem',
                    fontWeigh: 500,
                    textTransform: 'capitalize',
                    padding: '8px 16px 9px',
                },
                footer: {
                    fontSize: '0.75rem',
                    textTransform: 'uppercase',
                },
            },
        },
    };
}

import { useState, useRef, useEffect } from 'react';

export const useObserveElementWidth = <T extends HTMLElement>() => {
    const [width, setWidth] = useState(0);
    const ref = useRef<T>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            // wrap state update call in this setTimeout to prevent dev mode constantly throwing full-page errors on window resize
            setTimeout(() => {
                setWidth(entries[0].contentRect.width);
            }, 0);
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref.current]);

    return {
        width,
        ref,
    };
};

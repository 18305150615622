import React from 'react';
import { Typography, IconButton, Box, Theme, CSSObject } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    title: string;
    onCancel: () => void;
    theme: Theme;
    afterTitle?: React.ReactNode;
}

export const DrawerHeader = ({ title, onCancel, theme, afterTitle }: Props): JSX.Element => {
    const style: CSSObject = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px 10px 23px',
        borderBottom: `solid 1px ${theme.palette.divider}`,
    };

    return (
        <Box sx={style}>
            <Typography variant="h5" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                {title}
                {afterTitle ? afterTitle : null}
            </Typography>
            <IconButton size="medium" aria-label="close" color="inherit" sx={{ borderRadius: 5 }} onClick={onCancel}>
                <ClearIcon />
            </IconButton>
        </Box>
    );
};

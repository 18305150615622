/**
 * Converts seconds to hours, rounded up to decimal scale of 1
 *
 * @example
 * // returns 1
 * secondsToHours(3600);
 * @example
 * // returns 0.1
 * secondsToHours(1);
 * @example
 * // returns 0.2
 * secondsToHours(361);
 *
 * @param seconds - duration in seconds
 * @returns duration in hours
 */
export const secondsToHours = (seconds: number): number => {
    return Math.ceil((10 * seconds) / 3600) / 10;
};

export const hoursToSeconds = (hours: number): number => {
    return hours * 3600;
};

export const getRelativeDate = (date: string): string => {
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    const year = month * 12;

    const diff = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);

    if (diff < 30) {
        return 'just now';
    } else if (diff < minute) {
        return diff + ' seconds ago';
    } else if (diff < 2 * minute) {
        return 'a minute ago';
    } else if (diff < hour) {
        return Math.floor(diff / minute) + ' minutes ago';
    } else if (Math.floor(diff / hour) == 1) {
        return '1 hour ago';
    } else if (diff < day) {
        return Math.floor(diff / hour) + ' hours ago';
    } else if (diff < day * 2) {
        return 'yesterday';
    } else if (diff < week) {
        return Math.floor(diff / day) + ' days ago';
    } else if (Math.floor(diff / week) == 1) {
        return '1 week ago';
    } else if (diff < month) {
        return Math.floor(diff / week) + ' weeks ago';
    } else if (Math.floor(diff / month) == 1) {
        return '1 month ago';
    } else if (diff < year) {
        return Math.floor(diff / month) + ' months ago';
    } else if (Math.floor(diff / year) == 1) {
        return '1 year ago';
    } else {
        return Math.floor(diff / year) + ' years ago';
    }
};

import { useEffect, useState } from 'react';
import { cognitoUser } from '../utils/cognitoUser.ts';
import { getIsMfa } from '../utils/getIsMfa.ts';
import { useLocalStorage } from '@uidotdev/usehooks';

type MFAValue = 'NOT_SET' | boolean;

export function useIsMfa(): [boolean, (newValue: MFAValue) => void] {
    //this local storage state is designed to be short-term state for the setup mfa flow until first logout
    //to work around the bug that aws cognito js does not update localstorage state once MFA was setup (until re-login)
    //once user is logged out we clean up this state and no longer use it.
    const [isMfaInLocalStorage, setIsMfaInLocalStorage] = useLocalStorage<MFAValue>(
        `mfa-in-use:${cognitoUser.cognitoUser?.getUsername()}`,
        'NOT_SET'
    );

    const [isMfa, setIsMfa] = useState<boolean>(false);

    useEffect(() => {
        if (isMfaInLocalStorage === 'NOT_SET') {
            getIsMfa().then((flag) => setIsMfa(flag));
        } else {
            setIsMfa(isMfaInLocalStorage);
        }
    }, [isMfaInLocalStorage]);

    return [isMfa, setIsMfaInLocalStorage];
}

import { useTheme } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import { dispatch, useSelector } from 'store';
import {
    closeTemporaryDrawer,
    setCurrentTaskId,
    setNoBackdrop,
    toggleTemporaryDrawer,
} from 'store/reducers/temporaryDrawer';
import DrawerPortal from 'components/drawer/DrawerPortal';
import StyledHeaderButton from './StyledHeaderButton';
import { useGetMessagesAddressedToEmployeeQuery } from 'store/api/notifications';
import TasksDrawerContent from './Tasks/TasksDrawerContent';
import { NotificationIconWrap } from './Notifications';
import { useGetTasksAddressedToEmployeeQuery } from 'store/api/tasks';
import AddressedTaskDetailsView from './Tasks/AddressedTasks/AddressedTaskDetailsView';
import { TaskPriorityCode, TaskStatusType } from '../../../../../types/tasks';
import StatusChip from '../../../../../components/@extended/StatusChip';
import { getClassifierText } from '../../../../../utils/getTranslations';
import { useClassifiers } from '../../../../../utils/useClassifiers';
import { useMemo } from 'react';

const Tasks: React.FC = () => {
    const theme = useTheme();
    const isOpen = useSelector(
        (state) =>
            state.temporaryDrawer.activeDrawerId === 'tasks' ||
            state.temporaryDrawer.activeDrawerId === 'addressedTaskview'
    );
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['TASK_PRIORITY_TYPE'],
    });
    const userId = useSelector((store) => store.user.id);

    const currentTaskId = useSelector((state) => state.temporaryDrawer.currentTaskId);

    // Refresh messages every 4 min (copying pollingInterval from Notifications)
    const { data: messages, isLoading: isMessageLoading } = useGetMessagesAddressedToEmployeeQuery(
        { employeeId: userId ?? 0, search: '' },
        { skip: typeof userId !== 'number', pollingInterval: 240000 }
    );

    const { data: tasks, isLoading: isTaskLoading } = useGetTasksAddressedToEmployeeQuery(
        { employeeId: userId ?? 0, search: '' },
        { skip: typeof userId !== 'number', pollingInterval: 240000 }
    );

    // Filter tasks with status "not done"
    const notDoneTasks = tasks?.filter((task) => task.taskStatusTypeCode != TaskStatusType.DONE).length;

    // Filter tasks with priority "high"
    const highPriorityTasks = tasks?.filter((task) => task.taskPriorityTypeCode == TaskPriorityCode.HIGH).length;

    const toggleDrawer = () => {
        if (isOpen) dispatch(closeTemporaryDrawer());
        else {
            dispatch(toggleTemporaryDrawer('tasks'));
            dispatch(setNoBackdrop(true));
        }
    };

    const openAddressedTaskDetailsView = (taskId: number) => {
        if (isOpen) {
            dispatch(toggleTemporaryDrawer('addressedTaskview'));
            dispatch(setCurrentTaskId(`${taskId}`));
            dispatch(setNoBackdrop(true));
        }
    };

    const openTaskContext = () => {
        if (isOpen) {
            dispatch(toggleTemporaryDrawer('tasks'));
            dispatch(setCurrentTaskId(''));
            dispatch(setNoBackdrop(true));
        }
    };

    const highPriorityTaskStyle = {
        alignSelf: 'center',
        color: theme.palette.error.lighter,
        backgroundColor: theme.palette.error.light,
        fontSize: '12px',
        fontWeight: 600,
        minHeight: '24px',
        borderRadius: '4px',
        height: 'auto',
        marginLeft: '2px',
        marginRight: '-4px',
        '.MuiChip-label': {
            padding: '0px 8px',
            color: theme.palette.text.error,
        },
    };

    const [unreadCount, unreadImportantCount] = useMemo(() => {
        let unreadImportantCount = 0;
        let unreadCount = 0;
        (messages ?? [])?.forEach((message) => {
            if (message.isImportant && !message.readAt) unreadImportantCount += 1;
            if (!message.readAt) unreadCount += 1;
        });
        return [unreadCount, unreadImportantCount];
    }, [messages]);

    if (isMessageLoading && isTaskLoading) return null;

    if (isTaskLoading) return null;

    let totalNotificationsNumber = (notDoneTasks ? notDoneTasks : 0) + unreadCount;
    let totalHighNotificationsNumber = (highPriorityTasks ? highPriorityTasks : 0) + unreadImportantCount;

    return (
        <>
            <StyledHeaderButton size="small" color="inherit" onClick={toggleDrawer}>
                <NotificationIconWrap theme={theme} hasUnread={unreadImportantCount > 0}>
                    <TaskIcon sx={{ fontSize: '16px' }} />
                </NotificationIconWrap>
                {totalNotificationsNumber}
                {highPriorityTasks ? (
                    <StatusChip
                        style={highPriorityTaskStyle}
                        type="error"
                        theme={theme}
                        label={
                            totalHighNotificationsNumber +
                            ' ' +
                            getClassifierText(classifiers['TASK_PRIORITY_TYPE'], TaskPriorityCode.HIGH).toLowerCase()
                        }
                    />
                ) : null}
            </StyledHeaderButton>
            <DrawerPortal
                content={
                    <TasksDrawerContent
                        messages={messages ?? []}
                        tasks={tasks ?? []}
                        theme={theme}
                        opennAddressTaskView={openAddressedTaskDetailsView}
                        toggleDrawer={toggleDrawer}
                    />
                }
                drawerId="tasks"
                applyColor="secondary"
                type="temporary"
                sx={{
                    width: '480px',
                    height: '100%',
                    [theme.breakpoints.down('sm')]: {
                        width: 'calc(100vw - 48px)',
                    },
                }}
            />
            <DrawerPortal
                content={
                    <AddressedTaskDetailsView
                        addressedTaskId={currentTaskId ? Number(currentTaskId) : undefined}
                        openTaskContext={openTaskContext}
                        toggleDrawer={toggleDrawer}
                        theme={theme}
                    />
                }
                drawerId="addressedTaskview"
                applyColor="secondary"
                type="temporary"
                sx={{
                    width: '480px',
                    height: '100%',
                    [theme.breakpoints.down('sm')]: {
                        width: 'calc(100vw - 48px)',
                    },
                }}
            />
        </>
    );
};

export default Tasks;

import { DropdownField, MultiSelectField } from 'components/forms/EntityFormTypes';
import { useMemo } from 'react';
import { SelectType } from 'types/forms';
import { t } from './getTranslations';
import partition from 'lodash/partition';
import { FieldValues } from 'react-hook-form';

export const useAutocompleteGrouping = <FormDataType>(
    field: DropdownField<FormDataType> | MultiSelectField<FormDataType>
) => {
    const optionsGroupsCount = [...new Set(field.options.map((item) => item.group))].length;
    const hasGroupedOptions = field.options.filter((option) => option.group !== undefined).length > 0;

    // if at least one option has a group, the groups will get sorted by the order set in the groupSortOrder array.
    // if groupSortOrder is missing a group value the option will be sorted alphabetically, but after everything defined in groupSortOrder.
    // if no groupSortOrder is defined, groups will be sorted alphabetically
    const optionsMaybeSorted = useMemo(() => {
        const result = field.options || [];
        if (!hasGroupedOptions) {
            return result;
        }
        // sort by given order
        if (field.groupSortOrder && field.groupSortOrder.length > 0) {
            const { groupSortOrder } = field;
            const [definedGroups, undefinedGroups] = partition(
                result,
                (option) => option.group && groupSortOrder.includes(option.group)
            );

            const sortedDefinedGroupsOptions = definedGroups.sort((a, b) => {
                const groupSortOrderA = groupSortOrder.findIndex((item) => item === a.group);
                const groupSortOrderB = groupSortOrder.findIndex((item) => item === b.group);
                return groupSortOrderA - groupSortOrderB;
            });

            const sortedUndefinedGroupsOptions = undefinedGroups.sort((a, b) => {
                if (!a.group || !b.group) {
                    return 1;
                }
                return a.group.localeCompare(b.group);
            });
            return [...sortedDefinedGroupsOptions, ...sortedUndefinedGroupsOptions];
        }
        // otherwise sort alphabetically by group name (to avoid duplicate groups)
        // BUT unspecified groups (null, undefined) will still be sorted at the end (For example group called "Other")
        return result.sort((a, b) => {
            if (!a.group || !b.group) {
                return 1;
            }
            return -String(b.group).localeCompare(String(a.group));
        });
    }, [field]);

    // if at least one option has a group, the following method will be called
    // options will be grouped by their group name or if none exists, "Other" will be provided as a fallback
    const handleGroupBy = (option: SelectType, defaultGroup = 'field.other'): string => {
        return option.group || t(defaultGroup);
    };

    return { hasGroupedOptions, optionsGroupsCount, optionsMaybeSorted, handleGroupBy };
};

export const useAutocompleteGroupingV2 = <TOption extends FieldValues>(
    options: TOption[],
    groupSortOrder?: string[] | undefined
) => {
    const optionsGroupsCount = [...new Set(options.map((item) => item.group))].length;
    const hasGroupedOptions = !!options.find((option) => option.group !== undefined);

    // if at least one option has a group, the groups will get sorted by the order set in the groupSortOrder array.
    // if groupSortOrder is missing a group value the option will be sorted alphabetically, but after everything defined in groupSortOrder.
    // if no groupSortOrder is defined, groups will be sorted alphabetically
    const optionsMaybeSorted = useMemo(() => {
        const result = options || [];
        if (!hasGroupedOptions) {
            return result;
        }
        // sort by given order
        if (groupSortOrder && groupSortOrder.length > 0) {
            const [definedGroups, undefinedGroups] = partition(
                result,
                (option) => option.group && groupSortOrder.includes(option.group)
            );

            const sortedDefinedGroupsOptions = definedGroups.sort((a, b) => {
                const groupSortOrderA = groupSortOrder.findIndex((item) => item === a.group);
                const groupSortOrderB = groupSortOrder.findIndex((item) => item === b.group);
                return groupSortOrderA - groupSortOrderB;
            });

            const sortedUndefinedGroupsOptions = undefinedGroups.sort((a, b) => {
                if (!a.group || !b.group) {
                    return 1;
                }
                return a.group.localeCompare(b.group);
            });

            return [...sortedDefinedGroupsOptions, ...sortedUndefinedGroupsOptions];
        }
        // otherwise sort alphabetically by group name (to avoid duplicate groups)
        // BUT unspecified groups (null, undefined) will still be sorted at the end (For example group called "Other")
        return result.sort((a, b) => {
            if (!a.group || !b.group) {
                return 1;
            }
            return -String(b.group).localeCompare(String(a.group));
        });
    }, [options]);

    return { hasGroupedOptions, optionsGroupsCount, optionsMaybeSorted };
};

// material-ui
import { Theme } from '@mui/material/styles';

// types
import { ColorProps } from 'types/extended';

// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme: Theme, color?: ColorProps) => {
    switch (color!) {
        case 'secondary':
            return theme.palette.secondary;
        case 'error':
            return theme.palette.error;
        case 'warning':
            return theme.palette.warning;
        case 'info':
            return theme.palette.info;
        case 'success':
            return theme.palette.success;
        default:
            return theme.palette.primary;
    }
};

const getColorsExtended = (
    theme: Theme,
    color?:
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | 'textPrimary'
        | 'textSecondary'
        | 'link'
        | 'divider'
        | 'infoDarker'
) => {
    switch (color!) {
        case 'secondary':
            return theme.palette.secondary.main;
        case 'error':
            return theme.palette.error.main;
        case 'warning':
            return theme.palette.warning.main;
        case 'info':
            return theme.palette.info.main;
        case 'infoDarker':
            return theme.palette.info.darker;
        case 'success':
            return theme.palette.success.main;
        case 'textPrimary':
            return theme.palette.text.primary;
        case 'textSecondary':
            return theme.palette.text.secondary;
        case 'link':
            return theme.palette.text.link;
        case 'divider':
            return theme.palette.divider;
        default:
            return theme.palette.primary.main;
    }
};

export default getColors;
export { getColorsExtended };

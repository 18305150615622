import { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export interface CircularLoaderProps extends CircularProgressProps {
    isLoading?: boolean;
}

const CircularLoader: FC<CircularLoaderProps> = ({ sx, ...restProps }): JSX.Element => (
    <LoaderWrapper className="CircularLoader" sx={sx}>
        <CircularProgress color="primary" {...restProps} />
    </LoaderWrapper>
);

export default CircularLoader;

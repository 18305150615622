import { Suspense, lazy, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import useConfig from 'hooks/useConfig';

// types
import { openDrawer } from 'store/reducers/menu';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
// import SubDrawer from './drawers/SubDrawer';
const SubDrawer = lazy(() => import('./drawers/SubDrawer'));

import { closeSubDrawer } from 'store/reducers/subdrawer';
// import TemporaryDrawer from './drawers/TemporaryDrawer';
const TemporaryDrawer = lazy(() => import('./drawers/TemporaryDrawer'));
import GlobalAddButton from 'components/buttons/GlobalAddButton';
import SuspendedPlaceholder from 'components/SuspendedPlaceholder';
import Chat from 'components/chat/Chat';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const { pathname } = useLocation();

    const { miniDrawer } = useConfig();
    const dispatch = useDispatch();

    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;

    // drawer toggler
    const [open, setOpen] = useState(!miniDrawer || drawerOpen);
    const handleDrawerToggle = () => {
        if (matchDownSM) {
            setOpen(false);
            dispatch(openDrawer({ drawerOpen: false }));
        } else {
            setOpen(!open);
            dispatch(openDrawer({ drawerOpen: !open }));
        }
    };

    // set media wise responsive drawer
    useEffect(() => {
        if (!miniDrawer) {
            setOpen(!matchDownLG);
            dispatch(openDrawer({ drawerOpen: !matchDownLG }));
        }
    }, [matchDownLG]);

    useEffect(() => {
        if (miniDrawer) {
            setOpen(false);
            dispatch(openDrawer({ drawerOpen: false }));
        }
    }, [matchDownSM]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    useEffect(() => {
        dispatch(closeSubDrawer());
    }, [pathname]);

    const intl = useIntl();
    const defaultPageTitle = intl.formatMessage({ id: 'default-page-title' });

    // Added overlfowX hidden because openin temp drawer causes overflow. If this breaks anything else then remove it
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.grey[0],
                overflowX: 'hidden',
            }}
        >
            <Helmet>
                <title>{defaultPageTitle}</title>
                <meta name="breadcrumb" content={''} />
            </Helmet>
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <SuspendedPlaceholder>
                <SubDrawer />
            </SuspendedPlaceholder>
            <Box
                component="main"
                sx={{
                    width: 'calc(100% - 260px)',
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Header />
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'auto',
                        // Mostly for form content menu
                        scrollBehavior: 'smooth',
                    }}
                >
                    <Chat />
                    <GlobalAddButton />
                    <Outlet />
                    <Footer />
                </Box>
            </Box>
            <SuspendedPlaceholder>
                <TemporaryDrawer />
            </SuspendedPlaceholder>
        </Box>
    );
};

export default MainLayout;

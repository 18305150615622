import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { Task } from '../../../../../../../types/tasks.ts';
import { MinDto } from '../../../../../../../types/other.ts';

interface AddressedTaskCreatedInfoProps {
    task: Task;
}

interface AuditInfoProps {
    employee: MinDto;
    createdAt: number;
    type: 'create' | 'modify';
}

const AuditInfo = ({ employee, createdAt, type }: AuditInfoProps) => {
    const messageId = `tasks.view-form.${type === 'create' ? 'created-by' : 'last-modified-by'}`;
    return (
        <div>
            <Typography sx={{ color: 'text.secondary', display: 'flex' }}>
                <FormattedMessage id={messageId} />
                <Typography component="span">&nbsp;</Typography>
                <Typography component="span">{employee.name}</Typography>
                <Typography component="span">&nbsp;/ {format(new Date(createdAt), 'HH:mm yyyy-MM-dd')}</Typography>
            </Typography>
        </div>
    );
};

const AddressedTaskCreatedInfo: React.FC<AddressedTaskCreatedInfoProps> = ({ task }) => {
    return (
        <Stack
            direction="column"
            component="div"
            sx={{
                pb: '20px',
            }}
        >
            {task.createdByEmployee && task.createdAt && (
                <AuditInfo createdAt={task.createdAt} employee={task.createdByEmployee} type={'create'} />
            )}
            {task.lastModifiedByEmployee && task.lastModifiedAt && (
                <AuditInfo createdAt={task.lastModifiedAt} employee={task.lastModifiedByEmployee} type={'modify'} />
            )}
        </Stack>
    );
};

export default AddressedTaskCreatedInfo;

import { MainFilterInForm } from './UmbrellaTableTypes';

export const getSelectorKey = (group: MainFilterInForm): string => {
    let assumedComparator: string | undefined;
    switch (group.type) {
        case 'checkboxgroup':
        case 'date':
        case 'multiselect':
        case 'dynamicmultiselect':
        case 'dropdown':
        case 'dynamicdropdown':
            assumedComparator = '=in=';
            break;
        case 'offset-date-time':
        case 'text':
            assumedComparator = '=lk=';
            break;
        case 'interval':
            if (group.from) {
                assumedComparator = '=ge=';
            }
            if (group.to) {
                assumedComparator = '=le=';
            }
            break;
    }

    return assumedComparator || group.comperator
        ? `${group.selector}:${group.comperator ? group.comperator : assumedComparator}`
        : group.selector;
};

import useAuth from 'hooks/useAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { styled, useTheme } from '@mui/material/styles';
import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AnimateButton from '../../../components/@extended/AnimateButton';
import { Box } from '@mui/system';
import { t } from '../../../utils/getTranslations.ts';
import * as Sentry from '@sentry/react';

const InputStyled = styled('input')(({ theme }) => ({
    border: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300],
    ':focus': {
        outline: 'none',
        boxShadow: theme.customShadows.primary,
        border: `1px solid ${theme.palette.primary.main} !important`,
    },
}));

export const AuthMFAVerification = () => {
    const { sendOTP } = useAuth();
    const { handleSubmit, formState, control, setError } = useForm<{ mfaCode: string }>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
    });
    const navigate = useNavigate();
    const theme = useTheme();

    const onSubmit = async ({ mfaCode }: { mfaCode: string }) => {
        try {
            await sendOTP(mfaCode);
            navigate('/home');
        } catch (err: any) {
            if (err && err.message) {
                setError('mfaCode', { message: err.message });
            } else {
                setError('mfaCode', { message: t('auth.mfa-verification-error') });
            }
            Sentry.captureException(err);
            console.error('Error verifying TOTP:', err);
        }
    };

    const borderColor = theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300];

    return (
        <Box>
            <Stack spacing={1}>
                <div>
                    <Typography variant="h5">{t('auth.enter-code-auth-app')}</Typography>
                    <Controller
                        name="mfaCode"
                        control={control}
                        rules={{
                            required: t('auth.mfa-code-required'),
                            validate: (value) =>
                                (!isNaN(Number(value)) && value.length === 6) || t('auth.mfa-code-length'),
                        }}
                        render={({ field }) => {
                            return (
                                <OtpInput
                                    value={field.value}
                                    onChange={field.onChange}
                                    shouldAutoFocus
                                    numInputs={6}
                                    containerStyle={{ justifyContent: 'space-between' }}
                                    inputStyle={{
                                        width: '100%',
                                        margin: '8px',
                                        padding: '10px',
                                        border: `1px solid ${borderColor}`,
                                        borderRadius: 4,
                                    }}
                                    renderInput={(inputProps, index) => {
                                        if (index === 5) {
                                            const { onBlur, ...rest } = inputProps;
                                            return (
                                                <InputStyled
                                                    {...rest}
                                                    onBlur={(target) => {
                                                        onBlur(target);
                                                        field.onBlur();
                                                    }}
                                                />
                                            );
                                        }
                                        return <InputStyled {...inputProps} />;
                                    }}
                                />
                            );
                        }}
                    />
                    <div style={{ height: '20px' }}>
                        <FormHelperText error id="mfa-code-error">
                            {formState.errors.mfaCode?.message}
                        </FormHelperText>
                    </div>
                </div>
                <div>
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            disabled={formState.isSubmitting}
                        >
                            <FormattedMessage id="continue" />
                        </Button>
                    </AnimateButton>
                </div>
            </Stack>
        </Box>
    );
};

// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { ThemeMode } from 'types/config';

// ==============================|| PRESET THEME - GREEN THEME ||============================== //

const Default = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
    const { grey } = colors;
    const greyColors: PaletteColorOptions = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16],
    };
    const contrastText = '#fff';

    return {
        primary: {
            main: '#335131',
        },
        secondary: {
            main: '#FFC069',
        },
        error: {
            main: '#FF4D4F',
            light: '#FAE7E9',
            lighter: '#FEF4F4',
        },
        warning: {
            main: '#FA8C16',
            light: '#FFF4E8',
            lighter: '#FEF3E8',
        },
        info: {
            darker: '#29295B',
            main: '#9999FF',
            lighter: '#E5EDF7',
        },
        success: {
            main: '#52C41A',
            light: '#EBF5E7',
            lighter: '#EBF5E7',
            contrastText,
        },
        grey: greyColors,
    };
};

export default Default;

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import { MenuFoldOutlined } from '@ant-design/icons';
import IconButton from 'components/@extended/IconButton';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
    open: boolean;
    handleDrawerToggle?: () => void;
}

const DrawerHeader = ({ open, handleDrawerToggle }: Props) => {
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            {open ? (
                <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    variant="light"
                    sx={{
                        color: 'primary.main',
                        bgcolor: 'transparent',
                        opacity: 0.5,
                        height: 24,
                        width: 24,
                        p: 0.25,
                        fontSize: 20,
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                    }}
                >
                    <MenuFoldOutlined height={20} width={20} />
                </IconButton>
            ) : null}
            <Logo
                isIcon={!open}
                sx={{
                    width: open ? '100%' : 36,
                    height: open ? 30 : 36,
                    justifyContent: open ? 'flex-start' : 'center',
                    borderRadius: open ? 0 : 1,
                    overflow: open ? 'visible' : 'hidden',
                }}
                onClick={!open ? handleDrawerToggle : undefined}
            />
        </DrawerHeaderStyled>
    );
};

export default DrawerHeader;

import { ClassifierData } from 'types/classifiers';
import { AddressDto } from 'types/contacts';
import { SelectType } from 'types/forms';
import { getClassifierText, getClassifierTextDescription } from './getTranslations';

export const getOptionsFromClassifier = (classifier: ClassifierData | undefined): SelectType[] => {
    if (!classifier) {
        return [];
    }

    return classifier.values
        ? classifier.values
              .map((type) => ({
                  label: getClassifierText(classifier, type.code),
                  value: type.code,
                  description: getClassifierTextDescription(classifier, type.code),
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
};

export const getValidOptionsFromClassifier = (classifier: ClassifierData | undefined): SelectType[] => {
    if (!classifier) {
        return [];
    }

    const now = new Date();

    const toReturn = classifier.values
        ? classifier.values
              .filter((type) => {
                  return (
                      (!type.validFrom || new Date(type.validFrom) < now) &&
                      (!type.validUntil || new Date(type.validUntil) > now)
                  );
              })
              .map((type) => ({
                  label: getClassifierText(classifier, type.code),
                  value: type.code,
                  description: getClassifierTextDescription(classifier, type.code),
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    return toReturn;
};

export const getSortedOptions = <T>(
    getLabel: Extract<keyof T, string> | ((item: T) => string),
    getValue: Extract<keyof T, string> | ((item: T) => string),
    optionElements: T[]
): SelectType[] => {
    return optionElements
        .reduce<SelectType[]>((currentArray, currentItem) => {
            const label = (typeof getLabel === 'string' ? currentItem[getLabel] : getLabel(currentItem)) as string;
            const value = (typeof getValue === 'string' ? currentItem[getValue] : getValue(currentItem)) as string;
            if (label && value) {
                currentArray.push({ label, value });
            }
            return currentArray;
        }, [])
        .sort((a, b) => (a.label as string).localeCompare(b.label as string));
};

export const getAddress = (
    address: AddressDto | null,
    enabledFeatures: string[] | null,
    countyLabel?: string
): string => {
    if (address === null) return '';

    const filteredCustomFields = address.customFields?.filter((customField) =>
        customField.isMultivalue ? customField.values : customField.value
    );

    const hasCustomFieldsWithValue = filteredCustomFields?.length;

    return `${address?.address1 ?? ''} 
    ${address?.address2 ?? ''} 
    ${address?.cityName ?? ''}, 
    ${address?.zipCode ?? ''} 
    ${countyLabel ?? address?.countryCode ?? ''}${hasCustomFieldsWithValue ? ',' : ''}
    ${
        address.customFields && hasCustomFieldsWithValue
            ? filteredCustomFields?.map((field) => (field.isMultivalue ? field.values : field.value)).join(', ')
            : ''
    }`;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types
import { UserData, UserStoreProps } from 'types/user';

const initialState: UserStoreProps = {
    id: null,
    activeTenantId: null,
    name: null,
    active: false,
    workEmailAddress: null,
    permissions: null,
    features: null,
    enabledFeatures: null,
};

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setActiveTenant(state, action) {
            state.activeTenantId = action.payload;
        },
        setUserData(state, action: PayloadAction<UserData>) {
            state.name = action.payload.firstName + ' ' + action.payload.lastName;
            state.active = action.payload.active;
            state.workEmailAddress = action.payload.workEmailAddress;
            state.id = action.payload.id;
            state.permissions = action.payload.permissions;
            state.activeTenantId = action.payload.tenants[0];
            state.features = action.payload.features;
            state.enabledFeatures = action.payload.enabledFeatures;
        },
        logout(state) {
            state.name = null;
            state.active = false;
            state.workEmailAddress = null;
            state.id = null;
            state.permissions = null;
            state.activeTenantId = null;
            state.features = null;
            state.enabledFeatures = null;
        },
    },
});

export default userReducer.reducer;

export const { setActiveTenant, setUserData, logout } = userReducer.actions;

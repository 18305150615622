// material-ui
import { Theme } from '@mui/material/styles';
import { useMediaQuery, Container, Link, Typography, Stack } from '@mui/material';
import { t } from 'utils/getTranslations';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = (): JSX.Element => {
    const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="xl">
            <Stack
                direction={matchDownSM ? 'column' : 'row'}
                justifyContent={matchDownSM ? 'center' : 'flex-end'}
                spacing={2}
                textAlign={matchDownSM ? 'center' : 'inherit'}
            >
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={matchDownSM ? 1 : 3}
                    textAlign={matchDownSM ? 'center' : 'inherit'}
                >
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component={Link}
                        href="https://crespect.com/"
                        target="_blank"
                        underline="hover"
                    >
                        {t('footer.about-us')}
                    </Typography>
                    {/* // Hide for now CPD-292
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component={Link}
                        href="https://codedthemes.com"
                        target="_blank"
                        underline="hover"
                    >
                        {t('footer.terms-conditions')}
                    </Typography> */}
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component={Link}
                        href="https://crespect.com/home/privacy-policy/"
                        target="_blank"
                        underline="hover"
                    >
                        {t('footer.privacy-policy')}
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
};

export default AuthFooter;

import { Skeleton, TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';

const RowLoader = ({ amountOfColumns = 0, height = 40 }) => {
    const content = useMemo(() => {
        const rows = [] as React.ReactNode[];
        const cells = [] as React.ReactNode[];
        for (let i = 0; i < amountOfColumns; i++) {
            cells.push(
                <TableCell key={`cell-${i}`}>
                    <Skeleton variant="rectangular" width={'100%'} height={height} />
                </TableCell>
            );
        }

        for (let i = 0; i < 10; i++) {
            rows.push(
                <TableRow key={`row-${i}`} hover={false} sx={{ '&:hover': { background: 'transparent' } }}>
                    {cells}
                </TableRow>
            );
        }
        return rows;
    }, [amountOfColumns]);
    return <>{content}</>;
};

export default RowLoader;

import { Box, Button, Stack, Theme, styled } from '@mui/material';
import StatusChip from 'components/@extended/StatusChip';
import TimeSinceDate from 'components/util/TimeSinceDate';
import { AutomatedNotification } from 'types/notifications';
import CheckIcon from '@mui/icons-material/Check';
import { FormattedMessage } from 'react-intl';
import FormattedNotifcationText from './FormattedNotificationText';

interface Props {
    notification: AutomatedNotification;
    theme: Theme;
    handleRead: (id: string | number | 'all') => Promise<void>;
    handleArchive: (id: string | number | 'all') => Promise<void>;
    areButtonsDisabled: boolean;
}

const NotificationItem: React.FC<Props> = ({ notification, theme, handleArchive, handleRead, areButtonsDisabled }) => {
    return (
        <NotificationItemWrap isUnread={!notification?.readAt} theme={theme}>
            <Stack
                sx={{
                    px: 3,
                    py: 2,
                    gap: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    pl: notification?.readAt ? 3 : 5,
                }}
                direction="column"
                component="li"
            >
                <Stack spacing={0.5}>
                    {notification?.isImportant ? (
                        <Box>
                            <StatusChip
                                type="warning"
                                sx={{
                                    alignSelf: 'flex-start',
                                }}
                                theme={theme}
                                label={<FormattedMessage id="notifications.important" values={{ count: 0 }} />}
                            />
                        </Box>
                    ) : null}
                    <FormattedNotifcationText notification={notification} />
                    <TimeSinceDate date={notification.createdAt} />
                </Stack>
                <Stack direction="row" spacing={0.5}>
                    <StyledButton
                        startIcon={notification?.readAt ? null : <CheckIcon fontSize="inherit" color="success" />}
                        variant="outlined"
                        onClick={() => handleRead(notification.id)}
                        disabled={areButtonsDisabled}
                    >
                        <FormattedMessage
                            id={notification?.readAt ? 'notifications.mark-as-unread' : 'notifications.mark-as-read'}
                        />
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={() => handleArchive(notification.id)}
                        disabled={areButtonsDisabled}
                    >
                        <FormattedMessage id="notifications.archive" />
                    </StyledButton>
                </Stack>
            </Stack>
        </NotificationItemWrap>
    );
};

export const StyledButton = styled(
    Button,
    {}
)(() => ({
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 600,
    height: 24,
    paddingLeft: 10,
    paddingRight: 10,
    '.MuiButton-startIcon': {
        marginRight: 4,
    },
    '.MuiButton-startIcon, .MuiSvgIcon-root': {
        fontSize: 12,
    },
}));

export const NotificationItemWrap = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isUnread',
})(({ theme, isUnread }: { theme: Theme; isUnread: boolean }) => ({
    position: 'relative',
    '&::before': {
        display: isUnread ? 'block' : 'none',
        position: 'absolute',
        content: '""',
        backgroundColor: theme.palette.error.main,
        height: 8,
        width: 8,
        borderRadius: '50%',
        top: '25px',
        left: '24px',
    },
}));

export default NotificationItem;

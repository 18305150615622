import React, { useMemo } from 'react';
import TabsGallery, { TabsGalleryRef, TabType } from '../../components/TabsGallery.tsx';
import { FormattedMessage } from 'react-intl';
import SuspendedPlaceholder from '../../components/SuspendedPlaceholder.tsx';
import ClassifiersPage from '../classifiers/ClassifiersPage.tsx';
import CorporateRolePage from '../corporate-structure/CorporateRolePage.tsx';
import PaddedLayout from '../../layout/MainLayout/PaddedLayout.tsx';
import Configuration from '../configuration/Configuration.tsx';
import PageHeader from '../../components/PageHeader.tsx';
import DocumentTemplatesTable from '../document-templates/DocumentTemplatesTable.tsx';

const Settings = () => {
    const tabsGalleryRef = React.createRef<TabsGalleryRef>();

    const TABS = useMemo<TabType[]>(
        () => [
            {
                id: 'classifiers',
                label: <FormattedMessage id="menu.classifiers" />,
                component: (
                    <SuspendedPlaceholder>
                        <ClassifiersPage />
                    </SuspendedPlaceholder>
                ),
            },
            {
                id: 'roles',
                label: <FormattedMessage id="menu.company-roles" />,
                component: (
                    <SuspendedPlaceholder>
                        <CorporateRolePage />
                    </SuspendedPlaceholder>
                ),
            },
            {
                id: 'document-templates',
                label: <FormattedMessage id="menu.document-templates" />,
                component: (
                    <SuspendedPlaceholder>
                        <DocumentTemplatesTable />
                    </SuspendedPlaceholder>
                ),
            },

            {
                id: 'configuration',
                label: <FormattedMessage id="menu.configuration" />,
                component: (
                    <SuspendedPlaceholder>
                        <Configuration />
                    </SuspendedPlaceholder>
                ),
            },
        ],
        []
    );

    return (
        <PaddedLayout>
            <PageHeader titleId={'menu.settings'} showBreadcrumb />
            <>
                <TabsGallery ref={tabsGalleryRef} tabs={TABS} />
            </>
        </PaddedLayout>
    );
};

export default Settings;

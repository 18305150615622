import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChatStoreProps, ObjectType } from 'types/chat';

const initialState: ChatStoreProps = {
    objectId: null,
    objectType: null,
    isOpen: false,
};

const chat = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        openChat(state, action: PayloadAction<{ objectId: number; objectType: ObjectType }>) {
            const { objectId, objectType } = action.payload;
            state.objectId = objectId;
            state.objectType = objectType;
            state.isOpen = true;
        },
        closeChat(state) {
            state.isOpen = false;
        },
    },
});

export default chat.reducer;

export const { openChat, closeChat } = chat.actions;

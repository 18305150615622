import { CallForActionButtonTooltip, CallForActionButton } from '../../../components/buttons/CallForActionButton';
import { useTheme } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { t } from 'utils/getTranslations';

type Props = {
    onClick: () => void;
};

const StartTrackingIconButton = ({ onClick }: Props): JSX.Element => {
    const theme = useTheme();

    return (
        <CallForActionButtonTooltip title={t('timecards.continue')}>
            <CallForActionButton
                variant="contained"
                color="info"
                shape="rounded"
                aria-label={t('timecards.continue')}
                sx={{
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.common.white,
                }}
                onClick={onClick}
            >
                <PlayArrowIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    );
};

export default StartTrackingIconButton;

import { useCallback, useMemo } from 'react';
import { alpha, Box, CircularProgress, Stack, useTheme } from '@mui/material';
import Loader from 'components/Loader';
import { t } from '../../utils/getTranslations';
import { Classifier } from 'types/classifiers';
import { useLazyGetClassifiersWithFiltersQuery } from '../../store/api/classifiers';
import { QueryResponse } from '../../types/paginationAndFilter.ts';
import NoData from '../../components/NoData.tsx';
import { LoadingButton } from '@mui/lab';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { FilterConfig } from '../../components/tables/umbrella/UmbrellaTableTypes.tsx';
import UmbrellaTableWithFilters from '../../components/tables/umbrella/UmbrellaTableWithFilters.tsx';
import ClassifierItem from './ClassifierItem.tsx';
import ScrollX from '../../components/ScrollX.tsx';

const ClassifiersList = () => {
    const theme = useTheme();

    const [fetchData, { data, isFetching }] = useLazyGetClassifiersWithFiltersQuery();

    const filterConfig: FilterConfig = useMemo(
        () => ({
            quickFilters: [],
            filters: [],
            searchBarSelectors: ['code', 'values.code', 'values.texts.value'],
        }),
        []
    );

    const renderClassifiersTable = useCallback(
        (
            dataToDisplay: Classifier[],
            initialLoad: boolean,
            noData: boolean,
            textId?: string,
            paginationProps?: Partial<QueryResponse<Classifier>>,
            handleLoadMore?: () => void
        ): JSX.Element => {
            const classifierArray = dataToDisplay ?? [];

            if (initialLoad) {
                return <Loader />;
            }

            return (
                <ScrollX>
                    <Stack gap={1} sx={{ pr: 1 }}>
                        {isFetching && classifierArray.length > 0 ? (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    backgroundColor: alpha(theme.palette.divider, 0.4),
                                    zIndex: 12,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <CircularProgress size={60} disableShrink />
                            </Box>
                        ) : null}
                        {noData ? (
                            <NoData titleId={textId} />
                        ) : (
                            classifierArray.map((classifier: Classifier) => (
                                <ClassifierItem key={classifier.code} classifier={classifier} />
                            ))
                        )}
                        {!paginationProps?.last ? (
                            <LoadingButton
                                variant="outlined"
                                onClick={handleLoadMore}
                                sx={{ mt: '14px' }}
                                startIcon={<KeyboardDoubleArrowDownIcon fontSize="small" />}
                                disabled={isFetching}
                                loading={isFetching}
                                loadingIndicator={t('loading')}
                            >
                                {t('load-more')}
                            </LoadingButton>
                        ) : null}
                    </Stack>
                </ScrollX>
            );
        },
        [isFetching, theme]
    );

    return (
        <UmbrellaTableWithFilters<Classifier>
            data={data}
            isLoading={isFetching}
            fetchData={fetchData}
            filterConfig={filterConfig}
            rowsPerPage={50}
            renderCustomTable={renderClassifiersTable}
        />
    );
};

export default ClassifiersList;

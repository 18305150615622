import { useCallback, useContext } from 'react';
import { ModalContext } from './ModalProvider';
import { useDispatch } from 'store';
import { disableButtons } from 'store/reducers/buttons';
import { CSSObject } from '@mui/material';

export interface ModalActions {
    closeModal: () => void;
    openModal: (atrContent: JSX.Element, atrOnCancel?: () => void, atrModalStyle?: CSSObject) => void;
}

export const useModal = (): ModalActions => {
    const { setModalOptions } = useContext(ModalContext);
    const dispatch = useDispatch();

    const closeModal = useCallback((): void => {
        setModalOptions({
            content: undefined,
            onCancel: () => null,
            modalStyle: undefined,
        });

        // Change the save buttons active again
        dispatch(disableButtons(false));
    }, [dispatch, setModalOptions]);

    const openModal = useCallback(
        (atrContent: JSX.Element, atrOnCancel?: () => void, atrModalStyle?: CSSObject): void => {
            setModalOptions({
                content: atrContent,
                onCancel: atrOnCancel || closeModal,
                modalStyle: atrModalStyle,
            });
        },
        [closeModal, setModalOptions]
    );

    return {
        closeModal,
        openModal,
    };
};

import { useMemo } from 'react';
import { useSelector } from '../store';

export const useHasPermissions = (userPermissions: string[]) => {
    const permissions = useSelector((state) => state.user.permissions);
    const hasAnyPermission = useMemo(
        () => permissions?.find((permission) => userPermissions.includes(permission.code)),
        [permissions, userPermissions]
    );
    return !!hasAnyPermission;
};

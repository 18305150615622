// ==============================|| OVERRIDES - TABS ||============================== //

import { Theme } from '@mui/material';

export default function Tabs(theme: Theme) {
    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.info.main,
                    },
                    '.MuiTabScrollButton-root': {
                        '&.Mui-disabled': {
                            color: theme.palette.text.secondary,
                            opacity: 0.8,
                        },
                    },
                },
                vertical: {
                    overflow: 'visible',
                },
            },
        },
    };
}

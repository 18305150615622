// ==============================|| OVERRIDES - INPUT BASE ||============================== //

import { Theme } from '@mui/material';

export default function InputBase(theme: Theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                // root: {
                //     [theme.breakpoints.down('lg')]: {
                //         padding: '0',
                //         fontSize: '13px'
                //     },
                // },
                sizeSmall: {
                    fontSize: '0.75rem',
                },
            },
        },
    };
}

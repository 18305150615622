import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FinishTimecardIconButton from 'pages/timecards/buttons/FinishTimecardIconButton';
import TimecardTrackingButton from 'pages/timecards/buttons/TimecardTrackingButton';
import { TimecardDetails } from 'types/timecards';
import TrackingTimer from './TrackingTimer';

type Props = {
    timecard: TimecardDetails;
};

const TrackingWidgetControls = ({ timecard }: Props) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="34px"
            p="5px 4px"
            sx={{ backgroundColor: theme.palette.grey['200'] }}
        >
            <TimecardTrackingButton timecard={timecard} />
            <TrackingTimer timecard={timecard} />
            <FinishTimecardIconButton timecard={timecard} />
        </Box>
    );
};

export default TrackingWidgetControls;

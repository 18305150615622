import { useCallback } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithReturnState = (fallback?: To) => {
    const reactNavigate = useNavigate();

    const { state } = useLocation();

    const returnTo = state?.returnTo || fallback;

    return useCallback(
        (to: To, options: NavigateOptions = {}) => {
            reactNavigate(to, { ...options, state: { ...options?.state, returnTo } });
        },
        [reactNavigate, returnTo]
    );
};

export interface LocationParams<Data> {
    pathname: string;
    state: Data;
    search: string;
    hash: string;
    key: string;
}

export interface NavigateWithReturn {
    returnTo: To;
}

/**
 *
 * @param fallback May be either a URL or the pieces of a URL path.
 * @returns Return url from state or a fallback url if state is missing.
 */
export const useReturnToLocation = (fallback: To): To => {
    const { state } = useLocation() as LocationParams<NavigateWithReturn>;

    return state?.returnTo || fallback;
};

import { Fade } from '@mui/material';
import { Theme } from '@mui/material/styles';

export default function Tooltip(theme: Theme) {
    return {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
                TransitionComponent: Fade,
                PopperProps: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -8],
                            },
                        },
                    ],
                },
            },
            styleOverrides: {
                tooltip: {
                    borderRadius: 2,
                    padding: '4px 8px !important',
                    background: theme.palette.grey[0],
                    color: theme.palette.grey[700],
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                    fontSize: '12px',
                    lineHeight: '20px',
                    maxWidth: '40ch',
                },
                arrow: {
                    '&:before': {
                        border: `1px solid ${theme.palette.grey[0]}`,
                    },
                    color: theme.palette.grey[0],
                },
            },
        },
    };
}

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { JobPositionForm } from '../../types/jobPositions';
import { useGetJobPositionByIdQuery } from '../../store/api/jobPositions';
import JobPositionPageForm from './JobPositionPageForm';
import Loader from '../../components/Loader';
import { useClassifiers } from 'utils/useClassifiers';
import { getOptionsFromClassifier } from 'utils/formUtil';
import { skipToken } from '@reduxjs/toolkit/query';

const USED_CLASSIFIERS = ['JOB_POSITION_TYPE', 'LANGUAGE'];

const JobPositionPageEdit = () => {
    const { id: jobPositionId } = useParams();
    const [initialData, setInitialData] = useState<JobPositionForm>();

    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: USED_CLASSIFIERS,
    });

    const { data: jobPosition, isLoading, isError, refetch } = useGetJobPositionByIdQuery(jobPositionId ?? skipToken);

    // Set initial form data
    const getInitialData = useCallback((): JobPositionForm | undefined => {
        const supportedLanguages = Array.from(
            new Set(
                classifiers['LANGUAGE']?.values
                    ?.map((value) => value.code)
                    .sort((a: string, b: string) => a.localeCompare(b)) ?? []
            )
        );

        return {
            name: jobPosition?.name ?? '',
            externalId: jobPosition?.externalId ?? null,
            positionTypeCode:
                (
                    getOptionsFromClassifier(classifiers['JOB_POSITION_TYPE']) as {
                        label: string;
                        value: string;
                    }[]
                ).find((val) => val.value === jobPosition?.positionTypeCode) ?? null,
            entityTexts: supportedLanguages.map((langCode) => {
                const entityText = jobPosition?.entityTexts?.find((el) => el.langCode === langCode);
                return {
                    id: entityText?.id ?? null,
                    langCode,
                    value: entityText?.value ?? '',
                    description: entityText?.description ?? '',
                    textMale: entityText?.textMale ?? '',
                    textFemale: entityText?.textFemale ?? '',
                };
            }),
        };
    }, [jobPosition, classifiers]);

    useEffect(() => {
        setInitialData(getInitialData);
    }, [jobPosition, classifiers]);

    if (isLoading || isLoadingClassifiers || (!initialData && !isError)) {
        return <Loader />;
    }

    return <JobPositionPageForm type="edit" initialData={initialData} classifiers={classifiers} refetch={refetch} />;
};

export default JobPositionPageEdit;

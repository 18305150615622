import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

// Constant outside of components reduce redeclarations
const TIME_INTERVALS = [
    { interval: 31536000, messageId: 'time.years-ago' },
    { interval: 2592000, messageId: 'time.months-ago' },
    { interval: 86400, messageId: 'time.days-ago' },
    { interval: 3600, messageId: 'time.hours-ago' },
    { interval: 60, messageId: 'time.minutes-ago' },
    { interval: 1, messageId: 'time.seconds-ago' },
];

// Find how long ago the input date was and return a human readable text with it
const TimeSinceDate: React.FC<{ date: Date | string }> = ({ date }) => {
    // Passed time between now and input date in seconds.
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

    const { interval, messageId } = TIME_INTERVALS.find((unit) => {
        const unitsSinceDate = seconds / unit.interval;
        if (unitsSinceDate >= 1) {
            return true;
        }
        return false;
    }) || { interval: 0, messageId: 'time.seconds-ago' };

    return (
        <Typography sx={{ fontSize: 12 }} color="text.secondary">
            <FormattedMessage values={{ count: Math.floor(seconds / interval) }} id={messageId} />
        </Typography>
    );
};

export default TimeSinceDate;

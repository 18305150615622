import { useCallback, useEffect, useRef } from 'react';

export const useInfiniteScroll = (
    targetRef: React.MutableRefObject<HTMLElement | null>,
    options: {
        observer: IntersectionObserverInit;
        disabled?: boolean;
        isLoading?: boolean;
    },
    getData: () => void
) => {
    const observer = useRef<IntersectionObserver | null>(null);

    const handleIntersection = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !options.disabled && !options.isLoading) {
                    getData();
                }
            });
        },
        [getData, options.disabled, options.isLoading]
    );

    useEffect(() => {
        if (targetRef.current) {
            observer.current = new IntersectionObserver(handleIntersection, options.observer);
            observer.current.observe(targetRef.current);
        }
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [targetRef, handleIntersection, options]);

    return observer;
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ExportReducerState {
    isExporting: boolean;
}

// initial state
const initialState: ExportReducerState = {
    isExporting: false,
};

// ==============================|| SLICE - EXPORT ||============================== //

const slice = createSlice({
    name: 'exporting',
    initialState,
    reducers: {
        setIsExporting: (state, action: PayloadAction<boolean>) => {
            state.isExporting = action.payload;
        },
    },
});

export default slice.reducer;

export const { setIsExporting } = slice.actions;

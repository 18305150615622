import { Link, Stack, Theme, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

interface AddressedTaskPropertyProps {
    labelId: string;
    properties: {
        contactId: number;
        matterId: number | null;
        contactName: string;
        matterName: string | null;
    }[];
    direction: 'column' | 'row';
    theme: Theme;
    onClick?: (id: number) => void;
}

const AddressedTaskContactMatterInfo: React.FC<AddressedTaskPropertyProps> = ({
    labelId,
    properties,
    direction,
    theme,
    onClick,
}) => {
    const content = properties.map((property, index) => {
        const { matterId, contactId, matterName, contactName } = property;

        const renderMatterWithContact = (
            matterId: number,
            matterName: string,
            contactId: number,
            contactName: string
        ) => (
            <>
                <Link to={`/matter/${matterId}`} component={RouterLink}>
                    <Typography sx={{ color: 'text.link', cursor: 'pointer' }}>{matterName}</Typography>
                </Link>
                <Link to={`/contact/${contactId}`} component={RouterLink}>
                    <Typography sx={{ color: 'text.link', cursor: 'pointer' }}>({contactName})</Typography>
                </Link>
            </>
        );

        const renderContactOnly = (contactId: number, contactName: string) => (
            <Link to={`/contact/${contactId}`} component={RouterLink}>
                <Typography sx={{ color: 'text.link', cursor: 'pointer' }}>{contactName}</Typography>
            </Link>
        );

        return (
            <div key={index} style={{ display: 'inline-flex', gap: '4px' }}>
                {matterId && matterName
                    ? renderMatterWithContact(matterId, matterName, contactId, contactName)
                    : contactId && renderContactOnly(contactId, contactName)}
            </div>
        );
    });

    return (
        <Stack
            direction="column"
            component="div"
            sx={{ borderBottom: `1px solid ${theme.palette.divider}`, gap: 1, pb: '8px' }}
        >
            <Typography sx={{ color: 'text.secondary' }}>
                <FormattedMessage id={labelId} />
            </Typography>
            <Stack direction={direction} component="div" sx={{ gap: 1 }} flexWrap={'wrap'}>
                {content}
            </Stack>
        </Stack>
    );
};

export default AddressedTaskContactMatterInfo;

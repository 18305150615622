import { List, ListItem, ListItemButton, ListItemText, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getIdLink } from 'utils/linkUtil';

export type ContentMenuRow = {
    title: string;
    subtitles: string[];
    noTranslate?: boolean;
};

type Props = {
    rows: ContentMenuRow[];
    sx?: SxProps;
};

const ContentMenu = ({ rows, sx }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <List sx={sx}>
            {rows.map((row, index) => (
                <React.Fragment key={`menu-group-${index}-${row.title}`}>
                    <ListItem
                        disablePadding
                        key={`${index}-${row.title}-list-item`}
                        sx={{
                            color: theme.palette.primary.main,
                            '.MuiListItemText-primary': { fontWeight: 600 },
                            pt: index > 0 ? 1 : 0,
                        }}
                    >
                        <ListItemButton
                            href={`#${getIdLink(row.noTranslate ? row.title.toLocaleLowerCase() : row.title)}`}
                            sx={{ height: '40px' }}
                        >
                            <ListItemText primary={row.noTranslate ? row.title : <FormattedMessage id={row.title} />} />
                        </ListItemButton>
                    </ListItem>
                    {row.subtitles.map((subtitle, subIndex) => (
                        <ListItem
                            key={`${index}-${subIndex}-${subtitle}-list-item`}
                            sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                '.MuiListItemText-secondary': { fontWeight: 500 },
                            }}
                        >
                            <ListItemButton href={`#${getIdLink(subtitle)}`} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemText secondary={<FormattedMessage id={subtitle} />} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </React.Fragment>
            ))}
        </List>
    );
};

export default ContentMenu;

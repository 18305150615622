import { Box, Link } from '@mui/material';
import { t } from 'utils/getTranslations';
import TimecardCenterForm from './TimecardCenterForm';
import SectionTitle from './SectionTitle';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'store';
import { closeSubDrawer } from 'store/reducers/subdrawer';
import UserDraftTimecardsList from './UserDraftTimecardsList';

const TimecardCenterContent = (): JSX.Element => {
    const dispatch = useDispatch();

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box overflow="auto" p={3}>
                <TimecardCenterForm />
                <SectionTitle
                    title="timecards.continue-from-drafts"
                    rightComponent={
                        <Link component={RouterLink} to={'timecards'} onClick={() => dispatch(closeSubDrawer())}>
                            {t('timecards.all-timecards')}
                        </Link>
                    }
                    sx={{ py: 2 }}
                />
                <UserDraftTimecardsList />
            </Box>
        </Box>
    );
};

export default TimecardCenterContent;

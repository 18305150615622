import CreditCardIcon from 'assets/images/icons/CreditCardIcon.svg?react';
import { useModal } from 'components/modals/useModal';
import { errorAlert } from 'utils/alert';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'store';
import { useAddExpenseMutation } from 'store/api/expenses';
import { ExpenseCreateDto, ExpenseForm } from 'types/expenses';
import { TimecardListItemData } from 'types/timecards';
import { t } from 'utils/getTranslations';
import { useClassifiers } from 'utils/useClassifiers';
import { CallForActionButton, CallForActionButtonTooltip } from '../../../components/buttons/CallForActionButton';
import { formatDateStringInput } from 'components/forms/inputs/DatePickerInput';
import ExpenseModal from 'components/modals/expenses/ExpenseModal';
import Loader from 'components/Loader';
import { useGetEmployeesListWithFiltersQuery } from 'store/api/employees';
import { EmployeeListItemMinDto } from 'types/employees';

type Props = {
    timecard: TimecardListItemData;
};

const AddExpenseIconButton = ({ timecard: timecardData }: Props): JSX.Element | null => {
    const userId = useSelector((state) => state.user.id);

    const { openModal, closeModal } = useModal();

    const [addExpense] = useAddExpenseMutation();
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['CURRENCY', 'EXPENSE_TYPE'],
    });

    const { data: currentEmployeeQueryData, isLoading: isLoadingCurrentEmployeeQueryData } =
        useGetEmployeesListWithFiltersQuery({ pageSize: 1, page: 0, filters: `id==${userId}` }, { skip: !userId });

    const currentEmployeeData = useMemo<EmployeeListItemMinDto | undefined>(() => {
        return currentEmployeeQueryData?.content.at(0);
    }, [currentEmployeeQueryData]);

    const handleExpenseCreate = useCallback(
        async (expenseDto: ExpenseCreateDto) => {
            try {
                await addExpense(expenseDto);
            } catch (e) {
                console.error('onSubmit error: ', e);
                errorAlert(t('forms.error-submitting-the-form'));
            }
        },
        [addExpense]
    );

    const openExpenseModal = useCallback((): void => {
        const preSelectedEmployee = currentEmployeeData;

        const initalData: ExpenseForm = {
            expenseType: null,
            currencyType: null,
            description: '',
            expenseAmount: null,
            expenseDate: formatDateStringInput(new Date()),
            isNonBillable: false,
            employee: preSelectedEmployee ? { id: preSelectedEmployee?.id, name: preSelectedEmployee?.name } : null,
            matter: null, // prefilled at ExpenseModal component level
            timecardId: timecardData?.id ?? null,
        };

        openModal(
            <ExpenseModal
                onChange={(value) => handleExpenseCreate(value)}
                initialData={initalData}
                onClose={closeModal}
                titleId={'expenses.add'}
                disableSelectMatter={true}
                readOnly={false}
                preselectMatterId={timecardData?.matter.id ?? undefined}
            />,
            closeModal
        );
    }, [
        classifiers,
        currentEmployeeData,
        closeModal,
        handleExpenseCreate,
        openModal,
        timecardData?.id,
        timecardData?.matter.id,
    ]);

    if (isLoadingClassifiers || isLoadingCurrentEmployeeQueryData) {
        return <Loader />;
    }

    return (
        <CallForActionButtonTooltip title={t('timecards.add-expense')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('timecards.add-expense')}
                onClick={() => openExpenseModal()}
            >
                <CreditCardIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    );
};

export default AddExpenseIconButton;

import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import get from 'lodash/get';
import React, { useCallback, useRef, useState } from 'react';
import { Controller, FieldPathByValue, FieldValues, SetFieldValue } from 'react-hook-form';
import { t } from 'utils/getTranslations';
import { CommonInputProps } from './InputTypes';
import InputWrapper from './InputWrapper';
import { useMemoizedRules } from './useMemoizedRules';
import ReadonlyField from '../rhf-other/ReadonlyField';
import { ListboxComponent, StyledPopper } from '../ListboxComponent';
import { PaginatedListProps, usePaginatedList } from './usePaginatedList';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import CircularLoader from 'components/CircularLoader';

type GetOptionIdentifier<TOption extends FieldValues> =
    | ((option: TOption) => string | number)
    | FieldPathByValue<TOption, number | string>;
type GetOptionLabel<TOption extends FieldValues> =
    | ((option: TOption) => string)
    | FieldPathByValue<TOption, number | string>;

type GetOptionDescription<TOption extends FieldValues> =
    | ((option: TOption) => string)
    | FieldPathByValue<TOption, number | string>;

interface Props<TFormData extends FieldValues, TOption extends FieldValues> extends CommonInputProps<TFormData> {
    placeholderOnFocus?: string;
    noPreSelect?: boolean;
    clearable?: boolean;
    onChange?: (value: TOption | null) => void;
    getOptionLabel: GetOptionLabel<TOption>;
    setValue: SetFieldValue<TFormData>;
    getOptionIdentifier: GetOptionIdentifier<TOption>;
    groupSortOrder?: string[]; // legacy, does nothing but allows to preserve already set sort orders that could potentially be made functional again
    /**
     * Ensure that the value specified in `groupByField` is also included in the `fetch` sort options (`paginated.sort`)
     * with the same value to avoid duplicate grouping issues.
     */
    groupByField?: GetOptionIdentifier<TOption>;
    defaultGroup?: string;
    noOptionsContent?: React.ReactNode;
    readonly?: boolean | { linkTo: string };
    noReadOnlyDivider?: boolean;
    getOptionDescription?: GetOptionDescription<TOption>;
    /** Warning: Only use this variable if you know what you're doing as this CAN break autocomplete dropdown content */
    useVirtualization?: boolean;
    paginated: PaginatedListProps<TOption>;
}

const DynamicSelectInput = <TFormData extends FieldValues, TOption extends FieldValues>({
    name,
    label,
    gap,
    shouldTranslateLabel = true,
    tooltip,
    required,
    rightLabelComponent,
    disabled,
    placeholder,
    shouldTranslatePlaceholder = true,
    placeholderOnFocus,
    wrapperSx,
    control,
    noPreSelect,
    clearable,
    onChange,
    getOptionLabel,
    setValue,
    getOptionIdentifier,
    groupByField,
    defaultGroup,
    validate,
    noOptionsContent,
    readonly = false,
    noReadOnlyDivider,
    getOptionDescription,
    useVirtualization = false,
    paginated,
}: Props<TFormData, TOption>) => {
    const initialPlaceholder = placeholder ? (shouldTranslatePlaceholder ? t(placeholder) : placeholder) : t('select');
    const [controlledPlaceholder, setControlledPlaceholder] = useState<string>(initialPlaceholder);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { dataToDisplay, handleLoadMore, handleSearchChange, hasNextPage, isLoading, isError } =
        usePaginatedList<TOption>({ ...paginated, isEnabled: paginated.isEnabled === false ? false : isDropdownOpen });
    const rules = useMemoizedRules({ required, validate });
    const fallbackTranslation = defaultGroup ?? t('field.other');

    const listRootWrapperRef = useRef<any>();
    const listRootRef = useRef<any>();
    useInfiniteScroll(
        listRootRef,
        {
            observer: { root: listRootWrapperRef.current, rootMargin: '75px', threshold: 0 },
            disabled: isError,
            isLoading,
        },
        handleLoadMore
    );

    const getLabel = useCallback(
        (option: TOption | null) => {
            if (!option) {
                return null;
            }

            if (typeof getOptionLabel === 'string') {
                return get(option, getOptionLabel);
            } else {
                return getOptionLabel(option);
            }
        },
        [getOptionLabel]
    );

    const getValue = useCallback(
        (option: TOption | null) => {
            if (!option) {
                return null;
            }

            if (typeof getOptionIdentifier === 'string') {
                return get(option, getOptionIdentifier);
            } else {
                return getOptionIdentifier(option);
            }
        },
        [getOptionIdentifier]
    );

    const getDescription = useCallback(
        (option: TOption | null) => {
            if (!option) {
                return '';
            }
            if (!getOptionDescription) {
                return '';
            }

            if (typeof getOptionDescription === 'string') {
                return get(option, getOptionDescription);
            } else {
                return getOptionDescription(option);
            }
        },
        [getOptionDescription]
    );

    const getGroupedBy = (option: TOption | null) => {
        if (!option) {
            return fallbackTranslation;
        }

        if (typeof groupByField === 'string') {
            return get(option, groupByField);
        }

        return fallbackTranslation;
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { ref, onChange: innerOnChange, onBlur, value, ...field }, fieldState: { error } }) => (
                <InputWrapper
                    name={name}
                    label={label}
                    shouldTranslateLabel={shouldTranslateLabel}
                    tooltip={tooltip}
                    required={required}
                    readonly={!!readonly}
                    rightLabelComponent={rightLabelComponent}
                    hasErrors={!!error}
                    sx={wrapperSx}
                    gap={gap}
                    noReadOnlyDivider={noReadOnlyDivider}
                >
                    {readonly ? (
                        <ReadonlyField
                            value={value}
                            getLabel={getLabel}
                            getValue={getValue}
                            linkTo={typeof readonly === 'object' ? readonly?.linkTo : undefined}
                        />
                    ) : (
                        <Autocomplete
                            id={name}
                            filterOptions={(x) => x}
                            value={value ?? null}
                            fullWidth
                            includeInputInList
                            onOpen={() => setIsDropdownOpen(true)}
                            onClose={() => setIsDropdownOpen(false)}
                            loading={isLoading}
                            getOptionLabel={
                                typeof getOptionLabel === 'string'
                                    ? (option) => get(option, getOptionLabel)
                                    : getOptionLabel
                            }
                            disablePortal={false}
                            disabled={disabled}
                            slotProps={{
                                paper: {
                                    sx: { boxShadow: '0 0 6px 6px rgba(0, 0, 0, 0.10)' },
                                },
                            }}
                            ListboxProps={{ ref: listRootWrapperRef }}
                            options={dataToDisplay}
                            {...(groupByField ? { groupBy: (option: TOption) => getGroupedBy(option) } : {})}
                            onChange={(_, data) => {
                                innerOnChange(data as any);
                                if (onChange) {
                                    onChange(data);
                                }
                            }}
                            onInputChange={(_, data) => {
                                handleSearchChange(data);
                            }}
                            renderOption={(props, option, { index }) => {
                                const { key, ...restProps } = props;
                                const isLastOption = index + 1 === dataToDisplay.length;
                                const shouldAttachInfiniteScrollTrigger = isLastOption && hasNextPage;
                                if (shouldAttachInfiniteScrollTrigger) {
                                    return (
                                        <Box key={'@loadmore@'} ref={listRootRef}>
                                            <CircularLoader
                                                sx={{
                                                    position: 'unset',
                                                    mt: 1,
                                                    mb: 1,
                                                    '& > .MuiCircularProgress-root': {
                                                        width: '24px !important',
                                                        height: '24px !important',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    );
                                }

                                return getOptionDescription ? (
                                    <Tooltip
                                        title={getDescription(option)}
                                        key={
                                            typeof getOptionIdentifier === 'string'
                                                ? get(option, getOptionIdentifier)
                                                : getOptionIdentifier(option)
                                        }
                                    >
                                        <Box component="li" {...restProps}>
                                            {getLabel(option)}
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <Box
                                        component="li"
                                        {...restProps}
                                        key={
                                            typeof getOptionIdentifier === 'string'
                                                ? get(option, getOptionIdentifier)
                                                : getOptionIdentifier(option)
                                        }
                                    >
                                        {getLabel(option)}
                                    </Box>
                                );
                            }}
                            disableClearable={clearable !== undefined && !clearable}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...field}
                                    placeholder={controlledPlaceholder}
                                    onFocus={() => {
                                        if (placeholderOnFocus) {
                                            setControlledPlaceholder(t(placeholderOnFocus));
                                        }
                                    }}
                                    onBlur={() => {
                                        onBlur();
                                        if (placeholderOnFocus) {
                                            setControlledPlaceholder(initialPlaceholder);
                                        }
                                    }}
                                    error={!!error}
                                    required={required ?? false}
                                    helperText={error?.message}
                                    inputRef={ref}
                                />
                            )}
                            noOptionsText={noOptionsContent ? noOptionsContent : t('no-options')}
                            isOptionEqualToValue={(option, selectedOption) =>
                                getValue(option) === getValue(selectedOption)
                            }
                            ListboxComponent={useVirtualization ? ListboxComponent : undefined}
                            PopperComponent={useVirtualization ? StyledPopper : undefined}
                        />
                    )}
                </InputWrapper>
            )}
        />
    );
};

export default DynamicSelectInput;

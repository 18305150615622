import { forwardRef, ForwardRefExoticComponent, RefAttributes, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { useFeatureFlags } from 'utils/useFeatureFlags';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
    item: NavItemType;
    level: number;
    isPopup: boolean;
}

const NavItem = ({ item, level, isPopup }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state: RootStateProps) => state.menu);
    const { drawerOpen, openItem } = menu;

    const { isFlowDisabled } = useFeatureFlags(item.requiredFeature);

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = {
        component: forwardRef((props, ref) => (
            <Link {...props} onClick={(e) => maybePreventNavigation(e, item.url!)} to={item.url!} target={itemTarget} />
        )),
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const Icon = item.icon!;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    const { pathname, search } = useLocation();

    // active menu item on page load
    useEffect(() => {
        const itemPathnameStripped = item.url?.split('?')?.[0];
        // below regex will split the string at any "/" character if there is any character before it, I only want the first part
        const pathnameClipped =
            pathname
                ?.split('/')
                .filter((segment) => isNaN(Number(segment)))
                .pop() || '';

        if (
            pathname === itemPathnameStripped ||
            pathnameClipped === itemPathnameStripped ||
            `/${pathnameClipped}s` === itemPathnameStripped ||
            item.altActiveUrls?.includes(pathnameClipped || `${pathnameClipped}s`)
        ) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, [pathname]);

    const maybePreventNavigation = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
            const currentPath = `${pathname}${search}`;
            if (href === currentPath) {
                e.preventDefault();
            }
        },
        [pathname, search]
    );

    const primaryColor = 'primary.main';

    return isFlowDisabled ? null : (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: level * 3,
                py: 0.625,
                opacity: isPopup ? 1 : 0.5,
                '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.03);',
                    opacity: 1,
                },
                '&:hover .MuiTypography-root': {
                    fontWeight: isPopup ? 600 : undefined,
                },
                '&.Mui-selected': {
                    bgcolor: 'rgba(0, 0, 0, 0.03);',
                    borderRight: !isPopup ? `2px solid ${theme.palette.primary.main}` : undefined,
                    color: primaryColor,
                    opacity: 1,
                    '& .MuiTypography-root': {
                        fontWeight: isPopup ? 600 : undefined,
                    },
                    '&:hover': {
                        color: primaryColor,
                        bgcolor: 'rgba(0, 0, 0, 0.03);',
                        opacity: 1,
                    },
                },
                ...(!drawerOpen && {
                    px: 0,
                    py: 0.25,
                    justifyContent: 'center',
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 24,
                        color: isSelected ? primaryColor : primaryColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    sx={{ ...(isPopup ? { my: 0, px: 2, py: '5px' } : {}) }}
                    primary={
                        <Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            sx={{
                                fontSize: level == 1 || isPopup ? '14px' : '12px',
                                fontWeight: isPopup ? 400 : level == 1 ? 600 : 500,
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;

import { IconButton, styled } from '@mui/material';

const StyledHeaderButton = styled(
    IconButton,
    {}
)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    width: 'fit-content',
    boxSizing: 'border-box',
    padding: '6px 8px 6px 4px',
    borderRadius: '8px',
    display: 'flex',
    gap: '4px',
    fontWeight: 600,
    justifyContent: 'center',
    ':hover': {
        backgroundColor: theme.palette.grey[300],
        '.MuiChip-root': {
            cursor: 'pointer',
        },
    },
}));

export default StyledHeaderButton;

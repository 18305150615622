import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import MfaVerification from '../pages/auth/mfa-verification';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthSetPassword = Loadable(lazy(() => import('pages/auth/set-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const MfaSetup = Loadable(lazy(() => import('pages/auth/mfa-setup')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <GuestGuard>
                    <CommonLayout />
                </GuestGuard>
            ),
            children: [
                {
                    path: 'mfa-setup',
                    element: <MfaSetup />,
                },
                {
                    path: 'mfa-verification',
                    element: <MfaVerification />,
                },
                {
                    path: '/',
                    element: <AuthLogin />,
                },
                {
                    path: '/login/sso/azure',
                    exact: true,
                    element: <AuthLogin />,
                },
                {
                    path: 'login',
                    element: <AuthLogin />,
                },
                {
                    path: 'forgot-password',
                    element: <AuthForgotPassword />,
                },
                {
                    path: 'check-mail',
                    element: <AuthCheckMail />,
                },
                {
                    path: 'reset-password',
                    element: <AuthResetPassword />,
                },
                {
                    path: 'set-password',
                    element: <AuthSetPassword />,
                },
                {
                    path: 'code-verification',
                    element: <AuthCodeVerification />,
                },
            ],
        },
    ],
};

export default LoginRoutes;

import { Navigate, useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import FallbackRoute from './FallbackRoute';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null {
    return useRoutes([FallbackRoute, LoginRoutes, MainRoutes]);
}

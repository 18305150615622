import { AddressedTaskListItem, getPriorityStatusChipType } from '../../../../../../../types/tasks';
import { Box, Stack, Theme, Typography } from '@mui/material';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import StatusChip from 'components/@extended/StatusChip';
import { ClassifierData } from 'types/classifiers';
import { getClassifierText } from 'utils/getTranslations';

interface AddressedTaskProps {
    addressedTask: AddressedTaskListItem;
    openAddressedTaskView: (taskId: number) => void;
    classifiers: Record<string, ClassifierData>;
    theme: Theme;
}

const AddressedTaskItem: React.FC<AddressedTaskProps> = ({
    addressedTask,
    theme,
    openAddressedTaskView,
    classifiers,
}) => {
    return (
        <Stack
            sx={{
                px: 3,
                py: 2,
                gap: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                width: '100%',
                ':hover': {
                    background: theme.palette.grey[100],
                    cursor: 'pointer',
                },
            }}
            onClick={() => openAddressedTaskView(addressedTask.id)}
            direction="row"
            component="li"
        >
            <Stack sx={{ width: '90%' }} spacing={0.5} width="100%" direction="column">
                <Typography sx={{ fontWeight: 600 }}> {addressedTask.name}</Typography>
                {addressedTask.clientParty && (
                    <Typography
                        sx={{ color: theme.palette.grey[400] }}
                    >{`${addressedTask.clientParty.name}`}</Typography>
                )}
                {addressedTask.matters && addressedTask.matters.map((matter) => <Typography>{matter.name}</Typography>)}
                <Box
                    sx={{
                        flexWrap: 'nowrap',
                        display: 'flex',
                    }}
                >
                    <StatusChip
                        type={getPriorityStatusChipType(addressedTask.taskPriorityTypeCode)}
                        theme={theme}
                        label={getClassifierText(classifiers['TASK_PRIORITY_TYPE'], addressedTask.taskPriorityTypeCode)}
                    />
                </Box>
            </Stack>
            <Stack sx={{ justifyContent: 'center' }}>
                <RightOutlined style={{ fontSize: '0.625rem', marginLeft: 10, color: theme.palette.primary.main }} />
            </Stack>
        </Stack>
    );
};

export default AddressedTaskItem;

import { forwardRef, ForwardedRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

type LinkWithOriginProps = React.ComponentProps<typeof Link>;

const LinkWithOrigin = forwardRef((props: LinkWithOriginProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const { state, ...rest } = props;
    const { pathname, search } = useLocation();

    const adjustedState = { ...state, returnTo: `${pathname + search}` };

    return <Link ref={ref} {...rest} state={adjustedState} />;
});

export default LinkWithOrigin as typeof Link;

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, FieldPathByValue, FieldValues, PathValue } from 'react-hook-form';
import { t } from 'utils/getTranslations';
import { dateStringToDateObject, DEFAULT_DATE_FORMAT } from 'utils/parseDate';
import { formatDateStringInput } from '../DatePickerInput';
import { CommonInputProps } from './InputTypes';
import InputWrapper from './InputWrapper';
import { useMemoizedRules } from './useMemoizedRules';
import ReadonlyField from '../rhf-other/ReadonlyField';
import { enGB } from 'date-fns/locale';

interface DateInputProps<T extends FieldValues> extends CommonInputProps<T> {
    readonly?: boolean;
    fullWidth?: boolean;
    maxDate?: Date;
    minDate?: Date;
    hasErrors?: boolean;
}

const DateInput = <T extends FieldValues>({
    disabled,
    name,
    placeholder,
    shouldTranslatePlaceholder = true,
    control,
    required,
    label,
    shouldTranslateLabel,
    rightLabelComponent,
    wrapperSx,
    validate,
    fullWidth,
    readonly = false,
    maxDate = undefined,
    minDate = undefined,
    hasErrors,
}: DateInputProps<T>) => {
    const rules = useMemoizedRules({ required, validate });
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange: innerOnChange, value, ...field }, fieldState: { error } }) => (
                <InputWrapper
                    name={name}
                    label={label}
                    shouldTranslateLabel={shouldTranslateLabel}
                    required={required}
                    readonly={!!readonly}
                    rightLabelComponent={rightLabelComponent}
                    hasErrors={!!error || hasErrors == true}
                    sx={wrapperSx}
                >
                    {readonly ? (
                        <ReadonlyField value={value} />
                    ) : (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <DesktopDatePicker
                                maxDate={maxDate}
                                minDate={minDate}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                                inputRef={field.ref}
                                value={value === null ? value : dateStringToDateObject(value)}
                                onChange={(val: Date | null) => {
                                    if (val && val !== null) {
                                        innerOnChange(
                                            formatDateStringInput(val) as PathValue<
                                                T,
                                                FieldPathByValue<T, string | null>
                                            >
                                        );
                                    } else {
                                        innerOnChange(null as PathValue<T, FieldPathByValue<T, string | null>>);
                                    }
                                }}
                                disabled={disabled}
                                format={DEFAULT_DATE_FORMAT}
                                slotProps={{
                                    textField: {
                                        id: name,
                                        placeholder: placeholder
                                            ? shouldTranslatePlaceholder
                                                ? t(placeholder)
                                                : placeholder
                                            : t('select'),

                                        error: !!error,
                                        helperText: error?.message,
                                        fullWidth: fullWidth,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    )}
                </InputWrapper>
            )}
        />
    );
};

export default DateInput;

import { Stack, Typography } from '@mui/material';
import { TimecardListItemData } from 'types/timecards';
import { useClassifiers } from 'utils/useClassifiers';
import { t } from 'utils/getTranslations';
import TimecardGroup, { GroupedTimecards } from './TimecardGroup';

type Props = {
    timecards: TimecardListItemData[];
    compact?: boolean;
};

export const groupTimecardsByDate = (timecards: TimecardListItemData[]) => {
    const groupedTimecards: Map<string, GroupedTimecards> = new Map();
    timecards.forEach((timecard) => {
        const group = groupedTimecards.get(timecard.timecardDate);
        if (group) {
            group.billableTimeSumInHours += timecard.timecardStats?.billedTimeInHours || 0;
            group.loggedTimeSumInHours += timecard.timecardStats?.totalTimeInHours || 0;
            group.totalAmountInBaseCurrency += timecard.currencyDetails?.totalAmountInBaseCurrency || 0;
            group.timecards.push(timecard);
        } else {
            groupedTimecards.set(timecard.timecardDate, {
                billableTimeSumInHours: timecard.timecardStats?.billedTimeInHours || 0,
                loggedTimeSumInHours: timecard.timecardStats?.totalTimeInHours || 0,
                baseCurrency: timecard.currencyDetails?.baseCurrency || '',
                totalAmountInBaseCurrency: timecard.currencyDetails?.totalAmountInBaseCurrency || 0,
                timecards: [timecard],
            });
        }
    });

    return groupedTimecards;
};

const TimecardList = ({ timecards, compact }: Props): JSX.Element => {
    const { classifiers } = useClassifiers({
        codes: ['TIMECARD_STATUS_TYPE'],
    });

    if (timecards.length === 0) {
        return (
            <Stack direction="row" justifyContent="center" sx={{ margin: '14px' }}>
                <Typography>{t('timecards.no-timecards-found')}</Typography>
            </Stack>
        );
    }

    const groupedTimecards = groupTimecardsByDate(timecards);
    return (
        <Stack spacing={3} height="100%">
            {Array.from(groupedTimecards).map(([date, group]) => (
                <TimecardGroup
                    key={date}
                    date={date}
                    timecardGroup={group}
                    classifier={classifiers['TIMECARD_STATUS_TYPE']}
                    compact={compact}
                />
            ))}
        </Stack>
    );
};

export default TimecardList;

import { Box } from '@mui/system';
import PermissionButton from 'components/buttons/PermissionButton';
import PageHeader from 'components/PageHeader';
import PaddedLayout from 'layout/MainLayout/PaddedLayout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CorporateRoleFillingsTable from './CorporateRoleFillingsTable';
import { errorAlert } from 'utils/alert';
import { useGetCorporateRoleFillingListQuery, useUpdateCorporateRoleFillingsMutation } from 'store/api/companies';
import { CorporateRoleFillingForm, CorporateRoleType, UpdateCorporateRoleFillingDto } from 'types/companies';
import { useForm } from 'react-hook-form';
import Loader from 'components/Loader';
import { useClassifiers } from 'utils/useClassifiers';
import { t } from 'utils/getTranslations';
import { Button } from '@mui/material';
import { useSelector } from 'store';
import ConfirmNavigation from 'components/navigation/ConfirmNavigation';

export type CorporateRoleFillingRowType = {
    name: string;
    level: number;
    leadingRevenuePersonIndex?: number;
    leadingCompliancePersonIndex?: number;
};

const CorporateRolePage = (): JSX.Element => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const { buttonsDisabled: areButtonsDisabled } = useSelector((state) => state.buttons);
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['CORPORATE_ROLE_TYPE'],
    });

    const { data: corporateRoleFillingList, isLoading: isLoadingData } = useGetCorporateRoleFillingListQuery();
    const [updateCorporateRoleFillings] = useUpdateCorporateRoleFillingsMutation();

    const [initialData, setInitialData] = useState<CorporateRoleFillingForm | undefined>(undefined);
    const [dataToDisplay, setDataToDisplay] = useState<CorporateRoleFillingRowType[]>([]);

    useEffect(() => {
        if (corporateRoleFillingList) {
            const corporateRoleFillings: UpdateCorporateRoleFillingDto[] = corporateRoleFillingList.map((item) => ({
                corporateRoleFillingId: item.corporateRoleFillingId,
                roleFilledByEmployee: item.roleFilledByEmployee ?? null,
            }));
            setInitialData({ corporateRoleFillings });
            const corporateRoleFillingRows: CorporateRoleFillingRowType[] = corporateRoleFillingList
                .filter(
                    (corporateRole) => corporateRole.corporateRoleTypeCode === CorporateRoleType.LEADING_REVENUE_PERSON
                )
                .map((leadingRevenuePersonFilling) => {
                    const leadingCompliancePersonFilling = corporateRoleFillingList.find(
                        (el) =>
                            el.corporateRoleTypeCode === CorporateRoleType.LEADING_COMPLIANCE_PERSON &&
                            el.name === leadingRevenuePersonFilling.name &&
                            el.level === leadingRevenuePersonFilling.level &&
                            el.scopeTypeCode === leadingRevenuePersonFilling.scopeTypeCode &&
                            el.company?.id === leadingRevenuePersonFilling.company?.id &&
                            el.office?.id === leadingRevenuePersonFilling.office?.id
                    );
                    return {
                        name: leadingRevenuePersonFilling.name,
                        level: leadingRevenuePersonFilling.level,
                        leadingRevenuePersonIndex: corporateRoleFillingList.indexOf(leadingRevenuePersonFilling),
                        leadingCompliancePersonIndex: leadingCompliancePersonFilling
                            ? corporateRoleFillingList.indexOf(leadingCompliancePersonFilling)
                            : undefined,
                    };
                });
            setDataToDisplay(corporateRoleFillingRows);
        }
    }, [corporateRoleFillingList]);

    const { handleSubmit, control, setValue } = useForm<CorporateRoleFillingForm>({
        defaultValues: {
            corporateRoleFillings: [],
        },
        values: initialData,
    });

    const onSubmit = async (formData: CorporateRoleFillingForm): Promise<void> => {
        try {
            await updateCorporateRoleFillings(formData.corporateRoleFillings)
                .unwrap()
                .then(() => {
                    setIsEditing(false);
                })
                .catch(console.error);
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    const submitUpdateCorporateRoleFillings = () => {
        handleSubmit((data) => onSubmit(data))();
    };

    if (isLoadingData || initialData === undefined || isLoadingClassifiers) {
        return <Loader />;
    }

    return (
        <PaddedLayout sx={{ overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', height: '100%' }}>
                <PageHeader
                    titleId={'corporate-role.title'}
                    buttons={
                        isEditing ? (
                            <Box display="flex" gap={1}>
                                <Button
                                    key="cancel"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => setIsEditing(false)}
                                    disabled={areButtonsDisabled}
                                >
                                    {t('cancel')}
                                </Button>
                                <Box sx={{ justifyContent: 'flex-end' }}>
                                    <PermissionButton
                                        permissions={['UPDATE.COMPANIES']}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={submitUpdateCorporateRoleFillings}
                                        disabled={areButtonsDisabled}
                                    >
                                        <FormattedMessage id="save" />
                                    </PermissionButton>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ justifyContent: 'flex-end' }}>
                                <PermissionButton
                                    permissions={['UPDATE.COMPANIES']}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => setIsEditing(true)}
                                    disabled={areButtonsDisabled}
                                >
                                    <FormattedMessage id="edit" />
                                </PermissionButton>
                            </Box>
                        )
                    }
                />
                <CorporateRoleFillingsTable
                    classifiers={classifiers}
                    control={control}
                    setValue={setValue}
                    isReadOnly={!isEditing}
                    dataToDisplay={dataToDisplay}
                />
                <ConfirmNavigation control={control} />
            </Box>
        </PaddedLayout>
    );
};

export default CorporateRolePage;

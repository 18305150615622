import { Task, TaskStatusType } from '../../../../../../../types/tasks';
import { ClassifierData } from '../../../../../../../types/classifiers';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import StatusChip from '../../../../../../../components/@extended/StatusChip';
import { getClassifierText } from '../../../../../../../utils/getTranslations';
import { DEFAULT_DATE_FORMAT } from 'utils/parseDate';

interface AddressedTaskUrgencyInfoProps {
    taskData?: Task;
    classifiers: Record<string, ClassifierData>;
    theme: Theme;
}

const AddressedTaskUrgencyInfo: React.FC<AddressedTaskUrgencyInfoProps> = ({ taskData, classifiers, theme }) => {
    const getPriorytyStatusChip = useCallback((taskPriorityTypeCode: string) => {
        switch (taskPriorityTypeCode) {
            case 'HIGH':
                return 'error';
            case 'MEDIUM':
                return 'warning';
            case 'LOW':
                return 'neutral';
            default:
                return 'inactive';
        }
    }, []);

    return (
        <Stack
            direction="row"
            component="div"
            sx={{
                width: '100%',
                borderBottom: `1px solid ${theme.palette.divider}`,
                gap: 2,
                justifyContent: 'space-between',
                pb: '8px',
            }}
        >
            <Stack direction="column" component="div" sx={{ gap: 1 }}>
                <Typography sx={{ color: 'text.secondary' }}>
                    <FormattedMessage id="tasks.view-form.deadline" />
                </Typography>
                <Box>{taskData?.deadlineDate ? format(new Date(taskData?.deadlineDate), DEFAULT_DATE_FORMAT) : ''}</Box>
            </Stack>
            <Stack direction="column" component="div" sx={{ gap: 1 }}>
                <Typography sx={{ color: 'text.secondary' }}>
                    <FormattedMessage id="tasks.view-form.priority" />
                </Typography>
                {taskData?.taskPriorityTypeCode && (
                    <Box
                        sx={{
                            flexWrap: 'nowrap',
                            display: 'flex',
                        }}
                    >
                        <StatusChip
                            type={getPriorytyStatusChip(taskData.taskPriorityTypeCode)}
                            theme={theme}
                            label={getClassifierText(classifiers['TASK_PRIORITY_TYPE'], taskData.taskPriorityTypeCode)}
                        />
                    </Box>
                )}
            </Stack>
            <Stack direction="column" component="div" sx={{ gap: 1 }}>
                <Typography sx={{ color: 'text.secondary' }}>
                    <FormattedMessage id="tasks.view-form.status" />
                </Typography>
                {taskData?.taskStatusTypeCode && (
                    <Box
                        sx={{
                            flexWrap: 'nowrap',
                            display: 'flex',
                        }}
                    >
                        <StatusChip
                            sx={{ mt: '4px', cursor: 'pointer' }}
                            type={(() => {
                                switch (taskData.taskStatusTypeCode) {
                                    case TaskStatusType.DONE:
                                        return 'neutral';
                                    case TaskStatusType.IN_PROGRESS:
                                        return 'success';
                                }
                            })()}
                            theme={theme}
                            label={getClassifierText(classifiers['TASK_STATUS_TYPE'], taskData.taskStatusTypeCode)}
                        />
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export default AddressedTaskUrgencyInfo;

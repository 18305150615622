import { createListenerMiddleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setUserData, logout } from 'store/reducers/user';

// Create the middleware instance and methods
export const userListenerMiddleware = createListenerMiddleware();

// Add the middleware listeners to the user related actions
userListenerMiddleware.startListening({
    actionCreator: setUserData,
    effect: async (action) => {
        Sentry.setUser({ email: action.payload.workEmailAddress || undefined, id: String(action.payload.id) });
    },
});

userListenerMiddleware.startListening({
    actionCreator: logout,
    effect: async () => {
        Sentry.setUser(null);
    },
});

import { useLazyGetMessagesAddressedToEmployeeQuery } from '../../../../../../store/api/notifications';
import { useForm } from 'react-hook-form';
import { useAsyncDebounce } from 'react-table';
import { Box, Button, Stack, Theme, Typography } from '@mui/material';
import TextInput from '../../../../../../components/forms/inputs/rhf-inputs/TextInput';
import ChatItem from './ChatItem';
import { MessageDetailsDto } from '../../../../../../types/chat';
import { FormattedMessage } from 'react-intl';

interface ChatMessageListProps {
    messages: MessageDetailsDto[];
    theme: Theme;
    userId: number | null;
    unread: number;
    handleRead: (idList: number[]) => Promise<void>;
    handleArchive: (idList: number[]) => Promise<void>;
    areButtonsDisabled: boolean;
}

interface SearchForm {
    search: string;
}

const ChatMessageList: React.FC<ChatMessageListProps> = ({
    messages,
    theme,
    userId,
    unread,
    handleArchive,
    handleRead,
    areButtonsDisabled,
}) => {
    const [searchMessages, { data: searchedMessages }] = useLazyGetMessagesAddressedToEmployeeQuery();
    const { control, handleSubmit } = useForm<SearchForm>({
        defaultValues: {
            search: '',
        },
    });

    const onSubmit = async (formData: SearchForm): Promise<void> => {
        if (userId === null) {
            return;
        }
        const { search } = formData;
        const trimmedSearch = search.trim();

        searchMessages({ employeeId: userId, search: trimmedSearch });
    };

    const handleSearchChange = useAsyncDebounce(() => {
        handleSubmit((data) => onSubmit(data))();
    }, 600);

    const unreadMessages = searchedMessages ? (searchedMessages.filter((val) => !val.readAt).length ?? 0) : unread;

    return (
        <Box
            component="ul"
            sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
            }}
        >
            <Stack
                sx={{
                    width: '100%',
                    padding: '8px 24px 16px',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    justifyContent: 'space-between',
                }}
                component="li"
                direction="column"
            >
                <TextInput control={control} name="search" placeholder="search" onChange={handleSearchChange} />
            </Stack>
            <ChatMessageListHeader
                theme={theme}
                unread={unreadMessages}
                searchedMessages={searchedMessages}
                handleArchive={handleArchive}
                handleRead={handleRead}
                areButtonsDisabled={areButtonsDisabled}
            />
            {(searchedMessages ?? messages).map((val) => (
                <ChatItem
                    key={`chatItem-${val.id}`}
                    theme={theme}
                    message={val}
                    handleArchive={handleArchive}
                    handleRead={handleRead}
                    areButtonsDisabled={areButtonsDisabled}
                />
            ))}
        </Box>
    );
};

export const ChatMessageListHeader = ({
    theme,
    unread,
    areButtonsDisabled,
    handleArchive,
    handleRead,
    searchedMessages,
}: {
    theme: Theme;
    unread: number;
    handleRead: (idList: number[]) => Promise<void>;
    handleArchive: (idList: number[]) => Promise<void>;
    areButtonsDisabled: boolean;
    searchedMessages: MessageDetailsDto[] | undefined;
}) => {
    const isNoMessagesFound = !!searchedMessages && searchedMessages.length === 0;
    const messageIdList = searchedMessages?.map((message) => message.id) || [];

    return (
        <Stack
            sx={{
                width: '100%',
                padding: '9px 24px',
                borderBottom: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.grey[50],
                justifyContent: 'space-between',
            }}
            component="li"
            direction="row"
        >
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                <FormattedMessage values={{ count: unread }} id="notifications.unread" />
            </Typography>
            <Stack direction="row" spacing={2}>
                <Button
                    sx={{ fontWeight: 400, color: theme.palette.text.link, height: 22, px: 0 }}
                    size="small"
                    onClick={() => handleRead(messageIdList)}
                    disabled={areButtonsDisabled || isNoMessagesFound}
                >
                    <FormattedMessage id="notifications.mark-all-as-read" />
                </Button>
                <Button
                    sx={{ fontWeight: 400, color: theme.palette.text.link, height: 22, px: 0 }}
                    size="small"
                    onClick={() => handleArchive(messageIdList)}
                    disabled={areButtonsDisabled || isNoMessagesFound}
                >
                    <FormattedMessage id="notifications.archive-all" />
                </Button>
            </Stack>
        </Stack>
    );
};

export default ChatMessageList;

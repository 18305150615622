import { enqueueSnackbar, OptionsObject } from 'notistack';

const DEFAULT_AUTOHIDE_DURATION = 6000;

export type SnackOptions = {
    requestId?: string;
};

export const successAlert = (message: string, options?: SnackOptions & OptionsObject) => {
    return openAlert(message, {
        autoHideDuration: DEFAULT_AUTOHIDE_DURATION,
        ...options,
        variant: 'success',
    });
};
export const errorAlert = (message: string, options?: SnackOptions & OptionsObject) => {
    return openAlert(message, { autoHideDuration: DEFAULT_AUTOHIDE_DURATION, ...options, variant: 'error' });
};
export const warningAlert = (message: string, options?: SnackOptions & OptionsObject) => {
    return openAlert(message, {
        autoHideDuration: DEFAULT_AUTOHIDE_DURATION,
        ...options,
        variant: 'warning',
    });
};
export const infoAlert = (message: string, options?: SnackOptions & OptionsObject) => {
    return openAlert(message, { autoHideDuration: DEFAULT_AUTOHIDE_DURATION, ...options, variant: 'info' });
};

const openAlert = (message: string, options?: SnackOptions & OptionsObject) => {
    return enqueueSnackbar(message, { style: { maxWidth: 740 }, ...options });
};

export default { successAlert, errorAlert, warningAlert, infoAlert };

// ==============================|| MATTER TYPES ||============================== //

import { PartyType } from './contacts';
import { ContractListItem } from './contracts';
import { CurrencyData } from './currency';
import { DocumentTemplateMinDto } from './document';
import { MinDto, NamedEntityMinDto, NamedEntityMinWithEmailDto, NamedEntityMinWithMainEmailDto } from './other';
import { PracticeArea } from './practice-areas';
import { HourlyRate, Pricelist } from './pricelists';
import { CustomField, CustomFieldDto } from './customFields';

export enum MatterStateType {
    COMPLETED = 'COMPLETED',
    OFFER_LOST = 'OFFER_LOST',
    OFFER_PENDING = 'OFFER_PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    ARCHIVED = 'ARCHIVED',
}

export enum PricingLimitationType {
    HOURLY_CAPPED = 'HOURLY_CAPPED',
    FIXED_ONETIME = 'FIXED_ONETIME',
    FIXED_RECURRING = 'FIXED_RECURRING',
    HOURLY_UNLIMITED = 'HOURLY_UNLIMITED',
    SUCCESS_FEE = 'SUCCESS_FEE',
}

export enum MatterPartyRoleType {
    CLIENT_REPRESENTED = 'CLIENT_REPRESENTED',
    OPPONENT = 'OPPONENT',
    TARGET = 'TARGET',
    OTHER_PARTY = 'OTHER_PARTY',
    JUDGE = 'JUDGE',
}

interface ClientParty extends NamedEntityMinDto {
    partyType: PartyType;
}

export interface MatterListItem extends MatterListItemMin {
    matterStateTypeCode?: string;
    openedDate?: string;
    responsibleEmployee?: NamedEntityMinDto;
    supervisedByEmployee?: NamedEntityMinDto;
    handledByOffice?: {
        id: number;
        name: string;
        abbreviation: string;
        companyInvoicingIntervalTypeCode: string;
    };
    matterStats?: MatterStats;
    currencyDetails?: CurrencyData;
}

export interface MatterListItemMin {
    id: number;
    name: string;
    caseNumber: string;
    fullName: string;
    clientParty?: ClientParty;
    timecardLanguageCode?: string;
    pricelistCurrencyTypeCode?: string;
    isTimecardAddingBlocked: boolean;
}

export interface MatterStateCountDto {
    matterGroups: {
        groupName: 'connected' | 'opponent' | 'primaryContact' | 'judge' | 'other';
        stateCount: Map<string, number>;
    }[];
}

export interface MatterBasicDetailsListItemMinDto extends MatterListItemMin {
    responsibleEmployee?: MinDto;
    supervisedByEmployee?: MinDto;
}

export interface MatterDto {
    createdAt: Date;
    id: number;
    name: string;
    caseNumber: string;
    fullName: string;
    matterStateTypeCode: string;
    clientParty: ClientParty;
    openedDate?: string;
    closedDate?: string;
    responsibleEmployee?: NamedEntityMinDto;
    supervisedByEmployee?: NamedEntityMinDto;
    referredByEmployee?: NamedEntityMinDto;
    employees?: {
        employee: NamedEntityMinDto;
        matterEmployeeRoleTypeCode: string;
    }[];
    handledByOffice?: {
        id: number;
        name: string;
        abbreviation: string;
        companyInvoicingIntervalTypeCode: string;
    };
    primaryContactParty?: NamedEntityMinDto;
    isNoOpponent?: boolean;
    parties?: {
        party: NamedEntityMinDto;
        matterPartyRoleTypeCode: string;
    }[];
    rates?: HourlyRate[];
    generalNotes?: string;
    isConfidential?: boolean;
    description?: string;
    practiceAreas?: PracticeArea[];
    serviceAgreementContract?: ContractListItem;
    archivedDate?: string;
    archivedInOffice?: NamedEntityMinDto;
    archivingNotes?: string;
    discountPct?: string;
    discountExpiryDate?: Date;
    isCostIncluded?: boolean;
    pricingLimitationTypeCode?: string;
    accountingRevenueTypeCode?: string;
    capUnitTypeCode?: string;
    capLimit?: string;
    fixedFee?: string;
    recurringFixedFee?: string;
    billingNotes?: string;
    invoicingInfo: string | null;
    timecardLanguageCode?: string;
    primaryRecipientParty?: NamedEntityMinWithMainEmailDto;
    primaryRecipientAdditionalEmails?: string;
    secondaryRecipientParty?: NamedEntityMinWithMainEmailDto;
    secondaryRecipientAdditionalEmails?: string;
    matterClientReference?: string;
    invoiceMatterName?: string;
    invoiceMatterNameLocalLanguage?: string;
    invoiceTemplate?: DocumentTemplateMinDto;
    invoicedByOffice?: NamedEntityMinDto;
    invoicingIntervalTypeCode?: string;
    payerParty: NamedEntityMinDto;
    invoiceSpecTemplate?: DocumentTemplateMinDto;
    pricelist?: Pricelist;
    pricelistCurrencyTypeCode?: string;
    pricingHash?: string;
    matterStats?: MatterStats;
    currencyDetails?: CurrencyData;
    currencyAccessible: boolean;
    isDeletable: boolean;
    isEditable: boolean;
    isJointInvoiceDisabled: boolean;
    isAutomaticClosingExempt: boolean;
    isAutomaticBlockingExempt: boolean;
    isTimecardAddingBlocked: boolean;
    isTimecardAddingCurrentlyBlocked: boolean;
    timecardUnblockingExplanation?: string;
    timecardUnblockingPostponedUntil?: Date;
    customFields?: CustomField[];
    nextInvoicingDate?: Date;
}

export interface MatterBillingSummaryDto {
    feeArrangementTypeCode: string;
    budget?: MatterBillingSummaryBudgetDto;
    fixedFeeDetails?: MatterBillingSummaryFixedFeeDetailsDto;
    recurringFeeDetails?: MatterBillingSummaryRecurringFeeDetailsDto;
}

export interface MatterBillingSummaryBudgetDto {
    capTotal: number | null;
    usedCap: number | null;
    remainingCap: number | null;
}

export interface MatterBillingSummaryFixedFeeDetailsDto {
    fixedFee: number | null;
    billedAmount: number | null;
}

export interface MatterBillingSummaryRecurringFeeDetailsDto {
    recurringFixedFee: number | null;
    invoicingIntervalTypeCode: string | null;
}

export interface UpdateHourlyRatesDto {
    referencedPricelist: PricelistReference;
    rates: HourlyRate[];
}

export interface PricelistReference {
    id: number | null;
    pricingHash?: string;
}

export interface MatterStats {
    totalTimeInSeconds?: number;
    totalBlledTimeInSeconds?: number;
    totalTimeInHours?: number;
    totalBilledTimeInHours?: number;
    totalTimecardCount?: number;
    latestTimecardDate?: string;
    expenseList: Record<string, number>[];
    uninvoicedTimeInSeconds?: number;
    uninvoicedBilledTimeInSeconds?: number;
    uninvoicedTimeInHours?: number;
    uninvoicedBilledTimeInHours?: number;
    uninvoicedTimecardCount?: number;
    containsMissingJobRole: boolean;
    containsMissingHourlyPrice: boolean;
}

export interface MatterForm {
    readOnlyCaseNumber: string | null;
    name: string;
    clientParty: ClientParty | null;
    matterStateType: { label: string; value: string } | null;
    handledByOffice: {
        id: number;
        name: string;
        companyInvoicingIntervalTypeCode: string;
    } | null;
    generalNotes: string | null;
    isConfidential: boolean;
    description: string | null;
    practiceAreas: NamedEntityMinDto[];
    secondLevelPracticeAreas: NamedEntityMinDto[];
    serviceAgreementContract: { contractName: string; id: number; contractInvoicingIntervalTypeCode: string } | null;
    openedDate: string | null;
    closedDate: string | null;
    responsibleEmployee: NamedEntityMinDto | null;
    supervisedByEmployee: NamedEntityMinDto | null;
    employees: NamedEntityMinDto[];
    primaryContactParty: NamedEntityMinDto | null;
    representedClients: NamedEntityMinDto[];
    isNoOpponent: boolean;
    opponentParties: NamedEntityMinDto[];
    targetParties: NamedEntityMinDto[];
    otherParties: NamedEntityMinDto[];
    judgeParties: NamedEntityMinDto[];
    archivedInOffice: NamedEntityMinDto | null;
    archivedDate: string | null;
    archivingNotes: string | null;
    discountPct: string | null;
    discountExpiryDate: string | null;
    isCostIncluded: boolean;
    pricingLimitationType: { label: string; value: string } | null;
    accountingRevenueType: { label: string; value: string } | null;
    capUnitType: { label: string; value: string } | null;
    capLimit: string | null;
    fixedFee: string | null;
    recurringFixedFee: string | null;
    billingNotes: string | null;
    invoicingInfo: string | null;
    timecardLanguage: { label: string; value: string } | null;
    primaryRecipientParty: NamedEntityMinWithEmailDto | null;
    primaryRecipientAdditionalEmails: string | null;
    secondaryRecipientParty: NamedEntityMinWithEmailDto | null;
    secondaryRecipientAdditionalEmails: string | null;
    matterClientReference: string | null;
    invoiceMatterName: string | null;
    invoiceMatterNameLocalLanguage: string | null;
    invoiceTemplate: NamedEntityMinDto | null;
    invoicedByOffice: NamedEntityMinDto | null;
    invoicingIntervalType: { label: string; value: string } | null;
    payerParty: NamedEntityMinDto | null;
    invoiceSpecTemplate: NamedEntityMinDto | null;
    referencedPricelist: PricelistReference | null;
    rates: HourlyRate[];
    pricelistCurrencyType: { label: string; value: string } | null;
    isJointInvoicingDisabled: boolean;
    isAutomaticClosingExempt: boolean;
    isAutomaticBlockingExempt: boolean;
    isTimecardAddingBlocked: boolean;
    timecardUnblockingExplanation: string | null;
    timecardUnblockingPostponedUntil: string | null;
    nextInvoicingDate: Date | null;
}

export interface MatterCreateDto {
    name: string;
    clientPartyId: number | null;
    matterStateTypeCode: string | null;
    handledByOfficeId: number | null;
    generalNotes: string | null;
    isConfidential: boolean;
    description: string | null;
    practiceAreas: NamedEntityMinDto[];
    serviceAgreementContractId: number | null;
    openedDate: string | null;
    closedDate: string | null;
    responsibleEmployeeId: number | null;
    supervisedByEmployeeId: number | null;
    primaryContactPartyId: number | null;
    employees: NamedEntityMinDto[];
    representedClients: NamedEntityMinDto[];
    isNoOpponent: boolean;
    opponentParties: NamedEntityMinDto[];
    targetParties: NamedEntityMinDto[];
    otherParties: NamedEntityMinDto[];
    judgeParties: NamedEntityMinDto[];
    archivedDate: string | null;
    archivedInOfficeId: number | null;
    archivingNotes: string | null;
    discountPct: string | null;
    discountExpiryDate: Date | null;
    isCostIncluded: boolean;
    pricingLimitationTypeCode: string | null;
    accountingRevenueTypeCode: string | null;
    capUnitTypeCode: string | null;
    capLimit: string | null;
    fixedFee: string | null;
    recurringFixedFee: string | null;
    billingNotes: string | null;
    invoicingInfo: string | null;
    timecardLanguageCode: string | null;
    primaryRecipientPartyId: number | null;
    primaryRecipientAdditionalEmails: string | null;
    secondaryRecipientPartyId: number | null;
    secondaryRecipientAdditionalEmails: string | null;
    matterClientReference: string | null;
    invoiceMatterName: string | null;
    invoiceMatterNameLocalLanguage: string | null;
    invoiceTemplateId: number | null;
    invoicedByOfficeId: number | null;
    invoicingIntervalTypeCode: string | null;
    payerPartyId: number | null;
    invoiceSpecTemplateId: number | null;
    referencedPricelist: PricelistReference | null;
    rates: HourlyRate[];
    pricelistCurrencyTypeCode: string | null;
    isJointInvoiceDisabled: boolean;
    isAutomaticClosingExempt: boolean;
    isAutomaticBlockingExempt: boolean;
    timecardUnblockingExplanation: string | null;
    timecardUnblockingPostponedUntil: Date | null;
    customFields?: CustomFieldDto[];
    nextInvoicingDate: Date | null;
}

export interface MatterUpdateDto extends MatterCreateDto {
    id: string | number;
}

export interface MatterMin extends MinDto {
    fullName: string;
}

export interface MatterMinWithClientPartyId extends MatterMin {
    clientPartyId: number;
}

import {
    CognitoUserPool,
    CognitoUser as _CognitoUser,
    ICognitoUserPoolData,
    ICognitoUserData,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { getLocalStorage } from './localStorage';

export class CognitoUser {
    poolData: ICognitoUserPoolData | undefined;
    userPool: CognitoUserPool | undefined;
    userData: ICognitoUserData | undefined;
    cognitoUser: _CognitoUser | undefined;
    cognitoUserSession: CognitoUserSession | undefined;

    constructor(username: string) {
        if (!username) {
            return;
        }

        this.poolData = {
            UserPoolId: import.meta.env.VITE_COGNITO_POOL_ID || '', // Cognito user pool id
            ClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID || '', // Cognito App client id
        };

        this.userPool = new CognitoUserPool(this.poolData);

        this.userData = {
            Username: username,
            Pool: this.userPool,
        };

        this.cognitoUser = new _CognitoUser(this.userData);
        this.cognitoUser.getSession((error: Error, session: CognitoUserSession | null) => {
            if (error) {
                console.error('Error getting user session: ', error);
            } else {
                if (session) {
                    this.cognitoUserSession = session;
                }
            }
        });
    }

    public getCognitoUser(): _CognitoUser | undefined {
        return this.cognitoUser;
    }

    public setCognitoUser(user: _CognitoUser): void {
        this.cognitoUser = user;
    }

    public getSession(): Promise<CognitoUserSession | undefined> {
        return new Promise((resolve, reject) => {
            if (this.cognitoUser) {
                this.cognitoUser.getSession((error: Error, session: CognitoUserSession | null) => {
                    if (error) {
                        console.error('Error getting user session: ', error);
                        this.cognitoUserSession = undefined;
                        reject(error);
                    } else {
                        if (session && session !== null) {
                            this.cognitoUserSession = session;
                            resolve(session);
                        } else {
                            this.cognitoUserSession = undefined;
                            resolve(undefined);
                        }
                    }
                });
            } else {
                resolve(undefined);
            }
        });
    }

    public setCognitoUserSession(session: CognitoUserSession): void {
        this.cognitoUserSession = session;
    }
}

// Instatiate the Cognito user object to hold the reference of the user
export const cognitoUser = new CognitoUser(getLocalStorage('cognito_user') || '');

import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const EnvIndicatorBar = () => {
    const theme = useTheme();
    const env = import.meta.env.VITE_ENV;

    const envColorProps = useMemo(() => {
        switch (env.toLowerCase()) {
            case 'dev':
            case 'development':
                return { background: theme.palette.success.lighter, text: '#006400' }; // Dark green color
            case 'test':
                return { background: theme.palette.warning.lighter, text: '#FFA500' }; // Dark orange color
            default:
                return null;
        }
    }, [env, theme]);

    return envColorProps && env.toLowerCase() !== 'prod' ? (
        <Box
            sx={{
                height: '38px',
                backgroundColor: envColorProps.background,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: envColorProps.text,
            }}
        >
            <FormattedMessage id="portal.environment-indicator" values={{ environment: env.toLocaleUpperCase() }} />
        </Box>
    ) : null;
};

export default EnvIndicatorBar;

import React from 'react';
import NumberFormat from 'react-number-format';

type Props = {
    amount: number;
    currency?: string;
    forceSuffix?: boolean;
    displaySign?: boolean;
};

const MoneyDisplay = ({ amount, currency = '', forceSuffix = false, displaySign = false }: Props): JSX.Element => {
    return (
        <NumberFormat
            displayType="text"
            fixedDecimalScale={amount > 0 || forceSuffix}
            decimalScale={2}
            prefix={amount > 0 && displaySign ? '+' : undefined}
            suffix={amount > 0 || forceSuffix ? (currency?.length > 0 || forceSuffix ? ` ${currency}` : '') : undefined}
            value={amount}
        />
    );
};
export default MoneyDisplay;

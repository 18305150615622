import { CardContent, Box, Grid, SxProps, useMediaQuery, useTheme } from '@mui/material';
import React, { isValidElement, useEffect, useMemo, useRef, useState } from 'react';
import { SectionProps } from './Section';
import { SubSectionProps } from './SubSection';
import ContentMenu, { ContentMenuRow } from 'components/forms/ContentMenu';

type Props = {
    children: (React.ReactElement<SectionProps> | null)[] | React.ReactElement<SectionProps> | null;
    showMenu?: boolean;
    readonly?: boolean;
};

const gridLayout: SxProps = {
    display: 'grid',
    gridTemplateColumns: '192px 578px',
    gap: '6%',
};

/**
 * Parses children and returns rows for ContentMenu
 *
 * @returns ContentMenuRow[]
 */
const getRowsFromChildren = (
    children: React.ReactElement<SectionProps>[] | React.ReactElement<SectionProps>
): ContentMenuRow[] => {
    const rows: ContentMenuRow[] = [];
    let sectionComponents: React.ReactElement<SectionProps, string | React.JSXElementConstructor<any>>[] = [];
    if (Array.isArray(children)) {
        sectionComponents = children;
    } else {
        sectionComponents = [children];
    }
    sectionComponents.forEach((section) => {
        const row: ContentMenuRow = {
            title: section.props.title,
            subtitles: [],
        };
        if (Array.isArray(section.props.children)) {
            section.props.children.forEach((child) => {
                if (isValidElement(child) && (child.type as any).displayName === 'SubSection') {
                    row.subtitles.push((child.props as SubSectionProps).title);
                }
            });
        } else {
            if (
                isValidElement(section.props.children) &&
                (section.props.children.type as any).displayName === 'SubSection'
            ) {
                row.subtitles.push((section.props.children.props as SubSectionProps).title);
            }
        }
        rows.push(row);
    });
    return rows;
};

const SectionLayout = ({ children, showMenu = true, readonly }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const ref = useRef<HTMLDivElement>();
    const [additionalSections, setAdditionalSections] = useState<ContentMenuRow[]>([]);

    const shouldShowMenu = useMemo(() => showMenu && !isMobile, [showMenu, isMobile]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const titles = Array.from(ref.current?.querySelectorAll('div[id][data-no-translation]') ?? [])
            .map((element) => element.innerHTML)
            .map((title) => ({
                title: title,
                subtitles: [],
                noTranslate: true,
            }));
        setAdditionalSections(titles);
    }, [children]);

    // Remove null values from child list. If section is readonly, then also make all of its content readonly.
    const validChildren = useMemo(() => {
        return Array.isArray(children)
            ? children.reduce((acc: React.ReactElement<SectionProps>[], val) => {
                  if (val !== null && !val.props.isHidden && (val.type as any).displayName === 'Section') {
                      const updatedProps = {
                          ...val.props,
                          readonly: readonly === undefined ? val.props?.readonly : readonly,
                      };
                      acc.push({ ...val, props: updatedProps });
                  }
                  return acc;
              }, [])
            : ({
                  ...children,
                  props: {
                      ...children?.props,
                      readonly: readonly === undefined ? children?.props?.readonly : readonly,
                  },
              } as React.ReactElement<SectionProps>);
    }, [children, readonly]);

    const rowsFromChildren = useMemo(() => {
        return [...getRowsFromChildren(validChildren), ...additionalSections];
    }, [validChildren, additionalSections]);

    if (children === null) return <Box></Box>;

    return (
        <CardContent sx={{ p: 3 }}>
            <Box sx={shouldShowMenu ? gridLayout : undefined} ref={ref}>
                {shouldShowMenu && (
                    <ContentMenu
                        rows={rowsFromChildren}
                        sx={{
                            height: 'fit-content',
                            position: 'sticky',
                            top: '96px',
                            p: 0,
                        }}
                    />
                )}
                <Grid
                    container
                    sx={{
                        maxWidth: '578px',
                        padding: 0,
                        margin: '0 auto',
                        display: 'flex',
                        gap: readonly ? 3 : 0,
                        '.MuiBox-root:last-child': {
                            '.MuiDivider-root': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    {children}
                </Grid>
            </Box>
        </CardContent>
    );
};

export default SectionLayout;

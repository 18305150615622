import { useSelector } from 'store';
import { useStartTrackingTimecardMutation, useUpdateTimecardCacheMutation } from 'store/api/timecards';
import { TimecardDetails } from 'types/timecards';

type UseStartTrackingTimecard = {
    startTracking: (timecardId: number) => Promise<TimecardDetails | undefined>;
};

export const useStartTrackingTimecard = (): UseStartTrackingTimecard => {
    const { trackedTimecardId, isStopwatchRunning } = useSelector((state) => state.timecard);
    const [startTracking] = useStartTrackingTimecardMutation();
    const [updateTimecardCache] = useUpdateTimecardCacheMutation();

    const startTrackingProcess = async (timecardId: number) => {
        if (isStopwatchRunning && trackedTimecardId && parseInt(trackedTimecardId) !== timecardId) {
            const previouslyRunningTimecardId = parseInt(trackedTimecardId);
            try {
                const result = await startTracking(timecardId).unwrap();
                updateTimecardCache(previouslyRunningTimecardId);
                return result;
                // eslint-disable-next-line no-empty
            } catch {}
        } else {
            return startTracking(timecardId).unwrap();
        }
    };

    return {
        startTracking: startTrackingProcess,
    };
};

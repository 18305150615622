import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from '@mui/material/Modal';
import { alpha, Card, useTheme, CSSObject } from '@mui/material';

type Props = {
    children: string | JSX.Element[] | JSX.Element;
};

export type ModalOptions = {
    content: JSX.Element | undefined;
    onCancel: () => void;
    modalStyle?: CSSObject;
};

type Context = {
    setModalOptions: Dispatch<SetStateAction<ModalOptions>>;
};

export const ModalContext = React.createContext({} as Context);

const ModalProvider = ({ children }: Props): JSX.Element => {
    const theme = useTheme();
    const [modalOptions, setModalOptions] = useState<ModalOptions>({
        content: undefined,
        onCancel: () => null,
        modalStyle: undefined,
    });

    const contentStyle: CSSObject = {
        ...{
            display: 'flex',
            flexDirection: 'column',
            width: 600,
            bgcolor: theme.palette.grey[0],
            borderRadius: 3,
            overflow: 'hidden',
            maxHeight: 'calc(100vh - 64px)',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        ...modalOptions.modalStyle,
    };

    return (
        <ModalContext.Provider value={{ setModalOptions }}>
            {children}
            {!!modalOptions.content && (
                <Modal
                    open
                    onClose={modalOptions.onCancel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    slotProps={{ backdrop: { style: { backgroundColor: alpha(theme.palette.common.black, 0.8) } } }}
                    sx={{
                        display: 'grid',
                        placeContent: 'center',
                    }}
                >
                    <Card sx={contentStyle}>{modalOptions.content}</Card>
                </Modal>
            )}
        </ModalContext.Provider>
    );
};

export default ModalProvider;

export default function Baseline() {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                },
                body: {
                    height: '100%',
                    '& > #root': {
                        height: '100%',
                    },
                },
            },
        },
    };
}

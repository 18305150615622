import { useCallback, useMemo } from 'react';

// @MUI imports
// Assets
import AddTimecardIcon from 'assets/images/icons/AddTimecardIcon.svg?react';
import CreditCardIcon from 'assets/images/icons/CreditCardIcon.svg?react';
import PlayWithPlusIcon from 'assets/images/icons/PlayWithPlusIcon.svg?react';

// Project imports
import { CallForActionButton, CallForActionButtonTooltip } from 'components/buttons/CallForActionButton';
import { FORM_OVERFLOW_CUSTOM_STYLE } from 'components/modals/util';
import { useModal } from 'components/modals/useModal';
import { errorAlert, successAlert } from 'utils/alert';
import { t } from 'utils/getTranslations';

// Store
import { useDispatch, useSelector } from 'store';
import { useAddExpenseMutation } from 'store/api/expenses';
import { setDraftTimecardsLoading } from 'store/reducers/timecard';

// Third party
import { format } from 'date-fns';

// Types
import { useStartTrackingTimecard } from 'pages/timecards/useStartTrackingTimecard';
import { APITimecardsSlice, useAddTimecardMutation } from 'store/api/timecards';
import { ExpenseCreateDto, ExpenseForm } from 'types/expenses';
import { MatterListItem } from 'types/matters';
import { TimecardDto } from 'types/timecards';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';
import { NamedEntityMinDto } from 'types/other';
import ExpenseModal from 'components/modals/expenses/ExpenseModal';
import { formatDateStringInput } from 'components/forms/inputs/DatePickerInput';
import { useHasPermissions } from 'utils/permissionUtil';
import { DEFAULT_DATE_FORMAT } from 'utils/parseDate';
import { useGetEmployeesListWithFiltersQuery } from 'store/api/employees';
import { EmployeeListItemMinDto } from 'types/employees';

type Props = {
    matter: MatterListItem;
};

const MyCaseActionButtons = ({ matter }: Props): JSX.Element => {
    return (
        <>
            <AddExpenseButton matter={matter} />
            <AddNewTimecardButton matter={matter} />
            <AddAndStartTimecardButton matter={matter} />
        </>
    );
};

interface MatterProps {
    matter: MatterListItem;
}

const AddNewTimecardButton = ({ matter }: MatterProps): JSX.Element | null => {
    const navigateWithReturn = useNavigateWithReturnState();

    const hasPermissionCreateTimecards = useHasPermissions(['CREATE.TIMECARDS']);

    const addTimecard = () => {
        navigateWithReturn(`/matter/${matter.id}/timecards/new`);
    };

    return hasPermissionCreateTimecards ? (
        <CallForActionButtonTooltip title={t('dashboard.add-new-timecard')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('dashboard.add-new-timecard')}
                onClick={addTimecard}
            >
                <AddTimecardIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    ) : null;
};

const AddExpenseButton = ({ matter }: MatterProps): JSX.Element | null => {
    const userId = useSelector((state) => state.user.id);
    const { openModal, closeModal } = useModal();

    const { data: currentEmployeeQueryData } = useGetEmployeesListWithFiltersQuery(
        { pageSize: 1, page: 0, filters: `id==${userId}` },
        { skip: !userId }
    );

    const currentEmployeeData = useMemo<EmployeeListItemMinDto | undefined>(() => {
        return currentEmployeeQueryData?.content.at(0);
    }, [currentEmployeeQueryData]);

    const [addExpense] = useAddExpenseMutation();

    const hasPermissionCreateExpenses = useHasPermissions(['CREATE.EXPENSES']);

    const handleExpenseCreate = useCallback(
        async (expense: ExpenseCreateDto) => {
            try {
                await addExpense(expense);

                return 0;
            } catch (e) {
                console.error('onSubmit error: ', e);
                errorAlert(t('forms.error-submitting-the-form'));
            }
        },
        [addExpense]
    );

    const openExpenseModal = useCallback((): void => {
        const preSelectedEmployee = currentEmployeeData;

        const initalData: ExpenseForm = {
            expenseType: null,
            currencyType: null,
            description: '',
            expenseAmount: null,
            expenseDate: formatDateStringInput(new Date()),
            isNonBillable: false,
            employee: preSelectedEmployee ? { id: preSelectedEmployee?.id, name: preSelectedEmployee?.name } : null,
            matter: matter ? { id: matter?.id, name: matter?.name, fullName: matter.fullName } : null,
            timecardId: null,
        };

        openModal(
            <ExpenseModal
                onChange={(value) => handleExpenseCreate(value)}
                initialData={initalData}
                onClose={closeModal}
                titleId={'expenses.add'}
                disableSelectMatter={true}
                readOnly={false}
            />,
            closeModal,
            FORM_OVERFLOW_CUSTOM_STYLE
        );
    }, [currentEmployeeData, matter, closeModal, openModal, handleExpenseCreate]);

    return hasPermissionCreateExpenses ? (
        <CallForActionButtonTooltip title={t('dashboard.add-new-expense')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('dashboard.add-new-expense')}
                onClick={openExpenseModal}
            >
                <CreditCardIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    ) : null;
};

const AddAndStartTimecardButton = ({ matter }: MatterProps): JSX.Element | null => {
    const { id: userId } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [addTimecard] = useAddTimecardMutation();
    const { startTracking } = useStartTrackingTimecard();

    const hasPermissionCreateTimecards = useHasPermissions(['CREATE.TIMECARDS']);

    const addAndStartTimecard = async () => {
        const body: TimecardDto = {
            id: null,
            matter,
            employee: {
                id: userId || 0,
            } as NamedEntityMinDto,
            description: '',
            multiplierRate: '1.0',
            multiplierTypeCode: 'NONE',
            practiceAreas: [],
            isNonBillable: false,
            totalTimeInSeconds: 0,
            billedTimeInSeconds: 0,
            timecardDate: format(new Date(), DEFAULT_DATE_FORMAT),
        };

        try {
            dispatch(setDraftTimecardsLoading(true));

            const newTimeCard = await addTimecard(body).unwrap();
            const started = await startTracking(newTimeCard.id);

            if (started) {
                successAlert(t('dashboard.draft-timecard-added-and-stopwatch-started'));
                // Refresh the current user draft timecards list
                dispatch(APITimecardsSlice.util.invalidateTags(['Timecards']));
            }

            dispatch(setDraftTimecardsLoading(false));
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
            dispatch(setDraftTimecardsLoading(false));
        }
    };

    return hasPermissionCreateTimecards ? (
        <CallForActionButtonTooltip title={t('dashboard.start-new-timecard')}>
            <CallForActionButton
                variant="contained"
                shape="rounded"
                color="info"
                aria-label={t('dashboard.start-new-timecard')}
                onClick={addAndStartTimecard}
            >
                <PlayWithPlusIcon />
            </CallForActionButton>
        </CallForActionButtonTooltip>
    ) : null;
};

export default MyCaseActionButtons;

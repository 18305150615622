// project import
import getItems from './other';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const getMenuItems = (userId?: string): { items: NavItemType[] } => ({
    items: [getItems(userId)],
});

export default getMenuItems;

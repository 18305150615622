import { SnackbarProvider, closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack';
import React, { Fragment } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from 'assets/images/icons/InfoIcon.svg?react';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { CloseOutlined } from '@ant-design/icons';
import IconButton from 'components/@extended/IconButton';
import { Alert, Fade, Grow, Slide, SlideProps } from '@mui/material';
import { KeyedObject } from 'types/root';
import { SnackOptions } from 'utils/alert';
import CopyToClipboard from 'components/util/CopyToClipboard';
import styles from './Snackbar.module.css';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade,
};

// ==============================|| SNACKBAR PROVIDER ||============================== //

const SnackbarAlertProvider = ({ children }: { children: string | JSX.Element[] | JSX.Element }): JSX.Element => {
    const renderAlert = (type: 'success' | 'error' | 'info' | 'warning', props: CustomContentProps & SnackOptions) => {
        const { id, message, requestId } = props;
        return (
            <Alert
                key={id}
                variant="filled"
                severity={type}
                color={type}
                iconMapping={{
                    error: <PriorityHighRoundedIcon color="error" />,
                    warning: <PriorityHighRoundedIcon color="warning" />,
                    success: <DoneIcon color="success" />,
                    info: <InfoIcon color="info" />,
                }}
                action={
                    <IconButton
                        sx={{ ml: '50px' }}
                        size="small"
                        aria-label="close"
                        variant="contained"
                        onClick={() => closeSnackbar(id)}
                    >
                        <CloseOutlined />
                    </IconButton>
                }
            >
                {message}
                {requestId !== undefined ? (
                    <Fragment>
                        <br />
                        Request id: {requestId}
                        <CopyToClipboard text={requestId} />
                    </Fragment>
                ) : null}
            </Alert>
        );
    };

    const ErrorAlert = React.forwardRef<HTMLDivElement, CustomContentProps & SnackOptions>((props, ref) => {
        return (
            <SnackbarContent ref={ref} role="alert" style={props.style}>
                {renderAlert('error', props)}
            </SnackbarContent>
        );
    });

    const SuccessAlert = React.forwardRef<HTMLDivElement, CustomContentProps & SnackOptions>((props, ref) => {
        return (
            <SnackbarContent ref={ref} role="alert" style={props.style}>
                {renderAlert('success', props)}
            </SnackbarContent>
        );
    });
    const InfoAlert = React.forwardRef<HTMLDivElement, CustomContentProps & SnackOptions>((props, ref) => {
        return (
            <SnackbarContent ref={ref} role="alert" style={props.style}>
                {renderAlert('info', props)}
            </SnackbarContent>
        );
    });
    const WarningAlert = React.forwardRef<HTMLDivElement, CustomContentProps & SnackOptions>((props, ref) => {
        return (
            <SnackbarContent ref={ref} role="alert" style={props.style}>
                {renderAlert('warning', props)}
            </SnackbarContent>
        );
    });

    return (
        <SnackbarProvider
            autoHideDuration={12000}
            maxSnack={5}
            dense
            Components={{
                error: ErrorAlert,
                success: SuccessAlert,
                info: InfoAlert,
                warning: WarningAlert,
            }}
            classes={{ root: styles.root }}
            TransitionComponent={animation['Fade']}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default SnackbarAlertProvider;

import { NamedEntityMinDto } from './other';

export enum NotificationCategory {
    CASE_AND_CLIENT = 'CASE_AND_CLIENT',
    INVOICING = 'INVOICING',
}

// Not called Notification to prevent overlap with a global type
export interface AutomatedNotification {
    id: number;
    title: string;
    content: string;
    category: NotificationCategory | string;
    isArchived: boolean;
    isImportant: boolean;
    createdAt: string;
    readAt: string | null;
    metaDataJson: MetaData;
    isMessage: boolean;
}

type MetaData = {
    party?: NamedEntityMinDto;
    matter?: { id: number; fullName: string };
    contractSentAt?: string;
    invoice?: { id: number; invoiceNumber: string };
    employee?: NamedEntityMinDto;
};

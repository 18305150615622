import { useEffect } from 'react';

export const useMutationObserver = (
    domNodeSelector: string,
    callback: MutationCallback,
    observerOptions = options
): void => {
    useEffect(() => {
        const targetNode = document.querySelector(domNodeSelector);
        if (!targetNode) return;
        const observer = new MutationObserver(callback);

        observer.observe(targetNode as Node, observerOptions);

        return () => {
            observer.disconnect();
        };
    }, [domNodeSelector, observerOptions, callback]);
};

const options = { attributes: true, childList: true };

import { Box, styled } from '@mui/material';

// Small round box for count display
const CountBox = styled(
    Box,
    {}
)(({ theme }) => ({
    width: 'fit-content',
    fontSize: 10,
    fontWeight: 600,
    height: 16,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
}));

export default CountBox;

// material-ui
import { Grid, Typography } from '@mui/material';

// project import
import AuthWrapper from 'sections/auth/AuthWrapper';

// Hooks
import { AuthMFAVerification } from '../../sections/auth/auth-forms/AuthMFAVerification';
import { t } from '../../utils/getTranslations.ts';

const MfaVerification = () => {
    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('auth.mfa-verification')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AuthMFAVerification />
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default MfaVerification;

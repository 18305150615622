// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    TableProps,
    TableContainer,
    TableContainerProps,
    useTheme,
    SxProps,
} from '@mui/material';

// third-party
import { useTable, Column } from 'react-table';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

export function ReactTable({
    columns,
    data,
    striped,
    skipHeader = false,
    sx,
    noContentJSX = null,
    containerProps,
    ...tableProps
}: any) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <TableContainer {...containerProps}>
            <Table {...getTableProps()} sx={sx} {...tableProps}>
                {!skipHeader && (
                    <TableHead
                        sx={{
                            '.MuiTableCell-root': {
                                textTransform: 'none',
                            },
                        }}
                    >
                        {headerGroups.map((headerGroup) => {
                            const { key: tableRowKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <TableRow key={tableRowKey} {...headerGroupProps}>
                                    {headerGroup.headers
                                        .filter((column: any) => !column.isHidden)
                                        .map((column: any, index: number) => {
                                            const { key: headerGroupKey, ...columnProps } = column.getHeaderProps([
                                                {
                                                    className: column.className,
                                                    style: {
                                                        textAlign: column.textAlign,
                                                        minWidth: index === 0 && !isMobile ? 'auto' : column.minWidth,
                                                        width: column.width,
                                                    },
                                                },
                                            ]);

                                            const { key: cellKey, ...columnHeaderProps } = column.getHeaderProps([
                                                { className: column.className },
                                            ]);

                                            return (
                                                <TableCell
                                                    key={cellKey}
                                                    {...columnHeaderProps}
                                                    {...columnProps}
                                                    sx={{
                                                        borderBottom: `1px solid ${theme.palette.divider} !important`,
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                </TableCell>
                                            );
                                        })}
                                </TableRow>
                            );
                        })}
                    </TableHead>
                )}
                <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
                    {rows.length > 0
                        ? rows.map((row, i) => {
                              prepareRow(row);
                              const { key: tableRowKey, ...tableRowProps } = row.getRowProps();
                              return (
                                  <TableRow key={tableRowKey} {...tableRowProps}>
                                      {row.cells
                                          .filter((cell: any) => !cell.column.isHidden)
                                          .map((cell: any) => {
                                              const { key: cellKey, ...cellProps } = cell.getCellProps([
                                                  { className: cell.column.className },
                                              ]);
                                              return (
                                                  <TableCell key={cellKey} {...cellProps}>
                                                      {cell.render('Cell')}
                                                  </TableCell>
                                              );
                                          })}
                                  </TableRow>
                              );
                          })
                        : noContentJSX}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

interface Props extends TableProps {
    data: { [key: string]: any }[];
    striped?: boolean;
    title?: string;
    columns: Column[];
    skipHeader?: boolean;
    sx?: SxProps;
    wrapperSx?: SxProps;
    noContentJSX?: JSX.Element;
    containerProps?: TableContainerProps;
}

const BasicTable: React.FC<Props> = ({
    data,
    striped,
    title,
    columns,
    skipHeader = false,
    sx,
    containerProps,
    wrapperSx,
    noContentJSX,
    ...tableProps
}) => {
    return (
        <MainCard content={false} title={title} sx={wrapperSx}>
            <ScrollX>
                <ReactTable
                    columns={columns}
                    data={data}
                    striped={striped}
                    skipHeader={skipHeader}
                    sx={sx}
                    containerProps={containerProps}
                    noContentJSX={noContentJSX}
                    {...tableProps}
                />
            </ScrollX>
        </MainCard>
    );
};

export default BasicTable;

import { intlFormat, parse } from 'date-fns';

export const parseInternationalDate = (date: string) => {
    if (!date || typeof date !== 'number') {
        return '';
    }

    return intlFormat(new Date(date));
};

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const dateStringToDateObject = (date: string, format: string = DEFAULT_DATE_FORMAT) => {
    return parse(date, format, new Date());
};

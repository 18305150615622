import { Box, Typography, Link } from '@mui/material';
import React from 'react';
import { FieldPathByValue, FieldValues } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

interface Props<TOption extends FieldValues> {
    value: string | TOption | TOption[];
    getValue?: (option: TOption | null) => string | number | TOption[FieldPathByValue<TOption, string | number>] | null;
    getLabel?: (option: TOption | null) => string | TOption[FieldPathByValue<TOption, string | number>] | null;
    linkTo?: string;
    decimalScale?: number;
}

const ReadonlyField = <TOption extends FieldValues>({
    value,
    getValue,
    getLabel,
    linkTo,
    decimalScale,
}: Props<TOption>) => {
    // If value is string or number, then render it. Else return '-'.
    const renderTextValue = (textValue: string | TOption | TOption[] | null) => {
        if (typeof textValue === 'string' || typeof textValue === 'number') {
            return textValue;
        } else {
            return '-';
        }
    };
    // If is value list, then render all items in list.
    if (Array.isArray(value)) {
        const links = value.map((option, index) => {
            const maybeId = getValue && getValue(option) ? getValue(option) : null;
            const textValue = getLabel && getLabel(option) ? getLabel(option) : option;

            // Assume that the value is an id. If id exists and linkTo is set then link to the person, office etc page. Else just return text value.
            return (
                <React.Fragment key={index}>
                    {maybeId && textValue && linkTo ? (
                        <>
                            <Link to={`/${linkTo}/${maybeId}`} component={RouterLink} color="text.link">
                                {renderTextValue(textValue)}
                            </Link>
                            {index < value.length - 1 ? <span>,&nbsp;</span> : null}
                        </>
                    ) : (
                        <Typography
                            sx={{ fontSize: 14, fontWeight: 400, display: 'flex', width: 'fit-content' }}
                            color="text.primary"
                        >
                            {renderTextValue(textValue)}
                            {index < value.length - 1 ? <span>,&nbsp;</span> : null}
                        </Typography>
                    )}
                </React.Fragment>
            );
        });

        return <Box sx={{ minHeight: '20px', display: 'flex', flexWrap: 'wrap' }}>{links?.length ? links : '-'}</Box>;
    }

    const maybeId = typeof value !== 'string' && getValue && getValue(value) ? getValue(value) : null;
    const textValue = typeof value !== 'string' && getLabel && getLabel(value) ? getLabel(value) : value;

    // Assume that the value is an id. If id exists and linkTo is set then link to the person, office etc page. Else just return text value.
    if (maybeId && linkTo) {
        return (
            <Link to={`/${linkTo}/${maybeId}`} component={RouterLink} color="text.link">
                {textValue !== '' && textValue !== null ? renderTextValue(textValue) : '-'}
            </Link>
        );
    }
    const numericRegex = /^\d+$/;

    const isNumber = typeof textValue === 'string' && textValue !== '' && numericRegex.test(textValue);
    return (
        <Typography
            sx={{ minHeight: '20px', fontSize: 14, fontWeight: 400, display: 'flex', width: 'fit-content' }}
            color="text.primary"
        >
            {isNumber ? (
                <NumberFormat
                    displayType="text"
                    fixedDecimalScale={Number(textValue) > 0}
                    decimalScale={decimalScale ?? 2}
                    value={Number(textValue)}
                />
            ) : textValue !== '' && textValue !== null ? (
                renderTextValue(textValue)
            ) : (
                '-'
            )}
        </Typography>
    );
};

export default ReadonlyField;

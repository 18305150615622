import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TextField ||============================== //

export default function TextField(theme: Theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input.Mui-disabled': {
                        color: theme.palette.text.primary,
                        WebkitTextFillColor: 'initial',
                        backgroundColor: theme.palette.grey[50],
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled': {
                        backgroundColor: theme.palette.grey[50],
                        borderColor: theme.palette.grey[200],
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.grey[200],
                    },
                },
            },
        },
    };
}

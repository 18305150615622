import { Children, ForwardedRef, Suspense, SuspenseProps, cloneElement, forwardRef } from 'react';
import { Box, Skeleton } from '@mui/material';

const SuspendedPlaceholder = forwardRef((props: any, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const { children, ...restProps } = props;
    return (
        <Suspense
            key={`${props.key}-suspense`}
            fallback={
                <Box sx={{ padding: '24px' }}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </Box>
            }
        >
            {Children.map(children, (child: any) => cloneElement(child, { ...restProps, ref }))}
        </Suspense>
    );
});

export default SuspendedPlaceholder;

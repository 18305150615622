import { Box, CSSObject, Tab, Tabs } from '@mui/material';
import React, { forwardRef, SyntheticEvent, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type TabType = {
    id: string;
    label: string | React.ReactNode;
    displayLabel?: string | React.ReactNode;
    component?: React.ReactNode;
    childComponents?: React.ReactNode[];
    sx?: CSSObject;
    icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    isDropdown?: boolean;
    hidden?: boolean;
};

interface TabsGalleryProps {
    tabs: TabType[];
    sx?: CSSObject;
    wrapperSx?: CSSObject;
    initialActiveTab?: number;
    onChange?: (value: number) => void;
}

export interface TabsGalleryRef {
    updateActiveTab: (tabNumber?: number) => void;
    updateChildComponentIndex: (tabId: string, childIndex: number) => void;
}

const TabPanel = ({ children, sx }: { children: React.ReactNode | React.ReactNode[]; sx: CSSObject }) =>
    children ? <Box sx={sx}>{children}</Box> : null;

const TabsGallery: React.ForwardRefRenderFunction<TabsGalleryRef, TabsGalleryProps> = (
    { tabs, sx = {}, wrapperSx = {}, initialActiveTab = 0, onChange },
    ref
) => {
    const [currentTab, setCurrentTab] = useState(initialActiveTab);
    const [currentChildComponentIndexByTabId, setCurrentChildComponentIndexByTabId] = useState<Map<string, number>>(
        new Map()
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const visibleTabs = tabs.filter((tab) => !tab.hidden);

    useEffect(() => onChange && onChange(currentTab), [currentTab, onChange]);

    const updateChildComponentIndex = useCallback(
        (tabId: string, childIndex: number) => {
            const newMapState = new Map(currentChildComponentIndexByTabId);
            newMapState.set(tabId, childIndex);
            setCurrentChildComponentIndexByTabId(newMapState);

            const index = visibleTabs.findIndex((tab) => tab.id === tabId);
            if (index >= 0) {
                setCurrentTab(index);
            }
        },
        [currentChildComponentIndexByTabId, visibleTabs]
    );

    // Set the active tab based on the URL
    useEffect(() => {
        const tabFromUrl = searchParams.get('tab');
        const subTabFromUrl = parseInt(searchParams.get('subtab') || '-1', 10);
        const tabIndex = visibleTabs.findIndex((tab) => tab.id === tabFromUrl);

        // No tab found
        if (tabIndex === -1) {
            return;
        }

        // If subtab is present, set it as the active tab
        if (!isNaN(subTabFromUrl) && subTabFromUrl >= 0) {
            const childTabId = visibleTabs[tabIndex].id;
            if (childTabId) {
                setCurrentTab(tabIndex);
                updateChildComponentIndex(childTabId, subTabFromUrl);
            }
        }
        // If subtab is not present, set the main tab as the active tab
        else if (tabFromUrl) {
            setCurrentTab(tabIndex);
        }
    }, [searchParams, visibleTabs, updateChildComponentIndex]);

    const updateActiveTab = (tabNumber?: number) => {
        if (tabNumber != null && tabNumber >= 0 && visibleTabs.length > tabNumber && visibleTabs[tabNumber].component) {
            setCurrentTab(tabNumber);

            // Cleanup the URL if there is initial tab references
            cleanUpUrl();
        }
    };

    const cleanUpUrl = () => {
        if (searchParams.has('tab') || searchParams.has('subtab')) {
            searchParams.delete('tab');
            searchParams.delete('subtab');
            setSearchParams(searchParams);
        }
    };

    useImperativeHandle(ref, () => ({
        updateActiveTab,
        updateChildComponentIndex,
    }));

    const getTablePanel = useCallback((component: React.ReactNode, index: number, tab: TabType) => {
        return (
            <TabPanel key={`tab-panel-${index}`} sx={tab.sx ?? {}}>
                {component ? component : (tab.displayLabel ?? tab.label)}
            </TabPanel>
        );
    }, []);

    return (
        <Box sx={wrapperSx}>
            <Box sx={{ ...{ borderBottom: 1, borderColor: 'divider', mb: 2 }, ...sx }}>
                <Tabs
                    value={currentTab}
                    onChange={(_: SyntheticEvent, value: number) => {
                        if (visibleTabs[value]?.component) {
                            updateActiveTab(value);
                        }
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    {visibleTabs.map((tab, index) => (
                        <Tab
                            icon={tab.icon}
                            iconPosition="end"
                            key={`tab-${index}`}
                            sx={{
                                fontWeight: 500,
                                color: '#929292',
                                alignItems: 'center',
                                ...(tab.isDropdown ? { padding: 0 } : {}),
                            }}
                            label={tab.label}
                            aria-controls={`galley-tabpanel-${index}`}
                        />
                    ))}
                </Tabs>
            </Box>
            {visibleTabs.map((tab, index) => {
                const { component: Component, childComponents } = tab;
                if (index === currentTab) {
                    const currentChildComponentIndex = tab.id
                        ? (currentChildComponentIndexByTabId.get(tab.id) ?? -1)
                        : -1;
                    if (
                        childComponents != null &&
                        currentChildComponentIndex >= 0 &&
                        childComponents.length > currentChildComponentIndex
                    ) {
                        const childComponent = childComponents[currentChildComponentIndex];
                        return getTablePanel(childComponent, index, tab);
                    } else {
                        return getTablePanel(Component, index, tab);
                    }
                }
                return null;
            })}
        </Box>
    );
};

export default forwardRef(TabsGallery);

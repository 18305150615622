import { Box, Stack, SxProps, Tooltip, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'utils/getTranslations';
import { useMemo } from 'react';

type ColorType = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | undefined;

const defaultStyle = {
    ml: '1px',
    height: '1.2rem',
};

const InfoTooltip = ({ text, color, sx }: { text?: string | string[]; color?: ColorType; sx?: SxProps }) => {
    const theme = useTheme();

    const htmlColor = useMemo(() => {
        switch (color) {
            case 'primary': {
                return theme.palette.primary.main;
            }
            case 'secondary': {
                return theme.palette.secondary.main;
            }
            case 'error': {
                return theme.palette.error.main;
            }
            case 'warning': {
                return theme.palette.warning.main;
            }
            case 'info': {
                return theme.palette.info.main;
            }
            case 'success': {
                return theme.palette.success.main;
            }
            default: {
                return theme.palette.grey[400];
            }
        }
    }, [color, theme]);

    if (!text || text.length === 0) return <Box />;

    return (
        <Tooltip
            title={
                Array.isArray(text) ? (
                    <Stack gap={1}>
                        {text.map((item) => (
                            <Box key={item}>{t(item)}</Box>
                        ))}
                    </Stack>
                ) : (
                    t(text)
                )
            }
            describeChild
            sx={{ '.MuiTooltip-tooltip': { textAlign: 'center', width: 'fit-content' } }}
        >
            <InfoOutlinedIcon sx={{ ...defaultStyle, ...sx }} htmlColor={htmlColor} />
        </Tooltip>
    );
};

export default InfoTooltip;

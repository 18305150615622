import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicTable from '../../components/tables/BasicTable';
import { t } from '../../utils/getTranslations';
import { Classifier } from 'types/classifiers';

interface ClassifierItemProps {
    classifier: Classifier;
}

const ClassifierItem: React.FC<ClassifierItemProps> = ({ classifier }) => {
    const ACCORDION_HEAD_STYLE = () => ({
        background: '#F6F6F6',
        pl: '16px',
        pr: '16px',
        pt: '9px',
        pb: '9px',
        '.MuiAccordionSummary-content': {
            m: 0,
            '.MuiTypography-root': { fontSize: '14px' },
        },
    });

    const columns = useMemo(
        () => [
            {
                Header: t('classifiers.classifier.value.code'),
                accessor: 'code',
                disableSortBy: true,
                Cell: ({ value }: any) => value ?? '',
            },
            {
                Header: t('classifiers.classifier.value.text_eng'),
                accessor: 'texts',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    const englishText = value?.find((text: any) => text?.langCode?.toLocaleLowerCase() === 'eng');
                    return englishText?.value ?? '-';
                },
            },
        ],
        []
    );

    return (
        <Accordion key={classifier.code}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={ACCORDION_HEAD_STYLE}>
                <Typography fontSize={14}>
                    {classifier.code + (classifier.description ? ' - ' + classifier.description : '')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                {classifier.values && classifier.values.length > 0 ? (
                    <BasicTable data={classifier.values} striped={false} columns={columns} />
                ) : (
                    <Typography sx={{ padding: 2 }}>{t('classifiers.classifier.values-not-found')}</Typography>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default ClassifierItem;

import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder, ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { PaginationProps } from 'types/paginationAndFilter';
import { QueryParams } from '../timecards';
import { objectToQueryParams } from 'utils/linkUtil';

type Options<DataType, TagTypes extends string> = {
    providesTags?:
        | ResultDescription<TagTypes, DataType[], QueryParams, FetchBaseQueryError, FetchBaseQueryMeta | undefined>
        | undefined;
} & PaginationProps;

export const filteredAndSortedQuery = <DataType>(
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        string,
        'portalAPI'
    >,
    endpoint: string,
    options: Options<DataType, string> = {}
) =>
    builder.query<DataType[], Pick<PaginationProps, 'filters' | 'sort'>>({
        query: ({ filters = options.filters ?? '', sort = options.sort ?? '' }) =>
            `${endpoint}${objectToQueryParams({ filter: filters, sort })}`,
        providesTags: options.providesTags,
        ...options,
    });

import { cognitoUser } from './cognitoUser.ts';
import { callback } from './callback.ts';

type UserData =
    | {
          PreferredMfaSetting?: string;
      }
    | undefined;

export const getIsMfa = async () => {
    const userData = await callback<UserData>((callB) => cognitoUser.cognitoUser?.getUserData(callB));
    if (userData) {
        return userData?.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA';
    }
    return false;
};

import Profile from './Profile';
import Notifications from './actions/Notifications';
import Tasks from './actions/Tasks';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    return (
        <>
            {/* <Box sx={{ width: '100%', ml: 1 }} /> */}
            <Tasks />
            <Notifications />
            <Profile />
        </>
    );
};

export default HeaderContent;

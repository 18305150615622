import { usePauseTrackingTimecardMutation, useUpdateTimecardCacheMutation } from 'store/api/timecards';
import { TimecardDetails } from 'types/timecards';

type UsePauseTrackingTimecard = {
    pauseTracking: (timecardId: number) => Promise<TimecardDetails | undefined>;
};

export const usePauseTrackingTimecard = (): UsePauseTrackingTimecard => {
    const [pauseTracking] = usePauseTrackingTimecardMutation();
    const [updateTimecardCache] = useUpdateTimecardCacheMutation();

    const doPauseTracking = async (timecardId: number) => {
        try {
            const result = await pauseTracking(timecardId).unwrap();
            updateTimecardCache(timecardId);
            return result;
            // eslint-disable-next-line no-empty
        } catch {}
    };

    return {
        pauseTracking: doPauseTracking,
    };
};

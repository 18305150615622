import PermissionButton from '../../../../../../../components/buttons/PermissionButton';
import { CheckOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link, Stack, Typography } from '@mui/material';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';

interface AddressedTaskFooterProps {
    status?: string;
    taskId: number;
    handleSwitchTaskStatus: () => void;
    toggleDrawer: () => void;
}

const AddressedTaskFooter: React.FC<AddressedTaskFooterProps> = ({
    status,
    taskId,
    handleSwitchTaskStatus,
    toggleDrawer,
}) => {
    const navigate = useNavigateWithReturnState();

    let displayedButton: React.ReactNode = null;
    switch (status) {
        case 'IN_PROGRESS':
            displayedButton = (
                <PermissionButton
                    permissions={['CREATE.TASKS']}
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={handleSwitchTaskStatus}
                >
                    <CheckOutlined style={{ fontSize: '0.725rem', marginRight: 5 }} />
                    <FormattedMessage id="tasks.view-form.mark-as-done" />
                </PermissionButton>
            );
            break;
        case 'DONE':
            displayedButton = (
                <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        <FormattedMessage id="tasks.view-form.marked-as-done" />
                    </Typography>
                    <PermissionButton
                        permissions={['CREATE.TASKS']}
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={handleSwitchTaskStatus}
                    >
                        <FormattedMessage id="tasks.view-form.open-task" />
                    </PermissionButton>
                </Stack>
            );
    }

    const openEditTaskPage = () => {
        toggleDrawer();
        navigate(`/task/${taskId}`);
    };

    return (
        <Stack
            direction="row"
            component="div"
            sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1, mt: '10px' }}
        >
            <Link
                sx={{
                    color: 'text.link',
                    fontWeight: 400,
                    fontSize: 14,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textDecoration: 'none',
                    ':hover': { textDecoration: 'none' },
                }}
                onClick={openEditTaskPage}
            >
                <FormattedMessage id="tasks.view-form.edit-task" />
            </Link>
            {displayedButton}
        </Stack>
    );
};

export default AddressedTaskFooter;

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

interface Props {
    titleId?: string;
    title?: string;
    icons?: React.ReactNode;
    iconsEnd?: React.ReactNode;
    buttons?: React.ReactNode;
    showBreadcrumb?: boolean;
    children?: React.ReactNode;
}

/**
 * @param titleId - Translation ID of the page title
 */
const PageHeader: React.FC<Props> = (props) => {
    const { children, icons, iconsEnd, buttons, titleId, title, showBreadcrumb = false } = props;

    const theme = useTheme();
    const intl = useIntl();
    const pageTitle = useMemo(() => (titleId ? intl.formatMessage({ id: titleId }) : title), [titleId, title]);

    return (
        <>
            <Helmet>
                {pageTitle ? <title>{pageTitle}</title> : null}
                {showBreadcrumb && pageTitle ? <meta name="breadcrumb" content={pageTitle} /> : null}
            </Helmet>
            <Grid
                item
                sx={{ mt: 0.25, color: theme.palette.primary.main }}
                justifyContent={buttons ? 'space-between' : 'flex-start'}
                container
                direction="row"
                columns={16}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        [theme.breakpoints.down('sm')]: {
                            // flexDirection: 'column',
                        },
                    }}
                    item
                    xs={16}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            display: 'flex',
                            gap: 1.25,
                            alignItems: 'center',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: 24,
                            },
                        }}
                    >
                        {icons ?? null}
                        {pageTitle}
                        {iconsEnd ?? null}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>{buttons ?? null}</Box>
                </Grid>

                <Grid item xs={16}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default PageHeader;

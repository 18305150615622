import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Controller, FieldPathByValue, FieldValues, SetFieldValue, useWatch } from 'react-hook-form';
import { useAutocompleteGroupingV2 } from 'utils/autocompleteGrouping';
import { t } from 'utils/getTranslations';
import { CommonInputProps } from './InputTypes';
import InputWrapper from './InputWrapper';
import { useMemoizedRules } from './useMemoizedRules';
import ReadonlyField from '../rhf-other/ReadonlyField';
import { ListboxComponent, StyledPopper } from '../ListboxComponent';
import { useValidClassifierOptions } from '../../../../utils/useClassifiers.ts';

type GetOptionIdentifier<TOption extends FieldValues> =
    | ((option: TOption) => string | number)
    | FieldPathByValue<TOption, number | string>;
type GetOptionLabel<TOption extends FieldValues> =
    | ((option: TOption) => string)
    | FieldPathByValue<TOption, number | string>;

type GetOptionDescription<TOption extends FieldValues> =
    | ((option: TOption) => string)
    | FieldPathByValue<TOption, number | string>;

interface Props<TFormData extends FieldValues, TOption extends FieldValues> extends CommonInputProps<TFormData> {
    options: TOption[];
    placeholderOnFocus?: string;
    noPreSelect?: boolean;
    clearable?: boolean;
    onChange?: (value: TOption | null) => void;
    onOpen?: (event: React.SyntheticEvent) => void;
    getOptionLabel: GetOptionLabel<TOption>;
    setValue: SetFieldValue<TFormData>;
    getOptionIdentifier: GetOptionIdentifier<TOption>;
    sortDirection?: 'asc' | 'desc';
    groupSortOrder?: string[];
    defaultGroup?: string;
    noOptionsContent?: React.ReactNode;
    readonly?: boolean | { linkTo: string };
    noReadOnlyDivider?: boolean;
    getOptionDescription?: GetOptionDescription<TOption>;
    /** Warning: Only use this variable if you know what you're doing as this CAN break autocomplete dropdown content */
    useVirtualization?: boolean;
}

const SelectInput = <TFormData extends FieldValues, TOption extends FieldValues>({
    name,
    label,
    gap,
    shouldTranslateLabel = true,
    tooltip,
    required,
    rightLabelComponent,
    disabled,
    placeholder,
    shouldTranslatePlaceholder = true,
    placeholderOnFocus,
    wrapperSx,
    control,
    options,
    noPreSelect,
    clearable,
    onChange,
    onOpen,
    getOptionLabel,
    setValue,
    getOptionIdentifier,
    sortDirection,
    groupSortOrder,
    defaultGroup,
    validate,
    noOptionsContent,
    readonly = false,
    noReadOnlyDivider,
    getOptionDescription,
    useVirtualization = false,
}: Props<TFormData, TOption>) => {
    const watchedValue = useWatch({ control, name });

    const validOptions = useValidClassifierOptions(options, watchedValue);

    const initialPlaceholder = placeholder ? (shouldTranslatePlaceholder ? t(placeholder) : placeholder) : t('select');
    const [controlledPlaceholder, setControlledPlaceholder] = useState<string>(initialPlaceholder);
    const shouldPreSelectFirstValue = required && validOptions.length === 1 && !noPreSelect;
    const firstValue = shouldPreSelectFirstValue ? validOptions.at(0) : watchedValue;
    const rules = useMemoizedRules({ required, validate });
    const fallbackTranslation = defaultGroup ?? t('field.other');

    const { hasGroupedOptions, optionsMaybeSorted } = useAutocompleteGroupingV2(validOptions, groupSortOrder);

    const getLabel = (option: TOption | null) => {
        if (!option) {
            return null;
        }

        if (typeof getOptionLabel === 'string') {
            return get(option, getOptionLabel);
        } else {
            return getOptionLabel(option);
        }
    };

    const getValue = (option: TOption | null) => {
        if (!option) {
            return null;
        }

        if (typeof getOptionIdentifier === 'string') {
            return get(option, getOptionIdentifier);
        } else {
            return getOptionIdentifier(option);
        }
    };

    const getDescription = (option: TOption | null) => {
        if (!option) {
            return '';
        }
        if (!getOptionDescription) {
            return '';
        }

        if (typeof getOptionDescription === 'string') {
            return get(option, getOptionDescription);
        } else {
            return getOptionDescription(option);
        }
    };

    const getSortedOptions = (options: TOption[], direction: 'asc' | 'desc' | undefined) => {
        if (direction === undefined) {
            return options;
        }

        return [...options].sort((a, b) => {
            if (direction === 'asc') {
                return String(getLabel(a)).localeCompare(String(getLabel(b)));
            } else {
                return String(getLabel(b)).localeCompare(String(getLabel(a)));
            }
        });
    };

    // preselects the first value if the field is required, has only ONE option & doesn't have "noPreSelect: true" set
    useEffect(() => {
        if (!watchedValue && shouldPreSelectFirstValue && firstValue) {
            if (onChange) {
                onChange(firstValue);
            }
            setValue(name, firstValue);
        }
    }, [name, onChange, setValue, watchedValue, shouldPreSelectFirstValue, firstValue]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { ref, onChange: innerOnChange, onBlur, value, ...field }, fieldState: { error } }) => (
                <InputWrapper
                    name={name}
                    label={label}
                    shouldTranslateLabel={shouldTranslateLabel}
                    tooltip={tooltip}
                    required={required}
                    readonly={!!readonly}
                    rightLabelComponent={rightLabelComponent}
                    hasErrors={!!error}
                    sx={wrapperSx}
                    gap={gap}
                    noReadOnlyDivider={noReadOnlyDivider}
                >
                    {readonly ? (
                        <ReadonlyField
                            value={value}
                            getLabel={getLabel}
                            getValue={getValue}
                            linkTo={typeof readonly === 'object' ? readonly?.linkTo : undefined}
                        />
                    ) : (
                        <Autocomplete
                            id={name}
                            value={value ?? null}
                            fullWidth
                            slotProps={{ paper: { sx: { boxShadow: '0 0 6px 6px rgba(0, 0, 0, 0.10)' } } }}
                            getOptionLabel={
                                typeof getOptionLabel === 'string'
                                    ? (option) => get(option, getOptionLabel)
                                    : getOptionLabel
                            }
                            disablePortal={false}
                            disabled={disabled}
                            options={
                                hasGroupedOptions
                                    ? optionsMaybeSorted
                                    : options
                                      ? getSortedOptions(validOptions, sortDirection)
                                      : []
                            }
                            {...(hasGroupedOptions
                                ? { groupBy: (option: TOption) => option.group ?? fallbackTranslation }
                                : {})}
                            onChange={(_, data) => {
                                innerOnChange(data as any);
                                if (onChange) {
                                    onChange(data);
                                }
                            }}
                            onOpen={onOpen}
                            renderOption={(props, option) => {
                                const { key, ...restProps } = props;
                                return getOptionDescription ? (
                                    <Tooltip
                                        title={getDescription(option)}
                                        key={
                                            typeof getOptionIdentifier === 'string'
                                                ? get(option, getOptionIdentifier)
                                                : getOptionIdentifier(option)
                                        }
                                    >
                                        <Box component="li" {...restProps}>
                                            {getLabel(option)}
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <Box
                                        component="li"
                                        {...restProps}
                                        key={
                                            typeof getOptionIdentifier === 'string'
                                                ? get(option, getOptionIdentifier)
                                                : getOptionIdentifier(option)
                                        }
                                    >
                                        {getLabel(option)}
                                    </Box>
                                );
                            }}
                            disableClearable={(clearable !== undefined && !clearable) || shouldPreSelectFirstValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...field}
                                    placeholder={controlledPlaceholder}
                                    onFocus={() => {
                                        if (placeholderOnFocus) {
                                            setControlledPlaceholder(t(placeholderOnFocus));
                                        }
                                    }}
                                    onBlur={() => {
                                        onBlur();
                                        if (placeholderOnFocus) {
                                            setControlledPlaceholder(initialPlaceholder);
                                        }
                                    }}
                                    error={!!error}
                                    required={required ?? false}
                                    helperText={error?.message}
                                    inputRef={ref}
                                />
                            )}
                            noOptionsText={noOptionsContent ? noOptionsContent : t('no-options')}
                            isOptionEqualToValue={(option, selectedOption) =>
                                getValue(option) === getValue(selectedOption)
                            }
                            ListboxComponent={useVirtualization ? ListboxComponent : undefined}
                            PopperComponent={useVirtualization ? StyledPopper : undefined}
                        />
                    )}
                </InputWrapper>
            )}
        />
    );
};

export default SelectInput;

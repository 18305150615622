import React from 'react';
import { SubSectionProps } from './SubSection';
import { Divider, Grid, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getIdLink } from 'utils/linkUtil';

export type SectionProps = {
    title: string;
    children:
        | (React.ReactElement<SubSectionProps> | React.ReactNode)[]
        | (React.ReactElement<SubSectionProps> | React.ReactNode);

    isHidden?: boolean;
    readonly?: boolean;
    noTranslation?: boolean;
    sx?: SxProps;
    tooltip?: JSX.Element;
};

const Section = ({
    title,
    children,
    isHidden = false,
    readonly = false,
    noTranslation = false,
    sx,
    tooltip,
}: SectionProps) => {
    const theme = useTheme();
    if (isHidden) return null;
    return (
        <Grid
            item
            xs={12}
            container
            sx={{
                pb: readonly ? 2 : '25px',
                pt: readonly ? '3px' : 0,
                px: readonly ? 2 : 0,
                border: readonly ? `1px solid ${theme.palette.divider}` : 'hidden',
                borderRadius: 1,
                ...sx,
            }}
            alignItems="center"
        >
            <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography
                    id={getIdLink(noTranslation ? title.toLocaleLowerCase() : title)}
                    {...(noTranslation ? { 'data-no-translation': noTranslation } : {})}
                    component="div"
                    sx={{
                        my: '9px',
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: 700,
                        scrollMarginTop: '96px',
                    }}
                >
                    {noTranslation ? title : <FormattedMessage id={title} />}
                    {tooltip ? tooltip : null}
                </Typography>
                <Divider sx={{ mb: 1 }} />
            </Grid>
            <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                    {children}
                </Stack>
            </Grid>
        </Grid>
    );
};

Section.displayName = 'Section';

export default Section;

import { useEffect, useMemo, useState } from 'react';
import { useGetClassifiersListQuery } from 'store/api/classifiers';
import { ClassifierData } from 'types/classifiers';
import { FieldValues } from 'react-hook-form';

export const useClassifiers = ({ codes }: { codes: string[] }) => {
    const [classifierObject, setClassifierObject] = useState<Record<string, ClassifierData>>({});
    const [isLoadingObject, setIsLoadingObject] = useState(true);
    const { data: classifiers, isLoading } = useGetClassifiersListQuery();

    const codesStringify = useMemo(() => JSON.stringify(codes), [codes]);
    useEffect(() => {
        if (!classifiers) return;
        const collectedClassifiers = {} as Record<string, ClassifierData>;
        classifiers.forEach((classifier) => {
            if (codes.includes(classifier.code)) {
                collectedClassifiers[classifier.code] = classifier;
            }
        });
        setClassifierObject(collectedClassifiers);
        setIsLoadingObject(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classifiers, codesStringify]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filteredClassifiers = useMemo(
        () => classifiers?.filter((item) => codes.includes(item.code)),
        [classifiers, codesStringify]
    );
    return {
        classifiers: classifierObject,
        classifierArray: filteredClassifiers ?? [],
        allClassifiers: classifiers ?? [],
        isLoading: isLoading || isLoadingObject,
    };
};

export const useValidClassifierOptions = <TOption extends FieldValues>(
    options: TOption[],
    initialWatchedValue: TOption[] | TOption
) => {
    return useMemo(() => {
        const descriptionsSet = new Set(options.map((o) => o.description));

        const mergedOptions = [...options];
        const arrayWatchedValue = Array.isArray(initialWatchedValue) ? initialWatchedValue : [initialWatchedValue];

        for (const watchedValue of arrayWatchedValue) {
            const wvOption = watchedValue as TOption;
            if (wvOption && !descriptionsSet.has(wvOption.description)) {
                mergedOptions.push(wvOption);
                descriptionsSet.add(wvOption.description);
            }
        }

        return mergedOptions;
    }, [options]);
};

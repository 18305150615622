import { SearchOutlined } from '@ant-design/icons';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import { t } from 'utils/getTranslations';

export type Props = OutlinedInputProps & {
    count?: number | string;
    singularTextId?: string;
    pluralTextId?: string;
    placeHolder?: string;
    isInViewMode?: boolean;
};

const SearchBar: React.FC<Props> = ({
    count = 0,
    singularTextId = 'entry',
    pluralTextId = 'entries',
    placeHolder,
    isInViewMode,
    ...other
}) => {
    return (
        <OutlinedInput
            placeholder={
                placeHolder
                    ? placeHolder
                    : t('search-records', {
                          count: String(count),
                          record:
                              count === 1 ? t(singularTextId).toLocaleLowerCase() : t(pluralTextId).toLocaleLowerCase(),
                      })
            }
            startAdornment={<SearchOutlined />}
            disabled={isInViewMode}
            {...other}
        />
    );
};

export default SearchBar;

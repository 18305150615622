// ==============================|| OVERRIDES - BUTTON ||============================== //

import { Theme, alpha } from '@mui/material';

export default function ButtonGroup(theme: Theme) {
    return {
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    gap: '4px',
                    padding: '4px',
                    color: theme.palette.text.primary,
                    boxShadow: `0px 2px 10px ${alpha(theme.palette.common.black, 0.05)})`,
                },
                outlined: {
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '8px',
                },
            },
        },
    };
}

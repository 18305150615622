// material-ui
import { Link, Stack, Typography } from '@mui/material';
import { useGetVersionQuery } from 'store/api/version';
import { t } from 'utils/getTranslations';

const Footer = () => {
    const { isLoading, data: versionString } = useGetVersionQuery();
    if (isLoading) {
        return null;
    }
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: '24px 16px 0px', mt: 'auto' }}
        >
            <Typography variant="caption">&copy; {t('footer.all-rights-reserved')}</Typography>
            <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption">{versionString}</Typography>
                <Link href="https://crespect.com/" target="_blank" variant="caption">
                    {t('footer.about-us')}
                </Link>
                <Link href="https://crespect.com/home/privacy-policy/" target="_blank" variant="caption">
                    {t('footer.privacy-policy')}
                </Link>
                {/* // Hide for now CPD-292
            <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                {t('footer.terms-conditions')}
            </Link> */}
            </Stack>
        </Stack>
    );
};

export default Footer;

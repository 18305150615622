import * as React from 'react';
import { ReactNode, useMemo } from 'react';

// material-ui
import { AppBar, Box, styled, Toolbar } from '@mui/material';

// project import
import HeaderContent from './HeaderContent';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import EnvIndicatorBar from './EnvIndicatorBar.tsx';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
    // header content
    const headerContent = useMemo(() => <HeaderContent />, []);

    // common header
    const mainHeader: ReactNode = (
        <Toolbar sx={{ px: 0, py: 0, minHeight: '40px', height: '40px', display: 'flex', gap: 1 }}>
            {headerContent}
        </Toolbar>
    );

    return (
        <div>
            <EnvIndicatorBar />
            <StyledAppBar position="sticky" color="inherit" elevation={0}>
                <Breadcrumbs />
                <Box>{mainHeader}</Box>
            </StyledAppBar>
        </div>
    );
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        padding: '0px 8px',
    },
}));

export default Header;

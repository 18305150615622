import { ModalFooter } from 'components/modals/ModalFooter';
import { ModalHeader } from 'components/modals/ModalHeader';
import { useForm } from 'react-hook-form';
import { t } from 'utils/getTranslations';

import { Box, CardContent, Grid, Stack, useTheme } from '@mui/material';
import { DocumentTemplateUpdateDto } from '../../types/document-templates.ts';
import CheckboxInput from '../../components/forms/inputs/rhf-inputs/CheckboxInput.tsx';
import TextInput from '../../components/forms/inputs/rhf-inputs/TextInput.tsx';

interface Props {
    titleId?: string;
    onChange: (value: DocumentTemplateUpdateDto) => void;
    onClose: () => void;
    documentTemplate: DocumentTemplateUpdateDto;
}

const DocumentTemplateEditModal = (props: Props) => {
    const { titleId = 'document-template.edit-modal-title', onClose, onChange, documentTemplate } = props;
    const theme = useTheme();

    const defaultValues: DocumentTemplateUpdateDto = {
        name: documentTemplate.name ?? '',
        isDefault: documentTemplate.isDefault ?? false,
        id: documentTemplate.id,
    };

    const { handleSubmit, control } = useForm<DocumentTemplateUpdateDto>({
        defaultValues: defaultValues,
    });

    const handleOnChange = (data: DocumentTemplateUpdateDto) => {
        onChange(data);
        onClose();
    };

    const onSave = async () => {
        await handleSubmit((data) => handleOnChange(data))();
    };

    return (
        <>
            <ModalHeader title={t(titleId)} onCancel={onClose} theme={theme} />
            <CardContent sx={{ padding: '15px 23px 45px', height: '100%', overflowY: 'auto' }}>
                <Stack direction="column" spacing={2}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <TextInput
                                    name="name"
                                    control={control}
                                    label="name"
                                    placeholder="name"
                                    required
                                    inputProps={{ min: 0, max: 60 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item flex={1}>
                                <CheckboxInput
                                    name="isDefault"
                                    control={control}
                                    label={'document-template.is-default'}
                                    readonly={false}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </CardContent>
            <ModalFooter onCancel={onClose} onSave={onSave} theme={theme} />
        </>
    );
};

export default DocumentTemplateEditModal;

import { FieldValues } from 'react-hook-form';
import TextInput, { TextInputProps } from './TextInput';

interface Props {
    allowNegative?: boolean;
}

const DecimalInput = <T extends FieldValues>(props: TextInputProps<T> & Props) => {
    // Backend cannot handle comma value. Lets allow user to use comma but just change it to dot. win-win
    const replaceDotWithComma = (str: string) => {
        return str.replace(/,/g, '.');
    };

    const { allowNegative, ...restProps } = props;

    const transformEventBeforeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): React.ChangeEvent<HTMLInputElement> => {
        if (e?.target?.value) e.target.value = replaceDotWithComma(e.target.value);
        return e;
    };

    return (
        <TextInput
            pattern={`^${allowNegative ? '-?' : ''}[0-9]*[.,]?([0-9]{0,2})?$`}
            {...restProps}
            transformEventBeforeChange={transformEventBeforeChange}
        />
    );
};

export default DecimalInput;

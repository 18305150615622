import React from 'react';
import PaddedLayout from 'layout/MainLayout/PaddedLayout';
import { Box } from '@mui/material';
import ClassifiersList from './ClassifiersList.tsx';

const ClassifiersPage = () => {
    return (
        <PaddedLayout sx={{ overflow: 'hidden' }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <ClassifiersList />
            </Box>
        </PaddedLayout>
    );
};

export default ClassifiersPage;

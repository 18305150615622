import type { Action, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isAction, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { errorAlert, successAlert } from 'utils/alert';

import { cognitoUser } from 'utils/cognitoUser';
import { setSession, userPool } from 'contexts/AWSCognitoContext';
import { disableButtons } from 'store/reducers/buttons';
import { getIntl } from 'utils/getTranslations';
import { callback } from 'utils/callback';
import { EndpointBuilder, TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { PaginationProps } from 'types/paginationAndFilter';
import { objectToQueryParams } from 'utils/linkUtil';
import * as yup from 'yup';

export const getSessionToken = async (): Promise<string | undefined> => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const cognitoSession = await cognitoUser.getSession();

    if (!cognitoSession) {
        if (window.location.pathname !== '/login') {
            logout();
            window.location.href = '/login';
        }
        return undefined;
    }

    if (!cognitoSession?.isValid()) {
        await new Promise((resolve, reject) => {
            cognitoUser.getCognitoUser()?.refreshSession(cognitoSession.getRefreshToken(), (err, session) => {
                if (err) {
                    console.error('Error refreshing the user token: ', err);
                    reject(err);
                }

                cognitoUser.setCognitoUserSession(session);
                resolve(session);
            });
        });
    }

    return cognitoSession.getIdToken().getJwtToken();
};

const UPLOAD_ENDPOINTS = ['sendInvoiceEmailMessage', 'sendCoiCheckEmailMessage'];

const getHeaders = async (
    headers: Headers,
    { endpoint }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => {
    if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
        headers.set('Content-Type', 'application/json');
    }
    headers.set('Accept', 'application/json');

    // By default, if we have a token in the store, let's use that for authenticated requests
    // const cognitoSession = await cognitoUser.getSession();
    const sessionToken = await getSessionToken();

    if (!sessionToken) {
        return headers;
    }

    if (sessionToken) {
        headers.set('authorization', `Bearer ${sessionToken}`);
    }

    return headers;
};

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
    prepareHeaders: getHeaders,
});

const customBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    Record<string, unknown>,
    FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
    // api: { signal, dispatch, getState },

    /* console.log('args: ', args);
    console.log('api: ', api);
    console.log('extraOptions: ', extraOptions); */
    const reqMethod = typeof args === 'object' ? args.method : '';
    const shouldDisableButtons = reqMethod === 'POST' || reqMethod === 'PUT' || reqMethod === 'DELETE';

    try {
        // Disable the submit buttons
        if (shouldDisableButtons) {
            api.dispatch(disableButtons(true));
        }

        const result = await baseQuery(args, api, extraOptions);
        /* if (result.error && result.error.status === 401) {
        const errorCode: number = result?.error?.status;
        switch (errorCode) {
            case 401:
                handle401(result.error);
                break;
            case 404:
                console.error('404 error: ', result.error);
                break;
        }
    } */
        return result;
    } finally {
        // Enable the save buttons again
        if (shouldDisableButtons) {
            api.dispatch(disableButtons(false));
        }
    }
};

// Define a service using a base URL and expected endpoints
export const portalAPI = createApi({
    reducerPath: 'portalAPI',
    baseQuery: customBaseQuery,
    endpoints: () => ({}),
});

export const injectCountEndPoint = <T extends string>(
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        string,
        'portalAPI'
    >,
    basePath: string,
    tagType: TagDescription<T>
) => {
    return builder.query<number, Pick<PaginationProps, 'filters'>>({
        query: ({ filters }) => `${basePath}/count${objectToQueryParams({ filter: filters ?? '' })}`,
        providesTags: [tagType],
        keepUnusedDataFor: 10,
    });
};

const logout = async () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
        setSession(null);
        await callback<void>((callB) => loggedInUser.signOut(callB));
    }
    localStorage.removeItem('cognito_user');
};

/**

 {
 baseUrl: import.meta.env.VITE_API_URL,
 prepareHeaders: getHeaders,
 }

 */

const handle401 = (result: FetchBaseQueryError) => {
    // TODO: implement the 401 error handling here
    // try to get a new token
    /* const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    if (refreshResult.data) {
      // store the new token
      api.dispatch(tokenReceived(refreshResult.data))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(loggedOut())
    } */
    console.error('401 Error: ', result);
};

const ERROR_CODE_MESSAGES: Map<string, string | null> = new Map(
    Object.entries({
        MESSAGE_BUILD_FAILURE: 'email-error.message-build-failure',
        WHITELIST_ERROR: 'email-error.whitelist',
        EMAIL_TRANSPORT_FAILURE: 'email-error.transport-failure',
        INVOICE_SPEC_TEMPLATE_NOT_BOUND: 'biz-error.invoice-spec-not-bound',
        INVOICE_TEMPLATE_NOT_BOUND: 'biz-error.invoice-not-bound',
        INVOICE_TIMECARD_NO_ASSIGNMENT: 'biz-error.invoice-timecard-no-assignment',
    })
);

const API_ERROR_MESSAGES: Map<string, string | null> = new Map(
    Object.entries({
        getCorporateRoleFillingsWithFilters: 'corporate-role.error.fetching',
        updateCorporateRoleFillings: 'corporate-role.error.updating',
        getCompanyById: 'companies.error-fetching-company',
        getCompaniesList: 'companies.error-loading-companies-list',
        getContactById: 'contacts.error-fetching-contact',
        getContactsList: 'contacts.error-loading-contacts-list',
        addContact: 'contacts.error-creating-contact',
        updateContact: 'contacts.error-updating-contact',
        deleteContact: 'contacts.error-deleting-contact',
        addCoiCheck: 'coi.error-creating-coi-check',
        getEffortsList: 'efforts.error.loading-list',
        getEffortById: 'efforts.error.fetching',
        addEffort: 'efforts.error.creating',
        updateEffort: 'efforts.error.updating',
        deleteEffort: 'efforts.error.deleting',
        getGroupsWithFilters: 'groups.error.loading-list',
        getPartyGroupById: 'groups.error.fetching',
        addPartyGroup: 'groups.error.creating',
        updatePartyGroup: 'groups.error.updating',
        deletePartyGroup: 'groups.error.deleting',
        getExpenseById: 'expenses.error.fetching',
        getExpensesList: 'expenses.error.loading-list',
        addExpense: 'expenses.error.creating',
        updateExpense: 'expenses.error.updating',
        deleteExpense: 'expenses.error.deleting',
        getPracticeAreaById: 'practice-areas.error-fetching-practice-area',
        getPracticeAreasList: 'practice-areas.error-loading-practice-areas-list',
        addPracticeArea: 'practice-areas.error-creating-practice-area',
        updatePracticeArea: 'practice-areas.error-updating-practice-area',
        deletePracticeArea: 'practice-areas.error-deleting-practice-area',
        getTeamById: 'teams.error-fetching-team',
        getTeamsList: 'teams.error-loading-teams-list',
        addTeam: 'teams.error-creating-team',
        updateTeam: 'teams.error-updating-team',
        deleteTeam: 'teams.error-deleting-team',
        getEmployeeById: 'employees.error-fetching-employee',
        getEmployeesList: 'employees.error-loading-employees-list',
        addEmployee: 'employees.error-creating-employee',
        updateEmployee: 'employees.error-updating-employee',
        deleteEmployee: 'employees.error-deleting-employee',
        getOfficeById: 'offices.error-fetching-office',
        getOfficesList: 'offices.error-loading-offices-list',
        addOffice: 'offices.error-creating-office',
        updateOffice: 'offices.error-updating-office',
        deleteOffice: 'offices.error-deleting-office',
        getContractById: 'contracts.error-fetching-contract',
        getContractsList: 'contracts.error-loading-contracts-list',
        getContractsListByPartyId: 'contracts.error-loading-contracts-list',
        addContract: 'contracts.error-creating-contract',
        updateContract: 'contracts.error-updating-contract',
        deleteContract: 'contracts.error-deleting-contract',
        getClassifierByCode: 'classifiers.error-fetching-classifier',
        getClassifiersList: 'classifiers.error-loading-classifiers-list',
        getJobRoleById: 'job-roles.error-fetching-job-role',
        getJobRolesList: 'job-roles.error-fetching-job-roles-list',
        addJobRole: 'job-roles.error-creating-job-role',
        updateJobRole: 'job-roles.error-updating-job-role',
        deleteJobRole: 'job-roles.error-deleting-job-role',
        getFinancialConditionById: 'financial-conditions.error-fetching-financial-condition',
        getFinancialConditionsList: 'financial-conditions.error-loading-financial-conditions-list',
        addFinancialCondition: 'financial-conditions.error-creating-financial-condition',
        updateFinancialCondition: 'financial-conditions.error-updating-financial-condition',
        deleteFinancialCondition: 'financial-conditions.error-deleting-financial-condition',
        getMatterById: 'matters.error-fetching-matter',
        getMattersList: 'matters.error-loading-matters-list',
        getMattersListByPartyId: 'matters.error-loading-matters-list',
        addMatter: 'matters.error-creating-matter',
        updateMatter: 'matters.error-updating-matter',
        deleteMatter: 'matters.error-deleting-matter',
        getPricelistById: 'pricelists.error-fetching-pricelist',
        getPricelistsList: 'pricelists.error-loading-pricelists-list',
        addPricelist: 'pricelists.error-creating-pricelist',
        updatePricelist: 'pricelists.error-updating-pricelist',
        updateMatterPricelist: 'pricelists.error-updating-pricelist',
        deletePricelist: 'pricelists.error-deleting-pricelist',
        addTimecard: 'timecards.error-creating-timecard',
        finishTimecard: 'timecards.error-finishing-timecard',
        startTrackingTimecard: 'timecards.error-starting-tracking',
        pauseTrackingTimecard: 'timecards.error-pausing-tracking',
        getTimecardById: 'timecards.error-loading-timecard',
        getTimecardsExportList: 'timecards.error-loading-timecard-export-list',
        getCurrentlyTrackedTimecard: 'timecards.error-loading-timecard-tracked-timecard',
        updateTimecard: 'timecards.error-updating-timecard',
        deleteTimecard: 'timecards.error-deleting-timecard',
        findMatterRates: null,
        findPricelistRates: null,
        createDraftInvoices: null,
        createJointInvoice: null,
        createPrepaidInvoice: 'invoices.error-creating-prepaid-invoice',
        updateInvoice: 'invoices.error-updating-invoice',
        createInvoicePayment: 'invoices.error-adding-invoice-payment',
        deleteInvoicePayment: 'invoices.error-deleting-invoice-payment',
        sendInvoiceEmailMessage: 'email-sending-result.error-sending',
        sendCoiCheckEmailMessage: 'email-sending-result.error-sending',
        getUserData: null,
    })
);

const API_SUCCESS_MESSAGES: Map<string, string | null> = new Map(
    Object.entries({
        updateCorporateRoleFillings: 'corporate-role.success.updating',
        addContact: 'contacts.contact-created',
        updateContact: 'contacts.contact-updated',
        deleteContact: 'contacts.contact-deleted',
        addExpense: 'expenses.success.creating',
        updateExpense: 'expenses.success.updating',
        deleteExpense: 'expenses.success.deleting',
        addEffort: 'efforts.success.creating',
        updateEffort: 'efforts.success.updating',
        deleteEffort: 'efforts.success.deleting',
        addPartyGroup: 'groups.success.creating',
        updatePartyGroup: 'groups.success.updating',
        deletePartyGroup: 'groups.success.deleting',
        addPracticeArea: 'practice-areas.practice-area-created',
        updatePracticeArea: 'practice-areas.practice-area-updated',
        deletePracticeArea: 'practice-areas.practice-area-deleted',
        addEmployee: 'employees.employee-created',
        updateEmployee: 'employees.employee-updated',
        deleteEmployee: 'employees.employee-deleted',
        addOffice: 'offices.office-created',
        updateOffice: 'offices.office-updated',
        deleteOffice: 'offices.office-deleted',
        addTeam: 'teams.team-created',
        updateTeam: 'teams.team-updated',
        deleteTeam: 'teams.team-deleted',
        addJobRole: 'job-roles.job-role-created',
        updateJobRole: 'job-roles.job-role-updated',
        deleteJobRole: 'job-roles.job-role-deleted',
        addFinancialCondition: 'financial-conditions.financial-condition-created',
        updateFinancialCondition: 'financial-conditions.financial-condition-updated',
        deleteFinancialCondition: 'financial-conditions.financial-condition-deleted',
        addContract: 'contracts.contract-created',
        updateContract: 'contracts.contract-updated',
        deleteContract: 'contracts.contract-deleted',
        addMatter: 'matters.matter-created',
        updateMatter: 'matters.matter-updated',
        deleteMatter: 'matters.matter-deleted',
        addPricelist: 'pricelists.pricelist-created',
        updatePricelist: 'pricelists.pricelist-updated',
        updateMatterPricelist: 'pricelists.pricelist-updated',
        deletePricelist: 'pricelists.pricelist-deleted',
        addTimecard: 'timecards.timecard-created',
        finishTimecard: 'timecards.timecard-finished',
        startTrackingTimecard: null,
        pauseTrackingTimecard: null,
        updateTimecardCache: null,
        findMatterRates: null,
        findPricelistRates: null,
        updateTimecard: 'timecards.timecard-updated',
        createDraftInvoices: null,
        createJointInvoice: null,
        createPrepaidInvoice: 'invoices.invoice-prepaid-created',
        updateInvoice: 'invoices.invoice-updated',
        createInvoicePayment: 'invoices.invoice-payment-added',
        deleteInvoicePayment: 'invoices.invoice-payment-deleted',
        deleteTimecard: 'timecards.timecard-deleted',
        sendInvoiceEmailMessage: 'email-sending-result.successfully-sent',
        sendCoiCheckEmailMessage: 'email-sending-result.successfully-sent',
    })
);

interface ErrorAttributes {
    timestamp: Date;
    message: string;
    type: string;
    code: string;
    requestId: string;
}

interface ActionWithData extends Action {
    payload: any;
    meta: any;
}

const errorAttributesSchema: yup.Schema<ErrorAttributes> = yup.object({
    timestamp: yup.date().required(),
    message: yup.string().required(),
    type: yup.string().required(),
    code: yup.string().required(),
    requestId: yup.string().required(),
});

export function errorMessage(payloadData?: any, endpointName?: string) {
    if (payloadData) {
        try {
            const errorAttributes = errorAttributesSchema.validateSync(payloadData);
            const { type, code, message } = errorAttributes;

            if (code) {
                const codeErrorId = ERROR_CODE_MESSAGES.get(code);
                if (codeErrorId) {
                    return getIntl().formatMessage({
                        id: `error-code-message.${codeErrorId}`,
                    });
                }
            }

            if (message && code === 'METHOD_ARGUMENT' && type === 'TECHNICAL') {
                return message;
            }
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                console.error('Error message:', err.message);
            }
        }
    }

    if (!endpointName) {
        return 'technical-error';
    }

    return getIntl().formatMessage({
        id: API_ERROR_MESSAGES.get(endpointName) || 'technical-error',
    });
}

export const rtkQueryErrorHandler: Middleware = (_: MiddlewareAPI) => (next) => (action) => {
    const getErrorMessage = () => {
        if (!isAction(action)) {
            return;
        }
        const payloadData = (action as ActionWithData).payload?.data;
        const endpointName = (action as ActionWithData).meta?.arg?.endpointName;
        return errorMessage(payloadData, endpointName);
    };

    if (
        isFulfilled(action) &&
        action.type === 'portalAPI/executeMutation/fulfilled' &&
        API_SUCCESS_MESSAGES.get((action as ActionWithData).meta.arg.endpointName)
    ) {
        successAlert(
            getIntl().formatMessage({
                id: API_SUCCESS_MESSAGES.get((action as ActionWithData).meta.arg.endpointName) || 'success',
            })
        );
    } else if (
        isRejectedWithValue(action) &&
        (action.type === 'portalAPI/executeMutation/rejected' || action.type === 'portalAPI/executeQuery/rejected') &&
        API_ERROR_MESSAGES.get((action as ActionWithData).meta.arg.endpointName) !== null
    ) {
        const payloadData = (action as ActionWithData)?.payload?.data;
        errorAlert(getErrorMessage() || '', { requestId: payloadData?.requestId });
    }

    return next(action);
};

import React, { useCallback, useMemo } from 'react';

import { Box, Checkbox } from '@mui/material';

import UmbrellaTable from 'components/tables/umbrella/UmbrellaTableWithFilters';
import { getClassifierText, t } from 'utils/getTranslations';
import { Column } from 'react-table';
import {
    useLazyGetDocumentTemplatesWithFiltersQuery,
    useUpdateDocumentTemplateMutation,
} from '../../store/api/document-templates.ts';
import { DocumentTemplateDto, DocumentTemplateUpdateDto } from '../../types/document-templates.ts';
import { useClassifiers } from '../../utils/useClassifiers.ts';
import Loader from '../../components/Loader.tsx';
import PermissionButton from '../../components/buttons/PermissionButton.tsx';
import { FormattedMessage } from 'react-intl';
import DocumentTemplateEditModal from './DocumentTemplateEditModal.tsx';
import { useModal } from '../../components/modals/useModal.ts';
import { errorAlert } from 'utils/alert.ts';

const TeamsTable = () => {
    const [fetchData, { data, isFetching }] = useLazyGetDocumentTemplatesWithFiltersQuery();
    const [updateDocumentTemplate] = useUpdateDocumentTemplateMutation();

    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({ codes: ['DOCUMENT_TEMPLATE_TYPE'] });
    const { openModal, closeModal } = useModal();

    const onFormSubmit = useCallback(
        async (formData: DocumentTemplateUpdateDto): Promise<void> => {
            try {
                if (formData) {
                    updateDocumentTemplate({ ...formData })
                        .unwrap()
                        .then(() => {
                            closeModal();
                            fetchData({});
                        })
                        .catch(console.error);
                }
            } catch (e) {
                console.error('onSubmit error: ', e);
                errorAlert(t('forms.error-submitting-the-form'));
            }
        },
        [errorAlert]
    );

    const openEditDocumentTemplateModal = useCallback(
        (editableEntity: DocumentTemplateUpdateDto): void => {
            openModal(
                <DocumentTemplateEditModal
                    documentTemplate={editableEntity}
                    onChange={(data) => onFormSubmit(data)}
                    onClose={closeModal}
                />,
                closeModal
            );
        },
        [onFormSubmit, openModal, closeModal]
    );

    const columns = useMemo<Column<DocumentTemplateDto>[]>(
        () => [
            {
                Header: t('name'),
                id: 'name',
                disableSortBy: true,
                width: '50%',
                Cell: ({ row }) => {
                    const { id, name } = row.original;
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: 'bold' }}>{name ?? '-'}</Box>
                    );
                },
            },
            {
                Header: t('document-template.document-template-type'),
                accessor: 'documentTemplateType',
                disableSortBy: true,
                width: '25%',

                Cell: ({ value }: any) =>
                    classifiers['DOCUMENT_TEMPLATE_TYPE']
                        ? getClassifierText(classifiers['DOCUMENT_TEMPLATE_TYPE'], value)
                        : '',
            },
            {
                Header: t('document-template.is-default'),
                accessor: 'isDefault',
                disableSortBy: true,
                Cell: ({ value }: any) => <Checkbox checked={!!value} disabled />,
            },
            {
                Header: '',
                id: 'editButton',
                disableSortBy: true,
                Cell: ({ row }) => {
                    const documentTemplateDto = row.original;
                    return (
                        <Box
                            sx={{
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <PermissionButton
                                permissions={['UPDATE.DOCUMENT_TEMPLATE']}
                                variant="outlined"
                                size="extraSmall"
                                onClick={() => {
                                    openEditDocumentTemplateModal({
                                        id: documentTemplateDto.id,
                                        name: documentTemplateDto.name,
                                        isDefault: documentTemplateDto.isDefault,
                                    });
                                }}
                            >
                                <FormattedMessage id="edit" />
                            </PermissionButton>
                        </Box>
                    );
                },
            },
        ],
        [classifiers]
    );

    if (isLoadingClassifiers) {
        return <Loader />;
    }

    return (
        <UmbrellaTable<DocumentTemplateDto>
            columns={columns}
            data={data}
            isLoading={isFetching}
            pluralTextId="document-template.plural"
            singularTextId="document-template.singular"
            noEntriesId="document-template.no-document-templates-found"
            rowsPerPage={50}
            fetchData={fetchData}
        />
    );
};

export default TeamsTable;

import { CSSObject, useTheme } from '@mui/material';
import { ClassifierData } from 'types/classifiers';
import StatusChip from 'components/@extended/StatusChip';

export interface TimecardStatusLabelProps {
    statusCode: string;
    classifier: ClassifierData;
    sx?: CSSObject;
}

export const TimecardEmployeeLabel = ({ name }: { name: string }): JSX.Element => {
    const theme = useTheme();

    return <StatusChip type="info" theme={theme} label={name} />;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimecardStoreProps } from 'types/timecards';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorage';
import { setUserData } from './user';
import { UserData } from 'types/user';

const initialState: TimecardStoreProps = {
    trackedTimecardId: undefined,
    isStopwatchRunning: false,
    draftTimecardsLoading: false,
};

const timecard = createSlice({
    name: 'timecard',
    initialState,
    reducers: {
        trackTimecard(
            state,
            action: PayloadAction<{ timecardId: number; userId: number | null; tenantId: string | null }>
        ) {
            const { timecardId, userId, tenantId } = action.payload;
            state.trackedTimecardId = String(timecardId);
            state.isStopwatchRunning = true;
            setLocalStorage(`${userId}-${tenantId}-tracked-timecard-id`, String(timecardId));
        },
        setStopwatchRunning(state, action: PayloadAction<boolean>) {
            state.isStopwatchRunning = action.payload;
        },
        resetTimecardState(state, action: PayloadAction<{ userId: number | null; tenantId: string | null }>) {
            const { userId, tenantId } = action.payload;
            state.isStopwatchRunning = false;
            state.trackedTimecardId = undefined;
            removeLocalStorage(`${userId}-${tenantId}-tracked-timecard-id`);
        },
        setDraftTimecardsLoading(state, action: PayloadAction<boolean>) {
            state.draftTimecardsLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setUserData, (state, action: PayloadAction<UserData>) => {
            const userId = action.payload.id;
            const tenantId = action.payload.tenants[0];
            state.trackedTimecardId = getLocalStorage(`${userId}-${tenantId}-tracked-timecard-id`);
        });
    },
});

export default timecard.reducer;

export const { trackTimecard, setStopwatchRunning, resetTimecardState, setDraftTimecardsLoading } = timecard.actions;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { Control, UseFormGetValues } from 'react-hook-form';
import { EntityTextDto } from 'types/jobPositions';
import { getClassifierText, t } from 'utils/getTranslations';
import TextInput from '../rhf-inputs/TextInput';
import { ClassifierData } from 'types/classifiers';

type Props = {
    classifiers: Record<string, ClassifierData>;
    control: Control<any>;
    entityTexts: EntityTextDto[];
    getValues: UseFormGetValues<any>;
    readonly?: boolean;
};

const TranslationsInput = ({ classifiers, control, entityTexts, getValues, readonly = false }: Props): JSX.Element => {
    const theme = useTheme();
    const fieldStyles = {
        width: '25%',
        padding: readonly ? undefined : '8px 16px 8px 8px',
    };

    const isValidEntityText = async (value: any, index: number): Promise<true | string> => {
        const entityText: EntityTextDto = getValues(`entityTexts.${index}`);
        const errorMsg = '';

        if (value) {
            return Promise.resolve(true);
        }

        if (!entityText.value && !entityText.textMale && !entityText.textFemale) {
            return Promise.resolve(true);
        }

        if (entityText.value || (entityText.textMale && entityText.textFemale)) {
            return Promise.resolve(true);
        }

        return Promise.resolve(errorMsg);
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                '.MuiTableCell-root': {
                    textTransform: 'none',
                    textAlign: 'left',
                },
                boxShadow: 'none',
                border: `1px solid ${theme.palette.divider}`,
            }}
        >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: '25%' }}>{t('translations.language')}</TableCell>
                        <TableCell sx={{ width: '25%' }}>{t('translations.text')}</TableCell>
                        <TableCell sx={{ width: '25%' }}>{t('translations.text-male')}</TableCell>
                        <TableCell sx={{ width: '25%' }}>{t('translations.text-female')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entityTexts.map((field: EntityTextDto, index: number) => (
                        <TableRow
                            key={`row-${index}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, verticalAlign: 'top' }}
                        >
                            <TableCell sx={{ width: '25%' }}>
                                {getClassifierText(classifiers['LANGUAGE'], field.langCode)}
                            </TableCell>
                            <TableCell sx={fieldStyles}>
                                <TextInput
                                    gap={0}
                                    control={control}
                                    placeholder="forms.enter-text"
                                    name={`entityTexts.${index}.value`}
                                    readonly={readonly}
                                    validate={(val) => isValidEntityText(val, index)}
                                />
                            </TableCell>
                            <TableCell sx={fieldStyles}>
                                <TextInput
                                    gap={0}
                                    control={control}
                                    placeholder="forms.enter-text"
                                    name={`entityTexts.${index}.textMale`}
                                    readonly={readonly}
                                    validate={(val) => isValidEntityText(val, index)}
                                />
                            </TableCell>
                            <TableCell sx={fieldStyles}>
                                <TextInput
                                    gap={0}
                                    control={control}
                                    placeholder="forms.enter-text"
                                    name={`entityTexts.${index}.textFemale`}
                                    readonly={readonly}
                                    validate={(val) => isValidEntityText(val, index)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TranslationsInput;

import { Box, CSSObject, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { dispatch, useSelector } from 'store';
import { closeSubDrawer } from 'store/reducers/subdrawer';
import { t } from 'utils/getTranslations';
import { DrawerFooter } from './DrawerFooter';
import { DrawerHeader } from './DrawerHeader';
import { closeTemporaryDrawer } from 'store/reducers/temporaryDrawer';

type Props = {
    content: React.ReactNode;
    drawerId: string;
    sx?: CSSObject;
    titleId?: string;
    onFooterSecondaryAction?: () => void;
    onFooterPrimaryAction?: () => void;
    applyColor?: 'warning' | 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info';
    afterTitle?: React.ReactNode;
    type: 'sub' | 'temporary';
};

const DrawerPortal: React.FC<Props> = (props) => {
    const {
        content,
        drawerId,
        titleId,
        sx = { width: '310px', height: '100%' },
        onFooterSecondaryAction,
        onFooterPrimaryAction,
        applyColor,
        afterTitle,
        type,
    } = props;
    const theme = useTheme();

    const activeSubDrawerId = useSelector((state) => state.subDrawer.activeDrawerId === drawerId);
    const activeTemporaryDrawerId = useSelector((state) => state.temporaryDrawer.activeDrawerId === drawerId);

    const isOpenSubDrawer = activeSubDrawerId && type === 'sub';
    const isOpenTemporaryDrawer = activeTemporaryDrawerId && type === 'temporary';

    const isOpen = isOpenSubDrawer || isOpenTemporaryDrawer;

    const portalDestination =
        type === 'sub'
            ? document.querySelector('#sub-drawer-portal-destination')
            : document.querySelector('#temporary-drawer-portal-destination');

    const closeDrawer = useCallback(() => {
        if (type === 'sub') dispatch(closeSubDrawer());
        else dispatch(closeTemporaryDrawer());
    }, [type]);

    const handleFooterSecondary = onFooterSecondaryAction
        ? () => {
              closeDrawer();
              onFooterSecondaryAction();
          }
        : undefined;

    const handleFooterPrimary = onFooterPrimaryAction
        ? () => {
              closeDrawer();
              onFooterPrimaryAction();
          }
        : undefined;

    if (portalDestination === null) {
        return null;
    }

    return isOpen
        ? createPortal(
              <Box
                  sx={{
                      ...sx,
                      overflow: 'hidden',
                      display: 'grid',
                      gridTemplateRows: 'auto 1fr auto',
                  }}
              >
                  {titleId ? (
                      <DrawerHeader title={t(titleId)} onCancel={closeDrawer} theme={theme} afterTitle={afterTitle} />
                  ) : null}
                  {content}
                  <DrawerFooter
                      onSecondary={handleFooterSecondary}
                      onPrimary={handleFooterPrimary}
                      theme={theme}
                      applyColor={applyColor}
                  />
              </Box>,
              portalDestination
          )
        : null;
};

export default DrawerPortal;

import { Box, Stack, Theme, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import TimeSinceDate from 'components/util/TimeSinceDate';
import { MessageDetailsDto } from 'types/chat';
import { NotificationItemWrap, StyledButton } from '../Notifications/NotificationItem';
import { FormattedMessage } from 'react-intl';
import StatusChip from 'components/@extended/StatusChip';
import FormattedNotifcationText from '../Notifications/FormattedNotificationText';

interface Props {
    message: MessageDetailsDto;
    theme: Theme;
    handleRead: (idList: number[]) => Promise<void>;
    handleArchive: (idList: number[]) => Promise<void>;
    areButtonsDisabled: boolean;
}

const ChatItem: React.FC<Props> = ({ message, theme, handleArchive, handleRead, areButtonsDisabled }) => {
    const partyName = message.metaDataJson?.party?.name;
    const matterFullName = message.metaDataJson?.matter?.fullName;

    return (
        <NotificationItemWrap isUnread={!message?.readAt} theme={theme}>
            <Stack
                sx={{
                    px: 3,
                    py: 2,
                    gap: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    pl: message?.readAt ? 3 : 5,
                }}
                direction="column"
                component="li"
            >
                <Stack spacing={0.5} width="100%">
                    {message?.isImportant ? (
                        <Box>
                            <StatusChip
                                type="error"
                                sx={{
                                    alignSelf: 'flex-start',
                                }}
                                theme={theme}
                                label={<FormattedMessage id="notifications.high" values={{ count: 0 }} />}
                            />
                        </Box>
                    ) : null}
                    <FormattedNotifcationText notification={message} />
                    <Typography sx={{ fontWeight: message?.readAt ? 400 : 600 }}>
                        {message.manualMessageText}
                    </Typography>
                    <Stack spacing={1} direction="row">
                        {partyName ? <Typography>{`${partyName},`}</Typography> : null}
                        {matterFullName ? <Typography>{matterFullName}</Typography> : null}
                    </Stack>
                    <TimeSinceDate date={message.createdAt} />
                </Stack>
                <Stack direction="row" spacing={0.5}>
                    <StyledButton
                        startIcon={message?.readAt ? null : <CheckIcon fontSize="inherit" color="success" />}
                        variant="outlined"
                        onClick={() => handleRead([message.id])}
                        disabled={areButtonsDisabled}
                    >
                        <FormattedMessage
                            id={message?.readAt ? 'notifications.mark-as-unread' : 'notifications.mark-as-read'}
                        />
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={() => handleArchive([message.id])}
                        disabled={areButtonsDisabled}
                    >
                        <FormattedMessage id="notifications.archive" />
                    </StyledButton>
                </Stack>
            </Stack>
        </NotificationItemWrap>
    );
};

export default ChatItem;

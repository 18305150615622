import { CurrencyData } from './currency';
import { ExpenseCreateFromTimecardDto, ExpenseDto, ExpenseMapList } from './expenses';
import { MatterListItem, MatterListItemMin } from './matters';
import { NamedEntityMinDto } from './other';
import { MatterAssignmentDto } from './assignments';

export interface TimecardListItemData {
    id: number;
    employee: NamedEntityMinDto;
    matter: MatterListItem;
    languageTypeCode: string;
    timecardDate: string;
    description: string;
    timecardStatusTypeCode: string;
    isStopwatchRunning: boolean;
    totalTimeInSeconds: number;
    isNonBillable: boolean;
    billedTimeInSeconds: number;
    timecardStats?: TimecardStats;
    currencyDetails?: CurrencyData;
    practiceAreas: NamedEntityMinDto[];
    isDeletable: boolean;
    isEditable: boolean;
    matterAssignment: MatterAssignmentDto;
    timecardActivityTypeCode: string;
}

export enum TimecardStatusType {
    DRAFT = 'DRAFT',
    DRAFT_INVOICE = 'DRAFT_INVOICE',
    INVOICED = 'INVOICED',
    PAID = 'PAID',
    FINISHED = 'FINISHED',
}

export interface TimecardDto {
    id: number | null;
    description: string;
    isNonBillable: boolean;
    matter: MatterListItemMin | null;
    employee: NamedEntityMinDto | null;
    timecardDate: string | null;
    multiplierTypeCode: string;
    multiplierRate: string;
    practiceAreas: {
        id: number;
        name: string;
    }[];
    totalTimeInSeconds: number | null;
    totalTimeInHours?: string | null;
    billedTimeInSeconds: number | null;
    billedTimeInHours?: string | null;
    expenses?: ExpenseDto[] | ExpenseCreateFromTimecardDto[] | null;
    matterAssignment?: MatterAssignmentDto | null;
    timecardActivityType?: { label: string; value: string } | null;
}

export interface UpdateTimecardsMatter {
    currentMatterId: number;
    newMatterId: number;
    timecardIds: number[];
}

export interface TimecardCreateUpdateDto {
    id: number | null;
    description: string;
    isNonBillable: boolean;
    matter: MatterListItemMin | null;
    employee: NamedEntityMinDto | null;
    timecardDate: string | null;
    multiplierTypeCode: string;
    multiplierRate: string;
    practiceAreas: {
        id: number;
        name: string;
    }[];
    totalTimeInSeconds: number | null;
    totalTimeInHours?: string | null;
    billedTimeInSeconds: number | null;
    billedTimeInHours?: string | null;
    expenses?: ExpenseDto[] | ExpenseCreateFromTimecardDto[] | null;
    matterAssignment: MatterAssignmentDto | null;
    timecardActivityTypeCode: string | null;
}

export interface TimecardDetails extends TimecardListItemData {
    practiceAreas: { id: number; name: string }[];
    multiplierTypeCode: string;
    multiplierRate: string;
    lastStopwatchIntervalInSeconds: number;
    stopwatchRunningSince: string;
    expenses?: ExpenseDto[] | null;
}

export interface TimecardStoreProps {
    trackedTimecardId: string | null | undefined;
    isStopwatchRunning: boolean;
    draftTimecardsLoading: boolean;
}
interface TimecardStats {
    originalCurrencyTypeCode?: string;
    baseCurrencyTypeCode?: string;
    totalTimeInSeconds?: number;
    billedTimeInSeconds?: number;
    totalTimeInHours?: number;
    billedTimeInHours?: number;
    amountInOriginalCurrency?: number;
    amountInBaseCurrency?: number;
    currenthourlyPrice?: number;
    expenseList?: ExpenseMapList;
}

export interface TimecardBasicDto {
    id: number | null;
    matterId: number | null;
    matter: MatterListItemMin | null;
    isNonBillable: boolean | null;
    timecardDate?: string;
    employee?: NamedEntityMinDto;
    description?: string;
    totalTimeInSeconds?: number;
    billedTimeInSeconds?: number;
    timecardActivityTypeCode?: string;
}

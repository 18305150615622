import { MatterStateType } from 'types/matters';

// minimum 1 MatterStateType
const excludeMatters = (stateTypes: [MatterStateType, ...MatterStateType[]]): string => {
    return `matterStateTypeCode=out=(${stateTypes.toString()})`;
};

// minimum 1 MatterStateType
const includeMatters = (stateTypes: [MatterStateType, ...MatterStateType[]]): string => {
    return `matterStateTypeCode=in=(${stateTypes.toString()})`;
};

export const excludeCompletedAndOfferLostFilter = excludeMatters([
    MatterStateType.OFFER_LOST,
    MatterStateType.COMPLETED,
]);

export const includeInProgressAndOfferPending = includeMatters([
    MatterStateType.IN_PROGRESS,
    MatterStateType.OFFER_PENDING,
]);

export const unblockedCasesFilter = 'id=isunblockedcase=true';

export const myCasesFilter = 'id=ismycase=true';

export const openAndUnblockedCasesFilter = `${unblockedCasesFilter};${includeInProgressAndOfferPending}`;

import { Box, Stack, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'store';
import { TimecardDetails } from 'types/timecards';
import { Link } from '@mui/material';
import { t } from 'utils/getTranslations';
import TrackingWidgetControls from './TrackingWidgetControls';
import TimecardTrackingButton from 'pages/timecards/buttons/TimecardTrackingButton';

type Props = {
    timecard: TimecardDetails;
    toggleTimecardModal: () => void;
    closeTimecardModal: () => void;
};

const clampedTextProps: SxProps = {
    whiteSpace: 'normal',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
};

const TrackingWidget = ({ timecard, toggleTimecardModal, closeTimecardModal }: Props) => {
    const drawerOpen = useSelector((state) => state.menu.drawerOpen);
    const isTimecardCenterOpen = useSelector((state) => state.subDrawer.activeDrawerId === 'timecard-center');
    const theme = useTheme();

    return (
        <Box p={drawerOpen ? 2 : 1} sx={{ backgroundColor: '#F7F6F5' }}>
            {drawerOpen ? (
                <Stack>
                    <Typography variant="subtitle2" fontWeight={700} sx={clampedTextProps}>
                        {timecard.description}
                    </Typography>
                    <Typography variant="body2" sx={clampedTextProps}>
                        {timecard.matter.fullName}
                    </Typography>
                    <Typography color={theme.palette.text.secondary} variant="caption">
                        {timecard.matter.clientParty?.name}
                    </Typography>
                    <TrackingWidgetControls timecard={timecard} />
                    <Link
                        pt={2}
                        textAlign="center"
                        onClick={isTimecardCenterOpen ? closeTimecardModal : toggleTimecardModal}
                    >
                        {isTimecardCenterOpen ? t('timecards.hide-timecards') : t('timecards.show-timecards')}
                    </Link>
                </Stack>
            ) : (
                <Stack pt={1}>
                    <TimecardTrackingButton timecard={timecard} />
                    <Link
                        pt={2}
                        textAlign="center"
                        onClick={isTimecardCenterOpen ? closeTimecardModal : toggleTimecardModal}
                    >
                        {isTimecardCenterOpen
                            ? t('timecards.hide-timecards-minimal')
                            : t('timecards.show-timecards-minimal')}
                    </Link>
                </Stack>
            )}
        </Box>
    );
};

export default TrackingWidget;

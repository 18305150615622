import { Stack, Theme, Typography } from '@mui/material';
import Loader from '../../../../../../../components/Loader';
import { useGetTaskByIdQuery, useSwitchTasksStatusMutation } from 'store/api/tasks';
import { getClassifierText, t } from 'utils/getTranslations';
import { useClassifiers } from 'utils/useClassifiers';
import { errorAlert } from 'utils/alert';
import AddressedTaskCreatedInfo from './AddressedTaskCreatedInfo';
import AddressedTaskProperties from './AddressedTaskProperties';
import AddressedTaskUrgencyInfo from './AddressedTaskUrgencyInfo';
import AddressedTaskFooter from './AddressedTaskFooter';
import AddressedTaskHeader from './AddressedTaskHeader';
import { useNavigateWithReturnState } from '../../../../../../../utils/useNavigateWithReturnState';
import AddressedTaskContactMatterInfo from './AddressedTaskContactMatterInfo.tsx';
import { skipToken } from '@reduxjs/toolkit/query';

interface Props {
    addressedTaskId: number | undefined;
    openTaskContext: () => void;
    toggleDrawer: () => void;
    theme: Theme;
}

const AddressedTaskDetailsView: React.FC<Props> = ({ addressedTaskId, openTaskContext, toggleDrawer, theme }) => {
    const navigate = useNavigateWithReturnState();
    const { data: taskData, isLoading: isTaskLoading } = useGetTaskByIdQuery(addressedTaskId?.toString() ?? skipToken);
    const [switchTaskStatus] = useSwitchTasksStatusMutation();
    const { classifiers, isLoading: isLoadingClassifiers } = useClassifiers({
        codes: ['TASK_PRIORITY_TYPE', 'TASK_TYPE', 'TASK_STATUS_TYPE'],
    });

    const assignedToProperty = taskData?.assignedToEmployees ?? [];
    const getTaskTypeProperty = taskData?.taskTypeCodes
        ?.map((code) => getClassifierText(classifiers['TASK_TYPE'], code))
        .sort((a, b) => a.localeCompare(b))
        .join(', ')
        ? [
              taskData.taskTypeCodes
                  .map((code) => getClassifierText(classifiers['TASK_TYPE'], code))
                  .sort((a, b) => a.localeCompare(b))
                  .join(', '),
          ]
        : [];

    const getMatterClientCombinations = (): {
        contactId: number;
        matterId: number | null;
        contactName: string;
        matterName: string | null;
    }[] => {
        if (taskData) {
            const matters = taskData.matters ?? [];
            const contacts = taskData.clientParties ?? [];
            const contactsMap = new Map(contacts.map((contact) => [contact.id, contact.name]));

            const matterCombinations = matters
                .filter((matter) => contactsMap.has(matter.clientPartyId))
                .map((matter) => {
                    const contactName = contactsMap.get(matter.clientPartyId) ?? '';
                    return {
                        contactId: matter.clientPartyId,
                        matterId: matter.id,
                        contactName,
                        matterName: matter.name,
                    };
                });

            const contactOnlyCombinations = contacts
                .filter((contact) => !matters.some((matter) => matter.clientPartyId === contact.id))
                .map((contact) => ({
                    contactId: contact.id,
                    matterId: null,
                    contactName: contact.name,
                    matterName: null,
                }));

            return [...matterCombinations, ...contactOnlyCombinations];
        }
        return [];
    };

    const handleSwitchTaskStatus = async () => {
        try {
            if (taskData?.id) {
                await switchTaskStatus({ id: `${taskData.id}` });
            }
        } catch (e) {
            console.error('onSubmit error: ', e);
            errorAlert(t('forms.error-submitting-the-form'));
        }
    };

    if (isTaskLoading || isLoadingClassifiers) {
        return <Loader />;
    }
    return taskData ? (
        <>
            <Stack
                sx={{
                    px: 2,
                    py: 1,
                    pb: '10px',
                    gap: 2,
                    width: '100%',
                    boxShadow: 'inset 0 -6px 6px rgba(0, 0, 0, 0.05)',
                    overflow: 'auto',
                }}
                direction="column"
                component="div"
            >
                <AddressedTaskHeader openTaskContext={openTaskContext} toggleDrawer={toggleDrawer} />

                <Stack
                    direction="column"
                    component="div"
                    sx={{ width: '100%', borderBottom: `1px solid ${theme.palette.divider}`, gap: 2, pb: '8px' }}
                >
                    <Typography variant="h4">{taskData?.name}</Typography>
                    <Typography>{taskData?.description}</Typography>
                </Stack>

                <AddressedTaskUrgencyInfo taskData={taskData} classifiers={classifiers} theme={theme} />

                <AddressedTaskProperties
                    labelId="tasks.view-form.assigned-to"
                    properties={assignedToProperty}
                    direction="column"
                    theme={theme}
                    onClick={(id) => navigate(`/employee/${id}`)}
                />

                <AddressedTaskProperties
                    labelId="tasks.view-form.type"
                    properties={getTaskTypeProperty}
                    direction="row"
                    useStatusChip={false}
                    useLink={false}
                    theme={theme}
                />

                <AddressedTaskContactMatterInfo
                    labelId="tasks.view-form.cases-clients"
                    properties={getMatterClientCombinations()}
                    direction="column"
                    theme={theme}
                />

                <AddressedTaskCreatedInfo task={taskData} />
            </Stack>
            <AddressedTaskFooter
                status={taskData?.taskStatusTypeCode}
                taskId={taskData.id}
                handleSwitchTaskStatus={handleSwitchTaskStatus}
                toggleDrawer={toggleDrawer}
            />
        </>
    ) : (
        <></>
    );
};

export default AddressedTaskDetailsView;

import { Button, ButtonProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { useHasPermissions } from '../../utils/permissionUtil';
import { FeatureFlag } from '../../types/features';
import { useFeatureFlags } from '../../utils/useFeatureFlags';
import { useSelector } from 'store';

interface Props extends ButtonProps {
    permissions?: string[];
    hasAuthority?: boolean;
    requiredFeature?: FeatureFlag;
}

const PermissionButton: FC<Props> = (props: Props) => {
    const { permissions, hasAuthority = false, requiredFeature, children, ...rest } = props;

    const { isFlowDisabled } = useFeatureFlags(requiredFeature);
    const hasPermissions = useHasPermissions(permissions ?? []);
    const { isExporting } = useSelector((state) => state.exporting);

    const shouldShowButton = useMemo(() => {
        const hasPermission = permissions ? hasPermissions : false;
        return (hasPermission || hasAuthority) && !isFlowDisabled;
    }, [hasAuthority, hasPermissions, isFlowDisabled, permissions]);

    if (!shouldShowButton) {
        return null;
    } else {
        return (
            <>
                <Button {...rest} disabled={isExporting}>
                    {children}
                </Button>
            </>
        );
    }
};

export default PermissionButton;

import { useCallback, useMemo } from 'react';
import { CSSObject, Theme } from '@mui/material';
import { ClassifierData } from 'types/classifiers';
import { getClassifierText } from 'utils/getTranslations';
import { TimecardStatusType } from 'types/timecards';
import StatusChip, { ChipType } from 'components/@extended/StatusChip';

export interface TimecardStatusLabelProps {
    statusCode: string;
    classifier: ClassifierData;
    sx?: CSSObject;
    theme: Theme;
}

export const TimecardStatusLabel = ({ statusCode, classifier, sx, theme }: TimecardStatusLabelProps): JSX.Element => {
    const getLabel = useCallback(() => getClassifierText(classifier, statusCode), [statusCode, classifier]);

    const statusChipType = useMemo<ChipType>(() => {
        switch (statusCode) {
            case TimecardStatusType.DRAFT:
                return 'outlined';
            case TimecardStatusType.FINISHED:
                return 'success';
            default:
                return undefined;
        }
    }, [statusCode]);

    return <StatusChip type={statusChipType} theme={theme} label={getLabel()} sx={sx} />;
};

import { Box, Pagination, Stack } from '@mui/material';
import TimecardList from '../TimecardList';
import { useGetPaginatedTimecardsWithFiltersQuery } from 'store/api/timecards';
import { useSelector } from 'store';
import { useCallback, useEffect, useState } from 'react';
import { GridLoader } from 'pages/dashboard/MyCasesTab';

interface UserDraftTimecardsListProps {
    setNumberOfDrafts?: (numberOfDrafts: number) => void;
    pageSize?: number;
}

const UserDraftTimecardsList = ({ setNumberOfDrafts, pageSize = 5 }: UserDraftTimecardsListProps) => {
    const { id: userId } = useSelector((state) => state.user);

    const { draftTimecardsLoading } = useSelector((state) => state.timecard);

    const [currentPage, setCurrentPage] = useState(1);
    const { data, isFetching } = useGetPaginatedTimecardsWithFiltersQuery({
        pageSize,
        page: currentPage - 1,
        sort: ['timecardDate,desc', 'isStopwatchRunning,desc'],
        filters: `employee.id=in=(${userId});timecardStatusTypeCode=in=(DRAFT)`,
    });

    useEffect(() => {
        if (setNumberOfDrafts) {
            setNumberOfDrafts(data?.totalElements || 0);
        }
    }, [data?.totalElements]);

    const handlePageChange = useCallback((index: number) => {
        setCurrentPage(index);
    }, []);

    if (!data) {
        return null;
    }

    return (
        <Box>
            {isFetching || draftTimecardsLoading ? (
                <GridLoader height={120} numberOfRows={5} />
            ) : (
                <TimecardList timecards={data?.content || []} compact />
            )}
            {data.totalPages > 1 ? (
                <Stack direction="row" justifyContent="flex-end" sx={{ py: '20px', px: '16px' }}>
                    <Pagination
                        count={data.totalPages}
                        page={currentPage}
                        onChange={(_, page) => handlePageChange(page)}
                        variant="contained"
                        shape="rounded"
                    />
                </Stack>
            ) : null}
        </Box>
    );
};

export default UserDraftTimecardsList;

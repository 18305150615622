import React, { ReactNode } from 'react';
import { CardContent, useTheme } from '@mui/material';

import { ModalHeader } from '../ModalHeader';
import { t } from 'utils/getTranslations';
import { ModalFooter } from '../ModalFooter';

export type ConfirmationModalProps = {
    titleId?: string;
    confirmId?: string;
    cancelId?: string;
    content: ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    const { titleId = 'confirmation', confirmId = 'yes', cancelId = 'cancel', content, onCancel, onConfirm } = props;
    const theme = useTheme();
    return (
        <>
            <ModalHeader title={t(titleId)} onCancel={onCancel} theme={theme} />
            <CardContent
                sx={{
                    padding: '24px',
                    overflowY: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        padding: '24px 16px',
                    },
                }}
            >
                {content}
            </CardContent>
            <ModalFooter
                onCancel={onCancel}
                onSave={onConfirm}
                saveButtonTextId={confirmId}
                cancelButtonTextId={cancelId}
                theme={theme}
            />
        </>
    );
};

export default ConfirmationModal;

import { Link, Stack } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useNavigateWithReturnState } from 'utils/useNavigateWithReturnState';

interface AddressedTaskHeaderProps {
    openTaskContext: () => void;
    toggleDrawer: () => void;
}

const AddressedTaskHeader: React.FC<AddressedTaskHeaderProps> = ({ openTaskContext, toggleDrawer }) => {
    const navigate = useNavigateWithReturnState();

    const openTasksPage = () => {
        toggleDrawer();
        navigate('/tasks');
    };

    return (
        <Stack
            direction="row"
            component="div"
            sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <Link
                sx={{
                    color: 'text.link',
                    fontWeight: 400,
                    fontSize: 14,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textDecoration: 'none',
                    ':hover': { textDecoration: 'none' },
                }}
                onClick={openTaskContext}
            >
                <ArrowLeftOutlined style={{ fontSize: '0.625rem', marginRight: 5 }} />
                <FormattedMessage id="tasks.view-form.back" />
            </Link>
            <Link
                sx={{
                    color: 'text.link',
                    fontWeight: 400,
                    fontSize: 14,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textDecoration: 'none',
                    ':hover': { textDecoration: 'none' },
                }}
                onClick={openTasksPage}
            >
                <FormattedMessage id="tasks.view-form.all-tasks" />
            </Link>
        </Stack>
    );
};

export default AddressedTaskHeader;

import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';

export interface StoreProps {
    activeDrawerId: string;
}
const initialState: StoreProps = {
    activeDrawerId: '',
};

const subDrawer = createSlice({
    name: 'subDrawer',
    initialState,
    reducers: {
        toggleSubDrawer: {
            reducer: (state, payload: PayloadAction<{ activeDrawerId: string }>) => {
                if (state.activeDrawerId === payload.payload.activeDrawerId) {
                    state.activeDrawerId = '';
                }
                state.activeDrawerId = payload.payload.activeDrawerId;
            },
            prepare: (activeDrawerId: string) => {
                const id = nanoid();
                return { payload: { id, activeDrawerId } };
            },
        },
        closeSubDrawer(state) {
            state.activeDrawerId = '';
        },
    },
});

export default subDrawer.reducer;

export const { toggleSubDrawer, closeSubDrawer } = subDrawer.actions;

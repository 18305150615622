import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useDebounce } from '@uidotdev/usehooks';
import { useCallback, useEffect, useState } from 'react';
import { PaginationProps, QueryResponse } from 'types/paginationAndFilter';

export interface PaginatedListProps<T> extends PaginationProps {
    fetch: UseLazyQuery<
        QueryDefinition<
            PaginationProps,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
            string,
            QueryResponse<T>,
            'portalAPI'
        >
    >;
    isEnabled?: boolean;
    searchField: string;
    /** defaults to `=lk=` */
    searchComparator?: string;
}

export interface PaginatedListOptionProps {
    getOptionLabel: string | number;
    getOptionIdentifier: string | number;
}

export const usePaginatedList = <TOption>(props: PaginatedListProps<TOption>) => {
    const [fetch, { data, isLoading, isFetching, isError }] = props.fetch();
    const isEnabled = props.isEnabled === undefined ? true : props.isEnabled;
    const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const search = useDebounce(inputValue ?? '', 350);

    const refetch = useCallback(
        async ({ pageIndex = 0, search = '' }: { pageIndex?: number; search?: string }) => {
            if (!isEnabled) {
                return;
            }
            const filters =
                search?.length > 0
                    ? [`${props.searchField}${props.searchComparator ?? '=lk='}'${search}'`, props.filters]
                          .filter(Boolean)
                          .join(';')
                    : props.filters;

            return await fetch(
                {
                    filters,
                    page: pageIndex,
                    pageSize: props.pageSize ?? 50,
                    sort: props.sort,
                },
                false
            );
        },
        [fetch, props.pageSize, props.filters, props.searchField, props.searchComparator, props.sort, isEnabled]
    );

    const initialGet = useCallback(() => {
        setCurrentPageIndex(0);
        refetch({ search, pageIndex: 0 }).finally(() => setIsInitialFetchDone(true));
    }, [refetch, search]);

    useEffect(() => {
        initialGet();
    }, [search, initialGet]);

    useEffect(() => {
        if (!isInitialFetchDone) {
            initialGet();
        }
    }, [isInitialFetchDone, initialGet]);

    const handleLoadMore = useCallback(() => {
        if (data?.last) return;
        refetch({ pageIndex: currentPageIndex + 1, search }).then(() => {
            setCurrentPageIndex(currentPageIndex + 1);
        });
    }, [data, currentPageIndex, refetch, search]);

    const handleSearchChange = useCallback(
        (input: string) => {
            setInputValue(input.replace(/['"]+/g, '\\$&')); // replaces ' and " characters with \' & \" respectively
        },
        [setInputValue]
    );

    return {
        dataToDisplay: (isEnabled ? data?.content || [] : []) as TOption[],
        handleLoadMore,
        hasNextPage: !data?.last || false,
        isLoading: isLoading || isFetching,
        isError,
        handleSearchChange,
    };
};

export const getIdLink = (id: string): string => {
    return id.replace(/\.|\s/g, '-');
};

export const objectToQueryParams = (obj: Record<string, unknown>): string => {
    const sortParams: string[] = [];
    const filteredParams = Object.entries(obj).reduce(
        (currentArray, [key, value]) => {
            if (value !== undefined && value !== '') {
                // Special case to allow multiple sort params (&sort=field1&sort=field2)
                if (key?.trim() === 'sort' && Array.isArray(value)) {
                    value.forEach((v) => {
                        sortParams.push(`sort=${v}`);
                    });
                } else {
                    currentArray[key] = String(value);
                }
            }
            return currentArray;
        },
        {} as Record<string, string>
    );

    const stringParams = new URLSearchParams(filteredParams).toString();
    if (stringParams.length > 0 || sortParams.length > 0) {
        return `?${stringParams}${sortParams.length > 0 ? `&${sortParams.join('&')}` : ''}`;
    }

    return '';
};

interface ParseHashResult {
    [key: string]: string;
}

// Get the hash values from the URL (e.g. crespect.com/login/#access_token=123&token_type=Bearer)
export const parseHash = (hash: string) => {
    const params = new URLSearchParams(hash.substring(1)); // Remove the '#'
    const result: ParseHashResult = {};
    for (const [key, value] of params.entries()) {
        result[key] = value;
    }
    return result;
};
